<template lang="pug">
ja-content(:pageTitle='$t("activity_analyzer")')

  .ActivityReport

    iframe(
      v-for='graph in graphs'
      :src='graph.url'
      :style='{ height: graph.height }'
    )

</template>

<script>
const bertopicUrlBase = 'https://gcp.yamp.app/'

export default {
  name: 'ActivityAnalyzer',

  data () {
    const graphs = {
      word_relevance_visualization: {
        url: `${bertopicUrlBase}word_relevance_visualization.html`,
        height: '534px',
      },
      time_visualization: {
        url: `${bertopicUrlBase}time_visualization.html`,
        height: '484px',
      },
      document_visualization: {
        url: `${bertopicUrlBase}document_visualization.html`,
        height: '784px',
      },
      topic_visualization: {
        url: `${bertopicUrlBase}topic_visualization.html`,
        height: '700px',
      },
    }
    return { graphs }
  },

  computed: {
  },

  methods: {
  }
}
</script>

<style lang="sass" scoped>
.ActivityReport
  display: grid
  gap: 2rem
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr))

  iframe
    background: #fff
    border: none
    width: 100%
    height: 600px
</style>
