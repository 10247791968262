<template lang="pug">
.BlockSocial(:style='cssVars')
  .BlockSocial-ItemGroup
    .BlockSocial-Item(v-if='item.link', v-for='item in items')
      a.BlockSocial-Link(
        target='_blank',
        rel='noopener noreferrer',
        :href='`https://${item.link}`',
        @click='(e) => e.preventDefault()'
      )
        img.BlockSocial-Icon(:src='getIconSrc(item.type)', alt='item.type')
</template>

<script>
import CONFIG_CONST from '@config/config.const'
import { SOCIAL_SIZES } from '@plugins/content-editor/const'

const ALIGNMENT_FLEX = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  full: 'center',
}

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cssVars () {
      const justifyContent = ALIGNMENT_FLEX[this.block.config.alignment]
      const iconsColor = this.block.config.colors.icons
      const iconsSize = SOCIAL_SIZES[this.block.config.size]
      const iconsSpacing = parseInt(this.block.config.spacing) * 2 + 'px'
      const width = this.block.config.alignment === 'full' ? '100%' : 'auto'

      return {
        '--icons-color': iconsColor,
        '--icons-size': iconsSize,
        '--icons-spacing': iconsSpacing,
        '--justify-content': justifyContent,
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
        '--width': width,
      }
    },

    items () {
      return this.block.config.socialLinks
    },
  },

  methods: {
    getIconSrc (type) {
      const { colorScheme, iconStyle } = this.block.config

      return `${CONFIG_CONST.BASE_URL_PUBLIC}/html/social/${type}_${iconStyle}_${colorScheme}.png`
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables

.BlockSocial
  display: flex
  justify-content: var(--justify-content)
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-ItemGroup
    display: flex
    gap: var(--icons-spacing)
    justify-content: space-between
    width: var(--width)

  &-Icon
    margin-right: 0
    display: block
    height: var(--icons-size)
    width: var(--icons-size)

  &-Link
    color: var(--icons-color)
    display: block
    &:hover
      color: var(--icons-color)
</style>
