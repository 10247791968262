import Vue from 'vue'
import { TeamsService } from '../service/teams.service'
import { setQueryParam, useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  appModules: [],
  organization: {},
  projectPermissions: [],
  users: [],
  team: {},
})

// actions
export const addUserToTeam = async (teamId, userId) => {
  return await useLoader(TeamsService.addUser, teamId, userId)
}

export const getTeamById = async (id) => {
  const team = await useLoader(TeamsService.getById, id)
  if (!team) return
  Vue.set(state, 'team', team)
  Vue.set(state, 'organization', team.organization)
  setQueryParam('id_team', state.team.name)
  setQueryParam('id_organization', state.organization.name)
  return team
}

export const createTeam = async (data) => {
  return await useLoader(TeamsService.create, data)
}

export const deleteTeamById = async (id) => {
  return await useLoader(TeamsService.delete, id)
}

export const getTeamProjectPermissions = async (id) => {
  const { projects: projectPermissions } = await useLoader(TeamsService.getProjectPermissions, id)
  if (!projectPermissions) return
  Vue.set(state, 'projectPermissions', projectPermissions)
  return projectPermissions
}

export const getTeamUsersById = async (id) => {
  const users = await useLoader(TeamsService.getUsersById, id)
  if (!users?.items) return
  Vue.set(state, 'users', users)
  return users
}

export const getAppModules = async () => {
  const appModules = await useLoader(TeamsService.getAppModules)
  if (!appModules?.length) return
  Vue.set(state, 'appModules', appModules)
  return appModules
}

export const removeUserFromTeam = async (teamId, userId) => {
  return await useLoader(TeamsService.removeUser, teamId, userId)
}

export const updateTeamById = async (id, data) => {
  return await useLoader(TeamsService.update, id, data)
}

export const updateTeamProjectPermissions = async (permissionUpdates = []) => {
  const updatesPromises = []

  permissionUpdates.forEach(permission => {
    let permissionPromise = null

    if (permission.delete) {
      permissionPromise = useLoader(TeamsService.deleteProjectPermission, state.team.id, {
        module_id: permission.moduleId,
        project_id: permission.projectId,
      })
    } else {
      permissionPromise = useLoader(TeamsService.createProjectPermission, state.team.id, {
        mode: permission.mode,
        module_id: permission.moduleId,
        project_id: permission.projectId,
      })
    }

    updatesPromises.push(permissionPromise)
  })

  return Promise.allSettled(updatesPromises)
}

export default state
