import Vue from 'vue'
import { ProjectsService } from '@apps/Accounts/Organizations/Projects/service/projects.service'
import {
  PrestashopService,
  ShopifyService,
} from '@api/services/connectors.service'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'
import { formatDate } from '@utils'
import { queryGetProducts } from './queries'

// state
const state = Vue.observable({
  audiences: {},
  campaigns: {},
  emailSenders: [],
  integrations: [],
  organization: {},
  project: {},
  smtpProviders: [],
  teams: {},
})

// getters
export const getters = {
  audiences: () => state.audiences,
  campaigns: () => state.campaigns,
  organization: () => state.organization,
  project: () => state.project,
  teams: () => state.teams,
}

// actions
export const addTeamToProject = async (projectId, teamId) => {
  return await useLoader(ProjectsService.addTeam, projectId, teamId)
}

export const createProject = async (data) => {
  return await useLoader(ProjectsService.create, data)
}

export const deleteProjectById = async (id) => {
  return await useLoader(ProjectsService.delete, id)
}

export const deleteProjectMedia = async (id) => {
  return await useLoader(
    ProjectsService.deleteMedia,
    common.user.project_id,
    id
  )
}

export const getProjectById = async (id) => {
  const project = await useLoader(ProjectsService.getById, id)

  Vue.set(state, 'project', project)
  Vue.set(state, 'organization', project.organization)

  setQueryParam('id_project', state.project.name)
  setQueryParam('id_organization', state.organization.name)
}

export const getProjectAudiences = async ({ pagination } = {}) => {
  const audiences = await useLoader(
    ProjectsService.getAudiences,
    common.user.project_id,
    pagination
  )

  if (!audiences?.items) return

  audiences.items = audiences.items.map((audience) => ({
    ...audience,
    created_at: formatDate({
      date: audience.created_at,
    }),
    updated_at: formatDate({
      date: audience.updated_at,
    }),
  }))

  audiences.items.sort((a1, a2) => a2.id - a1.id)

  Vue.set(state, 'audiences', audiences)
}

export const getProjectCampaigns = async ({ bucket, pagination } = {}) => {
  const campaigns = await useLoader(
    ProjectsService.getCampaigns,
    common.user.project_id,
    bucket,
    pagination
  )

  if (!campaigns?.items) return

  campaigns.items = campaigns.items.map((campaign) => ({
    ...campaign,
    created_at: formatDate({
      date: campaign.created_at,
    }),
    updated_at: formatDate({
      date: campaign.updated_at,
    }),
  }))

  Vue.set(state, 'campaigns', campaigns)
}

export const getProjectEmailSenders = async () => {
  const emailSenders = await useLoader(
    ProjectsService.getEmailSenders,
    common.user.project_id
  )

  Vue.set(state, 'emailSenders', emailSenders)
}

export const getProjectIntegrations = async () => {
  const integrations = await useLoader(
    ProjectsService.getIntegrations,
    common.user.project_id
  )

  Vue.set(state, 'integrations', integrations)
}

export const getProjectMedia = async () => {
  return await useLoader(
    ProjectsService.getMedia,
    common.user.project_id
  )
}

export const getProductList = async (data) => {
  if (data.integration.name === 'prestashop') {
    return await getPrestashopProducts(data)
  }
  if (data.integration.name === 'shopify') {
    return await getShopifyProducts(data)
  }
}

export const getPrestashopProducts = async ({
  cursor = '',
  search = '',
  integration,
} = {}) => {
  return await useLoader(
    PrestashopService.getProducts,
    common.user.project_id,
    { integration_id: integration.id, filter: search }
  )
}

export const getShopifyProducts = async ({
  cursor = '',
  search = '',
  integration,
} = {}) => {
  const variables = {}
  variables.query = ''

  const filters = ['product_type', 'title']

  filters.forEach((field) => {
    variables.query += `${field}:*${search}* OR `
  })

  return await useLoader(
    ShopifyService.getProducts,
    common.user.project_id,
    { integration_id: integration.id, query: queryGetProducts, variables }
  )
}

export const getProjectSmtpProviders = async () => {
  const smtpProviders = await useLoader(
    ProjectsService.getSmtpProviders,
    common.user.project_id
  )
  Vue.set(state, 'smtpProviders', smtpProviders)
  return smtpProviders
}

export const getProjectTeamsById = async (id) => {
  const teams = await useLoader(ProjectsService.getTeams, id)
  Vue.set(state, 'teams', teams)
  return teams
}

export const removeTeamFromProject = async (projectId, teamId) => {
  return await useLoader(
    ProjectsService.removeTeam,
    projectId,
    teamId
  )
}

export const updateProjectById = async (id, data) => {
  return await useLoader(ProjectsService.update, id, data)
}

export const uploadProjectMedia = async (data) => {
  return await useLoader(
    ProjectsService.uploadMedia,
    common.user.project_id,
    data
  )
}

export default state
