import { merge } from 'lodash'
import activity from './Activity/activity.lang'
import analytics from './Analytics/analytics.lang'
import audiences from './Audiences/audiences.lang'
import campaigns from './Campaigns/campaigns.lang'
import configurations from './Configurations/configurations.lang'
import templates from './Templates/templates.lang'
import contentEditor from '@plugins/content-editor/lang'

const messages = merge(
  activity,
  analytics,
  audiences,
  campaigns,
  configurations,
  templates,
  contentEditor
)

export default messages
