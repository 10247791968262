import ApiClient from '@api/client'

const client = new ApiClient({ path: '/teams' })

export const TeamsService = {
  /**
   * Add User
   * @param {Number|String} id
   * @param {Object} user
   */
  async addUser (id, user) {
    return await client.post(`/${id}/users/${user}`)
  },

  /**
   * Create Team
   * @param {Object} data
   */
  async create (data) {
    return await client.post('', data)
  },

  /**
   * Create Project Permissions
   * @param {Number|String} id
   * @param {Object} data
   * @return {Array} permissions
   */
  async createProjectPermission (id, data) {
    return await client.post(`/${id}/permissions`, data)
  },

  /**
   * Delete Team
   * @param {Number|String} id
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },

  /**
   * Delete Project Permissions
   * @param {Number|String} id
   * @param {Object} data
   * @return {Array} permissions
   */
  async deleteProjectPermission (id, data) {
    return await client.delete(`/${id}/permissions`, { data })
  },

  /**
   * Get Team By Id
   * @param {Number|String} id
   * @return {Object} team
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },

  /**
   * Get All App Modules
   * @return {Array} team
   */
  async getAppModules (id) {
    return await client.get('/modules')
  },

  /**
   * Get Project Permissions
   * @param {Number|String} id
   * @return {Array} permissions
   */
  async getProjectPermissions (id) {
    return await client.get(`/${id}/permissions`)
  },

  /**
   * Get Users By Team Id
   * @param {Number|String} id
   * @return {Array} users
   */
  async getUsersById (id) {
    return await client.get(`/${id}/users`)
  },

  /**
   * Remove User
   * @param {Number|String} id
   * @param {Object} user
   */
  async removeUser (id, user) {
    return await client.delete(`/${id}/users/${user}`)
  },

  /**
   * Uptade Team
   * @param {Number|String} id
   * @param {Object} data
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
