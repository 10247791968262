<template lang="pug">
ja-content(:pageTitle='$t("projects")' :page-subtitle='pageSubtitle')
  ja-data-table(
    v-if='projects.length',
    :items='projects',
    :fields='fields',
    :pagination='pagination',
    :on-change-page='handleChangePage',
    :on-row-click='(row) => jaDetailView("project", row)'
  )
</template>

<script>
import { formatDate } from '@utils'
import state, {
  getProjectsByOrganizationId,
} from '../Organizations/store/organizations.store'

export default {
  name: 'ProjectList',

  data () {
    const pageSubtitle = 'Organize your campaigns creating projects.'

    const fields = [{ key: 'id', label: 'Id Project' }, 'name', 'updated', 'created']

    return {
      fields,
      pageSubtitle
    }
  },

  computed: {
    projects () {
      return state.projects?.items?.map(({ id, name, created_at, updated_at }) => {
        const created = formatDate(created_at)
        const updated = formatDate(updated_at)
        return { id, name, created, updated }
      }) || []
    },

    pagination () {
      return state.projects?.pagination
    },
  },

  beforeCreate () {
    getProjectsByOrganizationId(this.$route.params.id_organization)
  },

  methods: {
    handleChangePage (page) {
      getProjectsByOrganizationId(this.$route.params.id_organization, {
        pagination: { page },
      })
    },
  },
}
</script>
