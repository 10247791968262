import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { merge } from 'lodash'
import common from '@apps/Common/common.lang'
import accounts from '@apps/Accounts/accounts.lang'
import dymal from '@apps/Dymal/dymal.lang'
import store from 'store2'

Vue.use(VueI18n)

const messages = merge(common, accounts, dymal)

export default new VueI18n({
  locale: store.get('language') || 'en',
  messages,
})
