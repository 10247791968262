<template lang="pug">
.FieldsConfig
  // Button: Choose Integration
  ja-button(
    :on-click='toggleModal',
    :text='$t("update")',
    block,
    icon='mdi mdi-pencil',
    type='outline-primary'
  )

  // Modal
  b-modal(
    ref='modal-fields-config',
    centered,
    modal-class='FieldsConfig-Modal',
    scrollable,
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .FieldsConfig-ModalHeader.text-first-uppercase
        span {{ $t("fields_config") }}

    // Modal Content
    .FieldsConfig-FormGroup(v-for='(fieldRules, fieldName) in value')
      .FieldsConfig-Label {{ $t(fieldName) }}

      // Show
      ja-form-field(
        v-if='fieldName !== "phone"'
        :id='`${fieldName}_show`'
        :label='$t("show")'
        :fieldValue='fieldRules.show'
        :on-change='handleChange'
        label-align='left'
        type='switch'
      )

      // Required
      ja-form-field(
        v-if='fieldName !== "phone" && fieldRules.show'
        :id='`${fieldName}_required`'
        :label='$t("required")'
        :fieldValue='fieldRules.required'
        :on-change='handleChange'
        label-align='left'
        type='switch'
      )

      // RegExp
      ja-form-field(
        v-if='fieldRules.show && fieldRules.required && typeof fieldRules.regexp !== "undefined"'
        :id='`${fieldName}_regexp`'
        :label='$t("regexp")'
        :fieldValue='fieldRules.regexp'
        :on-change='handleChange'
        label-align='left'
        type='text'
      )

      // Link
      ja-form-field(
        v-if='fieldRules.show && typeof fieldRules.link !== "undefined"'
        :id='`${fieldName}_link`'
        :label='$t("link")'
        :fieldValue='fieldRules.link'
        :on-change='handleChange'
        label-align='left'
        type='text'
      )

</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      modalShow: false,
    }
  },

  computed: {

  },

  methods: {
    handleChange (validationValue, validationKey) {
      const newValidationsValue = cloneDeep(this.value)
      const [fieldName, validationName] = validationKey.split('_')

      newValidationsValue[fieldName][validationName] = validationValue

      this.onChange({ value: newValidationsValue })
    },

    toggleModal () {
      this.$refs['modal-fields-config'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
.FieldsConfig
  &-FormGroup
    margin-bottom: 20px

    .FormField
      margin-bottom: 0

  &-Label
    font-weight: 500
    margin: 10px 0 5px
    text-transform: capitalize
</style>
