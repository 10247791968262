import Profile from './Profile/Profile'
import Password from './Password/Password'
import Settings from './Settings/Settings'

// Sidebar Links
import sidebar from './profile.sidebar'
import topbar from '../accounts.topbar'

export default [
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    meta: {
      breadcrumb: [{ name: 'profile' }],
      pageActions: [{ icon: 'ti-save', text: 'update', type: 'outline-primary' }],
      sidebar,
      topbar,
    },
  },
  {
    name: 'password',
    path: '/profile/password',
    component: Password,
    meta: {
      breadcrumb: [{ name: 'profile', hasTo: true }, { name: 'password' }],
      pageActions: [{ icon: 'ti-save', text: 'save', type: 'outline-primary' }],
      parent: 'profile',
      sidebar,
      topbar,
    },
  },
  {
    name: 'settings',
    path: '/profile/settings',
    component: Settings,
    meta: {
      breadcrumb: [{ name: 'profile', hasTo: true }, { name: 'settings' }],
      pageActions: [{ icon: 'ti-save', text: 'save', type: 'outline-primary' }],
      parent: 'profile',
      sidebar,
      topbar,
    },
  },
]
