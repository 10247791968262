<template lang="pug">
.Checkbox
  input(
    :checked='checked'
    :id='value'
    :name='value'
    :required='required'
    @change='handleChange'
    class="material-inputs chk-col-purple"
    type='checkbox'
  )

  label(:for='value')
    i.Checkbox-Icon(v-if='icon', :class='[`${icon}`, label ? "" : "no-margin"]')
    span(v-if='label') {{ label }}
</template>

<script>
export default {
  name: 'Checkbox',

  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    handleChange (event) {
      this.onChange({
        event,
        el: event.target,
        value: this.value,
        checked: event.target.checked,
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.Checkbox
  position: relative

  input[type=checkbox]:not(:checked),
  input[type=checkbox]:checked
    position: absolute
    left: 3px
    top: 8px

  &-Icon
    font-size: 20px
</style>
