<template lang="pug">
.BlockSpacer(:style='cssVars')
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cssVars () {
      return {
        '--height': this.block.config.height + 'px',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.BlockSpacer
  height: var(--height)
</style>
