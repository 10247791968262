export default {
  enabled: true,
  items: [
    {
      name: 'integrations',
      classIcon: 'mdi mdi-connection',
      url: '/configurations/integrations',
      slug: 'integrations',
    },
    {
      name: 'smtp-providers',
      classIcon: 'mdi mdi-email-fast',
      url: '/configurations/smtp-providers',
      slug: 'smtp-providers',
    },
    {
      name: 'email-senders',
      classIcon: 'mdi mdi-card-account-mail',
      url: '/configurations/email-senders',
      slug: 'email-senders',
    },
  ],
}
