import Vue from 'vue'
import { ActivityService } from './activity.service'
import { useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  report: {}
})

export const getActivityReport = async (pagination = { page: 1 }) => {
  pagination.rows = 20

  const response = await useLoader(
    ActivityService.getReport,
    pagination
  )

  if (!response?.length) return

  const report = {
    items: [],
    pagination: {
      first_page: 1,
      last_page: 250,
      per_page: pagination.rows,
      total_items: 5000,
      current_page: pagination.page,
    }
  }

  report.items = response.map(item => {
    let neutral = Math.floor(Math.random() * 5)
    let positive = Math.round(item.content.rating * 2 - (neutral / 2))
    neutral = neutral + positive > 10 ? 10 - positive : neutral
    positive = positive < 0 ? 0 : positive
    let negative = 10 - positive - neutral
    negative = negative < 0 ? 0 : negative

    // console.log({ neutral, positive, negative })

    const sentiment = '🟩'.repeat(positive) + '⬜'.repeat(neutral) + '🟥'.repeat(negative)

    return {
      id: item.id,
      place: item.content.title,
      rating: '⭐'.repeat(item.content.rating),
      rating_num: item.content.rating,
      review_full: item.content.review,
      review: item.content.review.slice(0, 100) + '...',
      sentiment,
    }
  })

  Vue.set(state, 'report', report)
}

export default state
