<template lang="pug">
table.BlockSocial(
  :align='tableAlignment',
  :width='tableWidth',
  :style='tableStyle'
)
  tr
    td.BlockSocial-Item(
      v-for='item in block.config.socialLinks',
      v-if='item.link',
      align='center',
      :style='iconContainerStyle'
    )
      a(:href='`https://${item.link}`')
        template(v-if='format === "html"')
          img.image(
            :src='getIconSrc(item.type)',
            :height='iconSize',
            :width='iconSize'
          )

        template(v-if='format === "amp"')
          amp-img.image(
            :src='getIconSrc(item.type)',
            :height='iconSize',
            :width='iconSize',
            layout='fixed'
          )
</template>

<script>
import CONFIG_CONST from '@config/config.const'
import { SOCIAL_SIZES } from '@plugins/content-editor/const'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
  },

  computed: {
    iconContainerStyle () {
      const margin = this.isFull ? '0 auto' : ''
      const padding = this.isFull ? '' : `0 ${this.block.config.spacing}px`

      return {
        margin,
        padding,
      }
    },

    iconSize () {
      return SOCIAL_SIZES[this.block.config.size]
    },

    isFull () {
      return this.block.config.alignment === 'full'
    },

    items () {
      return this.block.config.socialLinks
    },

    tableAlignment () {
      if (this.isFull) return 'center'
      return this.block.config.alignment
    },

    tableStyle () {
      return {
        textAlign: this.tableAlignment,
        width: this.tableWidth,
      }
    },

    tableWidth () {
      return this.isFull ? '100%' : ''
    },
  },

  methods: {
    getIconSrc (type) {
      const { colorScheme, iconStyle } = this.block.config

      return `${CONFIG_CONST.BASE_URL_PUBLIC}/html/social/${type}_${iconStyle}_${colorScheme}.png`
    },
  },
}
</script>

<style lang="sass" scoped></style>
