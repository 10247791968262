// Components
import CampaignEdit from './Campaigns/CampaignEdit'
import CampaignList from './Campaigns/CampaignList'
import CampaignOverview from './Campaigns/CampaignOverview'
import CampaignReport from './Campaigns/CampaignReport'
import CampaignContentEditor from './Campaigns/CampaignContentEditor'
import CampaignContentPreview from './Campaigns/CampaignContentPreview'

// Sidebar Links
import sidebar from './campaigns.sidebar'

// Topbar Links
import topbar from '../dymal.topbar'

// Parent
const parent = 'campaigns'

// Breacrumb Level
const breadcrumbLevel = ({
  hasParent = false,
  hasTo = false,
  name = '',
  plural = true,
}) => ({ hasParent, hasTo, name, plural })

// Breacrumb Root
const breadcrumbRoot = breadcrumbLevel({
  hasParent: true,
  hasTo: true,
  name: 'campaign',
})

export default [
  // Campaign List (Default)
  {
    name: 'campaigns',
    path: '/campaigns',
    component: CampaignList,
    meta: {
      breadcrumb: [breadcrumbLevel({ name: 'campaign' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_campaign',
        to: '/campaigns/new',
        type: 'outline-secondary',
      },
      pageTitle: 'campaigns',
      sidebar,
      topbar,
    },
  },
  // Campaign Buckets
  {
    name: 'campaigns-bucket',
    path: '/campaigns/bucket/:bucket',
    component: CampaignList,
    meta: {
      breadcrumb: [
        breadcrumbLevel({ name: 'campaign', hasParent: false, hasTo: true }),
        { name: ':bucket' },
      ],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_campaign',
        to: '/campaigns/new',
        type: 'outline-secondary'
      },
      pageTitle: ':bucket',
      parent,
      sidebar,
      topbar,
    },
  },
  // Campaign New
  {
    name: 'campaign-new',
    path: '/campaigns/new',
    component: CampaignEdit,
    meta: {
      breadcrumb: [
        breadcrumbLevel({ name: 'campaign', hasParent: false, hasTo: true }),
        { name: 'new_campaign' },
      ],
      pageTitle: 'new_campaign',
      parent,
      sidebar,
      topbar,
    },
  },
  // Campaign Edit
  {
    name: 'campaign-manage',
    path: '/campaigns/:id_campaign/manage',
    component: CampaignEdit,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'manage_campaign' }],
      parent,
      sidebar,
      topbar,
    },
  },
  // Campaign Report
  {
    name: 'campaign-report',
    path: '/campaigns/:id_campaign/report',
    component: CampaignReport,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'report' }],
      parent,
      sidebar,
      topbar,
    },
  },
  // Campaign Details
  {
    name: 'campaign',
    path: '/campaigns/:id_campaign',
    component: CampaignOverview,
    meta: {
      breadcrumb: [breadcrumbRoot],
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_campaign',
        to: '/campaigns/:id_campaign/manage',
        type: 'outline-secondary'
      },
      pageTitle: ':id_campaign',
      parent,
      sidebar: { ...sidebar, parent: 'campaigns' },
      topbar,
    },
  },
  // Campaign Content Editor
  {
    name: 'campaign-content-editor',
    path: '/campaigns/:id_campaign/content-editor',
    component: CampaignContentEditor,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'content_editor', plural: false }),
      ],
      navbutton: {
        icon: 'ti-eye',
        text: 'preview',
        to: '/campaigns/:id_campaign/preview',
        type: 'info',
      },
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Campaign Content Preview
  {
    name: 'campaign-content-preview',
    path: '/campaigns/:id_campaign/preview',
    component: CampaignContentPreview,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'preview', plural: false }),
      ],
      navbutton: {
        icon: 'ti-pencil',
        text: 'edit_content',
        to: '/campaigns/:id_campaign/content-editor',
        type: 'outline-secondary'
      },
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Redirect: Audience List
  {
    path: '/campaigns/*',
    redirect: '/campaigns',
  },
]
