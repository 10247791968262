<template lang="pug">
router-link.Button(
  :class='["btn", `btn-${type}`, "btn-rounded", "waves-effect", "waves-light", `${disabled ? "disabled" : ""}`, `${block ? "btn-block" : ""}`]',
  :disabled='disabled',
  :to='jaGetRouteWithParams(to)',
  :tag='to ? "a" : "button"',
  @click.native='onClick',
  type='button'
)
  i.Button-Icon(v-if='icon', :class='[`${icon}`, text ? "" : "no-margin"]')
  span.Button-Text.text-first-uppercase(v-if='text') {{ $t(text) }}
</template>

<script>
export default {
  name: 'Button',
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'outline-secondary',
    },
    to: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.Button
  align-items: center
  display: inline-flex
  flex-direction: row
  justify-content: center
  line-height: 1.1
  overflow-x: hidden
  overflow-y: hidden
  text-rendering: optimizeLegibility
  vertical-align: middle
  padding: 9px 18px 7px
  &-Text
    display: block
    flex-basis: auto
    flex-grow: 0
    font-size: 14.5px
    flex-shrink: 1
    font-weight: 500
    text-align: center
    text-overflow: ellipsis
    white-space: nowrap
    -webkit-font-smoothing: antialiased
    @media (max-width: 425px)
      display: none
  &-Icon
    font-weight: 400
    font-size: 15px
    text-align: center
    &.no-margin
      margin-right: 0
    @media (max-width: 425px)
      margin-right: 0

</style>
