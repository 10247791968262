<template lang="pug">
.DataBar
  .card
    .card-body
      .DataBar-Text
        h3(class="font-weight-light") {{ `${value}%` }}
        h5(class="text-muted mb-3") {{ $t(name) }}
      .DataBar-Bar(class="progress")
        .DataBar-ProgressBar(
          aria-valuemax="100"
          aria-valuemin="0"
          :aria-valuenow="value"
          class="progress-bar"
          role="progress"
          :style="`width: ${value}%; height: 6px`"
        )
</template>

<script>
export default {
  name: 'DataBar',
  props: {
    name: { type: String, required: true },
    value: { type: Number, required: true },
  }
}
</script>

<style lang="sass" scoped>
.DataBar
  &-Icon
    background: #9175e7
    line-height: 62px
    min-height: 60px
  &-ProgressBar
    background-color: #b957f6
</style>
