<template lang="pug">
.BlockCallme
  template(v-if='isAmp')
    amp-state(:id='`callme_${block.id}`')
      component(is="script" type='application/json') {{ scriptState }}

    amp-bind-macro(
      :id='`paramsToString_${block.id}`',
      :expression='`keys(callme_${block.id}).reduce((reviewString, key, index, arr) => reviewString + \'"\' + key + \'":"\' + callme_${block.id}[key] + \'"\' + (index < arr.length - 1 ? \',\' : \'}\'), \'{"email": "%email%",\')`'
    )

    .BlockCallme-FormContainer(v-html='formTemplate')

  template(v-if='!isAmp')
    ce-preview-block-button(:block='buttonConfig')
</template>

<script>
import pug from 'pug'
import state from '@plugins/content-editor/state'
import CONFIG_CONST from '@config/config.const'
import formPugTemplate from '!!raw-loader!@plugins/content-editor/components/preview/blocks/Callme/templates/form.pug'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
  },

  data () {
    const isAmp = this.format === 'amp'
    const AMP_EVENTS = state.ampBlockEvents.CALL_ME
    const { BASE_URL_API } = CONFIG_CONST
    const { colors, fontSize, fieldsConfig, integration } = this.block.config
    const blockId = this.block.id
    const scriptState = JSON.stringify({})

    const inputStyle = {
      border: `3px solid ${colors.inputColor}`
    }

    const textStyle = {
      color: colors.text,
    }

    const textLabelStyle = {
      ...textStyle,
      'font-size': fontSize + 'px',
      'font-weight': 'bold',
    }

    const submitStyle = {
      'background-color': colors.buttonBackground,
      color: colors.buttonText,
    }

    const endpoint = {
      url: `${BASE_URL_API}/connectors`,
      method: 'post',
      integration_id: integration?.id || '',
      project_id: this.projectId,
      response: 'form',
    }

    const phoneValidationMessage = this.$t('phone_validation_message')

    const formTemplateVars = {
      ...this.block.content,
      AMP_EVENTS,
      BASE_URL_API,
      blockId,
      endpoint,
      fieldsConfig,
      phoneValidationMessage,
      inputStyle,
      submitStyle,
      textStyle,
      textLabelStyle,
    }

    const formTemplate = pug.render(formPugTemplate, formTemplateVars)

    return {
      isAmp,
      formTemplate,
      scriptState,
    }
  },

  computed: {
    buttonConfig () {
      const { colors, link } = this.block.config

      const config = {
        alignment: 'full',
        bold: true,
        colors: {
          border: colors.border,
          button: colors.buttonBackground,
          text: colors.buttonText,
        },
        fontSize: 18,
        shape: 'round',
      }

      if (link) {
        config.link = link
      }

      const content = this.block.content.fallback || this.block.content.submit

      return {
        config,
        content,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/blocks/callme
</style>
