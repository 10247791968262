/**
 * Transform Performance to show in DataWell
 * @param {Array} projectList
 * @return {Object} Engagement Metrics
 */
export const transformProjectPermissionsForTable = (projectList = []) => {
  return projectList.map(project => {
    const items = project.permissions.map(({ id, mode, module, team_id }) => ({
      id,
      mode,
      module: module.name,
      moduleId: module.id,
      teamId: team_id,
      project: project.name,
      projectId: project.id,
    }))

    return {
      projectId: project.id,
      projectName: project.name,
      permissions: {
        type: 'permission-group',
        items,
      },
    }
  })
}
