<template lang="pug">
ja-content(:pageTitle='organization.name', v-if='organization')

  // Overview
  b-card(:header='$t("overview")')
    b-card-text
      form.form
        // Owner
        ja-form-field(
          label='owner',
          type='info',
          :fieldValue='owner'
        )

        // Created
        ja-form-field(
          label='created_date',
          type='info',
          :fieldValue='createdDate'
        )

        // Plan
        ja-form-field(label='plan' v-if='plan')
          template(v-slot:value)
            span.text-capitalize
              router-link(
                :to='{ name: "organization-plan", params: { id_organization: organization.id } }'
              ) {{ plan.name }}

        // Billing Cycle
        ja-form-field(
          label='billing_cycle',
        )
          template(v-slot:value)
            span.text-capitalize
              router-link(
                :to='{ name: "organization-usage", params: { id_organization: organization.id } }'
              ) {{ billingCycle.dateRange }}
</template>

<script>
import state, { getOrganizationById } from './store/organizations.store'
import { formatDate } from '@utils'

export default {
  name: 'OrganizationOverview',

  data: () => ({
    dataUsage: {
      contacts: 12639,
      sends: 90364,
    },
    modalShow: false,
  }),

  computed: {
    billingCycle () {
      return state.dataUsageBillingCycle
    },

    createdDate () {
      return formatDate({ date: state.organization.created_at, format: 'MONTH_DAY_YEAR' })
    },

    organization () {
      return state.organization
    },

    owner () {
      if (!state.organization?.owner) return ''
      return `${state.organization.owner?.name} (${state.organization.owner?.email})`
    },

    plan () {
      return state.organization.subscription?.plan || false
    },
  },

  beforeCreate () {
    getOrganizationById(this.$route.params.id_organization)
  },
}
</script>
