<template lang="pug">
.DiscountCreator
  .DiscountCreator-Label(v-if='label') {{ label }}

  .DiscountCreator-Config(v-if='value')
    input.DiscountCreator-InputName(
      type='text',
      :placeholder='$t("name")',
      :value='discountName',
      @change='handleChangeName'
    )

    select.DiscountCreator-TypeSelector(
      @change='handleChangeType',
      :value='discountType'
    )
      option(v-for='value in options', :value='value') {{ $t(value) }}

    input.DiscountCreator-InputValue(
      type='number',
      :placeholder='$t("value")',
      :value='discountValue',
      @change='handleChangeValue',
      @keydown='handleKeydownValue'
    )

  button.DiscountCreator-ButtonAdd.btn.btn-outline-primary.btn-block(
    v-else,
    @click='handleClickAdd'
  )
    i.ti-plus
    span.text-first-uppercase {{ $t("add_discount") }}
</template>

<script>
const DISCOUNT_TYPES = ['fixed_amount', 'percentage']

const DISCOUNT_BASE = {
  type: DISCOUNT_TYPES[0],
  value: 0,
}

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [Object, null],
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      options: DISCOUNT_TYPES,
    }
  },

  computed: {
    discountName () {
      return this.value.name || ''
    },
    discountValue () {
      return this.value.value || 0
    },
    discountType () {
      return this.value.type || DISCOUNT_TYPES[0]
    },
  },

  methods: {
    handleClickAdd () {
      this.onChange({ value: DISCOUNT_BASE })
    },

    handleChangeName (event) {
      this.onChange({
        value: {
          ...this.value,
          name: event.target.value,
        },
      })
    },

    handleChangeValue (event) {
      this.onChange({
        value: {
          ...this.value,
          value: parseFloat(event.target.value),
        },
      })
    },

    handleChangeType (event) {
      this.onChange({
        value: {
          ...this.value,
          type: event.target.value,
        },
      })
    },

    handleKeydownValue (event) {
      if (event.keyCode === 69 || event.keyCode === 101) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.DiscountCreator
  &-Config
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 10px

  &-InputName
    grid-column-start: 1
    grid-column-end: 3

  &-InputName,
  &-InputValue
    @include input

  &-TypeSelector
    @include input
    text-transform: capitalize
</style>
