<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card
    b-card-text
      form.form(@submit.prevent='update' ref='form')
        // password
        ja-form-field(
          id="password",
          label="current_password",
          type="password",
          placeholder="current_password_placeholder"
          required
        )
        // New password
        ja-form-field(
          id="new_password",
          label="new_password",
          type="password",
          placeholder="new_password_placeholder"
          required
        )
</template>

<script>
import { AuthService } from '@api/services/auth.service'
import state, { setErrors } from '@apps/Common/common.store'
import { updateUserById } from '@apps/Accounts/Organizations/Users/store/users.store'

export default {
  name: 'Password',

  data () {
    const pageActions = []

    pageActions.push({
      callback: () => this.update(),
      icon: 'ti-save',
      text: 'save',
      type: 'outline-primary',
    })

    return {
      pageTitle: this.$t('password'),
      pageActions,
    }
  },

  computed: {
    user () {
      return state.user || {}
    }
  },

  methods: {
    async update () {
      const { form: formEl } = this.$refs
      formEl.reportValidity()

      if (!formEl.checkValidity()) return

      const formData = new FormData(formEl)
      const { password, new_password } = Object.fromEntries(formData)

      const userLogin = await AuthService.login({
        email: this.user.email,
        password,
      })

      if (!userLogin.success) {
        setErrors({ message: this.$t('current_password_invalid') })
        return
      }

      await updateUserById(this.user.id, {
        name: this.user.name,
        password: new_password
      })

      this.$router.go()
    }
  }
}
</script>
