<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageActions='pageActions')
  .ContentPreviewPage
    // Preview AMP
    .ContentPreviewPage-Preview
      h2 AMP Preview
      ce-content-preview(
        v-if='config',
        :config='config',
        :on-compile-content='(value) => onCompileContent({ format: "amp", value })',
        :projectId='projectId',
        format='amp',
        class=''
      )

    // Preview HTML
    .ContentPreviewPage-Preview
      h2 HTML Preview
      ce-content-preview(
        v-if='config',
        :config='config',
        :on-compile-content='(value) => onCompileContent({ format: "html", value })',
        :projectId='projectId',
        format='html',
        class=''
      )

  // Modal
  b-modal(centered, ref='modal-send-email', v-model='modalShow')
    // Modal Title
    template(v-slot:modal-title)
      i.mdi.mdi-email-send
      span {{ $t("send_email") }}

    // Modal Content
    form.form
      ja-form-field#senderName(
        :on-change='handleChangeEmailSettings',
        :placeholder='$t("sender_name_placeholder")',
        name='senderName',
        type='text'
      )
      ja-form-field#senderEmail(
        :on-change='handleChangeEmailSettings',
        :placeholder='$t("sender_placeholder")',
        name='senderEmail',
        type='email'
      )
      ja-form-field#recipientEmail(
        :on-change='handleChangeEmailSettings',
        :placeholder='$t("recipient_placeholder")',
        name='recipientEmail',
        type='email'
      )
      ja-form-field#subject(
        :on-change='handleChangeEmailSettings',
        :placeholder='$t("subject_placeholder")',
        name='subject',
        type='text'
      )

    // Modal Footer
    template(v-slot:modal-footer)
      ja-button(
        :on-click='sendEmail',
        :text='$t("send")',
        icon='ti-control-play',
        type='primary'
      )
</template>

<script>
import { TemplatesService } from '@apps/Dymal/Templates/Templates/service/templates.service'
import common from '@apps/Common/common.store'

export default {
  name: 'ContentPreviewPage',

  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data () {
    const pageActions = [
      {
        callback: this.modalSend,
        icon: 'ti-email',
        text: 'send_test',
      },
    ]
    return {
      modalShow: false,
      emailSettings: {},
      compiledContent: {
        amp: '',
        html: '',
      },
      pageActions,
    }
  },

  computed: {
    pageTitle () {
      return `${this.$t('preview')}: ${this.title}`
    },

    projectId: () => common.user.project_id,
  },

  methods: {
    modalSend () {
      this.toggleModalSendEmail()
    },

    handleChangeEmailSettings (value, name) {
      this.$set(this, 'emailSettings', { ...this.emailSettings, [name]: value })
    },

    onCompileContent ({ format, value }) {
      this.compiledContent[format] = value
    },

    sendEmail () {
      const { recipientEmail, senderEmail, senderName, subject } =
        this.emailSettings

      const emailData = {
        from: senderEmail,
        from_name: senderName,
        subject,
        contacts: [
          {
            email: recipientEmail,
          },
        ],
        content: {
          ...this.compiledContent,
          text: this.description,
        },
      }

      TemplatesService.sendEmail(emailData)

      this.toggleModalSendEmail()
    },

    toggleModalSendEmail () {
      this.$refs['modal-send-email'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
.ContentPreviewPage
  display: grid
  height: calc(100vh - 300px)
  justify-items: center
  grid-template-columns: 1fr 1fr
  gap: 60px

  &-Preview
    width: 100%
</style>
