<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        // Name
        ja-form-field#name(
          label='name',
          placeholder='name_project_placeholder',
          ref='inputName',
          type='text',
          :fieldValue='projectName',
          :on-change='handleChangeName'
        )

        // Timezone
        ja-form-field#time_zone(
          label='time_zone',
          type='select',
          :fieldValue='timezone',
          :on-change='handleChangeTimeZone',
          :options='timezonesList'
        )

        // Start of week
        ja-form-field#start_week(
          label='start_week',
          type='select',
          :fieldValue='weekStart',
          :on-change='handleChangeWeekDay',
          :options='weekDays',
          :translate='true'
        )
</template>

<script>
import Vue from 'vue'

import { WEEK_DAYS } from '@/const'

import {
  updateProjectById,
  deleteProjectById,
  createProject,
  getters,
  getProjectById,
} from './store/projects.store'

import { getOrganizationById } from '@apps/Accounts/Organizations/Organizations/store/organizations.store'

import commonState, { getTimeZones } from '@apps/Common/common.store'

export default {
  name: 'ProjectEdit',

  data () {
    return {
      pageActions: [],
      pageTitle: this.$t('new_project'),
      projectName: '',
      selectedTimezone: '',
      showForm: false,
      weekDays: WEEK_DAYS,
      weekStart: 0,
    }
  },

  computed: {
    project: getters.project,

    timezone () {
      if (this.isNew) return this.timezonesList[0]?.key || 1

      return this.project.timezone?.id || 1
    },

    timezones () {
      return commonState.timezones
    },

    timezonesList () {
      return this.timezones.map((timezone) => ({
        key: timezone.id,
        text: timezone.text,
      }))
    },

    isNew () {
      return !this.$route.params.id_project
    },
  },

  beforeCreate () {
    getTimeZones()
  },

  async created () {
    // Add action buttons to create a new project
    if (this.isNew) {
      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      // Load the project data
      getOrganizationById(this.$route.params.id_organization)

      return
    }

    // Add action buttons to edit an project
    this.pageActions.push({
      callback: this.delete,
      icon: 'ti-trash',
      text: 'delete',
      type: 'outline-danger',
    })
    this.pageActions.push({
      callback: this.update,
      icon: 'ti-save',
      text: 'update',
      type: 'outline-primary',
    })

    // Load the project data
    await getProjectById(this.$route.params.id_project)

    this.projectName = this.project.name
    this.pageTitle = this.project.name
    this.selectedTimezone = this.timezone
    this.weekStart = this.project.week_start

    this.showForm = true
  },

  mounted () {
    if (this.isNew) {
      this.$refs.inputName.$el.querySelector('input').focus()
    }
  },

  methods: {
    handleChangeName (value) {
      Vue.set(this, 'projectName', value)
    },

    handleChangeTimeZone (value) {
      Vue.set(this, 'selectedTimezone', value)
    },

    handleChangeWeekDay (value) {
      Vue.set(this, 'weekStart', value)
    },

    async delete () {
      await deleteProjectById(this.project.id)
      this.$router.push({ name: 'projects' })
    },

    async save () {
      const project = await createProject({
        name: this.projectName,
        organization_id: this.$route.params.id_organization,
        timezone_id: this.selectedTimezone,
        week_start: this.weekStart,
      })

      if (!project) return

      // Go to the new project page
      this.$router.push({
        name: 'project',
        params: { id_project: project.id },
      })
    },

    async update () {
      await updateProjectById(this.project.id, {
        name: this.projectName,
        timezone_id: this.selectedTimezone,
        week_start: this.weekStart,
      })

      // Go back the project overview page
      this.$router.push({
        name: 'project',
        params: { id_project: this.project.id },
      })
    },

    submit () {
      if (this.isNew) this.save()
      else this.update()
    },
  },
}
</script>
