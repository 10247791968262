<template lang="pug">
ja-page(
  :breadcrumb='$route.meta.breadcrumb'
  :errors='errors'
  :loading='loading'
  :navbutton='navbuttonEnabled && $route.meta.navbutton'
  :sidebar-enabled='$route.meta.sidebar.enabled'
  :vars='queryParams'
  :on-discard-alert='handleDiscardAlert'
)
</template>

<script>
import state, { removeError } from '@apps/Common/common.store'

export default {
  name: 'LayoutDefault',

  computed: {
    errors () {
      return state.errors
    },
    loading () {
      return state.loading
    },
    navbuttonEnabled () {
      return state.navbuttonEnabled
    },
    queryParams () {
      return state.queryParams
    },
  },

  methods: {
    handleDiscardAlert (uid) {
      removeError(uid)
    }
  }
}
</script>
