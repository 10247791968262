<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        // Name
        ja-form-field#name(
          :field-value='templateName',
          :on-change='handleTemplateName',
          ref='inputName',
          label='name',
          type='text',
          placeholder='name_template_placeholder'
        )

        // Description
        ja-form-field#description(
          :field-value='templateDescription',
          :on-change='handleDescriptionName',
          label='description',
          type='text',
          placeholder='description_template_placeholder'
        )

        // Content
        ja-form-field(v-if='!isNew', label='content')
          template(v-slot:value)
            ja-template-card(
              :image='template.preview',
              :to='templateEditorRoute',
              :overlay-text='$t("edit_content")'
            )
</template>

<script>
import state, {
  updateTemplateById,
  removeTemplateById,
  resetTemplateClone,
  createTemplate,
  getTemplateById,
} from './store/templates.store'

import contentSample from './content-sample'

export default {
  name: 'ProjectEdit',

  data () {
    return {
      templateName: '',
      templateDescription: '',
      projectName: '',
      pageActions: [],
      pageTitle: this.$t('new_template'),
      showForm: false,
    }
  },

  computed: {
    template: () => state.template,

    templateEditorRoute () {
      return {
        name: 'template-content-editor',
        params: { id_template: this.$route.params.id_template },
      }
    },

    isClone () {
      return !!state.templateClone
    },

    isNew () {
      return !this.$route.params.id_template
    },
  },

  async created () {
    // Add action buttons to create a new organization
    if (this.isClone) {
      this.templateDescription = state.templateClone.description
      this.pageTitle = this.templateName = `Copy of ${state.templateClone.name}`
    }

    if (this.isNew) {
      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })
      this.showForm = true
      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push(
      {
        callback: this.delete,
        icon: 'ti-trash',
        text: 'delete',
        type: 'outline-danger',
      },
      {
        callback: this.update,
        icon: 'ti-save',
        text: 'update',
        type: 'outline-primary',
      }
    )

    // Load the organization data
    await getTemplateById(this.$route.params.id_template)

    // await getOrganizationById(this.$route.params.id_organization)
    this.templateName = this.template?.name
    this.templateDescription = this.template?.description
    this.pageTitle = this.template?.name
    this.showForm = true
  },

  mounted () {
    if (this.isNew) {
      this.$refs.inputName.$el.querySelector('input').focus()
    }
  },

  beforeDestroy () {
    if (this.isClone) {
      resetTemplateClone()
    }
  },

  methods: {
    handleTemplateName (value) {
      this.templateName = value
    },

    handleDescriptionName (value) {
      this.templateDescription = value
    },

    async delete () {
      await removeTemplateById(this.template.id)
      this.$router.push({ name: 'templates' })
    },

    async save () {
      const templateData = {}

      templateData.name = this.templateName
      templateData.description = this.templateDescription
      templateData.config = JSON.stringify(contentSample)

      if (this.isClone) {
        templateData.config = JSON.stringify(state.templateClone.config)
        templateData.content_amp = state.templateClone.content_amp
        templateData.content_html = state.templateClone.content_html
        templateData.preview = state.templateClone.preview
      }

      const newTemplate = await createTemplate(templateData)

      if (!newTemplate) return

      // Go to the new template page
      this.$router.push({
        name: 'template',
        params: { id_template: newTemplate.id },
      })
    },

    async update () {
      await updateTemplateById(this.template.id, {
        ...this.template,
        config: JSON.stringify(this.template.config),
        name: this.templateName,
        description: this.templateDescription,
      })

      this.$router.push({
        name: 'template',
        params: { id_template: this.template.id },
      })
    },

    submit () {
      if (this.isNew) this.save()
      else this.update()
    },
  },
}
</script>
