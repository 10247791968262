<template lang="pug">
ja-content(:pageTitle='$t("audiences")')
  ja-data-table(
    v-if='audiences.length'
    :fields='fields',
    :items='audiences',
    :pagination='pagination',
    :on-change-page='handleChangePage',
    :on-row-click='(row) => jaDetailView("audience", row)'
  )
</template>

<script>
import { deleteAudienceById } from './store/audiences.store'

import projectState, {
  getProjectAudiences,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

export default {
  name: 'AudienceList',

  data () {
    return {
      fields: ['id', 'name', 'contacts', 'updated_at', 'created_at'],
      rowButtons: [
        {
          icon: 'ti-trash',
          type: 'outline-danger',
          onClick: (audience) => this.deleteAudience(audience),
        },
      ],
    }
  },

  computed: {
    audiences () {
      return projectState?.audiences?.items || []
    },

    pagination () {
      return projectState?.audiences?.pagination
    },
  },

  beforeCreate () {
    getProjectAudiences()
  },

  methods: {
    async deleteAudience ({ id }) {
      await deleteAudienceById(id)
      await getProjectAudiences()
    },

    handleChangePage (page) {
      getProjectAudiences({ pagination: { page } })
    },
  },
}
</script>
