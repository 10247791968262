<template lang="pug">
ja-content(:pageTitle='$t("integrations")')
  // Apps
  h2.text-first-uppercase {{ $t("apps") }}
  .IntegrationDeck
    b-card.IntegrationCard(
      v-for='(app, key) in apps',
      :key='key'
      @click='() => handleClickApp(app)'
    )
      b-card-img.IntegrationCard-Image(:src='getImageSrc(key)')
      b-card-title.IntegrationCard-Title {{ app.name }}
      .IntegrationCard-Tick(v-if='app.config')
        i.mdi.mdi-check-circle

  // Webhooks
  template(v-if='webhooks.length')
    h2.text-first-uppercase {{ $t("webhooks") }}
    .IntegrationDeck
      b-card.IntegrationCard(
        v-for='(webhook, key) in webhooks',
        :key='key'
        @click='() => handleClickWebhook(webhook)'
      )
        b-card-img.IntegrationCard-Image(:src='getImageSrc("webhook")')
        b-card-title.IntegrationCard-Title {{ webhook.name }}
</template>

<script>
import config from '@config/config.const'
import { cloneDeep } from 'lodash'
import { INTEGRATION_APP_LIST } from '@/const'

import projectState, {
  getProjectIntegrations,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

import state from './store/integrations.store'

export default {
  name: 'IntegrationList',

  computed: {
    integrations: () => projectState.integrations,

    apps () {
      const apps = cloneDeep(INTEGRATION_APP_LIST)

      this.integrations
        .filter((integration) => integration.category === 'app')
        .forEach((integration) => {
          apps[integration.name] = integration
        })

      return apps
    },

    webhooks () {
      if (!this.integrations.length) return []

      return this.integrations.filter(
        (integration) => integration.category === 'webhook'
      )
    },
  },

  beforeCreate () {
    getProjectIntegrations()
  },

  methods: {
    getImageSrc (name) {
      return `${config.BASE_URL_PUBLIC}/img/icons/${name}.png`
    },

    handleClickApp (app) {
      if (app.config) {
        this.$router.push({
          name: 'integration-app-manage',
          params: { app_name: app.name, id_integration: app.id },
        })
      } else {
        this.$set(state, 'integration', app)
        this.$router.push({
          name: 'integration-app-install',
          params: { app_name: app.name },
        })
      }
    },

    handleClickWebhook (webhook) {
      this.$router.push({
        name: 'integration-webhook-manage',
        params: { id_integration: webhook.id },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
$card: ".IntegrationCard"
$pink: #ce3fd0
$purple: #6330e3

#{$card}

.IntegrationDeck
  display: grid
  gap: 1.5rem
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr))

#{$card}
  position: relative

  &-Image
    padding:20px

  &-Tick
    bottom: 5px
    color: $purple
    font-size: 20px
    position: absolute
    right: 0px

  &-Title
    margin-top: 20px
    text-align: center
    text-transform: capitalize

  &:hover
    border: 1px solid $purple
    cursor: pointer

    #{$card}-Text, #{$card}-Title
      color: $purple
</style>
