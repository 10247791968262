<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageSubtitle='pageSubtitle')

  // Performance Indicators
  h2 {{ $t("performance") }}

  ja-performance(
    :metrics='performance'
    :default-metrics='defaultMetrics'
  )

  //- Growth
    h2 {{ $t("growth") }}
    ja-apex-chart(
      :categories='growth.months',
      :series='growth.values',
      type='bar'
    )
</template>

<script>
import state, { getAudienceById } from './store/audiences.store'
import { DEFAULT_METRICS_PERFORMANCE } from '@/const/analytics'

export default {
  name: 'AudienceOverview',

  data () {
    return {
      growth: {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        values: [
          { name: this.$t('contacts'), data: [250, 367, 490, 596, 709, 662] },
        ],
      },
    }
  },

  computed: {
    audience: () => state.audience,

    defaultMetrics () {
      return DEFAULT_METRICS_PERFORMANCE
    },

    pageSubtitle () {
      return this.audience?.description
    },

    pageTitle () {
      return this.audience?.name
    },

    performance () {
      return state.performance
    },
  },

  beforeCreate () {
    getAudienceById(this.$route.params.id_audience)
  },
}
</script>
