import { isAuthenticated } from '@utils'
import state, { getCurrentUser, setUser, logout } from '@apps/Common/common.store'

/**
 * Login Guard
 */
export const loginGuard = async (to, _from, next) => {
  const isAuthRoute = !!to.meta.isAuth

  // User is not authenticated
  // and goes to a not login route
  if (
    !isAuthenticated() &&
    !isAuthRoute
  ) {
    return next({ name: 'login' })
  }

  // User is authenticated
  // and goes to a login route
  if (
    isAuthenticated() &&
    isAuthRoute
  ) {
    await logout()
    return next()
  }

  // User is authenticated
  // and the state is empty or invalid (logged user is not the same)
  if (
    isAuthenticated() &&
    (!state.user || state.user.id !== store.get('user_id'))
  ) {
    const user = await getCurrentUser()

    if (!user) {
      return next({ name: 'login' })
    }

    setUser(user)
  }

  // User is authenticated
  // and this doesn't have an active project
  if (
    isAuthenticated() &&
    !state.user.project &&
    to.name !== 'project-selection'
  ) {
    return next({ name: 'project-selection' })
  }

  // Continue
  next()
}
