<template lang="pug">
.IntegrationDeck
  b-card.IntegrationCard(
    v-for='(integration, key) in integrations',
    :key='key'
    :class='{ "IntegrationCard--selected": isSelected(integration) }',
    @click='() => handleClick(integration)'
  )
    b-card-img.IntegrationCard-Image(:src='integration.image')
    b-card-title.IntegrationCard-Title {{ integration.name }}
</template>

<script>
export default {
  props: {
    integrations: {
      type: Array,
      required: true,
    },
    value: {
      type: [Object, null],
      default: null,
    },
  },

  methods: {
    isSelected (item) {
      return this.value?.id === item.id
    },

    handleClick (item) {
      this.$emit('input', item)
    },
  },
}
</script>

<style lang="sass">
@import @plugins/content-editor/styles/mixins

.IntegrationDeck
  display: grid
  gap: 1.5rem
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr))
  padding-left: 30px
  padding-right: 30px
  padding-top: 30px

.IntegrationCard
  position: relative
  transition: all .15s ease-in-out

  &:hover,
  &--selected
    outline: 3px solid $purple

  &:hover
    cursor: pointer

  &-Image
    padding:20px

  &-Title
    margin-top: 20px
    text-align: center
    text-transform: capitalize
</style>
