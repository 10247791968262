<template lang="pug">
ja-content(:page-title='team.name')
  // Permissions
  h2 {{ $t("permissions") }}
  ja-table(
    :fields='projectPermissions.fields',
    :items='projectPermissions.items'
    :on-click-row='onClickPermissionTableRow'
  )

  // Team Permission Manager (Modal)
  team-permission-manager(
    ref='team-permission-manager'
    v-if='selectedProjectPermissions'
    :app-modules='appModules'
    :on-save='saveTeamPermissionsUpdates'
    :project='{ name: selectedProjectPermissions.projectName, id: selectedProjectPermissions.projectId }'
    :permissions='selectedProjectPermissions.permissions.items'
    :team='team.name'
  )

  // Users
  h2 {{ $t("users") }}
  ja-table(
    :fields='userTable.fields',
    :headButtons='userTable.headButtons',
    :items='users',
    :rowButtons='userTable.rowButtons'
  )

  // Team User Assinger (Modal)
  team-user-assigner(
    ref='team-user-assinger'
    v-if='assignableUsers.length'
    :users='assignableUsers'
    :on-assign-user='assignUser'
  )

</template>

<script>
import state, {
  addUserToTeam,
  getAppModules,
  getTeamById,
  getTeamProjectPermissions,
  getTeamUsersById,
  removeUserFromTeam,
  updateTeamProjectPermissions,
} from './store/teams.store'

import organizationState, {
  getUsersByOrganizationId,
} from '@apps/Accounts/Organizations/Organizations/store/organizations.store'

import TeamPermissionManager from './components/TeamPermissionManager.vue'
import TeamUserAssigner from './components/TeamUserAssigner.vue'

import { transformProjectPermissionsForTable } from '@/data-transformers/permissions'

export default {
  name: 'TeamOverview',

  components: { TeamPermissionManager, TeamUserAssigner },

  data () {
    const { id_team: idTeam, id_organization: idOrganization } = this.$route.params

    return {
      idTeam,
      idOrganization,
      selectedProjectPermissions: null,
      modalShow: false,
      assignableUsers: [],
      userTable: {
        fields: ['id', 'name', 'email'],
        headButtons: [
          {
            icon: 'ti-plus',
            text: 'add_user',
            onClick: () => this.onClickAssignUser(),
          },
        ],
        rowButtons: [
          {
            icon: 'ti-trash',
            type: 'outline-danger',
            onClick: (user) => this.onClickUnassignUser(user.id),
          },
        ],
      },
    }
  },

  computed: {
    appModules () {
      return state.appModules
    },

    projectPermissions () {
      return {
        fields: [{ key: 'projectName', label: 'project' }, 'permissions'],
        items: transformProjectPermissionsForTable(state.projectPermissions)
      }
    },

    team () {
      return state.team
    },

    users () {
      return state.users?.items || []
    },
  },

  beforeCreate () {
    const { id_team: idTeam } = this.$route.params
    getAppModules()
    getTeamById(idTeam)
    getTeamProjectPermissions(idTeam)
    getTeamUsersById(idTeam)
  },

  methods: {
    async assignUser (selectedUser) {
      this.toggleModalAssignUser()

      await addUserToTeam(this.idTeam, selectedUser)

      await getTeamUsersById(this.idTeam)
    },

    async onClickAssignUser () {
      await getUsersByOrganizationId(this.idOrganization)

      const assignableUsers = organizationState.users.items
        .filter((user) => !this.users.map((u) => u.id).includes(user.id))
        .map((user) => ({
          key: user.id,
          text: user.name,
        }))

      await this.$set(this, 'assignableUsers', assignableUsers)

      this.toggleModalAssignUser()
    },

    async onClickUnassignUser (userId) {
      await this.unassignUser(userId)
      await getTeamUsersById(this.idTeam)
    },

    async onClickPermissionTableRow (item) {
      await this.$set(this, 'selectedProjectPermissions', item)
      this.toggleModalPermissionManager()
    },

    async saveTeamPermissionsUpdates (updates) {
      this.toggleModalPermissionManager()
      if (!updates.length) return
      await updateTeamProjectPermissions(updates)
      await getTeamProjectPermissions(this.idTeam)
    },

    toggleModalPermissionManager () {
      this.$refs['team-permission-manager'].toggle()
    },

    toggleModalAssignUser () {
      this.$refs['team-user-assinger'].toggle()
    },

    async unassignUser (userId) {
      await removeUserFromTeam(this.idTeam, userId)
    },
  },
}
</script>
