<template lang='pug'>
.TeamUserAssigner
  // Modal
  b-modal(centered, ref='modal-assign-user', v-model='modalShow')
    // Modal Title
    template(v-slot:modal-title)
      i.mdi.mdi-account-multiple
      span {{ $t("assign_user") }}

    // Modal Content
    template(v-if='users.length')
      form.form
        ja-form-field(
          type='select',
          :label='$t("select_user")',
          :options='users',
          :on-change='handleChangeSelectedUser'
        )

    template(v-else)
      p {{ $t("not_available_users") }}

    // Modal Footer
    template(v-slot:modal-footer)
      template(v-if='users.length')
        ja-button(
          icon='ti-save',
          type='outline-primary',
          :text='$t("save")',
          :on-click='handleAssignUser'
        )
      template(v-else)
        div
</template>

<script>
export default {
  name: 'TeamUserAssigner',

  props: {
    users: {
      type: Array,
      default: () => []
    },
    onAssignUser: {
      type: Function,
      default: () => {}
    },
  },

  data () {
    let selectedUser

    if (this.users.length) {
      selectedUser = this.users[0].key
    }

    return {
      selectedUser,
      modalShow: false,
    }
  },

  methods: {
    toggle () {
      this.$refs['modal-assign-user'].toggle()
    },

    handleChangeSelectedUser (value) {
      this.$set(this, 'selectedUser', value)
    },

    handleAssignUser () {
      this.onAssignUser(this.selectedUser)
    }
  },
}
</script>

<style lang="sass" scoped>
</style>
