<template lang="pug">
.AlertContainer
  Transition(name='transition-fade' @after-leave="onDiscard(id)")

    .Alert(
      @click.prevent='close'
      v-show='show'
      :class='["alert", typeClass]'
    )

      .AlertClose(
        aria-label='close'
        class='btn-close'
        type='button'
      )
        i.mdi.mdi-close

      .AlertContent
        i.mdi.mdi-alert
        span {{ content }}
</template>

<script>
export default {
  name: 'Alert',

  props: {
    content: {
      type: String,
      required: true,
    },

    id: {
      type: [Number, String],
      required: true,
    },

    onDiscard: {
      type: Function,
      default: () => {}
    },

    type: {
      type: String,
      default: 'info'
    },

  },

  data () {
    return {
      show: true
    }
  },

  computed: {
    typeClass () {
      return `alert-${this.type}`
    }
  },

  methods: {
    close (event) {
      this.show = false
    }
  }
}
</script>

<style lang="sass" scoped>
.Alert
  color: #455a64
  border: none
  position: relative

  &.alert-danger
    background: white
    border: 1px solid #ff6395

  &:hover
    cursor: pointer

    .AlertClose
      opacity: 1

.AlertClose
  display: flex
  align-items: center
  background: transparent
  border: none
  bottom: 0
  font-size: 22px
  margin: auto 0
  opacity: 0
  outline: none
  position: absolute
  right: 10px
  top: 0
  i
    margin-right: 0

.transition-fade-enter-active,
.transition-fade-leave-active
  transition-duration: .25s
  transition-property: all
  transition-timing-function: cubic-bezier(.4,1,.75,.9)
  transform-origin: center 0px

.transition-fade-enter-active
  opacity: 1
  transform: translate(0)

.transition-fade-enter,
.transition-fade-enter-from,
.transition-fade-leave-to,
.transition-fade-leave-active
  opacity: 0
  transform: translate(200px)
</style>
