<template lang="pug">
.FieldSetKeyValue
  .FieldSetKeyValue-Fields(v-if='fields.length')
    template(v-for='(field, index) in fields')
      input.form-control(
        type='text',
        :value='field.name',
        @input='(e) => updateField("input", index, e.target.value, field.value)'
        @change='(e) => updateField("change", index, e.target.value, field.value)'
      )
      input.form-control(
        type='text',
        :value='field.value',
        @input='(e) => updateField("input", index, field.name, e.target.value)'
        @change='(e) => updateField("change", index, field.name, e.target.value)'
      )
      ja-button.FieldSetKeyValue-ButtonRemove(
        icon='mdi mdi-close',
        type='outline-danger',
        :on-click='() => removeField(index)'
      )

  ja-button.FieldSetKeyValue-ButtonAdd(
    :on-click='addNewField',
    block,
    type='outline-secondary',
    icon='mdi mdi-plus'
  )
</template>

<script>
export default {
  name: 'FieldSetKeyValue',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data () {
    const fields = Object.entries(this.value).map((entry) => ({
      name: entry[0],
      value: entry[1],
    }))

    return { fields }
  },

  methods: {
    addNewField () {
      this.fields.push({ name: `_${this.fields.length + 1}`, value: '' })
    },

    removeField (index) {
      this.fields.splice(index, 1)

      this.updateModelValue()
    },

    updateField (eventName, index, name, value) {
      this.$set(this.fields, index, { name, value })

      this.updateModelValue(eventName)
    },

    updateModelValue (eventName) {
      const newValue = this.fields.length
        ? this.fields.reduce((newValue, field) => {
          newValue[field.name] = field.value
          return newValue
        }, {})
        : {}

      this.$emit(eventName, newValue)
    },
  },
}
</script>

<style lang="sass">
.FieldSetKeyValue
  &-ButtonAdd
    font-size: 22px

  &-ButtonRemove
    font-size: 18px

  &-Fields
    display: grid
    grid-template-columns: 2fr 3fr 30px
    gap: 20px
    margin-bottom: 20px

    input
      display: block
</style>
