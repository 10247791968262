<template lang="pug">
.ContentPreview
  .ContentPreview-Dots
    span(v-for='dot in dots')

  ce-content-compiler(
    :format='format',
    :on-compile-content='onCompileContent',
    :projectId='projectId',
    v-bind='config'
  )
    template(v-slot='{ srcdoc }')
      iframe.ContentPreview-Iframe(:srcdoc='srcdoc')
</template>

<script>
const DOTS = 3

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    onCompileContent: {
      type: Function,
      default: () => {},
    },
    projectId: {
      type: [String, Number],
      default: 0,
    },
  },

  data () {
    return {
      dots: DOTS,
    }
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.ContentPreview
  @include card-shadow
  background-color: white
  border-radius: 5px
  height: 100%
  min-height: 100%
  overflow: hidden
  padding: 26px 0
  position: relative
  width: 100%

  &-Dots
    position: absolute
    top: 0
    left: 13px
    span
      display: inline-block
      width: 6px
      height: 6px
      background-color: #99abb4
      border-radius: 3px
      margin-right: 6px

  &-Iframe
    border: none
    width: 100%
    height: 100%
</style>
