<template lang="pug">
.BlockShopifyCart
  .BlockShopifyCartState(v-if='isAmp')
    amp-state#appliedDiscountState
      script(type='application/json') {{ appliedDiscountState }}

    amp-state#cart
      script(type='application/json') {{ cartState }}

    amp-state#order
      script(type='application/json') {}

    amp-state#productOrderStep
      script(type='application/json') 1

    amp-state#salePrice
      script(type='application/json') {{ salePrice }}

    amp-state#shippingAddress
      script(type='application/json') {{ shippingAddressState }}

    amp-state#target
      script(type='application/json') "{{ block.id }}"

    amp-state#tags
      script(type='application/json') "{{ config.productTag }}"

    amp-bind-macro#findProduct(
      arguments='productId',
      expression='cart.filter(product => product.id == productId)[0] || false'
    )

    amp-bind-macro#calculateAddQuantity(
      arguments='quantity',
      expression='quantity <= 99 ? quantity + 1 : 99'
    )

    amp-bind-macro#calculateSubstractQuantity(
      arguments='quantity',
      expression='quantity > 1 ? quantity - 1 : 1'
    )

    amp-bind-macro#addToCart(
      arguments='productId',
      expression='cart.map(prod => prod.id == productId ? { id:productId, quantity:calculateAddQuantity(prod.quantity), price:prod.price, variant_id:prod.variant_id, name:prod.name} : prod)'
    )

    amp-bind-macro#appliedDiscountToString(
      expression=`keys(appliedDiscountState).reduce((initString, key, index, arr) => initString + '"' + key + '":"' + appliedDiscountState[key] + '"' + (index < arr.length - 1 ? ',' : '}'), '{')`
    )

    amp-bind-macro#calculateSubstractQuantity(
      arguments='quantity',
      expression='quantity > 1 ? quantity - 1 : 1'
    )

    amp-bind-macro#addToCart(
      arguments='productId',
      expression='cart.map(prod => prod.id == productId ? { id:productId, quantity:calculateAddQuantity(prod.quantity), price:prod.price, variant_id:prod.variant_id, name:prod.name} : prod)'
    )
    amp-bind-macro#removeFromCart(
      arguments='productId',
      expression='cart.map(prod => prod.id == productId ? { id:productId, quantity:calculateSubstractQuantity(prod.quantity), price:prod.price, variant_id:prod.variant_id, name:prod.name} : prod)'
    )

    amp-bind-macro#updateVariantId(
      arguments='productId, variantId',
      expression='cart.map(prod => prod.id == productId ? { id:productId, quantity:prod.quantity, price:prod.price, variant_id:variantId, name:prod.name } : prod)'
    )

    amp-bind-macro#getTotal(
      expression='cart.reduce((total, product) => total + (product.quantity * product.price), 0)'
    )

    amp-bind-macro#getTotalWithDiscount(
      expression='cart.reduce((total, product) => total + (product.quantity * salePrice), 0)'
    )

    amp-bind-macro#productToString(
      arguments='productId',
      expression=`keys(findProduct(productId)).reduce((productString, productKey, index, arr) => productString + '"' + productKey + '":"' + findProduct(productId)[productKey] + '"' + (index < arr.length - 1 ? ',' : '}'), '{"applied_discount":' + applied_discount + ', ')`
    )

    amp-bind-macro#cartToString(
      expression=`cart.reduce((cartString, product, index, arr) => cartString + productToString(product.id) + (index < arr.length - 1 ? "," : "]"), "[")`
    )

    amp-bind-macro#shippingAddressToString(
      expression=`keys(shippingAddress).reduce((shippingAddressString, key, index, arr) => shippingAddressString + '"' + key + '":"' + shippingAddress[key] + '"' + (index < arr.length - 1 ? ',' : '}'), '{')`
    )

    amp-bind-macro#orderParamsToString(
      expression=`'{"draft_order":{ "email": "%email%", "line_items":' + line_items + ',' + '"shipping_address":' + shipping_address + ', "tags": "' + tags + '"}}'`
    )

  .BlockShopifyCart-Step1(v-html='templateProductInfo')

  template(v-if='isAmp')
    .BlockShopifyCart-Step2(v-html='templateShippingAddress')

    .BlockShopifyCart-Step3(v-html='templateCartSummary')
</template>

<script>
import pug from 'pug'
import CONFIG_CONST from '@config/config.const'
import state from '@plugins/content-editor/state'
import pugCartSummary from '!!raw-loader!./templates/cart-summary.pug'
import pugProductInfo from '!!raw-loader!./templates/product-info.pug'
import pugShippingAddress from '!!raw-loader!./templates/shipping-address.pug'
import getTexts from './texts'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
  },

  data () {
    const AMP_EVENTS = state.ampBlockEvents.SHOPIFY_CART
    const { BASE_URL_API } = CONFIG_CONST

    const config = this.block.config
    const content = this.block.content
    const product = this.block.config.product || {}

    const TEXTS = getTexts()
    TEXTS.LABEL_VARIANT = content.variantLabel
    TEXTS.BUTTON_BUY = content.button

    const layerIsFull = this.block.config.layer === 'full'
    const isAmp = this.format === 'amp'
    const link = config.link || content.url

    const areThereVariants = product.variants.length > 1
    const projectId = this.projectId
    const integrationId = 1
    const productId = product.id
    const source = product.image
    const currency = product.currency
    const variants = product.variants
    const salePrice = config.productDiscount
      ? content.price - config.productDiscount.value
      : false

    const productList = [
      {
        id: productId,
        variant_id: variants[0].id,
        quantity: 1,
        price: content.price,
        name: content.name,
      },
    ]

    const appliedDiscountState = JSON.stringify(
      !!config.productDiscount && {
        value_type: config.productDiscount.type,
        value: config.productDiscount.value,
        amount: config.productDiscount.value,
        title: config.productDiscount.name,
      }
    )

    const cartState = JSON.stringify(productList)

    const shippingAddressState = JSON.stringify({ country: 'spain' })

    const buttonStyles = {
      'background-color': config.colors.buttonBackground,
      border: 'none',
      color: config.colors.buttonText,
    }

    const templateVars = {
      AMP_EVENTS,
      BASE_URL_API,
      areThereVariants,
      buttonStyles,
      content,
      currency,
      integrationId,
      isAmp,
      link,
      layerIsFull,
      productId,
      projectId,
      productList,
      salePrice,
      source,
      variants,
      TEXTS,
    }

    const templateCartSummary = pug.render(pugCartSummary, templateVars)
    const templateProductInfo = pug.render(pugProductInfo, templateVars)
    const templateShippingAddress = pug.render(pugShippingAddress, templateVars)

    return {
      appliedDiscountState,
      config,
      cartState,
      isAmp,
      salePrice,
      shippingAddressState,
      templateCartSummary,
      templateProductInfo,
      templateShippingAddress,
    }
  },
}
</script>
