import axios from 'axios'

const client = axios.create({ baseURL: 'https://cloud-fns.yamp.app/api' })

export const ActivityService = {
  /**
   * Get Activity Report
   * @param {Object} pagination
   * @return {Array} contacts
   */
  async getReport ({ page = 1, rows = 20 } = {}) {
    const report = await client.get(
      `/ai/get-demo-analysis?page=${page}&rows=${rows}`
    )

    report.success = report.status === 200

    return report
  },
}
