import axios from 'axios'
import config from '@config/config.const'
import interceptors from './interceptors'

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = ({ baseURL, headers = {}, path = '' }) => {
  const options = {
    baseURL: (baseURL || config.BASE_URL_API) + path,

    headers: {
      ...headers,
      Accept: 'application/json',
    },
  }

  const client = axios.create(options)
  setInterceptors(client)

  return client
}

/**
 * Set Interceptor
 * @param {axios} instance
 */
const setInterceptors = (instance) => {
  instance.interceptors.request.use(interceptors.request)
  instance.interceptors.response.use(
    interceptors.responseSuccess,
    interceptors.responseError
  )
}

class ApiClient {
  constructor (config) {
    this.client = getClient(config)
  }

  async get (url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  async delete (url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  async head (url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  async options (url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  async post (url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  async put (url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  async patch (url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}

export default ApiClient
