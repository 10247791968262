import Vue from 'vue'
import { AudiencesService } from '../service/audiences.service'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'
import { formatDate } from '@utils'
import { transformPerformanceForDataWell } from '@/data-transformers/analytics'

const DATE_FORMAT = 'EUROPE_FULL_DATE'

// state
const state = Vue.observable({
  audience: {},
  contacts: [],
  performance: [],
  total_contacts: 0,
})

// getters
export const getters = {
  audience: () => state.audience,
}

// actions
export const getAudienceById = async (id) => {
  const audience = await useLoader(AudiencesService.getById, id)
  const performance = transformPerformanceForDataWell(audience.stats)
  Vue.set(state, 'performance', performance)
  Vue.set(state, 'audience', audience)
  setQueryParam('id_audience', state.audience.name)
}

export const getContactsByAudienceId = async (id, { pagination } = {}) => {
  const contacts = await useLoader(
    AudiencesService.getContactsByAudienceId,
    id,
    pagination
  )

  if (!contacts?.items) return

  contacts.items = contacts.items.map((contact) => ({
    ...contact,
    created_at: formatDate({
      date: contact.created_at,
      format: DATE_FORMAT,
    }),
    updated_at: formatDate({
      date: contact.created_at,
      format: DATE_FORMAT,
    }),
  }))

  Vue.set(state, 'contacts', contacts)

  Vue.set(state, 'total_contacts', contacts.pagination.total_items)

  Vue.set(state, 'audience', contacts.audience)

  setQueryParam('id_audience', state.audience.name)
}

export const createAudience = async (data) => {
  return await useLoader(AudiencesService.create, {
    ...data,
    project_id: common.user.project_id,
  })
}

export const deleteAudienceById = async (id) => {
  return await useLoader(AudiencesService.delete, id)
}

export const importContacts = async (id, data) => {
  return await useLoader(AudiencesService.importContacts, id, data)
}

export const updateAudienceById = async (id, data) => {
  return await useLoader(AudiencesService.update, id, {
    ...data,
    project_id: common.user.project_id,
  })
}

export default state
