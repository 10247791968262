<template lang="pug">
.PermissionSelector
  i.PermissionSelector-Icon(:class='iconClass')
  select.PermissionSelector-Select
    option(
      v-for='option in permissionOptions'
      :key="option"
      :value="option"
      :selected='option === value'
    ) {{ option }}
</template>

<script>
export default {
  name: 'PermissionSelector',

  props: {
    category: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
  },

  data () {
    const permissionOptions = ['read-only', 'read-write']
    const permissionValue = this.value

    const mapIconClass = {
      user: 'mdi mdi-account',
      team: 'mdi mdi-account-multiple',
    }

    return {
      iconClass: mapIconClass[this.category],
      permissionOptions,
      permissionValue,
    }
  },

  methods: {
    handleChange ({ value, checked }) {
      console.log({ value, checked })
      this.$emit('change', this.model)
    },
  }
}
</script>

<style lang="sass" scoped>
.PermissionSelector
  align-items: center
  display: flex

  &-Icon
    font-size: 20px

  &-Select
    appearance: none
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px
    border: 1px solid #ccc
    padding: 5px 20px 5px 10px
    border-radius: 4px
    display: block

</style>
