<template lang="pug">
.ContentBlock(
  :id='block.id',
  :style='cssVars',
  :class='{ active: isActiveBlock, "full-width": isFullWidth }',
  @click='handleClick',
  @mousemove='handleMousemove',
  @mouseleave='handleMouseleave'
)
  component(
    :block='block',
    :is='componentName',
    :on-change='handleChange',
    :show-fallback='showFallback'
  )
</template>

<script>
import state, {
  isActiveBlock,
  resetOveredBlock,
  setActiveBlock,
  setOveredBlock,
  setBlockContent,
} from '@plugins/content-editor/state'

import { BLOCKTYPES_COMPONENTS } from '@plugins/content-editor/const'

export default {
  name: 'ContentBlock',
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      componentName: BLOCKTYPES_COMPONENTS[this.block.type],
      positionStart: 0,
      positionEnd: 0,
      height: 0,
    }
  },

  computed: {
    cssVars () {
      return {
        '--block-bg-color': this.block.config.colors.blockBackground || '',
      }
    },

    isActiveBlock () {
      return isActiveBlock(this.block.id)
    },

    isFullWidth () {
      return this.block.config.fullWidth
    },

    showFallback () {
      return state.showFallback
    },
  },

  mounted () {
    this.updatePosition()
  },

  methods: {
    updatePosition () {
      this.height = this.$el.offsetHeight
      this.positionStart = this.$el.offsetTop
      this.positionEnd = this.positionStart + this.height
    },

    handleClick () {
      this.updatePosition()

      setActiveBlock({
        ...this.block,
        height: this.height,
        positionEnd: this.positionEnd,
        positionStart: this.positionStart,
      })
    },

    handleChange (content) {
      setBlockContent({ content, blockId: this.block.id })
    },

    handleMousemove (event) {
      this.updatePosition()

      const mousePositionY = event.offsetY
      const blockHeight = this.$el.offsetHeight
      const middlePosition = blockHeight / 2

      const mouseLocation = mousePositionY < middlePosition ? 'top' : 'bottom'

      setOveredBlock({
        ...this.block,
        mouseLocation,
        height: this.height,
        positionEnd: this.positionEnd,
        positionStart: this.positionStart,
      })
    },

    handleMouseleave (event) {
      const parentHeight = this.$el.offsetParent.offsetHeight
      const parentWidth = this.$el.offsetParent.offsetWidth

      if (
        event.layerY < parentHeight &&
        event.layerY > 0 &&
        event.layerX > 0 &&
        event.layerX < parentWidth
      ) {
        return
      }

      resetOveredBlock()
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.ContentBlock
  background-color: var(--block-bg-color)
  padding: $block-padding
  outline: $block-outline transparent
  box-sizing: border-box
  &:hover,&.active
    @include active-shadow
  &.full-width
    padding-left: 0
    padding-right: 0
</style>
