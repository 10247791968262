import { generateUid } from '@utils'

export default {
  request (config) {
    const token = store.get('token')

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }

    return config
  },

  responseSuccess (response) {
    if (response.data?.data) {
      response.data = response.data.data
    }

    response.success = true

    return response
  },

  responseError (error) {
    const { response: { data, status } } = error
    let errors = []

    if (data?.errors?.length && Array.isArray(data?.errors)) {
      errors.push(...data.errors)
    } else if (data?.error) {
      errors.push({ message: data.error })
    } else if (data && typeof data === 'object') {
      errors.push(data)
    } else if (data) {
      errors.push({ message: data })
    } else {
      errors.push(error)
    }

    errors = errors.map(error => ({ ...error, uid: generateUid() }))

    return {
      errors,
      status,
      success: false,
    }
  }
}
