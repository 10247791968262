<template lang="pug">
.OptionsGroup.btn-group
  button.OptionsGroup-Option.btn(
    v-for='option in options',
    :class='{ "btn-outline-secondary": option !== selected, "btn-primary": option === selected }',
    @click='() => handleClick(option)'
  ) {{ getOptionText(option) }}
</template>

<script>
import { SELECT_DEFAULTS } from '@plugins/content-editor/const'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    selected: {
      get () {
        if (!this.value) return SELECT_DEFAULTS[this.field]
        return this.value
      },
    },
  },

  methods: {
    handleClick (value) {
      this.onChange({ value })
    },

    getOptionText (option) {
      if (typeof option !== 'boolean') return option

      return option ? this.$t('enabled') : this.$t('disabled')
    },
  },
}
</script>

<style lang="sass" scoped>
.OptionsGroup
  display: flex
</style>
