<template lang="pug">
.InputWrapper
  .InputWrapper-Label.text-first-uppercase {{ $t(label) }}
  .InputWrapper-Items
    component(
      v-for='(input, index) in items',
      v-bind='input',
      :key='index',
      :on-change='handleChange'
    )
</template>

<script>
import {
  INPUTTYPES_COMPONENTS,
  INPUT_LABELS,
  SELECT_OPTIONS,
} from '@plugins/content-editor/const'

export default {
  props: {
    description: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      required: true,
    },
    inputs: {
      type: [Object, String, Number, Array, Boolean, null],
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    items () {
      const items = []

      if (!INPUTTYPES_COMPONENTS[this.field]) return items

      if (['colors', 'padding'].includes(this.field)) {
        for (const key in this.inputs) {
          let label = key

          // Add Prefix
          if (this.field === 'padding') label = `${this.field}_${key}`

          const item = {
            is: INPUTTYPES_COMPONENTS[this.field],
            label: INPUT_LABELS[label],
            value: this.inputs[key],
            field: key,
          }

          items.push(item)
        }

        return items
      }

      const item = {
        is: INPUTTYPES_COMPONENTS[this.field],
        value: this.inputs,
        field: this.field,
      }

      // Add Select Options
      if (Object.keys(SELECT_OPTIONS).includes(this.field)) {
        item.options = SELECT_OPTIONS[this.field]
      }

      items.push(item)
      return items
    },

    label () {
      return INPUT_LABELS[this.field]
    },
  },

  methods: {
    handleChange ({ field, value }) {
      const data = { field: this.field, value: null }

      if (Number.isInteger(field)) {
        data.value = [...this.inputs]
        data.value[field] = value
      } else if (field) {
        data.value = { ...this.inputs }
        data.value[field] = value
      } else {
        data.value = value
      }

      this.onChange(data)
    },
  },
}
</script>

<style lang="sass" scoped>
.InputWrapper
  margin: 32px 22px 0

  &-Label
    font-weight: 500
    margin-bottom: 8px
</style>
