<template lang="pug">
ja-content(:pageTitle='billingCycle.name', v-if='billingCycle.id')

  // Quota Summary
  b-card(:header='$t("billing_cycle")')
    b-card-text
      form.form
        // Plan
        ja-form-field(
          label='Plan'
        )
          template(v-slot:value)
            span.text-capitalize {{ billingCycle.plan.name }}
        // Date
        ja-form-field(
          label='Date',
          type='info',
          :fieldValue='billingCycle.dateRange'
        )
        // Sends
        ja-form-field(
          label='sends',
          type='info',
          :fieldValue='`${jaFormatNumber(billingCycle.sends)} ${$t("of")} ${jaFormatNumber(billingCycle.limit)}`'
        )
        // Rest
        ja-form-field(
          label='Your quota will rest',
          type='info',
          :fieldValue='jaFormatNumber(billingCycle.rest)'
        )

  // Quota Chart
  b-card(v-if='billingCycle.sends')
    b-card-text
      // Apex Charts
      apexchart(
        ref='apexchart'
        :options='chartOptions'
        :height='chartHeight'
        :series='shownMetrics'
      )

  // Quota Table
  ja-data-table(
    v-if='projects.length'
    :fields='fields',
    :items='projects',
  )
</template>

<script>
import state, {
  getBillingCylcle,
  getOrganizationById,
} from './store/organizations.store'

const chartOptions = {
  chart: {
    fontFamily: 'Roboto',
    type: 'bar',
    stacked: true,
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: false,
        reset: false,
      },
    },
  },

  colors: [
    '#5372E8',
    '#7E53E8',
    '#E85373',
    '#E8C953',
    '#BDE853',
    '#53E8C8',
  ],

  dataLabels: {
    // enabled: false,
  },

  legend: {
    show: false,
  },

  plotOptions: {
    bar: {
      distributed: false,
      hideZeroBarsWhenGrouped: true,
      rangeBarGroupRows: true,
    },
  },

  stroke: {
    width: 0,
  },

  xaxis: {},
  yaxis: []
}

export default {
  name: 'OrganizationOverview',

  data () {
    const fields = [{ key: 'name', label: 'Project' }, 'sends']

    return {
      chartHeight: '280px',
      fields,
    }
  },

  computed: {
    organization () {
      return state.organization
    },

    billingCycle () {
      return state.dataUsageBillingCycle
    },

    projects () {
      return state.dataUsageBillingCycle.projects || []
    },

    chartOptions () {
      const chartXaxis = {
        categories: this.billingCycle.categories,
        tickPlacement: 'between',
      }

      const chartYaxis = [
        {
          show: true,
          opposite: false,
          labels: { formatter: val => parseInt(val) },
        },

      ]

      // const getSendsFromProject = (projectName) => {
      //   const project = this.projects.find(({ name }) => name === projectName)
      //   console.log(project)
      //   return project.sends
      // }

      const tooltip = {
        x: {
          formatter: function (category) {
            // return `<b>${category}</b>: ${getSendsFromProject(category)} sends`
            return `<b>${category}</b>`
          },
        }
      }

      return {
        ...chartOptions,
        tooltip,
        xaxis: chartXaxis,
        yaxis: chartYaxis,
      }
    },

    shownMetrics () {
      return this.billingCycle.series || []
    },
  },

  beforeCreate () {
    getOrganizationById(this.$route.params.id_organization)
    getBillingCylcle(this.$route.params.id_billing_cycle)
  },

  methods: {
    handleChangePage () {}
  },
}
</script>
