<template lang="pug">
.ProductList
  .ProductList-Controls

  .ProductList-DataTable
    .ProductList-Row(
      v-for='product in products',
      :key='product.id',
      :class='{ "ProductList-Row--selected": isProductSelected(product) }',
      @click='handleClickProduct(product)'
    )
      img.ProductList-ProductImage(:src='product.image')

      .ProductList-ProductName
        .ProductList-Label {{ $t("product_name") }}
        .ProductList-Value {{ product.name }}

      .ProductList-ProductPrice
        .ProductList-Label {{ $t("price") }}
        .ProductList-Value {{ product.price }} {{ product.currency }}

      .ProductList-ProductDate
        .ProductList-Label {{ $t("date_added") }}
        .ProductList-Value {{ product.date }}
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
    selectedProduct: {
      type: [Object, null],
      default: null,
    },
  },

  methods: {
    isProductSelected (product) {
      return this.selectedProduct && this.selectedProduct.id === product.id
    },

    handleClickProduct (product) {
      this.$emit('update:selected-product', product)
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.ProductList

  &-Label
    color: $gray-mid
    font-size: 14px
    margin-bottom: 10px
    &::first-letter
      text-transform: uppercase

  &-Row
    display: grid
    gap: 20px
    grid-template-columns: 100px 3fr 1fr 2fr
    margin-bottom: 10px
    padding: 12px 16px

    &:hover,
    &--selected
      background-color: $gray-bg

    &:hover
      cursor: pointer

    &--selected
      outline: 3px solid $purple

  &-ProductDate,
  &-ProductPrice
    text-align: right

  &-ProductImage
    display: block
    width: 100px
    object-fit: cover

  &-Value
    color: $gray-dark
</style>
