import Vue from 'vue'

// state
const state = Vue.observable({
  config: null,
  content_html: '',
  content_amp: '',
})

export const setConfig = (config) => {
  Vue.set(state, 'config', config)
}

export default state
