import Vue from 'vue'
import { generateBlockId, sleep, useBlockDefaults } from './utils'
import { cloneDeep, merge } from 'lodash'

const state = Vue.observable({
  activeBlock: null,
  ampBlockEvents: {},
  blocks: [],
  body: {},
  integrations: [],
  integrationTypes: [],
  overedBlock: null,
  showFallback: false,
})

let onChangeState = async () => {}
let onDeleteImage = async () => {}
let onGetImageLibrary = async () => {}
let onGetProductList = async () => {}
let onUploadImage = async () => {}

// Click Active Block
const clickActiveBlock = () => {
  const activeBlockEl = document.getElementById(state.activeBlock.id)
  activeBlockEl.click()
}

// Get Block Index
const getBlockIndex = (blockId) => {
  return state.blocks.findIndex((block) => block.id === blockId)
}

// Swap Block
const swapBlock = async ({ block, overBlock }) => {
  const blockIndex = getBlockIndex(block.id)
  const overBlockIndex =
    overBlock === 'prev'
      ? blockIndex - 1
      : overBlock === 'next'
        ? blockIndex + 1
        : getBlockIndex(overBlock.id)

  const overBlockCopy = cloneDeep(state.blocks[overBlockIndex])

  state.blocks.splice(overBlockIndex, 1, block)
  state.blocks.splice(blockIndex, 1, overBlockCopy)

  await sleep(1)

  clickActiveBlock()
}

/*********************************
 * GETTERS
 *********************************/
export const isActiveBlock = (blockId) => {
  return state.activeBlock?.id === blockId
}

export const getIntegration = (integrationTypeName) => {
  return (
    state.integrations.find(
      (integration) => integration.type.name === integrationTypeName
    ) || null
  )
}

export const getIntegrationType = (integrationTypeName) => {
  return state.integrationTypes.find(
    (integrationType) => integrationType.name === integrationTypeName
  )
}

export const getWebhooks = () => {
  return state.integrations.filter(
    (integration) => integration.type.name === 'webhook'
  )
}

/*********************************
 * SETTERS
 *********************************/
export const setActiveBlock = (block) => {
  if (!block) return Vue.set(state, 'activeBlock', block)

  const blockIndex = getBlockIndex(block.id)
  const isTheFirstOne = blockIndex === 0
  const isTheLastOne = blockIndex === state.blocks.length - 1

  Vue.set(state, 'activeBlock', { ...block, isTheFirstOne, isTheLastOne })
}

export const setActiveBlockConfig = ({ field, value }) => {
  Vue.set(state.activeBlock.config, field, value)
  onChangeState(state.activeBlock)
}

export const setBlockConfig = ({ config, blockId }) => {
  const blockIndex = getBlockIndex(blockId)
  Vue.set(state.blocks[blockIndex], 'config', config)
  onChangeState(state.blocks[blockIndex])
}

export const setBlockAttrs = ({ attrs, blockId }) => {
  const blockIndex = getBlockIndex(blockId)
  Vue.set(state.blocks[blockIndex], 'attrs', attrs)
  onChangeState(state.blocks[blockIndex])
}

export const setBlockContent = ({ content, blockId }) => {
  const blockIndex = getBlockIndex(blockId)
  Vue.set(state.blocks[blockIndex], 'content', content)
  onChangeState(state.blocks[blockIndex])
}

export const setOveredBlock = (block) => {
  Vue.set(state, 'overedBlock', block)
}

export const resetActiveBlock = () => {
  setActiveBlock(null)
}

export const resetOveredBlock = () => {
  setOveredBlock(null)
}

export const resetState = () => {
  Vue.set(state, 'blocks', [])
  Vue.set(state, 'body', {})
}

export const setAmpBlockEvents = (ampBlockEvents = {}) => {
  Vue.set(state, 'ampBlockEvents', ampBlockEvents)
}

export const setBlocks = (blocks) => {
  Vue.set(state, 'blocks', blocks)
}

export const setBody = (body) => {
  Vue.set(state, 'body', body)
}

export const setIntegrations = (integrations) => {
  Vue.set(state, 'integrations', integrations)
}

export const setIntegrationTypes = (integrationTypes) => {
  Vue.set(state, 'integrationTypes', integrationTypes)
}

export const setShowFallback = (show) => {
  Vue.set(state, 'showFallback', show)
}

/*********************************
 * Listeners
 *********************************/
export const setStateListeners = ({
  listenerChangeState,
  listenerDeleteImage,
  listenerGetImageLibrary,
  listenerGetProductList,
  listenerUpdateImage,
}) => {
  onChangeState = (block) => {
    listenerChangeState({
      changedBlock: block,
      updatedConfig: {
        rows: state.blocks,
        body: state.body,
      },
    })
  }

  onDeleteImage = async (id) => await listenerDeleteImage(id)
  onGetImageLibrary = async () => await listenerGetImageLibrary()
  onGetProductList = async (data) => await listenerGetProductList(data)
  onUploadImage = async (file) => await listenerUpdateImage(file)
}

/*********************************
 * Actions
 *********************************/

// Add Block to Blocks Collection
export const actionAddBlock = async ({ index, data }) => {
  const blockId = generateBlockId()
  state.blocks.splice(index, 0, { ...data, id: blockId })

  await sleep(500)
  setActiveBlock(state.blocks[index])
  clickActiveBlock()
}

// Create New Block
export const actionCreateNewBlock = (type) => {
  const refBlockIndex = getBlockIndex(state.overedBlock.id)

  const newBlockData = { ...useBlockDefaults(type), type }
  const newBlockIndex =
    state.overedBlock.mouseLocation === 'top'
      ? refBlockIndex
      : refBlockIndex + 1

  actionAddBlock({ index: newBlockIndex, data: newBlockData })
}

// Clone Active Block
export const actionCloneActiveBlock = () => {
  const newBlockData = useBlockDefaults(state.activeBlock.type)
  const activeBlockIndex = getBlockIndex(state.activeBlock.id)

  resetActiveBlock()

  actionAddBlock({
    index: activeBlockIndex + 1,
    data: merge(newBlockData, cloneDeep(state.blocks[activeBlockIndex])),
  })
}

// Delete Image
export const actionDeleteImage = async (image) => {
  return await onDeleteImage(image)
}

// Remove Block
export const actionRemoveBlock = (blockId) => {
  const blockIndex = getBlockIndex(blockId)
  state.blocks.splice(blockIndex, 1)
}

// Remove Active Block
export const actionRemoveActiveBlock = () => {
  actionRemoveBlock(state.activeBlock.id)
  resetActiveBlock()
}

// SwapDown Active Block
export const actionSwapDownActiveBlock = () => {
  swapBlock({ block: state.activeBlock, overBlock: 'next' })
}

// SwapUp Active Block
export const actionSwapUpActiveBlock = () => {
  swapBlock({ block: state.activeBlock, overBlock: 'prev' })
}

// Get Image LIbrary
export const actionGetImageLibrary = async () => {
  return await onGetImageLibrary()
}

// Get Product List
export const actionGetProductList = async (data = {}) => {
  return await onGetProductList(data)
}

// Upload Image
export const actionUploadImage = async (image) => {
  return await onUploadImage(image)
}

export default state
