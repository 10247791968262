import ApiClient from '@api/client'

const client = new ApiClient({ path: '/' })

export const UsersService = {
  /**
   * Create User
   * @param {object} userData
   */
  async assingProject (userId, projectId) {
    return await client.post(`/users/${userId}/projects/${projectId}`)
  },

  /**
   * Create User
   * @param {object} userData
   */
  async create (userData) {
    return await client.post('/users', userData)
  },

  /**
   * Delete User
   */
  async delete (id) {
    return await client.delete(`/users/${id}`)
  },

  /**
   * Get Users By Organizations
   * @return {Array} users
   */
  async getUserByUserIdAndOrganizationId (userId, organizationId) {
    return await client.get(`/organizations/${organizationId}/users/${userId}`)
  },

  /**
   * Invite User
   */
  async invite (data) {
    return await client.post('/organizations/user/invite', data)
  },

  /**
   * Register User in an Organization with or without invitation
   */
  async registerOrganization (data) {
    return await client.post('/organizations/user/register', data)
  },

  /**
   * Remove User from an Organization
   */
  async removeFromOrganization (data) {
    return await client.delete('/organizations/user/reject', { data })
  },

  /**
   * Create Team
   */
  async update (id, data) {
    return await client.put(`/users/${id}`, data)
  },
}
