import { formatPrice } from './format-price.util'

export const calculatePriceWithDiscount = ({ price, discountType, discountValue }) => {
  let finalPrice = parseFloat(price)

  if (discountType === 'fixed_amount') {
    finalPrice = finalPrice - discountValue
  }

  if (discountType === 'percentage') {
    finalPrice = finalPrice - (finalPrice * (discountValue / 100))
  }

  return formatPrice(finalPrice)
}
