<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageActions='pageActions')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        // Email
        ja-form-field(
          :field-value='fields.email',
          :on-change='(value) => handleFieldChange("email", value)',
          label='email',
          type='text'
        )

        // Name
        ja-form-field(
          :field-value='fields.name',
          :on-change='(value) => handleFieldChange("name", value)',
          label='name',
          type='text'
        )

        // Providers
        ja-form-field(
          :field-value='fields.email_provider_id',
          :on-change='(value) => handleFieldChange("email_provider_id", value)',
          :options='smtpProviders',
          label='smtp_provider',
          type='select'
        )

        // AMP Validated
        ja-form-field(
          :field-value='fields.amp_validated',
          :on-change='(value) => handleFieldChange("amp_validated", value)',
          label='amp_validated',
          type='switch'
        )
</template>

<script>
import state, {
  createEmailSender,
  deleteEmailSenderById,
  getEmailSenderById,
  initEmailSender,
  updateEmailSenderById,
} from './store/email-senders.store'

import projectState, {
  getProjectSmtpProviders,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

export default {
  name: 'EmailProviderEdit',

  data () {
    const isNew = !this.$route.params.id_email_sender
    const pageActions = []
    const fields = {}
    const showForm = false

    return {
      fields,
      isNew,
      pageActions,
      showForm,
    }
  },

  computed: {
    emailSender () {
      return state.emailSender
    },

    smtpProviders () {
      return projectState.smtpProviders.map((sp) => ({
        text: this.$t(sp.name),
        key: sp.id,
      }))
    },

    pageTitle () {
      if (!this.emailSender?.id) return this.$t('new_email_sender')
      return this.$t(this.emailSender.name || 'email_sender')
    },
  },

  async created () {
    if (this.isNew) {
      await initEmailSender()

      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push(
      {
        callback: this.delete,
        icon: 'ti-trash',
        text: 'delete',
        type: 'outline-danger',
      },
      {
        callback: this.update,
        icon: 'ti-save',
        text: 'update',
        type: 'outline-primary',
      }
    )

    await Promise.all([
      getEmailSenderById(this.$route.params.id_email_sender),
      getProjectSmtpProviders(),
    ])

    this.fields = {
      email: state.emailSender.email,
      name: state.emailSender.name,
      email_provider_id: state.emailSender.email_provider_id,
      amp_validated: !!state.emailSender.amp_validated,
    }

    this.showForm = true
  },

  methods: {
    handleFieldChange (field, value) {
      this.$set(this.emailSender, field, value)
    },

    async delete () {
      await deleteEmailSenderById(this.emailSender.id)

      this.$router.push({
        name: 'email-senders',
      })
    },

    async save () {
      await createEmailSender(this.emailSender)

      this.$router.push({
        name: 'email-senders',
      })
    },

    async update () {
      await updateEmailSenderById(this.emailSender.id, this.emailSender)

      this.$router.push({
        name: 'email-senders',
      })
    },

    submit () {
      if (this.isNew) return this.save()
      else this.update()
    },
  },
}
</script>

<style lang="sass" scoped></style>
