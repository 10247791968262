<template lang="pug">
ja-content(:pageTitle='$t("data_usage")', v-if='organization')

  // Quota Summary
  b-card(header='Current Bylling Cycle' v-if='currentBillingCycle.id')
    b-card-text
      form.form
        // Plan
        ja-form-field(
          label='Plan'
        )
          template(v-slot:value)
            span.text-capitalize
              router-link(
                :to='{ name: "organization-plan", params: { id_organization: organization.id } }'
              ) {{ currentBillingCycle.plan.name }}

        // Date
        ja-form-field(
          label='Date',
          type='info',
          :fieldValue='currentBillingCycle.dateRange',
        )
        // Sends
        ja-form-field(
          label='Sends',
          type='info',
          :fieldValue='`${jaFormatNumber(currentBillingCycle.sends)} ${$t("of")} ${jaFormatNumber(currentBillingCycle.limit)}`'
        )

        // Rest
        ja-form-field(
          label='Your quota will rest',
          type='info',
          :fieldValue='jaFormatNumber(currentBillingCycle.rest)'
        )

  // Quota Chart
  b-card
    b-card-text
      // Apex Charts
      apexchart(
        ref='apexchart'
        :options='chartOptions'
        :height='chartHeight'
        :series='shownMetrics'
      )

  // Quota Table
  ja-data-table(
    v-if='billingCycles.length'
    :fields='fields',
    :items='billingCycles',
    :on-change-page='handleChangePage',
    :on-row-click='handleClickRow'
  )
</template>

<script>
import state, {
  getOrganizationById,
  getOrganizationDataUsage,
  // getBillingCylcle,
} from './store/organizations.store'

const chartOptions = {
  chart: {
    fontFamily: 'Roboto',
    type: 'line',
    stacked: false,
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: false,
        reset: false,
      },
    },
  },

  colors: [
    '#5372E8',
    '#7E53E8',
    '#E85373',
    '#E8C953',
    '#BDE853',
    '#53E8C8',
  ],

  dataLabels: {
    enabled: false,
  },

  legend: {
    fontSize: '14px',
  },

  stroke: {
    curve: 'straight',
    width: [1, 1, 1, 1, 1, 1],
  },

  xaxis: {},
  yaxis: []
}

export default {
  name: 'OrganizationOverview',

  data () {
    const fields = ['cycle_start_date', 'cycle_end_date', 'sends']

    return {
      chartHeight: '240px',
      fields,
    }
  },

  computed: {
    dataUsageOrganization () {
      return state.dataUsageOrganization
    },

    billingCycles () {
      return state.dataUsageOrganization.billingCycles || []
    },

    currentBillingCycle () {
      return state.dataUsageBillingCycle
    },

    chartOptions () {
      const chartXaxis = {
        categories: state.dataUsageOrganization.categories || [],
        tickPlacement: 'between',
      }

      const chartYaxis = [
        {
          show: true,
          opposite: false,
          labels: { formatter: val => parseInt(val) },
        },
      ]

      const tooltip = {
        x: {
          formatter: function (category) {
            return `<b>${category}</b>: ${state.dataUsageOrganization.categoriesSends[category]} sends`
          },
        }
      }

      return {
        ...chartOptions,
        tooltip,
        xaxis: chartXaxis,
        yaxis: chartYaxis,
      }
    },

    organization () {
      return state.organization
    },

    shownMetrics () {
      return state.dataUsageOrganization.series || []
    },
  },

  beforeCreate () {
    const { id_organization } = this.$route.params
    getOrganizationById(id_organization).then(organization => getOrganizationDataUsage(id_organization))
  },

  methods: {
    handleChangePage () {},

    handleClickRow (row) {
      this.$router.push({
        name: 'billing-cycle',
        params: { id_organization: this.organization.id, id_billing_cycle: row.id },
      })
    },
  },
}
</script>
