export default {
  en: {
    edit_content: 'Edit Content',
    new_template: 'New Template',
    templates: 'Templates',
    description: 'Description',
    description_template_placeholder: 'Template description',
    name_template_placeholder: 'Template name',
    manage_template: 'Manage Template',
  },

  es: {
    edit_content: 'Editar Contenido',
    new_template: 'Nueva Plantilla',
    templates: 'Plantillas',
    description: 'Descripción',
    description_template_placeholder: 'Descripción de la plantilla',
    name_template_placeholder: 'Nombre de la plantilla',
    manage_template: 'Gestionar Plantilla',
  },
}
