import ApiClient from '@api/client'

const client = new ApiClient({ path: '/projects' })

export const ProjectsService = {
  /**
   * Add Team
   * @param {Number|String} projectId
   * @param {Number|String} teamId
   */
  async addTeam (projectId, teamId) {
    return await client.post(`/${projectId}/teams/${teamId}`)
  },

  /**
   * Create Project
   * @param {Object} data
   */
  async create (data) {
    return await client.post('', data)
  },

  /**
   * Delete Project
   * @param {Number|String} id
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },

  /**
   * Delete Project
   * @param {Number|String} id
   */
  async deleteMedia (projectId, mediaId) {
    return await client.delete(`/${projectId}/media/${mediaId}`)
  },

  /**
   * Get Project By Id
   * @return {Object} project
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },

  /**
   * Get Audieances By Project Id
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} audiences
   */
  async getAudiences (id, { page = 1, rows = 20 } = {}) {
    return await client.get(
      `/${id}/audiences`,
      {
        params: {
          page,
          rows,
        }
      }
    )
  },

  /**
   * Get Campaigns By Project Id
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} campaigns
   */
  async getCampaigns (id, bucket = '', { page = 1, rows = 8 } = {}) {
    return await client.get(
      `/${id}/campaigns`,
      {
        params: {
          campaign_status: bucket,
          page,
          rows,
        }
      }
    )
  },

  /**
   * Get Email Senders By Project Id
   * @param {Number|String} id
   * @return {Array} email-senders
   */
  async getEmailSenders (id) {
    return await client.get(`/${id}/email-senders`)
  },

  /**
   * Get Integrations By Project Id
   * @param {Number|String} id
   * @return {Array} integrations
   */
  async getIntegrations (id) {
    return await client.get(`/${id}/integrations`)
  },

  /**
   * Get Media Files By Project Id
   * @param {Number|String} id
   * @return {Array} media
   */
  async getMedia (id) {
    return await client.get(`/${id}/media`)
  },

  /**
   * Get SMTP Providers By Project Id
   * @param {Number|String} id
   * @return {Array} smtp-providers
   */
  async getSmtpProviders (id) {
    return await client.get(`/${id}/email-providers`)
  },

  /**
   * Get Teams By Project Id
   * @param {Number|String} id
   * @return {Array} teams
   */
  async getTeams (id) {
    return await client.get(`/${id}/teams`)
  },

  /**
   * Remove Team
   * @param {Number|String} projectId
   * @param {Number|String} teamId
   */
  async removeTeam (projectId, teamId) {
    return await client.delete(`/${projectId}/teams/${teamId}`)
  },

  /**
   * Update Project
   * @param {Number|String} id
   * @param {Object} data
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },

  /**
   * Upload Media
   * @param {Number|String} id
   * @param {Object} data
   */
  async uploadMedia (id, data) {
    return await client.post(`/${id}/media`, data, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  },
}
