import Vue from 'vue'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// animate.css
import 'animate.css'

// datatables
import '../assets/plugins/datatables/datatables.css'

// material-pro
import '../assets/material-pro/scss/style.scss'
import '../assets/material-pro/scss/colors/jampink.scss'

Vue.use(BootstrapVue)
