<template lang="pug">
.InputSearch
  .InputSearch-Icon
    i.fas.fa-search
  input.InputSearch-Input(v-bind='$attrs', v-on='$listeners')
</template>

<script>
export default {}
</script>

<style lang="sass">
@import "@plugins/content-editor/styles/variables"
@import "@plugins/content-editor/styles/mixins"

.InputSearch
  @include input
  padding: 14px 20px
  display: flex
  align-items: center

  &-Icon
    display: flex
    align-items: center
    i
      color: $gray-light
      font-size: 18px

  &-Input
    border: none
    line-height: 1
    font-size: 20px
    &::placeholder
      color: $gray-light
</style>
