<template lang="pug">
.NewBlockButtonList
  ce-new-block-button.NewBlockButtonList-Item(
    v-for='blocktype in blocktypes',
    :key='blocktype',
    :type='blocktype',
    :on-click='onButtonClick'
  )
</template>

<script>
export default {
  props: {
    blocktypes: {
      type: Array,
      default: () => [],
    },
    onButtonClick: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style lang="sass" scoped>
.NewBlockButtonList
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr))
  gap: 10px
</style>
