<template lang="pug">
table.ImageTable(
  border='0'
  cellpadding='0'
  cellspacing='0'
  :align='alignment'
)
  tr(:align='alignment')
    td(
      v-if='src'
      :align='alignment'
    )
      table(
        border='0'
        cellpadding='0'
        cellspacing='0'
        :align='alignment'
      )
        tr
          td(:style='tdParentStyles')
            component(
              :is='parentComponent'
              :target='link ? "_blank" : false'
              :href='link'
            )
              template(v-if='format === "html"')
                img.image(
                  :src='src'
                  :style='imageStyles'
                  :width='width'
                )

              template(v-if='format === "amp"')
                amp-img.image(
                  :height='height',
                  :src='src',
                  :width='width',
                  layout='responsive'
                )
</template>

<script>
import { addQueryParams } from '@/utils'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    ampWidth: {
      type: String,
      default: '500px',
    },
    ampHeight: {
      type: String,
      default: '260px',
    },
  },

  computed: {
    alignment () {
      const { alignment } = this.block.config

      if (alignment === 'full') {
        return 'center'
      }

      return alignment
    },

    isAmp () {
      return this.format === 'amp'
    },

    link () {
      if (!this.block.config.link) return false

      const url = addQueryParams(this.block.config.link, {
        ybte: `13__${this.block.id}`
      })

      return url
    },

    src () {
      return this.block.config.image?.src || ''
    },

    height () {
      return this.block.attrs?.height || this.ampHeight
    },

    width () {
      return this.block.attrs?.width || this.ampWidth
    },

    parentComponent () {
      return this.block.config.link ? 'a' : 'div'
    },

    imageStyles () {
      return {
        width: this.width + 'px',
      }
    },

    tdParentStyles () {
      const styles = {
        width: this.width + 'px',
      }

      return styles
    },
  },
}
</script>
