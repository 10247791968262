export const addQueryParams = (initialUrl = '', queryParams = {}) => {
  let link = initialUrl

  if (Object.keys(queryParams).length) {
    const url = new URL(link)
    const concatChar = url.searchParams.size ? '&' : '?'
    const queryParamsLink = new URLSearchParams(queryParams)
    link += `${concatChar}${queryParamsLink.toString()}`
  }

  return link
}
