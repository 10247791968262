<template lang="pug">
.PermissionGroup(
  :class='{ "clickable": !!onClick }'
  @click='onClick'
)

  ja-permission-item(
    v-for='permission in sortedPermissions'
    v-bind='permission'
    :key='permission.id'
  )
</template>

<script>
import APP_MODULES from '@/const/app-modules'

export default {
  name: 'PermissionGroup',

  props: {
    permissions: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function,
      default: () => {}
    },
  },

  computed: {
    sortedPermissions () {
      const sortedPermissions = []

      APP_MODULES.forEach((module) => {
        const permission = this.permissions.find(permission => permission.module === module)
        if (permission) sortedPermissions.push(permission)
      })

      return sortedPermissions
    }
  }
}
</script>

<style lang="sass" scoped>
.PermissionGroup
  display: flex
  gap: 10px
  &.clickable:hover
    cursor: pointer
</style>
