<template lang="pug">
.DataBlockStats.card
  .DataBlockStats-Header.card-header.d-flex.flex-row
    .DataBlockStats-BlockIcon
      i(:class='[blockIcon, "mr-"]')
    .DataBlockStats-BlockName {{ blockName }}

  .DataBlockStats-EventList.card-body
    .DataBlockStats-Event(v-for='event in blockStats')
      .DataBlockStats-EventName {{ event.name.replaceAll("_", " ") }}
      .DataBlockStats-EventValue {{ event.total }}
</template>

<script>
export default {
  name: 'DataBlockStats',
  props: {
    blockIcon: {
      type: String,
      required: true,
    },
    blockName: {
      type: String,
      required: true,
    },
    blockStats: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.DataBlockStats
  &-BlockIcon
    font-size: 24px

  &-BlockName
    align-self: center
    font-size: 16px
    text-transform: capitalize

  &-Header
    padding: .5rem 1.25rem

  &-EventList
    padding: .75rem

  &-Event
    color: #838c91
    display: flex
    gap: 20px
    justify-content: space-between
    margin-bottom: .75rem
    padding: 3px 5px
    background-color: #f3f3f3
    &:nth-child(even)
      background-color: #f1f5ff
</style>
