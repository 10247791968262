// Components
import ActivityReport from './ActivityReport'
import ActivityAnalyzer from './ActivityAnalyzer'

// Sidebar Links
import sidebar from './activity.sidebar'

// Topbar Links
import topbar from '../dymal.topbar'

// Parent
const parent = 'activity'

export default [
  {
    name: 'activity-analyzer',
    path: '/activity/analyzer',
    component: ActivityAnalyzer,
    meta: {
      breadcrumb: [
        { name: 'activity_analyzer' },
      ],
      pageTitle: 'activity_analyzer',
      parent,
      sidebar,
      topbar,
    },
  },
  {
    name: 'activity-report',
    path: '/activity/report',
    component: ActivityReport,
    meta: {
      breadcrumb: [
        { name: 'activity_report' },
      ],
      pageTitle: 'activity_report',
      parent,
      sidebar,
      topbar,
    },
  },

  // Redirect: Activity Report
  {
    path: '/activity',
    redirect: '/activity/analyzer',
  },
  {
    path: '/activity/*',
    redirect: '/activity/analyzer',
  },
]
