<template lang="pug">
.Subtitle {{ subtitle }}
</template>

<script>
export default {
  props: {
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.Subtitle
  display flex
  font-size 1em
  margin-top .5em
</style>
