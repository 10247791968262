<template lang="pug">
.ResetPassword

  form#ResetPassword.form-horizontal.form-material(
    @submit.prevent='resetPassword'
  )
    h3.auth-title.m-b-20 {{ $t('new_password')}}

    .form-group
      .col-xs-12
        input#password.form-control(
          v-model='password',
          autocomplete='new-password',
          name='password',
          type='password',
          required,
          placeholder='Password'
        )

    .form-group.text-center.m-t-20
      .col-xs-12
        button.btn.btn-primary.btn-lg.btn-block.waves-effect.waves-light.auth-button(
          type='submit'
        ) {{ $t('update')}}

    .form-group
      .ml-auto
        p.text-muted.text-center
          router-link(:to='{ name: "login" }') {{ $t('login')}}
          span &nbsp;
          span —
          span &nbsp;
          router-link(:to='{ name: "register" }') {{ $t('sign_up')}}
</template>

<script>
import { resetPassword } from '@apps/Common/common.store'

export default {
  name: 'Login',

  data () {
    return {
      password: '',
      isSentRequest: false,
    }
  },

  computed: {
    token () {
      return this.$route.params.token || false
    }
  },

  methods: {
    async resetPassword () {
      const result = await resetPassword({
        token: this.token,
        password: this.password,
      })

      if (result?.errors) return

      this.$router.push({ name: 'login', query: { resetpass: 1 } })
    },
  }
}
</script>
