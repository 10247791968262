<template lang="pug">
.Register
  form.form-horizontal.form-material(
    @submit.prevent="createOrganization"
    ref='form'
  )
    h3.auth-title.m-b-20 {{ $t('new_organization')}}

    p {{ $t('before_enter_yamp')}}

    .form-group
      .col-xs-12
        input#name.form-control(
          name='name',
          type='text',
          required,
          :placeholder='$t("name_organization_placeholder")'
        )

    .form-group.text-center.m-t-20
      .col-xs-12
        button.btn.btn-primary.btn-lg.btn-block.waves-effect.waves-light.auth-button(
          type='submit'
        ) {{ $t('create')}}

    .form-group
      .ml-auto
        p.text-muted
          span {{ $t('try_access_as')}}
          span &nbsp;
          strong {{ current_user_email }}
        p.text-muted
          span {{ $t('to_use_another_account')}}
          span &nbsp;
          router-link(:to='{ name: "login" }') {{ $t('go_to_login')}}
</template>

<script>
import { createOrganization } from '@apps/Accounts/Organizations/Organizations/store/organizations.store'
import state, { getCurrentUser } from '@apps/Common/common.store'

export default {
  name: 'Register',

  computed: {
    current_user_email () {
      return state.user?.email
    }
  },

  methods: {
    async createOrganization (event) {
      const formData = new FormData(event.target)
      const { name } = Object.fromEntries(formData)

      const { id: id_organization } = await createOrganization({ name })

      await getCurrentUser()

      this.$router.push({
        name: 'organization',
        params: { id_organization }
      })
    }
  },
}
</script>
