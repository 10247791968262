export default {
  en: {
    analytics: 'Analytics',
    current: 'current',
    custom: 'custom',
    customize_metrics: 'Customize your metrics',
    dashboard: 'Dashboard',
    day: 'day',
    days: 'days',
    end_date: 'End date',
    engagement: 'Engagement',
    events_unique: 'Unique Events',
    last: 'last',
    metrics: 'Metrics',
    month: 'month',
    months: 'months',
    prev: 'previous',
    start_date: 'Start date',
    timeframe: 'Time frame',
    total_events: 'Total Events',
    total_yamps: 'Total Yamps',
    unique_yamps: 'Unique Yamps',
    week: 'week',
    weeks: 'weeks',
    yamp_average: 'Yamp Average',
    yamp_rate: 'Yamp Rate',
    year: 'year',
    years: 'years',

    timeframe_label: (ctx) => {
      const { label } = ctx.values
      const { linked } = ctx
      const [prefix, count, interval] = label.split('_')
      const isPlural = (count > 1)
      return `${linked(prefix)} ${isPlural ? count : ''} ${linked(interval + (isPlural ? 's' : ''))}`
    }
  },

  es: {
    analytics: 'Analítica',
    current: 'actual',
    custom: 'personalizado',
    customize_metrics: 'Personaliza tus métricas',
    dashboard: 'Panel de control',
    day: 'día',
    days: 'días',
    end_date: 'Fecha final',
    engagement: 'Evolución',
    events_unique: 'Eventos únicos',
    last: 'anterior',
    metrics: 'Métricas',
    month: 'mes',
    months: 'meses',
    prev: 'últimos',
    start_date: 'Fecha inicial',
    timeframe: 'Periodo',
    total_events: 'Total de eventos',
    total_yamps: 'Total de Yamps',
    unique_yamps: 'Yamps únicos',
    week: 'semana',
    weeks: 'semanas',
    yamp_average: 'Media de Yamps',
    yamp_rate: 'Tasa de Yamps',
    year: 'año',
    years: 'años',

    timeframe_label: (ctx) => {
      const { label } = ctx.values
      const { linked } = ctx
      const [prefix, count, interval] = label.split('_')
      const isPlural = (count > 1)

      if (prefix === 'current' || prefix === 'last') {
        return `${linked(interval)} ${linked(prefix)}`
      }

      return `${linked(prefix)} ${isPlural ? count : ''} ${linked(interval + (isPlural ? 's' : ''))}`
    }
  },
}
