import ApiClient from '@api/client'

const client = new ApiClient({ path: '/integrations' })

export const IntegrationsService = {
  /**
   * Create Integration
   * @param {Object} data
   */
  async create (data) {
    return await client.post('', data)
  },
  /**
   * Get Integration By Id
   * @return {Object} integration
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },
  /**
   * Get Integration Types
   * @return {Array} integration_types
   */
  async getTypes () {
    return await client.get('/types')
  },
  /**
   * Delete Integration
   * @param {Number|String} id
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },
  /**
   * Uptade Integration
   * @param {Number|String} id
   * @param {Object} data
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
