<template lang="pug">
.table-responsive
  .dataTables_wrapper.dt-bootstrap4
    b-table.DataTable(
      outlined,
      :hover='!!onRowClick',
      :selectable='!!onRowClick',
      :fields='fields',
      :items='items',
      @row-selected='onRowSelected'
    )

    ja-pagination(
      v-if='items.length && pagination',
      :options='pagination',
      :on-change-page='onChangePage'
    )
</template>

<script>
import Filters from './Filters.vue'

export default {
  name: 'DataTable',
  components: { Filters },
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    onChangePage: {
      type: Function,
      default: () => {},
    },
    onRowClick: {
      type: [Function, null],
      default: null,
    },
    pagination: {
      type: [Object, null],
      default: null,
    },
  },
  methods: {
    onRowSelected (items) {
      this.onRowClick(items[0])
    },
  },
}
</script>

<style lang="sass">
@import @plugins/ja/styles/variables

.DataTable
  background-color: #fff
  border-radius: 4px
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05)
  color: #3d434a

  tr
    td:first-child, th:first-child
      padding-left: 20px

  thead
    background-color: #f8f9fb
    font-size: 15px
    font-weight: 600
    th
      padding-bottom: 8px
      padding-top: 8px

  tbody
    tr
      font-size: 14px
      color: #67757c
      transition: all .25s ease-in-out
      &:hover
        background-color: #f1f1f1
        cursor: pointer
        color: $purple
  .col-240
    width: 240px
</style>
