<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='invite' ref='form')

        // Email
        ja-form-field(
          :on-change='handleUserInvitation'
          label='email'
          ref='inputEmail'
          id="email"
          type='email'
        )

        // Admin
        ja-form-field(
          :fieldValue='userInvitation.isAdmin'
          :label='$t("is_admin")'
          :on-change='handleUserInvitation'
          id='isAdmin'
          type='switch'
        )

        // Admin
        ja-form-field(
          label='teams'
          id='teams'
        )
          template(v-slot:value)
            ja-checkbox-list(
              v-if='teams.length'
              :options='teams'
              :on-change='(values) => handleUserInvitation(values, "teams")'
            )
</template>

<script>
import {
  inviteUser,
} from '@apps/Accounts/Organizations/Users/store/users.store'

import organizationState, {
  getTeamsByOrganizationId,
} from '@apps/Accounts/Organizations/Organizations/store/organizations.store'

export default {
  name: 'UserInvite',

  data () {
    const pageTitle = this.$t('invite_user')

    const pageActions = []

    pageActions.push({
      callback: this.invite,
      icon: 'mdi mdi-email-plus',
      text: 'invite',
      type: 'outline-primary',
    })

    const userInvitation = {
      email: '',
      organizationId: this.$route.params.id_organization,
      isAdmin: false,
      teams: [],
    }

    return {
      userInvitation,
      pageActions,
      pageTitle,
      showForm: false,
    }
  },

  computed: {
    teams: () => {
      return organizationState?.teams?.items?.map((team, index) => ({
        value: team.id,
        label: team.name,
        checked: index === 0
      })) || []
    },
  },

  watch: {
    showForm (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.inputEmail.$el.querySelector('input').focus()
        })
      }
    }
  },

  async created () {
    // Load teams
    await getTeamsByOrganizationId(this.$route.params.id_organization)

    this.showForm = true
  },

  methods: {

    handleUserInvitation (fieldValue, fieldName) {
      this.$set(this.userInvitation, fieldName, fieldValue)
    },

    async invite () {
      const {
        email,
        teams,
        isAdmin: admin,
        organizationId: organization_id,
      } = this.userInvitation

      const invitation = await inviteUser({
        admin,
        email,
        organization_id,
        teams,
      })

      if (!invitation) return

      // Go to users organization page
      this.$router.push({
        name: 'users',
        params: { id_organization: organization_id }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.Team
  margin-bottom: 10px
</style>
