<template lang="pug">
.Filters.card

  .card-body

    .Filters-header.d-flex
      .align-self-center
        button.btn.btn-secondary(data-toggle='dropdown')
          i.ti.ti-angle-down
          span Configurar el informe

      .ml-auto
        .button-group
          button.btn.btn-primary(type='button')
            span Run
            i.mdi.mdi-play
          button.btn.btn-info(type='button')
            span Save
            i.mdi.mdi-content-save

    .Filters-body
      .row-wrap
        .row-cell
          .label-desc-wrap Timeframe
        .row-cell
          button.btn.btn-secondary(type='button')
            span.btn-label
              i.ti-calendar
            span Last week
      .row-wrap
        .row-cell
          .label-desc-wrap Performed by
        .row-cell
          button.btn.btn-secondary(type='button')
            span.btn-label
              i.fas.fa-users
            span All visitors
              i.mdi.mdi-unfold-more
      .row-wrap
        .row-cell
          .label-desc-wrap Columns
        .row-cell
          .button-group
            .btn.btn-success.btn-rounded
              .btn-inner
                i.mdi.mdi-close
              span Visitor ID
            .btn.btn-success.btn-rounded
              .btn-inner
                i.mdi.mdi-close
              span AMP ID
            .btn.btn-success.btn-rounded
              .btn-inner
                i.mdi.mdi-close
              span Page name
            .btn.btn-outline-success.btn-circle
              i.ti-plus
</template>

<script>
export default {
  name: 'Filters'
}
</script>

<style lang='stylus' scoped>
.Filters
  &-body
    padding-top 15px
    display table

    .btn
      position relative
      padding 5px 10px

      &-circle
        height 36px
        line-height 36px
        width 36px
        padding 0

      &-label
        margin -7px 12px -7px -10px
        padding 7px 0
        text-align center
        width 44px
        i
          font-size 1.2em

      &-rounded
        padding 5px 30px

      &:hover
        .btn-inner
          opacity 1

      &-inner
        background-color #20a8b9
        border none
        border-radius 20px
        color #fff
        height 20px
        line-height 20px
        left 6px
        opacity 0
        padding 0
        position absolute
        text-align center
        width 20px
        transition all .15s cubic-bezier(.14,.92,1,.52)

        &:hover
          background-color #de3c60

    .label-desc-wrap
      display flex
      font-weight normal
      white-space nowrap
      margin-right 15px

    .row-wrap
      display table-row

      .row-cell
        display table-cell
        vertical-align middle
        height 46px

  // Icons
  .mdi-unfold-more,
  .mdi-play,
  .mdi-content-save
    margin-left 5px

  .ti-angle-down
    margin-top 2px
    margin-right 5px

  &-header

    .btn
      padding 3px 10px
</style>
