<template lang="pug">
ja-content(:pageActions='pageActions' :pageTitle='pageTitle')
  // Campaign Settings
  b-card(v-if='showForm')
    b-card-text
      form.form
        // Campaign Name
        ja-form-field#name(
          label='name'
          placeholder='name_campaign_placeholder'
          ref='inputName'
          type='text'
          :field-value='campaignName'
          :on-change='handleChangeName'
        )

        // Description
        ja-form-field#description(
          label='description'
          placeholder='description_placeholder'
          type='text'
          :field-value='description'
          :on-change='handleChangeDescription'
        )

        // To (email recipients)
        ja-form-field#to(
          label='to'
          type='select'
          :field-value='audience'
          :options='audienceList'
          :on-change='handleChangeAudience'
        )

        // From Name
        ja-form-field#fromName(
          label='sender_name'
          placeholder='sender_name_placeholder'
          type='text'
          :field-value='senderName'
          :on-change='handleChangeSenderName'
        )

        // From Email
        ja-form-field#from(
          label='sender_email'
          type='select'
          :field-value='senderEmail'
          :options='emailSenderList'
          :on-change='handleChangeSenderEmail'
        )

        // Subject
        ja-form-field#subject(
          label='subject'
          placeholder='subject_placeholder'
          type='text',
          :field-value='subject',
          :on-change='handleChangeSubject'
        )

        // Template Selector
        template(v-if='isNew || !campaignContent')
          ja-form-field(label='choose_template')
            template(v-slot:value)
              ja-template-deck(
                selectable-item
                :items='templates'
                :on-click='handleSelectTemplate'
              )

        // Content
        template(v-else)
          ja-form-field(label='content')
            template(v-slot:value)
              ja-template-card(
                :image='campaignContent.preview'
                :overlay-text='$t("edit_content")'
                :to='contentEditorRoute'
              )
</template>

<script>
import { sleep } from '@utils'

import campaignState, {
  addAudienceToCampaign,
  createCampaign,
  createCampaignContent,
  deleteCampaignById,
  getCampaignById,
  removeAudienceFromCampaign,
  updateCampaignById,
} from './store/campaigns.store'

import templateState, {
  getTemplatesByProject,
} from '@apps/Dymal/Templates/Templates/store/templates.store'

import projectState, {
  getProjectAudiences,
  getProjectEmailSenders,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

const loadingPromises = []

export default {
  name: 'CampaignEdit',

  data () {
    return {
      campaignName: '',
      description: '',
      pageActions: [],
      pageTitle: this.$t('new_campaign'),
      selectedAudience: 0,
      senderEmail: '',
      senderName: '',
      showForm: false,
      subject: '',
      content: null,
    }
  },

  computed: {
    campaign: () => campaignState.campaign,
    campaignContent: () => campaignState.campaign.campaign_content,

    audience () {
      if (this.isNew) return this.audienceList[0]?.key || 0

      const audienceIndex = this.campaign?.audiences[0]?.id
        ? this.audienceList.findIndex(
          (aud) => aud.key === this.campaign.audiences[0].id
        )
        : 0

      return this.audienceList[audienceIndex]?.key
    },

    audiences () {
      return projectState.audiences?.items || []
    },

    audienceList () {
      return this.audiences.map((audience) => ({
        key: audience.id,
        text: audience.name,
      }))
    },

    emailSenders () {
      return projectState.emailSenders
    },

    emailSenderList () {
      return this.emailSenders.map((emailSender) => ({
        key: emailSender.email,
        text: emailSender.email,
      }))
    },

    templates () {
      return templateState.templates
    },

    campaignData () {
      return {
        name: this.campaignName,
        description: this.description,
        from: this.senderEmail,
        from_name: this.senderName,
        subject: this.subject,
      }
    },

    contentEditorRoute () {
      return {
        name: 'campaign-content-editor',
        params: { id_campaign: this.$route.params.id_campaign },
      }
    },

    isCampaignEditable () {
      return this.campaign?.status === 'draft'
    },

    isNew () {
      return !this.$route.params.id_campaign
    },
  },

  beforeCreate () {
    loadingPromises.push(
      getProjectEmailSenders(),
      getProjectAudiences({ pagination: { rows: 100 } })
    )

    if (this.isNew || !this.campaignContent) {
      loadingPromises.push(getTemplatesByProject())
    }
  },

  async created () {
    // Add action buttons to create a new campaign
    if (this.isNew) {
      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      await Promise.allSettled(loadingPromises)

      this.senderEmail = this.emailSenders[0]?.email
      this.selectedAudience = this.audience
      this.showForm = true

      return
    }

    // Add action buttons to edit an project
    this.pageActions.push({
      callback: this.delete,
      icon: 'ti-trash',
      text: 'delete',
      type: 'outline-danger',
    })

    this.pageActions.push({
      callback: this.update,
      icon: 'ti-save',
      text: 'update',
      type: 'outline-primary',
    })

    await getCampaignById(this.$route.params.id_campaign)

    this.pageTitle = this.campaign.name
    this.campaignName = this.campaign.name
    this.description = this.campaign.description
    this.senderEmail = this.campaign.from
    this.senderName = this.campaign.from_name
    this.selectedAudience = this.audience
    this.subject = this.campaign.subject

    this.showForm = true
  },

  mounted () {
    this.$nextTick(async () => {
      await Promise.allSettled(loadingPromises)
      await sleep(1)

      if (this.isNew) {
        this.$refs.inputName.$el.querySelector('input').focus()
      }
    })
  },

  methods: {
    handleChangeAudience (value) {
      this.$set(this, 'selectedAudience', Number(value))
    },

    handleChangeDescription (value) {
      this.description = value
    },

    handleChangeName (value) {
      this.campaignName = value
    },

    handleChangeFrom (value) {
      this.from = value
    },

    handleChangeSenderEmail (value) {
      const sender = this.emailSenders.find(es => es.email === value)
      if (sender?.name) this.handleChangeSenderName(sender.name)
      this.senderEmail = value
    },

    handleChangeSenderName (value) {
      this.senderName = value
    },

    handleChangeSubject (value) {
      this.subject = value
    },

    handleSelectTemplate (template) {
      const previewUrl = new URL(template.preview)

      this.content = {
        campaign_template_id: template.id,
        config: template.config,
        content_html: template.content_html,
        content_amp: template.content_amp,
        preview: previewUrl.pathname,
      }
    },

    async delete () {
      await deleteCampaignById(this.campaign.id)
      this.$router.push({ name: 'campaigns' })
    },

    async save () {
      // Save Campaing
      const campaign = await createCampaign(this.campaignData)

      if (!campaign) return

      const savePromises = []

      // Save Content
      if (this.content) {
        savePromises.push(
          createCampaignContent({
            campaign_id: campaign.id,
            ...this.content,
          })
        )
      }

      // Save Audience
      savePromises.push(
        addAudienceToCampaign(campaign.id, this.selectedAudience)
      )

      await Promise.all(savePromises)

      // Go to the new project page
      this.$router.push({
        name: 'campaign',
        params: { id_campaign: campaign.id },
      })
    },

    async update () {
      await updateCampaignById(this.campaign.id, this.campaignData)

      if (this.audience !== this.selectedAudience) {
        await Promise.allSettled([
          removeAudienceFromCampaign(this.campaign.id, this.audience),
          addAudienceToCampaign(this.campaign.id, this.selectedAudience),
        ])
      } else if (!this.audience) {
        await addAudienceToCampaign(this.campaign.id, this.selectedAudience)
      }

      if (!this.campaignContent && this.content) {
        createCampaignContent({
          campaign_id: this.campaign.id,
          ...this.content,
        })
      }

      // Go to the campaign overview page
      this.$router.push({
        name: 'campaign',
        params: { id_campaign: this.campaign.id },
      })
    },
  },
}
</script>
