export default [
  {
    key: 0,
    text: 'sunday',
  },
  {
    key: 1,
    text: 'monday',
  },
  {
    key: 2,
    text: 'tuesday',
  },
  {
    key: 3,
    text: 'wednesday',
  },
  {
    key: 4,
    text: 'thursday',
  },
  {
    key: 5,
    text: 'friday',
  },
  {
    key: 6,
    text: 'saturday',
  },
]
