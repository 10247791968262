import Vue from 'vue'
import { UsersService } from '../service/users.service'
import { setQueryParam, setLoadingMessage, useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  user: {},
  organization: {},
})

// actions
export const assingProject = async (userId, projectId) => {
  return await useLoader(UsersService.assingProject, userId, projectId)
}

export const getUserByUserIdAndOrganizationId = async (userId, organizationId) => {
  const { admin, user, organization } = await useLoader(UsersService.getUserByUserIdAndOrganizationId, userId, organizationId)
  user.admin = admin

  Vue.set(state, 'user', user)
  Vue.set(state, 'organization', organization)

  setQueryParam('id_user', user.name)
  setQueryParam('id_organization', organization.name)

  return user
}

export const createUser = async (data) => {
  return await useLoader(UsersService.create, data)
}

export const inviteUser = async (data) => {
  return await useLoader(UsersService.invite, data)
}

export const registerUserOrganization = async (data) => {
  setLoadingMessage('We are processing your invitation')
  return await useLoader(UsersService.registerOrganization, data)
}

export const updateUserById = async (id, data) => {
  const user = await useLoader(UsersService.update, id, data)
  Vue.set(state, 'user', user)
  return user
}

export const removeUserFromOrganization = async (data) => {
  return await useLoader(UsersService.removeFromOrganization, data)
}

export default state
