export default {
  en: {
    activity_feed: 'Activity Feed',
    audience: 'Audience',
    audiences: 'Audiences',
    bounced_emails: 'Bounced',
    browse_file: 'Browse a file',
    campagin_defaults: 'Campagin Defaults',
    clicked_emails: 'Clicks',
    default_email: 'Default Email',
    default_name_placeholder: 'Default Name',
    default_name: 'Default Name',
    delivered_emails: 'Delivered',
    description_audience_placeholder: 'The audience description',
    description: 'Description',
    dropped_emails: 'Dropped',
    email_client: 'Cliente de email',
    email_format: 'Formato de email',
    events: 'Events',
    gdpr: 'GDPR',
    id: 'ID',
    import_contacts: 'Import contacts',
    label_text: 'Label Text',
    legal_text: 'Legal Text',
    manage_audience: 'Manage Audience',
    manage_contact: 'Manage Contact',
    name_audience_placeholder: 'The audience name',
    new_audience: 'New Audience',
    new_contact: 'New Contact',
    opened_emails: 'Opened',
    opened_amp_emails: 'Opened AMP',
    opened_html_emails: 'Opened HTML',
    policy_url: 'Policy URL',
    profile_information: 'Profile Information',
    project_id: 'Project ID',
    sender: 'Sender',
    sent_emails: 'Sent',
    spam_reported: 'Spam Reported',
    total_clicks: 'Total Clicks',
    total_opens: 'Total Opens',
    unique_clicks: 'Unique Clicks',
    unique_clicks_amp: 'Unique Clicks AMP',
    unique_clicks_html: 'Unique Clicks HTML',
    unique_opens: 'Unique Opens',
    unique_opens_amp: 'Unique Opens AMP',
    unique_opens_html: 'Unique Opens HTML',
    unsubscribed: 'Unsubscribed',
  },

  es: {
    activity_feed: 'Reporte de Actividad',
    audience: 'Audiencia',
    audiences: 'Audiencias',
    bounced_emails: 'Rebotes',
    browse_file: 'Buscar un archivo',
    campagin_defaults: 'Valores predeterminados para las Campañas',
    clicked_emails: 'Clicks',
    default_email: 'Email por defecto',
    default_name: 'Nombre por defecto',
    delivered_emails: 'Entregas',
    description_audience_placeholder: 'Descripción de audiencia',
    description: 'Descripción',
    dropped_emails: 'Descartados',
    email_client: 'Cliente de email',
    email_format: 'Formato de email',
    events: 'Events',
    gdpr: 'GDPR',
    id: 'ID',
    import_contacts: 'Importar contactos',
    label_text: 'Texto de la etiqueta',
    legal_text: 'Texto legal',
    manage_audience: 'Gestionar Audiencia',
    manage_contact: 'Gestionar Contacto',
    name_audience_placeholder: 'Nombre de audiencia',
    new_audience: 'Nueva Audiencia',
    new_contact: 'Nuevo Contacto',
    opened_emails: 'Aperturas',
    opened_amp_emails: 'Aperturas AMP',
    opened_html_emails: 'Aperturas HTML',
    policy_url: 'URL de las condiciones',
    profile_information: 'Información de Perfil',
    project_id: 'ID Proyecto',
    sender: 'Remitente',
    sent_emails: 'Envíos',
    spam_reported: 'Reportados spam',
    total_clicks: 'Total de clicks',
    total_opens: 'Total de aperturas',
    unique_clicks: 'Clicks únicos',
    unique_clicks_amp: 'Clicks únicos AMP',
    unique_clicks_html: 'Clicks únicos HTML',
    unique_opens: 'Aperturas únicas',
    unique_opens_amp: 'Aperturas únicas AMP',
    unique_opens_html: 'Aperturas únicas HTML',
    unsubscribed: 'Desuscritos',
  },
}
