export default {
  items: [
    {
      name: 'campaigns',
      classIcon: 'mdi mdi-bullhorn',
      to: '/campaigns',
      slug: 'campaigns',
    },
    {
      name: 'templates',
      classIcon: 'mdi mdi-view-dashboard',
      to: '/templates',
      slug: 'templates',
    },
    {
      name: 'audiences',
      classIcon: 'mdi mdi-target-account',
      to: '/audiences',
      slug: 'audiences',
    },
    {
      name: 'analytics',
      classIcon: 'mdi mdi-chart-bar',
      to: '/analytics',
      slug: 'analytics',
    },
    // {
    //   name: 'activity',
    //   classIcon: 'mdi mdi-chart-donut',
    //   to: '/activity',
    //   slug: 'activity',
    // },
    {
      name: 'configure',
      classIcon: 'mdi mdi-cog',
      to: '/configurations',
      slug: 'configurations',
    },
  ],
}
