import ApiClient from '@api/client'

// TODO: move to a env var
const client = new ApiClient({ path: '/auth' })

export const AuthService = {
  /**
   * login
   * @param {Object} credentials
   */
  async login (credentials = {}) {
    return await client.post('/login', credentials)
  },

  /**
   * login
   * @param {Object} credentials
   */
  async logout (credentials = {}) {
    return await client.delete('/logout', credentials)
  },
}
