export default {
  en: {
    amp_events: 'AMP Events',
    amp_opens: 'Opened AMP',
    batches: 'batches',
    bounce_rate: 'Bounce Rate',
    campaign: 'Campaign',
    campaign_send_audience_number: 'The audience has {number} recipients',
    campaigns_canceled: 'Canceled campaigns',
    campaigns_completed: 'Completed campaigns',
    campaigns_draft: 'Campaign drafts',
    campaigns_ongoing: 'Campaigns on going',
    campaigns: 'Campaigns',
    choose_template: 'Choose a template',
    click_rate: 'Click Rate',
    click_rate_amp: 'Click Rate AMP',
    click_rate_html: 'Click Rate HTML',
    clicks: 'Clicks',
    contacts_batch: 'Contacts by batch',
    content: 'Content',
    conversion_rate: 'Conversion Rate',
    conversions: 'Conversions',
    delivered_p: 'Delivered',
    delivered: 'Delivered',
    deliveries: 'Delivered',
    delivery_date: 'Delivery Date',
    delivery_rate: 'Delivery Rate',
    delivery_time: 'Delivery Time',
    description_placeholder: 'The campaign description',
    frequency: 'Frequency',
    from: 'From',
    growth: 'Growth',
    html_opens: 'Opened HTML',
    manage_campaign: 'Manage Campaign',
    name_campaign_placeholder: 'The campaign name',
    new_campaign: 'New Campaign',
    open_rate: 'Open Rate',
    open_rate_amp: 'Open Rate HTML',
    open_rate_html: 'Open Rate AMP',
    opens: 'Opened',
    recipient: 'Recipient',
    recipient_placeholder: 'The recipient email',
    report: 'Report',
    reschedule: 'Reschedule',
    reschedule_campaign: 'Reschedule Campaign',
    schedule: 'Schedule',
    schedule_campaign: 'Schedule Campaign',
    send: 'Send',
    send_email: 'Send email',
    send_test: 'Send test',
    sender_email: 'Sender email',
    sender_name: 'Sender name',
    sender_name_placeholder: 'The sender name',
    sender_placeholder: 'The sender email',
    sending: 'Sending',
    sent: 'Sent',
    setup_schedule: 'Set up your schedule',
    split_audiences_batches: 'Deliver your campaign in batches to prevent website-crushing click floods.',
    subject_placeholder: 'The email subject',
    subject: 'Subject',
    to: 'To',
    yamps: 'Yamps',
  },

  es: {
    amp_events: 'AMP Events',
    amp_opens: 'Opened AMP',
    batches: 'Lotes',
    bounce_rate: 'Tasa de Rebote',
    campaign: 'Campaña',
    campaigns_canceled: 'Campañas canceladas',
    campaigns_draft: 'Campañas en borrador',
    campaigns_ended: 'Campañas finalizadas',
    campaigns_ongoing: 'Campañas en curso',
    campaign_send_audience_number: 'La audiencia tiene {number} contactos',
    campaigns: 'Campañas',
    choose_template: 'Selecciona una plantilla',
    click_rate: 'Tasa de Clicks',
    click_rate_amp: 'Tasa de Clicks AMP',
    click_rate_html: 'Tasa de Clicks HTML',
    clicks: 'Clicks',
    contacts_batch: 'Contactos por lote',
    content: 'Contenido',
    conversion_rate: 'Tasa de Conversion',
    conversions: 'Conversiones',
    delivered_p: 'Entregados',
    delivered: 'Entregado',
    deliveries: 'Entregas',
    delivery_date: 'Fecha de envío',
    delivery_rate: 'Tasa de Entrega',
    delivery_time: 'Hora de envío',
    description_placeholder: 'Escribe una descripción para la campaña ',
    frequency: 'Frecuencia',
    from: 'De',
    growth: 'Crecimiento',
    html_opens: 'Opened HTML',
    manage_campaign: 'Gestionar Campaña',
    name_campaign_placeholder: 'Escribe el nombre de la campaña',
    new_campaign: 'Nueva Campaña',
    open_rate: 'Tasa de Apertura',
    open_rate_amp: 'Tasa de Apertura HTML',
    open_rate_html: 'Tasa de Apertura AMP',
    opens: 'Aperturas',
    recipient: 'Destinatario',
    recipient_placeholder: 'Escribe el email de destino',
    report: 'Informe',
    reschedule: 'Replanificar',
    reschedule_campaign: 'Replanificar envío',
    schedule: 'Planificar',
    schedule_campaign: 'Planificar envío',
    send: 'Enviar',
    send_email: 'Enviar email',
    send_test: 'Envío de prueba',
    sender_email: 'Email del remitente',
    sender_name: 'Nombre del remitente',
    sender_name_placeholder: 'Escribe el nombre del remitente',
    sender_placeholder: 'Escribe el email del remitente',
    sending: 'Enviando',
    sent: 'Enviado',
    setup_schedule: 'Planifica tu campaña',
    split_audiences_batches: 'Envía esta campaña en lotes para evitar problemas en la entrega y saturación del website de aterrizaje.',
    subject_placeholder: 'Escribe el asunto del email',
    subject: 'Asunto',
    to: 'Para',
    yamps: 'Yamps',
  },
}
