import { isArray, size } from 'lodash'
import numeral from 'numeral'
import commonState from '@apps/Common/common.store'

export default {
  methods: {
    jaActiveLink (parent) {
      return !!(
        (this.to === decodeURIComponent(this.$route.path) &&
          !size(this.$route.query)) ||
        parent === this.slug
      )
    },

    jaDetailView (slug, item, idName = '') {
      const route = { name: slug, params: {} }
      idName = idName || `id_${slug}`

      route.params[idName] =
        item[item.idField] || item.id || item.name || item[idName]

      this.$router.push(route)
    },

    jaFormatNumber (number) {
      return numeral(number).format('0,0')
    },

    jaGetRouteParam (param) {
      if (param[0] === ':') {
        return (
          commonState.queryParams[param.substr(1)] ||
          this.$t(this.$route.params[param.substr(1)]) ||
          this.$t(param)
        )
      }
      return this.$t(param)
    },

    jaGetRouteWithParams (route) {
      return route.replace(
        /(:[\w_]+)/g,
        (_str, param) => this.$route.params[param.substr(1)]
      )
    },

    jaGenerateUuid () {
      let dt = new Date().getTime()
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        (c) => {
          const r = (dt + Math.random() * 16) % 16 | 0
          dt = Math.floor(dt / 16)
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
        }
      )
      return uuid
    },

    jaLog (message) {
      if (typeof console !== 'undefined') console.log(message)
      return message
    },

    jaIsArray (data) {
      return isArray(data)
    },

    // Numeral.js
    numeral,
  },
}
