<template lang="pug">
content-editor-page(
  v-if='isDataloaded',
  :title='template.name',
  :config='template.config',
  :ampBlockEvents='ampBlockEvents',
  :integrations='integrations',
  :integration-types='integrationTypes',
  :on-delete-image='deleteImage',
  :on-get-image-library='getImageLibrary',
  :on-get-product-list='getProductList',
  :on-save-content='saveContent',
  :on-save-preview='savePreview',
  :on-upload-image='uploadImage'
)
</template>

<script>
import ContentEditorPage from '@apps/Dymal/ContentEditor/ContentEditor'

import state, {
  getTemplateById,
  saveTemplatePreview,
  updateTemplateById,
} from './store/templates.store'

import projectState, {
  deleteProjectMedia,
  getProjectMedia,
  getProjectIntegrations,
  getProductList,
  uploadProjectMedia,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

import integrationState, {
  getIntegrationTypes,
} from '@apps/Dymal/Configurations/Integrations/store/integrations.store'

import ampBlockState, { getAmpBlocks } from '@/store/yamp-blocks.store'

export default {
  name: 'TemplateContentEditor',

  components: { ContentEditorPage },

  data () {
    return {
      isDataloaded: false,
    }
  },

  computed: {
    ampBlockEvents () {
      return ampBlockState.ampBlockEventsMap
    },
    integrations () {
      return projectState.integrations
    },
    integrationTypes () {
      return integrationState.integrationTypes
    },
    template () {
      return state.template
    },
  },

  created () {
    const promises = []

    if (!this.template) {
      promises.push(getTemplateById(this.$route.params.id_template))
    }

    if (!this.ampBlockEvents) {
      promises.push(getAmpBlocks())
    }

    if (!this.integrations.length) {
      promises.push(getProjectIntegrations())
    }

    if (!this.integrationTypes.length) {
      promises.push(getIntegrationTypes())
    }

    Promise.all(promises).then(() => {
      this.isDataloaded = true
    })
  },

  methods: {
    async deleteImage (id) {
      return await deleteProjectMedia(id)
    },

    async getImageLibrary () {
      return await getProjectMedia()
    },

    async getProductList (data) {
      return await getProductList(data)
    },

    async saveContent ({ config, content_amp = '', content_html = '' }) {
      await updateTemplateById(this.template.id, {
        ...this.template,
        config: JSON.stringify(config),
        content_amp,
        content_html,
      })
    },

    async savePreview (data) {
      return await saveTemplatePreview(this.template.id, data)
    },

    async uploadImage (data) {
      return await uploadProjectMedia(data)
    },
  },
}
</script>
