<template lang="pug">
.ForgotPassword

  form#ForgotPassword.form-horizontal.form-material(
    @submit.prevent='requestNewPassword'
  )
    h3.auth-title.m-b-20 {{ $t('forgot_password') }}

    p {{ $t('request_password_message')}}

    template(v-if='!isSentRequest')

      .form-group
        .col-xs-12
          input#email.form-control(
            v-model='email',
            type='text',
            required='',
            placeholder='Email'
          )

      .form-group.text-center.m-t-20
        .col-xs-12
          button.btn.btn-primary.btn-lg.btn-block.waves-effect.waves-light.auth-button(
            type='submit'
          ) {{ $t('reset_password') }}

    template(v-else)
      p If {{ email }} is a valid Yamp account, you will receive an email containing your password reset instructons. You may now close this window.

    .form-group
      .ml-auto
        p.text-muted.text-center
          router-link(:to='{ name: "login" }') {{ $t('login')}}
          span &nbsp;
          span —
          span &nbsp;
          router-link(:to='{ name: "register" }') {{ $t('sign_up')}}
</template>

<script>
import { requestNewPassword } from '@apps/Common/common.store'

export default {
  name: 'Login',

  data () {
    return {
      email: '',
      isSentRequest: false,
    }
  },

  methods: {
    async requestNewPassword (_event) {
      await requestNewPassword(this.email)

      this.isSentRequest = true
    },
  }
}
</script>
