<template lang="pug">
.BlockReview(:style='cssVars')
  template(v-if='!showFallback')
    .BlockReview-Title(
      contenteditable,
      @input='(event) => handleChange(event, "heading")'
    ) {{ content.heading }}

    .BlockReview-Rating
      img(
        v-for='rating in config.rating',
        @mouseenter='() => (itemHovered = rating)',
        @mouseleave='() => (itemHovered = 0)',
        :src='getIconSrc(config.iconFigure, rating <= itemHovered)',
        :key='rating',
        :data-key='rating'
      )

    .BlockReview-Label(
      contenteditable,
      @input='(event) => handleChange(event, "titleLabel")'
    ) {{ content.titleLabel }}

    .BlockReview-Input(
      contenteditable,
      @input='(event) => handleChange(event, "titlePlaceholder")'
    ) {{ content.titlePlaceholder }}

    .BlockReview-Label(
      contenteditable,
      @input='(event) => handleChange(event, "reviewLabel")'
    ) {{ content.reviewLabel }}

    .BlockReview-Textarea(
      contenteditable,
      @input='(event) => handleChange(event, "reviewPlaceholder")'
    ) {{ content.reviewPlaceholder }}

    .BlockReview-Submit(
      contenteditable,
      @input='(event) => handleChange(event, "submit")'
    ) {{ content.submit }}

  template(v-if='showFallback')
    .BlockReview-Submit(
      contenteditable,
      @input='(event) => handleChange(event, "fallback")'
    ) {{ content.fallback }}
</template>

<script>
import CONFIG_CONST from '@config/config.const'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    showFallback: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      content: { ...this.block.content },
      itemHovered: 0,
    }
  },
  computed: {
    config () {
      return this.block.config
    },
    cssVars () {
      return {
        '--button-background': this.block.config.colors.buttonBackground,
        '--button-text': this.block.config.colors.buttonText,
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
        '--text-color': this.config.colors.text,
      }
    },
  },

  methods: {
    getIconSrc (figure, full = false) {
      const { colorScheme } = this.block.config

      // TODO: support more figures
      // return `${CONFIG_CONST.BASE_URL_PUBLIC}/amp/review/${figure}_${

      return `${CONFIG_CONST.BASE_URL_PUBLIC}/amp/review/star_${
        full ? 'full' : 'empty'
      }_${colorScheme}.png`
    },

    handleChange (event, fieldName) {
      const value = event.target.innerText

      this.onChange({
        ...this.block.content,
        [fieldName]: value,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

$marginBottom: 20px
@mixin vertical-spacing($mb: $marginBottom)
  margin-bottom: $mb

.BlockReview
  color: var(--text-color)
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-Description
    font-size: 18px
    @include vertical-spacing

  &-Description,
  &-Title
    text-align: center

  &-Label
    color: $gray-dark
    margin-bottom: 10px
    font-weight: bold

  &-Rating
    @include vertical-spacing(30px)
    display: flex
    gap: 1vh
    justify-content: center
    font-size: 30px
    img
      cursor: pointer
      height: 32px
      width: 32px

  &-Submit
    @include submit-button

  &-Input,
  &-Textarea
    @include input
    @include vertical-spacing

  &-Input
    padding: 10px 24px

  &-Textarea
    line-height: 1.65
    padding: 18px 24px

  &-Title
    font-size: 32px
    @include vertical-spacing
</style>
