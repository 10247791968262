<template lang="pug">
.SmartCheck
  .SmartCheck-Checkbox.checkbox(v-if="checkbox")
    input(type="checkbox" :id="uuid" :checked="checkbox.checked")
    label(:for="uuid")
  i.SmartCheck-Icon(:class="icon")
  .SmartCheck-Content
    router-link(v-if="to" :to="to").SmartCheck-Title {{ title }}
    .SmartCheck-Title(v-else) {{ title }}
    .SmartCheck-Subtitle {{ subtitle }}
    .SmartCheck-Info {{ info }}
</template>

<script>
export default {
  name: 'SmartCheck',
  props: {
    checkbox: { type: [Object, null], required: false, default: null },
    info: { type: [String, null], required: false, default: null },
    icon: { type: [String, null], required: false, default: null },
    name: { type: [String, null], required: false, default: null },
    subtitle: { type: [String, null], required: false, default: null },
    title: { type: String, required: true },
    to: { type: Object, required: true },
  },
  data () {
    return {
      uuid: this.jaGenerateUuid(),
    }
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

.SmartCheck
  vertical-align: middle
  display: flex
  &-Checkbox
    [type=checkbox]
      & + label
        height: auto
        margin: 4px 0
        &:before
          border: 1px solid #a4a5a7
          border-radius: 3px
          height: 22px
          width: 22px
      &:checked + label:before
        op: -4px
        left: -5px
        width: 14px
        height: 24px
        border-top: 2px solid transparent
        border-left: 2px solid transparent
        border-right: 2px solid #26a69a
        border-bottom: 2px solid #26a69a
        transform: rotate(40deg)
        -webkit-backface-visibility: hidden
        backface-visibility: hidden
        -webkit-transform-origin: 100% 100%
        transform-origin: 100% 100%
  &-Content
    margin-left: 4px
  &-Icon
    margin-left: 20px
    font-size: 28px
    color: #b1b1b1
  &-Info
    color: #96949d
    font-size: 13px
    margin-top: 7px
  &-Subtitle
    color: #6a6a6a
    font-size: 14px
    margin-top: 4px
  &-Title
    font-size: 17px
  a#{&}-Title
    color: $purple
    &:hover
      color: $purple-dark
      text-decoration: underline
</style>
