import Vue from 'vue'
import { IntegrationsService } from '../service/integrations.service'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  integration: {},
  integrationTypes: [],
})

const getWebhookSkeleton = () => ({
  name: '',
  category: 'webhook',
  type_id: 0,
  project_id: common.user.project_id,
  params: {},
  config: {
    url: '',
    method: 'get',
    headers: {},
    queryParams: {},
    payload: {},
  },
})

const getAppSkeleton = () => ({
  name: '',
  category: 'app',
  type_id: 0,
  project_id: common.user.project_id,
  config: {},
})

// actions
export const getIntegrationById = async (id) => {
  const integration = await useLoader(IntegrationsService.getById, id)
  if (!integration) return
  Vue.set(state, 'integration', integration)
  setQueryParam('id_integration', state.integration.name)
  return integration
}

export const findIntegrationType = (integrationTypeName) => {
  return state.integrationTypes.find(
    (integrationType) => integrationType.name === integrationTypeName
  )
}

export const getIntegrationTypes = async () => {
  const integrationTypes = await useLoader(IntegrationsService.getTypes)
  if (!integrationTypes) return
  Vue.set(state, 'integrationTypes', integrationTypes)
  return integrationTypes
}

export const createIntegration = async (data) => {
  return await useLoader(IntegrationsService.create, data)
}

export const initWebhook = async () => {
  await getIntegrationTypes()

  const integrationType = findIntegrationType('webhook')
  const newWebhook = getWebhookSkeleton()

  newWebhook.type_id = integrationType.id

  Vue.set(state, 'integration', newWebhook)

  return newWebhook
}

export const initApp = async (name) => {
  await getIntegrationTypes()

  const integrationType = findIntegrationType(name)
  const newApp = getAppSkeleton()

  newApp.name = name
  newApp.type_id = integrationType.id

  Vue.set(state, 'integration', newApp)

  return newApp
}

export const deleteIntegrationById = async (id) => {
  return await useLoader(IntegrationsService.delete, id)
}

export const updateIntegrationById = async (id, data) => {
  return await useLoader(IntegrationsService.update, id, {
    ...data,
    type_id: data.type.id,
  })
}

export default state
