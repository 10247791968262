<template lang="pug">
.IntegrationSelector
  // Button: Choose Integration
  ja-button(
    v-if='!selectedIntegration',
    :on-click='openModalIntegrationList',
    :text='$t("choose_webhook")',
    block,
    icon='mdi mdi-connection',
    type='outline-primary'
  )

  // Button: Change Integration
  .IntegrationSelector-Summary(v-if='selectedIntegration')
    img.IntegrationSelector-Icon(:src='selectedIntegration.image')
    .IntegrationSelector-Name {{ selectedIntegration.name }}
    ja-button(
      :on-click='openModalIntegrationList',
      :text='$t("change_webhook")',
      block,
      icon='mdi mdi-connection',
      type='outline-primary'
    )

  // Modal
  b-modal(
    ref='modal-integration-list',
    centered,
    modal-class='IntegrationSelector-Modal',
    size='xl',
    scrollable,
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .IntegrationSelector-ModalHeader.text-first-uppercase
        span {{ $t("webhooks") }}

    // Modal Content
    ce-integration-deck(
      :integrations='integrationList',
      v-model='selectedIntegration'
    )

    // Modal Footer
    template(v-slot:modal-footer)
      .IntegrationSelector-ModalFooter
        ja-button(
          :disabled='!areThereAnySelected',
          :on-click='handleModalSelect',
          :text='$t("select_webhook")',
          type='primary'
        )
</template>

<script>
import { getWebhooks } from '@plugins/content-editor/state'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [Object, null],
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    const selectedIntegration = this.value || null

    return {
      modalShow: false,
      selectedIntegration,
    }
  },

  computed: {
    areThereAnySelected () {
      return !!this.selectedIntegration
    },

    integrationList () {
      const webhooks = getWebhooks()

      return webhooks.map((webhook) => {
        return {
          ...webhook,
          image: webhook.type.image,
        }
      })
    },
  },

  methods: {
    handleModalSelect () {
      this.onChange({
        value: this.selectedIntegration,
      })

      this.toggleIntegrationList()
    },

    openModalIntegrationList () {
      this.toggleIntegrationList()
    },

    toggleIntegrationList () {
      this.$refs['modal-integration-list'].toggle()
    },
  },
}
</script>

<style lang="sass">
$iconSize: 32px

.IntegrationSelector
  &-Icon
    height: $iconSize
    width: $iconSize
  &-Name
    font-size: 18px
    padding-left: 10px
  &-Summary
    align-items: center
    display: grid
    grid-template-columns: $iconSize 1fr max-content
</style>
