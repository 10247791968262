<template lang="pug">
ja-content(:pageTitle='$t("report")')
  // Summary
  h2 {{ $t("summary") }}

  b-card(v-if='audience')
    b-card-text
      form.form
        // Audience
        ja-form-field(
          label='audience',
          type='info',
          :field-value='audience.name'
        )

        // Contacts
        ja-form-field(
          label='contacts',
          type='info',
          :field-value='audienceContacts'
        )

        // Delivered
        ja-form-field(
          v-if='deliveryDate',
          label='delivered',
          type='info',
          :field-value='deliveryDate'
        )

  // Performance
  h2 {{ $t("performance") }}

  ja-performance(
    :metrics='performance'
    :default-metrics='defaultMetrics'
  )

  // AMP Events
  h2 Yamps: {{ yamps }}

  b-row
    b-col(v-for='(block, index) in ampBlocksStats', :key='index')
      ja-data-block-stats(
        :block-name='block.title',
        :block-icon='block.icon',
        :block-stats='block.events'
      )
</template>

<script>
import { formatDate } from '@utils'

import state, {
  getCampaignById,
  getAmpBlockStatsByCampaign,
} from './store/campaigns.store'

import {
  BLOCKTYPES_ICONS,
  BLOCKTYPES_NAMES,
} from '@plugins/content-editor/const'

import { DEFAULT_METRICS_PERFORMANCE } from '@/const/analytics'

export default {
  data () {
    const pageActions = []

    return {
      pageActions,
    }
  },

  computed: {
    campaign: () => state.campaign,

    ampBlocksStats () {
      const ampBlocksStats = state.ampBlocksStats.map((block) => {
        return {
          ...block,
          icon: BLOCKTYPES_ICONS[block.name],
          title: BLOCKTYPES_NAMES[block.name],
        }
      })

      return ampBlocksStats.filter((block = []) => {
        let showBlock = false
        block.events.forEach(event => { if (event.total > 0) { showBlock = true } })
        return showBlock
      })
    },

    audience () {
      return state.audiences[0]
    },

    audienceContacts () {
      return this.audience?.contacts || 0
    },

    defaultMetrics () {
      return DEFAULT_METRICS_PERFORMANCE
    },

    deliveryDate () {
      return this.campaign?.delivered_date ? formatDate({ date: this.campaign.delivered_date }) : false
    },

    performance () {
      return state.performance
    },

    yamps () {
      return state.campaign?.stats?.total.yamps ?? '0'
    }
  },

  async created () {
    const { id_campaign } = this.$route.params
    await getCampaignById(id_campaign)
    await getAmpBlockStatsByCampaign(id_campaign)
  },
}
</script>

<style lang="sass" scoped></style>
