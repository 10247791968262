import router from './config.router'
import i18n from './config.lang'
import 'store2/src/store.cache'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
// import serviceWorker from './config.registerServiceWorker'

export {
  router,
  i18n,
  // serviceWorker
}
