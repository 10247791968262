// Components
import AudienceList from './Audiences/AudienceList'
import AudienceOverview from './Audiences/AudienceOverview'
import AudienceEdit from './Audiences/AudienceEdit'
import AudienceSettings from './Audiences/AudienceSettings'
import ContactList from './Contacts/ContactList'
import ContactOverview from './Contacts/ContactOverview'
import ContactEdit from './Contacts/ContactEdit'

// Sidebar Links
import sidebar from './audiences.sidebar'

// Topbar Links
import topbar from '../dymal.topbar'

// Parent
const parent = 'audiences'

// Breacrumb Level
const breadcrumbLevel = ({
  hasParent = false,
  hasTo = false,
  name = '',
  plural = true,
}) => ({ hasParent, hasTo, name, plural })

// Breacrumb Root
const breadcrumbRoot = breadcrumbLevel({
  hasParent: true,
  hasTo: true,
  name: 'audience',
})

export default [
  // Audience List (Default)
  {
    name: 'audiences',
    path: '/audiences',
    component: AudienceList,
    meta: {
      breadcrumb: [breadcrumbLevel({ name: 'audience' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_audience',
        to: '/audiences/new',
        type: 'outline-secondary'
      },
      pageTitle: 'audiences',
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Audience New
  {
    name: 'audience-new',
    path: '/audiences/new',
    component: AudienceEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'new_audience' },
      ],
      pageTitle: 'new_audience',
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  {
    name: 'audience-manage',
    path: '/audiences/:id_audience/manage',
    component: AudienceEdit,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'manage_audience' }],
      pageActions: [
        { icon: 'ti-trash', text: 'delete', type: 'outline-danger' },
        { icon: 'ti-save', text: 'update', type: 'outline-primary' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },
  // Audience Overview
  {
    name: 'audience',
    path: '/audiences/:id_audience',
    component: AudienceOverview,
    meta: {
      breadcrumb: [breadcrumbRoot],
      pageTitle: ':id_audience',
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_audience',
        to: '/audiences/:id_audience/manage',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },
  // Audience Settings
  {
    name: 'audience-settings',
    path: '/audiences/:id_audience/settings',
    component: AudienceSettings,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'settings', plural: false }),
      ],
      pageActions: [
        { icon: 'ti-trash', text: 'delete', type: 'outline-danger' },
        { icon: 'ti-save', text: 'save', type: 'outline-primary' },
      ],
      pageTitle: ':id_audience',
      parent,
      sidebar,
      topbar,
    },
  },
  // Contact List
  {
    name: 'contacts',
    path: '/audiences/:id_audience/contacts',
    component: ContactList,
    meta: {
      breadcrumb: [breadcrumbRoot, breadcrumbLevel({ name: 'contact' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_contact',
        to: '/audiences/:id_audience/contacts/new',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },
  // Contact New
  {
    name: 'contact-new',
    path: '/audiences/:id_audience/contacts/new',
    component: ContactEdit,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'contact', hasParent: false, hasTo: true }),
        { name: 'new_contact' },
      ],
      pageTitle: 'new_contact',
      parent,
      sidebar: { ...sidebar, parent: 'contacts' },
      topbar,
    },
  },
  // Contact Manage
  {
    name: 'contact-manage',
    path: '/audiences/:id_audience/contacts/:id_contact/manage',
    component: ContactEdit,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'contact', hasParent: true, hasTo: true }),
      ],
      parent,
      sidebar: { ...sidebar, parent: 'contacts' },
      topbar,
    },
  },
  // Contact Overview
  {
    name: 'contact',
    path: '/audiences/:id_audience/contacts/:id_contact',
    component: ContactOverview,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'contact', hasParent: true, hasTo: true }),
      ],
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_contact',
        to: '/audiences/:id_audience/contacts/:id_contact/manage',
        type: 'outline-secondary'
      },
      parent,
      sidebar: { ...sidebar, parent: 'contacts' },
      topbar,
    },
  },
]
