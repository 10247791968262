<template lang="pug">
.Title
  // Title Content
  h1.Title-Content
    i(v-if='icon', :class='icon')

    ja-editable.TitleContent-text(
      :editable='isTitleEditable',
      :on-change='onChangeTitle',
      :value='title'
    )

  // Title Actions
  .Title-Actions(v-if='actions.length')
    ja-button.m-l-10(
      v-for='({ callback, icon, text, type }, index) in actions',
      :icon='icon',
      :text='text',
      :key='`ta_${index}`',
      :type='type',
      :on-click='callback'
    )
</template>

<script>
export default {
  name: 'Title',
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    onChangeTitle: {
      type: Function,
      default: () => {},
    },
    isTitleEditable: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.Title
  display: flex

  &-Actions
    display: block

  &-Content
    font-family: 'Africa'
    flex: 1
    font-size: 2.25em
    font-weight: normal
    i
      color: #ce6ad4
      margin-right: 10px
</style>
