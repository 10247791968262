<template lang="pug">
ja-content(:pageTitle='contact.email')
  b-row
    //- Activity
      b-col
        //- Metrics
          h2 {{ $t("metrics") }}
          b-row
            b-col.col-md-6(
              v-for='(data, index) in metrics',
              :key='`metric_${index}`'
            )
              ja-data-bar(
                :name='data.name',
                :value='data.value',
                :icon='data.icon'
              )

        //- Activity Feed
          h2.mb-3 {{ $t("activity_feed") }}
          ja-timeline(:timeline='timeline')

    // Contact Data
    b-col
      // Profile Information
      h2 {{ $t("profile_information") }}
      ja-table(:head='profile.head', :items='profileItems')

      //- Properties
        h2 {{ $t("properties") }}
        ja-table(
          :head='properties.head',
          :items='properties.items',
          :rowButtons='properties.rowButtons'
        )
    b-col
</template>

<script>
import state, {
  getContactById,
} from '@apps/Dymal/Audiences/Contacts/store/contacts.store'

export default {
  name: 'ContactOverview',
  data () {
    return {
      metrics: [
        { name: 'open_rate', value: 86 },
        { name: 'click_rate', value: 71 },
      ],
      profile: {
        head: { enabled: false },
      },
      properties: {
        head: { enabled: false },
        rowButtons: [{ icon: 'ti-pencil' }],
        items: [
          { name: this.$t('language'), value: 'English' },
          { name: this.$t('location'), value: 'New York City, NY, USA' },
          { name: this.$t('email_client'), value: 'Gmail' },
          { name: this.$t('email_format'), value: 'AMP' },
          { name: this.$t('updated_at'), value: '10/10/1980' },
        ],
      },
      timeline: [
        {
          date: 'Mar 18th',
          events: [
            {
              name: 'Submitted the email Catálogo Huawei',
              time: '12:01',
              icon: 'mdi mdi-account-check',
            },
            {
              name: 'Clicked the email Catálogo Huawei',
              time: '11:58',
              icon: 'mdi mdi-cursor-default',
            },
            {
              name: 'Opened the email Catálogo Huawei',
              time: '11:57',
              icon: 'mdi mdi-email-open',
            },
          ],
        },
        {
          date: 'Mar 17th',
          events: [
            {
              name: 'Opened the email Catálogo Huawei',
              time: '16:21',
              icon: 'mdi mdi-email-open',
            },
            {
              name: 'Delivered the email Catálogo Huawei',
              time: '16:02',
              icon: 'mdi mdi-email-check',
            },
          ],
        },
        {
          date: 'Mar 10th',
          events: [{ name: 'Added', time: '09:36', icon: 'mdi mdi-plus' }],
        },
      ],
    }
  },

  computed: {
    contact () {
      return state.contact
    },

    profileItems () {
      return [
        { name: this.$t('email'), value: this.contact.email || '' },
        { name: this.$t('name'), value: this.contact.first_name || '' },
        { name: this.$t('surname'), value: this.contact.surname || '' },
        { name: this.$t('phone'), value: this.contact.phone || '' },
        { name: this.$t('birthdate'), value: this.contact.birthdate || '' },
      ]
    },
  },

  beforeCreate () {
    getContactById(this.$route.params.id_contact)
  },
}
</script>
