<template lang="pug">
.TemplateDeck
  ja-template-card.TemplateDeck-Card(
    v-for='(item, index) in items',
    :description='item.description',
    :image='item.preview',
    :name='item.name',
    :key='item.id',
    :selectable='selectableItem',
    :selected='isItemSelected(item)',
    @click.native='() => handleItemClick(item)'
  )
</template>

<script>
export default {
  name: 'TemplateDeck',
  props: {
    items: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    selectableItem: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      selected: null,
    }
  },

  methods: {
    handleItemClick (item) {
      if (this.onClick) {
        this.onClick(item)
      }

      if (this.selectableItem) {
        this.selected = item.id
      }
    },

    isItemSelected (item) {
      return this.selected === item.id
    },
  },
}
</script>

<style lang="sass" scoped>
.TemplateDeck
  display: grid
  gap: 1.5rem
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr))
</style>
