import ApiClient from '@api/client'

const client = new ApiClient({ path: '/organizations' })

export const OrganizationService = {
  /**
   * Create Organization
   */
  async create (data) {
    return await client.post('/', data)
  },
  /**
   * Delete Organization
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },
  /**
   * Get All Organizations
   * @param {Object} pagination
   * @return {Array} organizations
   */
  async getAll ({ page = 1, rows = 20 } = {}) {
    return await client.get(`/?page=${page}&rows=${rows}`)
  },
  /**
   * Get Organization By Id
   * @return {Array} organizations
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },
  /**
   * Get Projects By Organization
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} projects
   */
  async getProjectsByOrganizationId (id, { page = 1, rows = 20 } = {}) {
    return await client.get(
        `/${id}/projects?page=${page}&rows=${rows}`
    )
  },
  /**
   * Get Teams By Organization
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} teams
   */
  async getTeamsByOrganizationId (id, { page = 1, rows = 20 } = {}) {
    return await client.get(
        `/${id}/teams?page=${page}&rows=${rows}`
    )
  },
  /**
   * Get Users By Organizations
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} users
   */
  async getUsersByOrganizationId (id, { page = 1, rows = 20 } = {}) {
    return await client.get(
        `/${id}/users?page=${page}&rows=${rows}`
    )
  },
  /**
   * Get Stripe Customer Portal Session
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} users
   */
  async getStripeCustomerPortalSession (id, returnUrl) {
    return await client.get(
        `/${id}/customer-billing-portal?return_url=${returnUrl}`
    )
  },
  /**
   * Create Organization
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
