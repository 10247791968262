<template lang="pug">
.FallbackSwitch.btn-group
  // Show AMP
  button.btn.btn-rounded(
    :class='[!showFallback ? "btn-primary" : "btn-outline-secondary"]',
    @click='setShowFallback(false)'
  )
    i.fas.fa-bolt
    span AMP

  // Show Fallback
  button.btn.btn-rounded(
    :class='[showFallback ? "btn-primary" : "btn-outline-secondary"]',
    @click='setShowFallback(true)'
  )
    i.fas.fa-exchange-alt
    span Fallback
</template>

<script>
import state, { setShowFallback } from '@plugins/content-editor/state'

export default {
  name: 'FallbackSwitch',

  computed: {
    showFallback () {
      return state.showFallback
    },
  },

  methods: {
    setShowFallback (show) {
      setShowFallback(show)
    },
  },
}
</script>

<style lang="sass" scoped></style>
