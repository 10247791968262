<template lang="pug">
ja-content(:pageTitle='$t("organizations")')
  ja-data-table(
    v-if='organizations.length',
    :items='organizations',
    :fields='fields',
    :pagination='pagination',
    :on-change-page='handleChangePage',
    :on-row-click='(row) => jaDetailView("organization", row)'
  )
</template>

<script>
import state, { getOrganizations } from './store/organizations.store'

export default {
  name: 'OrganizationList',

  data: () => ({
    fields: [{ key: 'id', label: 'Id Organization', class: 'col-240' }, 'name'],
  }),

  computed: {
    organizations () {
      return state?.organizations?.items || []
    },

    pagination () {
      return state?.organizations?.pagination
    },
  },

  beforeCreate () {
    getOrganizations()
  },

  methods: {
    handleChangePage (page) {
      getOrganizations({ pagination: { page } })
    },
  },
}
</script>
