import Vue from 'vue'
import { AmpBlockService } from '@api/services/yamp-blocks.service'
import { useLoader } from '@apps/Common/common.store'
import { transformAmpBlockEventsMap } from '@/data-transformers/yamp-blocks'

const state = Vue.observable({
  ampBlocks: [],
  ampBlockEventsMap: null,
})

export const getAmpBlocks = async () => {
  const ampBlocks = await useLoader(AmpBlockService.getAll)
  Vue.set(state, 'ampBlocks', ampBlocks)
  Vue.set(state, 'ampBlockEventsMap', transformAmpBlockEventsMap(ampBlocks))
}

export default state
