export default {
  en: {
    birthdate_placeholder: 'Your birthdate',
    change_password: 'Change Password',
    credentials_invalid: 'Invalid credentials',
    current_password: 'Current Password',
    current_password_invalid: 'Current password is invalid',
    current_password_placeholder: 'Your current password',
    email: 'Email',
    email_placeholder: 'Your email',
    full_name: 'Full name',
    name: 'Name',
    new_password: 'New Password',
    new_password_placeholder: 'Your new password',
    name_placeholder: 'Your first name',
    password: 'Password',
    permissions: 'Permissions',
    phone_placeholder: 'Your phone',
    profile: 'Profile',
    surname: 'Name',
    surname_placeholder: 'Your surname',
  },

  es: {
    birthdate_placeholder: 'Escribe tu fecha de nacimiento',
    change_password: 'Cambiar contraseña',
    credentials_invalid: 'Las credenciales de acceso son incorrectas',
    current_password: 'Contraseña actual',
    current_password_invalid: 'La contraseña actual es incorrecta',
    current_password_placeholder: 'Escribe tu contraseña actual',
    email: 'Email',
    email_placeholder: 'Escribe tu email',
    full_name: 'Nombre completo',
    name: 'Nombre',
    name_placeholder: 'Escribe tu nombre',
    new_password: 'Nueva contraseña',
    new_password_placeholder: 'Escribe tu nueva contraseña',
    password: 'Contraseña',
    permissions: 'Permisos',
    phone_placeholder: 'Escribe tu teléfono',
    profile: 'Perfil',
    surname: 'Apellido',
    surname_placeholder: 'Escribe tu apellido',
  },
}
