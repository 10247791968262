export default {
  en: {
    add_discount: 'add discount',
    address_placeholder: 'Your address',
    address: 'address',
    alignment: 'alignment',
    block_background: 'block background',
    change_webhook: 'change webhook',
    checkout: 'checkout',
    choose_product: 'choose a product',
    choose_webhook: 'choose a webhook',
    city_placeholder: 'Your city',
    city: 'city',
    colors: 'colors',
    color_scheme: 'color scheme',
    confirm_shipping_address: 'confirm shipping address',
    country_placeholder: 'Your country',
    country: 'country',
    custom_text: 'custom text',
    default_phone: 'default phone',
    date_added: 'date added',
    disabled: 'disabled',
    discount: 'discount',
    enabled: 'enabled',
    error_process_order:
    'Unfortunately, we were unable to process your order. You can access our website to place your order through the following link',
    fields_config: 'fields config',
    first_name_placeholder: 'Your first name',
    fixed_amount: 'fixed amount',
    font_family: 'font family',
    font_size: 'font size',
    full_width: 'full width',
    go_website: 'go to the website',
    height: 'height',
    icon_color: 'icon color',
    icon_figure: 'icon figure',
    icon_style: 'icon style',
    image_library: 'image library',
    in_process_order: 'Your order is processing',
    link: 'link',
    make_payment: 'make the payment',
    ok_process_order:
      'Your order is ready. To finish, you need to make the payment',
    order_summary: 'order summary',
    padding_bottom: 'padding bottom',
    padding_top: 'padding top',
    padding: 'padding',
    pay_now: 'pay now',
    percentage: 'percentage',
    phone_placeholder: 'Your phone',
    phone_validation_message: 'Must be a phone number',
    policy: 'policy statement',
    postal_code_placeholder: 'Your postal code',
    postal_code: 'postal code',
    price: 'price',
    product_list: 'product list',
    product_name: 'product name',
    province_placeholder: 'Your province',
    province: 'province',
    quantity: 'quantity',
    query_params: 'query params',
    required: 'required',
    regexp: 'regexp',
    select_image: 'select image',
    select_product: 'select a product',
    select_project: 'select a project',
    select_webhook: 'select webhook',
    shape: 'shape',
    shipping_address: 'shipping address',
    shopify_cart: 'shopify cart',
    shopping_cart: 'shopping cart',
    show: 'show',
    style: 'style',
    surname_placeholder: 'Your surname',
    surname: 'surname',
    tag: 'tag',
    thickness: 'thickness',
    value: 'value',
  },

  es: {
    add_discount: 'añadir descuento',
    address_placeholder: 'Escribe tu dirección completa',
    address: 'dirección',
    alignment: 'alineación',
    block_background: 'fondo del bloque',
    change_webhook: 'cambiar webhook',
    checkout: 'comprar',
    choose_product: 'elegir un producto',
    choose_webhook: 'elegir un webhook',
    city_placeholder: 'Escribe tu ciudad',
    city: 'city',
    color_scheme: 'esquema de color',
    colors: 'colores',
    confirm_shipping_address: 'confirmar dirección',
    country_placeholder: 'Escribe tu país',
    country: 'país',
    custom_text: 'texto personalizado',
    date_added: 'fecha de adición',
    default_phone: 'teléfono por defecto',
    disabled: 'desactivado',
    discount: 'descuento',
    enabled: 'activado',
    error_process_order: 'Unfortunately, we were unable to process your order. You can access our website to place your order through the following link',
    fields_config: 'configuración de campos',
    first_name_placeholder: 'Escribe tu nombre',
    fixed_amount: 'importe exacto',
    font_family: 'familia de la fuente',
    font_size: 'tamaño de la fuente',
    full_width: 'ancho completo',
    go_website: 'ir a la web',
    height: 'altura',
    icon_color: 'color del icono',
    icon_figure: 'tipo de icono',
    icon_style: 'estilo del icono',
    image_library: 'biblioteca de imágenes',
    in_process_order: 'Your order is processing',
    link: 'enlace',
    make_payment: 'realizar el pago',
    ok_process_order: 'Your order is ready. To finish, you need to make the payment',
    order_summary: 'resumen de tu pedido',
    padding_bottom: 'separación inferior',
    padding_top: 'separación superior',
    padding: 'separación',
    pay_now: 'pagar ahora',
    percentage: 'porcentual',
    phone_placeholder: 'Escribe tu teléfono',
    phone_validation_message: 'Debe ser un teléfono válido',
    policy: 'política de privacidad',
    postal_code_placeholder: 'Escribe tu código postal',
    postal_code: 'código postal',
    price: 'precio',
    product_list: 'lista de productos',
    product_name: 'nombre del producto',
    province_placeholder: 'Escribe tu provincia',
    province: 'provincia',
    quantity: 'cantidad',
    query_params: 'parametros url',
    regexp: 'regexp',
    required: 'requerido',
    select_image: 'elegir imagen',
    select_product: 'seleccionar producto',
    select_webhook: 'seleccionar webhook',
    shape: 'forma',
    shipping_address: 'dirección de envío',
    shopify_cart: 'shopify cart',
    shopping_cart: 'tu carrito',
    show: 'mostrar',
    style: 'estilo',
    surname_placeholder: 'Escribe tu apellido',
    surname: 'apellido',
    tag: 'tag',
    thickness: 'grosor',
    value: 'valor',
  },
}
