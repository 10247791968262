export default {
  en: {
    activity: 'Activity',
    analyzer: 'Analyzer',
    activity_analyzer: 'Activity Analyzer',
    activity_report: 'Activity Report',
  },
  es: {
    activity: 'Activity',
    analyzer: 'Analyzer',
    activity_analyzer: 'Activity Analyzer',
    activity_report: 'Activity Report',
  },
}
