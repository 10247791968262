<template lang="pug">
.row
  .col-12.col-sm-4
    .form-group
      label.form-label.form-label-lg {{ $t("timeframe") }}

      b-form-select.TimeframeSelect(
        v-model="value.timeframe"
        @input='handleTimeframeChange'
      )
        b-form-select-option.TimeframeOption(
          v-show='!value.timeframe'
          value=''
        ) {{ $t("custom") }}

        b-form-select-option.TimeframeOption(
          v-for='option in timeframeOptions'
          :key="option.value"
          :value="option.value"
        ) {{ $t('timeframe_label', { label: option.text }) }}

  .col-12.col-sm-4
    .form-group
      label.form-label.form-label-lg {{ $t("start_date") }}
      b-form-datepicker(
        v-model='value.startDate'
        start-weekday='1'
        :max='value.endDate'
        @input='(value) => handleDateChage("startDate", value)'
      )

  .col-12.col-sm-4
    .form-group
      label.form-label.form-label-lg {{ $t("end_date") }}
      b-form-datepicker(
        v-model='value.endDate'
        start-weekday='1'
        :min='value.startDate'
        :max='today'
        @input='(value) => handleDateChage("endDate", value)'
      )
</template>

<script>
import { TIMEFRAMES, TIMEFRAMES_OPTIONS, TODAY } from '@plugins/ja/const/timeframes'

export default {
  name: 'Timeframe',

  props: {
    value: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      default: () => {}
    },
  },

  data () {
    return {
      today: TODAY
    }
  },

  computed: {
    timeframeOptions () {
      return TIMEFRAMES_OPTIONS
    }
  },

  created () {
    if (this.value.timeframe) {
      this.handleTimeframeChange(this.value.timeframe)
    } else if (this.value.startDate && this.value.endDate) {
      this.updateTimeframeDateRage(this.value)
    }
  },

  methods: {
    handleDateChage (dateKey, value) {
      this.$set(this.value, 'timeframe', '')
      this.$set(this.value, dateKey, value)
      this.onChange(this.value)
    },

    handleTimeframeChange (timeframeKey) {
      if (timeframeKey) {
        const timeframe = TIMEFRAMES[timeframeKey]
        this.updateTimeframeDateRage({ ...timeframe, timeframe: timeframeKey })
      }
    },

    updateTimeframeDateRage ({ startDate, endDate, timeframe }) {
      this.$set(this.value, 'timeframe', timeframe)
      this.$set(this.value, 'endDate', endDate)
      this.$set(this.value, 'startDate', startDate)
      this.onChange(this.value)
    }
  }
}
</script>

<style lang="sass" scoped>
.TimeframeOption,
.TimeframeSelect
  text-transform: capitalize
</style>
