export default {
  enabled: true,
  items: [
    {
      name: 'activity_analyzer',
      classIcon: 'mdi mdi-chart-bar-stacked',
      url: '/activity/analyzer',
      slug: 'activity-analyzer'
    },
    {
      name: 'activity_report',
      classIcon: 'mdi mdi-table-clock',
      url: '/activity/report',
      slug: 'activity-report',
    },
  ],
}
