<template lang="pug">
// Documentation: https://apexcharts.com/docs/
.ApexChart.card
  .card-body
    apexchart(
      ref='apexchart'
      :options='options',
      :series='series',
      :height='height'
    )
</template>

<script>
export default {
  name: 'JaApexChart',

  props: {
    categories: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'line'
    },
  },

  data () {
    return {
      height: '480px',
    }
  },

  computed: {
    options () {
      return {
        chart: {
          fontFamily: 'Roboto',
          type: 'line',
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },

        colors: ['#53E8C8', '#BDE853', '#E85373', '#7E53E8'], // #E85373
        // colors: ['#30E363', '#E36330', '#6330E3'], // #6330e3

        dataLabels: {
          enabled: false,
        },
        // dataLabels: {
        //   enabled: true,
        //   enabledOnSeries: [2],
        //   style: {
        //     fontSize: '14px',
        //   }
        // },

        legend: {
          fontSize: '14px',
        },

        stroke: {
          curve: 'straight',
          width: [1, 1, 1, 1],
          // width: [1, 1, 4],
        },

        xaxis: {
          categories: this.categories || [],
          tickPlacement: 'between',
        },

        // theme: {
        //   palette: 'palette7'
        // },
      }
    }
  },
}
</script>

<style lang="sass">
.ApexChart
  padding-right: 20px

  .apexcharts-datalabel
    font-size: 14px
    font-family: 'DM Sans', sans-serif !important
    font-weight: normal

  .apexcharts-legend
    gap: 10px
    padding-top: 20px

  .apexcharts-text
    font-family: Roboto, sans-serif
    font-size: 15px

  .apexcharts-tooltip
    top: -52px !important
    &.apexcharts-theme-light
      background: rgba(255, 255, 255, .8)
    .apexcharts-tooltip-text-y-label
      font-family: Roboto, sans-serif
      font-size: 15px
</style>
