<template lang="pug">
.BlockImage(:style='cssVars')
  .BlockImage-Resizable(v-if='source' ref='resizable' :style='cssResize')
    img.BlockImage-Image(
      @load='setInitialImageDimensions'
      :src='source'
      :height='height'
      :width='width'
      ref='image'
    )
    button.BlockImage-Resizer(v-show='showResizer' ref='resizer')
      .BlockImage-ResizerCircle

  template(v-else)
    ce-image-skeleton(text='Add your image')

</template>

<script>
import { setBlockAttrs } from '@plugins/content-editor/state'
import interact from 'interactjs'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  data () {
    const imageRatio = 0
    const resizableInstance = null

    return {
      imageRatio,
      resizableInstance,
    }
  },

  computed: {
    cssVars () {
      const flexAlignMap = {
        left: 'flex-start',
        center: 'center',
        full: 'center',
        right: 'flex-end',
      }

      return {
        '--flex-align': flexAlignMap[this.configAlign],
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
      }
    },

    cssResize () {
      return {
        '--height': this.height ? this.height + 'px' : 'auto',
        '--width': this.width ? this.width + 'px' : 'auto',
      }
    },

    configAlign () {
      return this.block.config.alignment || 'center'
    },

    showResizer () {
      return this.configAlign !== 'full'
    },

    source () {
      return this.block.config.source || this.block.config.image?.src
    },

    height () {
      return this.block.attrs?.height
    },

    maxWidth () {
      return this.block.config.fullWidth ? 600 : 520
    },

    width () {
      return this.block.attrs?.width
    }
  },

  watch: {
    configAlign (newValue) {
      if (newValue === 'full') {
        this.updateImageDimensions({ width: this.maxWidth })
      }
    },

    maxWidth (newValue) {
      if (this.configAlign === 'full') {
        this.updateImageDimensions({ width: newValue })
      }
    },
  },

  updated () {
    const { maxWidth, updateImageDimensions } = this
    const { attrs } = this.block

    if (!this.imageRatio && attrs) {
      this.imageRatio = attrs.width / attrs.height
    }

    if (!attrs || this.resizableInstance) return

    this.resizableInstance = interact(this.$refs.resizable).resizable({
      edges: {
        top: false,
        left: false,
        bottom: this.$refs.resizer,
        right: this.$refs.resizer,
      },

      listeners: {
        move (event) {
          const { width } = event.rect

          if (width > maxWidth) return

          updateImageDimensions({ width })
        }
      },

      modifiers: [
        interact.modifiers.aspectRatio({
          ratio: this.imageRatio,
        }),

        interact.modifiers.restrictSize({
          max: { width: maxWidth }
        })
      ]
    })
  },

  methods: {
    setInitialImageDimensions () {
      const { width, naturalHeight, naturalWidth } = this.$refs.image

      this.imageRatio = naturalWidth / naturalHeight

      this.updateImageDimensions({ width })
    },

    updateImageDimensions ({ width, height }) {
      if (!height) height = Math.round(width / this.imageRatio)

      setBlockAttrs({
        blockId: this.block.id,
        attrs: {
          height,
          width,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.BlockImage
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)
  position: relative
  display: flex
  justify-content: var(--flex-align)
  font-size: 0
  line-height: 0

  &:active &-Resizer,
  &:active &-Resizable::after,
  &:hover &-Resizer,
  &:hover &-Resizable::after
    display: block

  &-Image
    display: block
    max-width: 100%

  &-Resizable
    height: var(--height)
    width: var(--width)
    position: relative
    &::after
      display: none
      position: absolute
      top: 0
      left: 0
      content: ' '
      pointer-events: none
      background: transparent
      border: 2px dotted $purple
      width: 100%
      height: 100%

  &-Resizer
    background: transparent
    display: none
    cursor: nwse-resize
    position: absolute
    bottom: -20px
    right: -20px
    padding: 10px
    border: none
    z-index: 1
    &:active, &:focus
      outline: none

  &-ResizerCircle
    pointer-events: none
    background: #fff
    border: 2px solid $purple
    box-sizing: border-box
    border-radius: 10px
    height: 20px
    width: 20px

  &-Skeleton
    @include image-skeleton
</style>
