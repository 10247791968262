import { formatDate, formatPrice } from '@utils'
import { CURRENCIES } from '@plugins/content-editor/const'

const composePrestashopProductUrl = ({ product, integration }) => {
  const urlBase = `https://${extractHostname(
    integration.config.hostUrl
  )}/index.php`

  const params = new URLSearchParams()
  params.append('id_product', product.id)
  params.append('id_product_attribute', product.cache_default_attribute)
  params.append('rewrite', product.link_rewrite[0].value)
  params.append('controller', 'product')
  params.append('id_lang', '1')

  return `${urlBase}?${params.toString()}`
}

const extractShopifyId = (shopifyId) => {
  const regex = /gid:\/\/shopify\/(?:.*)\/(.*)/gi
  return regex.exec(shopifyId)[1] || ''
}

const extractHostname = (url) => {
  const urlInstance = new URL(url)
  return urlInstance.hostname
}

export const dataTransformers = {
  prestashop ({ products }, integration) {
    return products.map((product) => {
      return {
        currency: CURRENCIES.EUR,
        date: formatDate({ date: product.date_upd }),
        id: product.id,
        // image: `${integration.config.hostUrl}/images/products/${product.id}/${product.id_default_image}`,
        image: product.image.medium,
        name: typeof product.name === 'string' ? product.name : product.name[0].value,
        price: formatPrice(product.price * 1.21), // TODO: implement tax price field
        rawPrice: formatPrice(product.price),
        quantity: product.quantity,
        siteUrl: `https://${extractHostname(integration.config.hostUrl)}`,
        url: composePrestashopProductUrl({ product, integration }),
      }
    })
  },

  shopify ({ shop, products }, integration) {
    return products.edges.map(({ node: product }) => {
      const variants = product.variants.edges.map(({ node: variant }) => ({
        gid: variant.id,
        id: extractShopifyId(variant.id),
        name: variant.title,
        price: variant.price,
        quantity: variant.inventoryQuantity,
        sku: variant.sku,
      }))

      return {
        currency: CURRENCIES[shop.currencyCode],
        currencyCode: shop.currencyCode,
        date: formatDate({ date: product.createdAt }),
        gid: product.id,
        id: extractShopifyId(product.id),
        image: product.featuredImage?.src || '',
        name: product.title,
        path: product.handle,
        price: formatPrice(variants[0].price),
        url: `https://${extractHostname(shop.primaryDomain.url)}/products/${
          product.handle
        }`,
        variants,
      }
    })
  },
}
