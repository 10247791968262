<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageActions='pageActions')
  ja-data-table(
    v-if='contacts.length',
    :items='contacts',
    :fields='fields',
    :pagination='pagination',
    :on-change-page='handleChangePage',
    :on-row-click='(row) => jaDetailView("contact", row)'
  )

  // Modal
  b-modal(centered, ref='modal-import-contacts', v-model='modalShow')
    // Modal Title
    template(v-slot:modal-title)
      i.ti-upload
      span {{ $t("import_contacts") }}

    // Modal Content
    .ContactsFile
      ja-button.ContactsFile-UploadButton(
        v-if='!contactsFile',
        icon='ti-folder',
        type='outline-primary',
        :block='true',
        :text='$t("browse_file")',
        :on-click='clickInputContacts'
      )

      .ContactsFile-Summary(v-else)
        .ContactsFile-Summary-Name {{ contactsFile.name }}
        ja-button.ContactsFile-Summary-Remove(
          type='outline-danger',
          icon='ti-close',
          :on-click='resetContactsFile'
        )

    input(
      @change='handleInputFile',
      type='file',
      name='contacts-upload',
      ref='input-contacts',
      style='display: none',
      accept='.csv'
    )

    // Modal Footer
    template(v-slot:modal-footer)
      ja-button(:on-click='importContacts', :text='$t("send")', type='primary')
</template>

<script>
import state, {
  getContactsByAudienceId,
  importContacts,
} from '@apps/Dymal/Audiences/Audiences/store/audiences.store'

import { setLoading } from '@apps/Common/common.store'

export default {
  name: 'ContactList',
  data () {
    const fields = [
      'email',
      'first_name',
      'surname',
      'phone',
      'created_at',
      'updated_at',
    ]

    const pageActions = [
      {
        callback: this.modalImportContacts,
        icon: 'ti-upload',
        text: 'import_contacts',
        type: 'outline-primary',
      },
    ]

    return {
      contactsFile: null,
      fields,
      modalShow: false,
      pageActions,
    }
  },

  computed: {
    contacts () {
      return state?.contacts?.items || []
    },

    pagination () {
      return state?.contacts?.pagination
    },

    pageTitle () {
      return `${this.$t('contacts')}: ${state.total_contacts}`
    },
  },

  beforeCreate () {
    getContactsByAudienceId(this.$route.params.id_audience)
  },

  methods: {
    clickInputContacts () {
      this.$refs['input-contacts'].click()
    },

    handleChangePage (page) {
      getContactsByAudienceId(this.$route.params.id_audience, {
        pagination: { page },
      })
    },

    handleInputFile (event) {
      const contactsFile = event.target.files[0]
      this.$set(this, 'contactsFile', contactsFile)
    },

    resetContactsFile () {
      this.$set(this, 'contactsFile', null)
    },

    async importContacts () {
      setLoading(true)
      this.toggleImportContacts()

      const data = new FormData()
      data.append('file', this.contactsFile)
      await importContacts(this.$route.params.id_audience, data)
      await getContactsByAudienceId(this.$route.params.id_audience)
    },

    modalImportContacts () {
      this.toggleImportContacts()
    },

    toggleImportContacts () {
      this.$refs['modal-import-contacts'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
.ContactsFile
  &-Summary
    display: flex
    justify-content: space-between
</style>
