export default {
  en: {
    access_keys: 'Access Keys',
    add_project: 'Add Project',
    add_user: 'Add User',
    api_key: 'API Key',
    assign_project: 'Assign a project',
    assign_user: 'Assign a user',
    assign_team: 'Assign a team',
    before_enter_yamp: 'Before entering Yamp, you need to create your first organization. Please, write the name below:',
    billing: 'Billing',
    billing_cycle: 'Billing Cycle',
    billing_history: 'Billing History',
    create: 'create',
    created_by: 'Created By',
    created_date: 'Created Date',
    data_joined: 'Date Joined',
    data_usage: 'Data Usage',
    edit_organization: 'Edit Organization',
    edit_project: 'Edit project',
    edit_team: 'Edit team',
    edit_user: 'Edit user',
    granted_by: 'Granted By',
    invite_user: 'Invite User',
    invite: 'Invite',
    is_admin: 'Admin',
    last_activity: 'Last Activity',
    manage_organization: 'Manage Organization',
    manage_project: 'Manage Project',
    manage_team: 'Manage team',
    manage: 'Manage',
    name_organization_placeholder: 'The organization name',
    name_project_placeholder: 'The project name',
    name_team_placeholder: 'The team name',
    new_organization: 'New Organization',
    new_project: 'New Project',
    new_team: 'New Team',
    new_user: 'New User',
    not_available_teams: 'Not available teams',
    not_available_users: 'Not available users',
    organization: 'Organization',
    organizations: 'Organizations',
    owner: 'Owner',
    payment_method: 'Payment Method',
    permission_management: 'Permission Management',
    plan: 'Plan',
    plan_billing: 'Plan & Billing',
    project_details: 'Project Details',
    project: 'Project',
    projects: 'Projects',
    role: 'Role',
    select_team: 'Select a team',
    select_user: 'Select a user',
    start_week: 'Start of Week',
    subscription: 'Subscription',
    teams: 'Teams',
    time_zone: 'Time Zone',
    token: 'Token',
    usage: 'Usage',
    user_details: 'Users Details',
    users: 'Users',
  },
  es: {
    access_keys: 'Access Keys',
    add_project: 'Añadir Proyecto',
    assign_user: 'Añadir Usuario',
    add_user: 'Añadir Usuario',
    api_key: 'API Key',
    assign_project: 'Asignar Proyecto',
    assign_team: 'Asignar Equipo',
    before_enter_yamp: 'Antes de entrar a Yamp debes crear tu primera organización',
    billing: 'Facturación',
    billing_cycle: 'Cliclo de Facturación',
    billing_history: 'Historial de Facturación',
    create: 'crear',
    created_by: 'Creado por',
    created_date: 'Fecha de Creación',
    data_joined: 'Fecha de Registro',
    data_usage: 'Consumo',
    edit_organization: 'Editar Organización',
    edit_project: 'Editar proyecto',
    edit_team: 'Editar equipo',
    edit_user: 'Editar usuario',
    granted_by: 'Concedido por',
    invite_user: 'Invitar Usuario',
    invite: 'Invitar',
    is_admin: 'Administrador',
    last_activity: 'Actividad Reciente',
    manage_organization: 'Gestionar Organización',
    manage_project: 'Gestionar Proyecto',
    manage_team: 'Gestionar Equipo',
    manage: 'Gestionar',
    name_organization_placeholder: 'Escribe el nombre de la organización',
    name_project_placeholder: 'Escribe el nombre del proyecto',
    name_team_placeholder: 'Escribe el nombre del equipo',
    new_organization: 'Nueva Organización',
    new_project: 'Nuevo Proyecto',
    not_available_teams: 'No hay equipos disponibles',
    not_available_users: 'No hay usuarios disponibles',
    new_team: 'Nuevo Equipo',
    new_user: 'Nuevo Usuario',
    organization: 'Organización',
    organizations: 'Organizaciones',
    owner: 'Administrador',
    payment_method: 'Método de pago',
    permission_management: 'Gestión de Permisos',
    plan: 'Plan',
    plan_billing: 'Plan y Facturación',
    project_details: 'Detalles del Proyecto',
    project: 'Proyecto',
    projects: 'Proyectos',
    role: 'Rol',
    select_team: 'Selecciona un equipo',
    select_user: 'Selecciona un usuario',
    start_week: 'Inicio de la Semana',
    subscription: 'Suscripción',
    teams: 'Equipos',
    time_zone: 'Zona Horaria',
    token: 'Token',
    usage: 'Consumo',
    user_details: 'Detalles del Usuario',
    users: 'Usuarios',
  }
}
