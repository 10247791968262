<template lang="pug">
.ImageLibrary
  .ImageLibrary-Item(
    v-for='image in images',
    :class='{ "ImageLibrary-Item--selected": isImageSelected(image) }',
    @click='handleClickImage(image)'
  )
    img.ImageLibrary-Image(:key='image.id', :src='image.src')
    .ImageLibrary-Checkbox
      span(v-if='multiSelect') {{ getSelectedImagePosition(image) }}
      i.mdi.mdi-check-bold(v-else)
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      selectedImages: [],
    }
  },

  watch: {
    value () {
      this.initSelectedImages()
    },
  },

  mounted () {
    this.initSelectedImages()
  },

  methods: {
    initSelectedImages () {
      this.selectedImages = [...cloneDeep(this.value)]
    },

    handleClickImage (image) {
      if (this.isImageSelected(image)) {
        const imageIndex = this.getIndexFromSelectedImages(image)
        this.selectedImages.splice(imageIndex, 1)
      } else {
        if (this.multiSelect) {
          this.selectedImages.push(image)
        } else {
          this.selectedImages = [image]
        }
      }

      this.$emit('input', this.selectedImages)
    },

    getIndexFromSelectedImages (image) {
      return this.selectedImages.findIndex(
        (selectedImage) => selectedImage.id === image.id
      )
    },

    getSelectedImagePosition (image) {
      return this.getIndexFromSelectedImages(image) + 1
    },

    isImageSelected (image) {
      return this.selectedImages.some(
        (selectedImage) => selectedImage.id === image.id
      )
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables

.ImageLibrary
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr
  gap: 30px

  &-Checkbox
    visibility: hidden
    display: flex
    justify-content: center
    align-items: center
    background-color: $purple
    width: 24px
    height: 24px
    position: absolute
    left: 10px
    top: 10px
    color: $white
    i
      display: block
      margin-right: 0

  &-Item
    height: 8vw
    position: relative
    cursor: pointer
    display: flex
    background-color: $gray-light-bg
    align-content: center
    justify-content: center
    flex-wrap: wrap
    transition: all 0.25s ease-in-out

    &:hover,
    &--selected
      background-color: $gray-bg

    &--selected
      .ImageLibrary-Checkbox
        visibility: visible

  &-Image
    max-width: 100%
    max-height: 8vw
</style>
