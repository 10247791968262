import ApiClient from '@api/client'

const PATH_TEMPLATES = '/campaign-templates'
const PATH_PROJECTS = '/projects'

const client = new ApiClient({ path: PATH_TEMPLATES })
const projectClient = new ApiClient({ path: PATH_PROJECTS })

export const TemplatesService = {
  /**
   * Create Team
   * @param {Object} data
   */
  async create (data) {
    return await client.post('', data)
  },
  /**
   * Delete Team
   * @param {Number|String} id
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },
  /**
   * Get Team By Id
   * @param {Number|String} id
   * @return {Object} team
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },
  /**
   * Get Templates By Project Id
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Object} team
   */
  async getTemplateByProject (id, { page = 1, rows = 30 } = {}) {
    return await projectClient.get(
      `/${id}/campaign-templates?page=${page}&rows=${rows}`
    )
  },

  /**
   * Save Template Preview
   * @param {Number|String} id
   * @param {Object} data
   */
  async savePreview (id, data) {
    return await client.post(`/${id}/preview`, data, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  },

  /**
   * Send Email
   */
  async sendEmail (data) {
    return await client.post('/send-email', data)
  },

  /**
   * Uptade Template
   * @param {Number|String} id
   * @param {Object} data
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
