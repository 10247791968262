<template lang="pug">
content-preview-page(
  v-if='template',
  :title='template.name',
  :config='template.config',
  :description='template.description'
)
</template>

<script>
import ContentPreviewPage from '@apps/Dymal/ContentEditor/ContentPreview.vue'
import state, { getTemplateById } from './store/templates.store'

export default {
  name: 'TemplateContentEditor',

  components: { ContentPreviewPage },

  computed: {
    template: () => state.template,
  },

  async created () {
    if (!this.template) {
      await getTemplateById(this.$route.params.id_template)
    }
  },
}
</script>
