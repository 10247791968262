// Components
import EmailSenderEdit from './EmailSenders/EmailSenderEdit'
import EmailSenderList from './EmailSenders/EmailSenderList'
import IntegrationApp from './Integrations/IntegrationApp'
import IntegrationList from './Integrations/IntegrationList'
import IntegrationWebhook from './Integrations/IntegrationWebhook'
import SmtpProviderEdit from './SmtpProviders/SmtpProviderEdit'
import SmtpProviderList from './SmtpProviders/SmtpProviderList'

// Sidebar Links
import sidebar from './configurations.sidebar'

// Topbar Links
import topbar from '../dymal.topbar'

// Parent
const parent = 'configurations'

// Breacrumb Level
const breadcrumbLevel = ({
  hasParent = false,
  hasTo = false,
  name = '',
  plural = true,
}) => ({ hasParent, hasTo, name, plural })

// Breacrumb Root
const breadcrumbRoot = breadcrumbLevel({
  hasParent: true,
  hasTo: true,
  name: 'configuration',
})

export default [
  // Email Senders List
  {
    name: 'email-senders',
    path: '/configurations/email-senders',
    component: EmailSenderList,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'email-senders' },
      ],
      navbutton: {
        icon: 'ti-plus',
        text: 'add_email_sender',
        to: '/configurations/email-senders/new',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },

  // Email Senders New
  {
    name: 'email-sender-new',
    path: '/configurations/email-senders/new',
    component: EmailSenderEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        breadcrumbLevel({
          name: 'email-sender',
          hasParent: false,
          hasTo: true,
        }),
        { name: 'new_email_sender' },
      ],
      parent,
      sidebar: { ...sidebar, parent: 'email-senders' },
      topbar,
    },
  },

  // Email Senders Manage
  {
    name: 'email-sender-manage',
    path: '/configurations/email-senders/:id_email_sender',
    component: EmailSenderEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        breadcrumbLevel({
          name: 'email-sender',
          hasParent: true,
          hasTo: true,
        }),
      ],
      parent,
      sidebar: { ...sidebar, parent: 'email-senders' },
      topbar,
    },
  },

  // Integration List (Default)
  {
    name: 'integrations',
    path: '/configurations/integrations',
    component: IntegrationList,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'integrations' },
      ],
      navbutton: {
        icon: 'ti-plus',
        text: 'add_webhook',
        to: '/configurations/integrations/webhook/new',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },

  // Integration App Install
  {
    name: 'integration-app-install',
    path: '/configurations/integrations/app/:app_name/install',
    component: IntegrationApp,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'integrations', hasTo: true },
        { name: ':app_name' },
      ],
      parent,
      sidebar: { ...sidebar, parent: 'integrations' },
      topbar,
    },
  },

  // Integration App Manage
  {
    name: 'integration-app-manage',
    path: '/configurations/integrations/app/:app_name/:id_integration',
    component: IntegrationApp,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        breadcrumbLevel({ name: 'integration', hasParent: true, hasTo: true }),
      ],
      parent,
      sidebar: { ...sidebar, parent: 'integrations' },
      topbar,
    },
  },

  // Integration Webhook New
  {
    name: 'integration-webhook-new',
    path: '/configurations/integrations/webhook/new',
    component: IntegrationWebhook,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        breadcrumbLevel({ name: 'integration', hasParent: false, hasTo: true }),
        { name: 'new_webhook' },
      ],
      parent,
      sidebar: { ...sidebar, parent: 'integrations' },
      topbar,
    },
  },

  // Integration Webhook Manage
  {
    name: 'integration-webhook-manage',
    path: '/configurations/integrations/webhook/:id_integration',
    component: IntegrationWebhook,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        breadcrumbLevel({ name: 'integration', hasParent: true, hasTo: true }),
      ],
      parent,
      sidebar: { ...sidebar, parent: 'integrations' },
      topbar,
    },
  },

  // SMTP Provider List
  {
    name: 'smtp-providers',
    path: '/configurations/smtp-providers',
    component: SmtpProviderList,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'smtp-providers' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },

  // SMTP Provider Install
  {
    name: 'smtp-provider-install',
    path: '/configurations/smtp-providers/:provider_name/install',
    component: SmtpProviderEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'smtp-providers', hasTo: true },
        { name: ':provider_name' },
      ],
      parent,
      sidebar: { ...sidebar, parent: 'smtp-providers' },
      topbar,
    },
  },

  // SMTP Provider Manage
  {
    name: 'smtp-provider-manage',
    path: '/configurations/smtp-providers/:provider_name/:id_smtp_provider',
    component: SmtpProviderEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        breadcrumbLevel({
          name: 'smtp-provider',
          hasParent: true,
          hasTo: true,
        }),
      ],
      parent,
      sidebar: { ...sidebar, parent: 'smtp-providers' },
      topbar,
    },
  },

  // Redirect: Integrations List
  {
    path: '/configurations',
    redirect: '/configurations/integrations',
  },
  {
    path: '/configurations/*',
    redirect: '/configurations/integrations',
  },
]
