<template lang="pug">
.ColorPicker
  .ColorPicker-Label.text-first-uppercase {{ $t(label) }}
  .ColorPicker-Picker
    .pickr(ref='pickr')
</template>

<script>
// Github: @Simonwep/pickr [https://github.com/Simonwep/pickr]
import Pickr from '@simonwep/pickr'
import '@simonwep/pickr/dist/themes/monolith.min.css'
import { SWATCHES } from '@plugins/content-editor/const'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      picker: null,
    }
  },

  mounted () {
    this.picker = Pickr.create({
      el: this.$refs.pickr,
      container: this.$el,
      theme: 'monolith', // 'classic' or 'monolith', or 'nano'
      default: this.value || 'transparent',
      swatches: SWATCHES,
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          input: true,
        },
      },
    })

    this.picker.on('change', (color, source, instance) => {
      const newColor = color.toHEXA().toString()
      this.picker.setColor(newColor)
      this.handleChange(newColor)
    })
  },

  methods: {
    handleChange (value) {
      this.onChange({ field: this.field, value })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.ColorPicker
  @include input
  align-items: center
  display: grid
  grid-template-columns: 1fr 32px
  padding: 6px 12px
  margin-bottom: 12px

  &-Picker
    place-self: center
    &::v-deep .pickr .pcr-button
      border: 1px solid #ccc
</style>
