<template lang="pug">
.Chartist(class="card")
  .Chartist-Graphics(class="chartist-chart ct-chart ct-perfect-fourth andvios")
</template>

<script>
// styles
import 'chartist/dist/chartist.min.css'
import 'chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css'

// libs
import Chartist from 'chartist'
import 'chartist-plugin-tooltips'

export default {
  name: 'Chart',
  props: {
    labels: { type: Array, required: true },
    series: { type: Array, required: true },
    title: { type: [String, null], required: false, default: null },
  },
  mounted () {
    this.chart = new Chartist.Line(
      '.Chartist-Graphics',
      { labels: this.labels, series: this.series },
      {
        chartPadding: 30,
        fullWidth: true,
        showArea: true,
        plugins: [
          Chartist.plugins.tooltip()
        ]
      }
    )
  }
}
</script>

<style lang="sass">
.Chartist
  padding: 25px 10px 0px

  &-Graphics
    height: 400px

    .ct-grid-background, .ct-line
      fill: none

    .ct-sm-line-chart .ct-series-a .ct-line,
    .ct-bar-chart .ct-series-a .ct-bar,
    .ct-sm-line-chart .ct-series-a .ct-point,
    .ct-donute-chart .ct-series-a .ct-slice-donut,
    .ct-gauge-chart .ct-series-a .ct-slice-donut,
    .ct-area-ln-chart .ct-series-a .ct-line,
    .ct-area-ln-chart .ct-series-a .ct-point,
    .ct-animation-chart .ct-series-a .ct-line,
    .ct-animation-chart .ct-series-a .ct-point,
    .ct-svg-chart .ct-series-a .ct-line,
    .user-analytics .ct-series-a .ct-line,
    .user-analytics .ct-series-a .ct-point
        stroke: #009efb

    .ct-sm-line-chart .ct-series-b .ct-line,
    .ct-bar-chart .ct-series-b .ct-bar,
    .ct-sm-line-chart .ct-series-b .ct-point,
    .ct-donute-chart .ct-series-b .ct-slice-donut,
    .ct-gauge-chart .ct-series-b .ct-slice-donut,
    .ct-animation-chart .ct-series-b .ct-line,
    .ct-animation-chart .ct-series-b .ct-point,
    .ct-svg-chart .ct-series-b .ct-line
        stroke: #f62d51

    .ct-sm-line-chart .ct-series-c .ct-line,
    .ct-sm-line-chart .ct-series-c .ct-point,
    .ct-donute-chart .ct-series-c .ct-slice-donut,
    .ct-gauge-chart .ct-series-c .ct-slice-donut,
    .ct-animation-chart .ct-series-c .ct-line,
    .ct-animation-chart .ct-series-c .ct-point,
    .ct-svg-chart .ct-series-c .ct-line
        stroke: #26c6da

    .ct-gauge-chart .ct-series-d .ct-slice-donut,
    .ct-donute-chart .ct-series-d .ct-slice-donut
        stroke: #ffbc34

    .ct-donute-chart .ct-series-e .ct-slice-donut
        stroke: #4c5667

    .ct-donute-chart .ct-series-f .ct-slice-donut
        stroke: #02bec9

    .ct-area-ln-chart .ct-series-a .ct-area,
    .ct-svg-chart .ct-series-a .ct-area
        fill: #009efb

    .ct-series-a .ct-line,
    .ct-series-b .ct-line
      stroke: transparent

    .ct-series-a .ct-point, .ct-series-b .ct-point
      stroke-width: 3px
      stroke: #26c6da

    .ct-series-a .ct-area
      fill: #3a1ebe
      fill-opacity: 0.3

    .ct-series-b .ct-area
      fill: #26c6da
      fill-opacity: 0.7

</style>
