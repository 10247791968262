<template lang="pug">
.FormField.form-group.row
  // Label
  .FormField-Label.col-xs.col-md.col-form-label(
    v-if='label || $slots.label',
    :class='[`align-${labelAlign}`]'
    :style='{ "flex-grow": labelAlign === "left" ? 0 : 1 }'
  )
    label(v-if='label', :for='id') {{ $t(label) }}
    slot(name='label')

  // TODO Convertir en grupo de campos como en ja-table-td
  // template(v-for="item in items")

  // Field
  .FormField-Field(:class='["col-xs", label ? "col-md" : "col-md-12"]')
    // Text
    template(v-if='type === "info"')
      span(:id='id') {{ fieldValue }}

    // Select
    template(v-else-if='type === "select"')
      select.custom-select(:id='id', :name='id', @change='onChangeSelect')
        template(v-for='option in options')
          template(v-if='typeof option === "object"')
            option(
              v-bind='option.key === fieldValue ? { selected: "selected" } : {}',
              :value='option.key'
            ) {{ translate ? $t(option.text) : option.text }}
          template(v-else)
            option(
              v-bind='option === fieldValue ? { selected: "selected" } : {}'
            ) {{ translate ? $t(option) : option }}

    // Switch
    template(v-else-if='type === "switch"')
      .switch
        label
          input(type='checkbox', v-model='value' :checked='value')
          span.lever.switch-col-purple

    // Textarea
    template(v-else-if='type === "textarea"')
      b-form-textarea(
        :id='id',
        :name='id'
        :placeholder='$t(placeholder)'
        :required='required'
        :rows='rows'
        :type='type'
        v-model='value'
      )

    // Input
    template(v-else-if='type')
      b-form-input(
        :id='id'
        :name='id'
        :placeholder='$t(placeholder)'
        :required='required'
        :type='type'
        v-model='value'
      )

    // slot
    slot(name='value')
</template>

<script>
export default {
  name: 'FormField',
  props: {
    id: {
      type: [String, null],
      default: null,
    },
    label: {
      type: [String, null],
      default: null,
    },
    labelAlign: {
      type: [String, null],
      default: 'right',
    },
    options: {
      type: [Array, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    translate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [String, null],
      default: null,
    },
    fieldValue: {
      type: [String, Number, Boolean, null],
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data () {
    return {
      value: this.fieldValue,
    }
  },
  watch: {
    fieldValue (value) {
      this.value = value
    },
    value (value) {
      this.onChange(value, this.id)
    },
  },
  methods: {
    onChangeSelect (event) {
      this.onChange(event.target.value, this.id)
    },
  },
}
</script>

<style lang="sass" scoped>
.FormField
  margin-bottom: 15px
  &:last-child
    margin-bottom: 0

.FormField-Field
  vertical-align: top
  span
    display: inline-flex
    margin-top: 7px
    @media (max-width: 450px)
      margin-top: 0
.FormField-Label
  font-size: 15px
  max-width: 200px
  &.align-left
    text-align: left
  &.align-right
    text-align: right
  label
    font-weight: normal
  @media (max-width: 450px)
    padding-bottom: 0
    label
      margin-bottom: 5px
    &.align-right
      text-align: left

.form-control
  font-size: 15px
  min-height: 32px
</style>
