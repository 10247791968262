<template lang="pug">
.BlockDivider(:style='cssVars')
  .BlockDivider-Line
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cssVars () {
      return {
        '--line-color': this.block.config.colors.line,
        '--line-thickness': this.block.config.thickness + 'px',
        '--line-style': this.block.config.style,
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.BlockDivider
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-Line
    box-sizing: border-box
    border-top-width: var(--line-thickness)
    border-top-style: var(--line-style)
    border-top-color: var(--line-color)
</style>
