<template lang="pug">
.ContentRow
  ce-content-block(:block='block')
</template>

<script>
export default {
  name: 'ContentRow',
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.ContentRow
</style>
