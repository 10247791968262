<template lang="pug">
ja-content(:pageTitle='$t("plan_billing")' :pageActions='pageActions' v-if='organization')

  b-card
    b-card-text
      form.form
        // Plan
        ja-form-field(label='plan')
          template(v-slot:value)
            span.text-capitalize {{ plan.name }}

        // Price
        ja-form-field(label='Price')
          template(v-slot:value)
            span.text-capitalize {{ plan.price }}€

        // Limit
        ja-form-field(label='Limit')
          template(v-slot:value)
            span.text-capitalize {{ jaFormatNumber(billingCycle.limit) }}

        // Billing Cycle
        ja-form-field(
          label='billing_cycle',
        )
          template(v-slot:value)
            span.text-capitalize
              router-link(
                :to='{ name: "organization-usage", params: { id_organization: organization.id } }'
              ) {{ billingCycle.dateRange }}

        // Billing History
          ja-form-field#billing_history(label='billing_history')
            template(v-slot:value)
              ja-button(
                icon='mdi mdi-history',
                text='view',
                type='outline-secondary'
              )
</template>

<script>
import state, {
  getOrganizationById,
  getStripeCustomerPortalSession,
} from './store/organizations.store'

export default {
  name: 'OrganizationPlan',

  data () {
    const pageActions = []

    pageActions.push({
      callback: this.goToStripePortal,
      icon: 'ti-settings',
      text: 'Manage Plan and Billing History',
      type: 'primary',
    })

    return {
      pageActions,
    }
  },

  computed: {
    billingCycle () {
      return state.dataUsageBillingCycle
    },

    organization () {
      return state.organization
    },

    plan () {
      return state.organization?.subscription?.plan || false
    },

    stripeCustomerPortalLink () {
      return state.stripe?.portal_url || false
    },
  },

  beforeCreate () {
    const { id_organization } = this.$route.params
    getOrganizationById(id_organization)
    getStripeCustomerPortalSession(id_organization, document.location.href)
  },

  methods: {
    goToStripePortal () {
      document.location.assign(this.stripeCustomerPortalLink)
    },
  },
}
</script>
