<template lang="pug">
section.auth-container
  ja-preloader(v-if='loading' :text='loadingMessage')

  .auth-leftside
    img.auth-logo(src='@/assets/images/logo_yamp_.png' alt='yamp')

    .auth-box
      router-view

      #error-box
        ja-alert(
          v-for='(error, index) in errors'
          type='danger'
          :content='error.message'
          :id='error.uid'
          :key='index'
          :on-discard='handleDiscardAlert'
        )

  .auth-rightside(
    :style='styles'
  )
    h2 Start living the Yamp experience
    p We are not going to tell you that we're very good at what we do, we will let you find out bit by bit

</template>

<script>
import state, { removeError } from '@apps/Common/common.store'

export default {
  name: 'LayoutAuth',

  data () {
    const styles = { backgroundImage: `url(${require('@/assets/images/home01.png')}` }

    return {
      styles,
    }
  },

  computed: {
    errors () {
      return state.errors
    },

    loading () {
      return state.loading
    },

    loadingMessage () {
      return state.loadingMessage
    },

    queryParams () {
      return state.queryParams
    },

    user () {
      return state.user
    }
  },

  methods: {
    handleDiscardAlert (uid) {
      removeError(uid)
    }
  }
}
</script>

<style lang="sass">
.auth
  &-box
    background: rgba(255, 255, 255, 0.95)
    border: 1px solid rgba(122, 122, 122, 0.2)
    border-radius: 4px
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1)
    box-sizing: border-box
    height: auto
    margin: 20vh auto 0
    padding: 40px
    width: 400px

  &-button
    background: linear-gradient(to right, #992275 0%, #411e92 100%)
    border:none
    text-transform: uppercase

  &-container
    background-color: #fff
    display: grid
    grid-template-columns: 4fr 6fr
    min-width: 100%
    min-height: 100%
    height: 100vh
    width: 100%

  &-logo
    display: block
    margin: 15px
    width: 103px

  &-leftside
    position: relative

  &-rightside
    background-color: #f9f7fe
    background-position: center left
    background-repeat: no-repeat
    background-size: contain
    padding: 0 1em
    text-align: center
    text-shadow: 1px 1px rgba(256, 256, 256, 0.8)

    h2
      font-family: "Africa"
      font-size: 36px
      font-weight: 600
      line-height: 1.5
      margin: 15vh auto 1vh
      width: 100%
    p
      font-size: 18px

  &-title
    color: #757b87
    font-size: 28px
    font-weight: 500

@media only screen and (max-width: 768px)
  .auth
    &-container
      grid-template-columns: 1fr

    &-logo
      width: 103px

    &-leftside
      background-color: #f9f7fe

    &-rightside
      display: none

    &-box
      position: initial
      margin: 15vh auto 0
      width: 90%
</style>
