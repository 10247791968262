<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        // Name
        ja-form-field#name(
          :fieldValue='audienceName',
          :on-change='handleFieldName',
          label='name',
          ref='inputName'
          type='text',
          placeholder='name_audience_placeholder'
        )

        // Description
        ja-form-field#description(
          :fieldValue='audienceDescription',
          :on-change='handleFieldDescription',
          label='description',
          type='textarea',
          placeholder='description_audience_placeholder'
        )
</template>

<script>
import state, {
  createAudience,
  deleteAudienceById,
  getAudienceById,
  updateAudienceById,
} from './store/audiences.store'

export default {
  name: 'AudienceEdit',

  data () {
    return {
      audienceName: '',
      audienceDescription: '',
      pageActions: [],
      pageTitle: this.$t('new_audience'),
      showForm: false,
    }
  },

  computed: {
    audience () {
      return state.audience
    },

    isNew () {
      return !this.$route.params.id_audience
    },
  },

  async created () {
    // Add action buttons to create a new organization
    if (this.isNew) {
      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })
      this.showForm = true
      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push({
      callback: this.delete,
      icon: 'ti-trash',
      text: 'delete',
      type: 'outline-danger',
    })
    this.pageActions.push({
      callback: this.update,
      icon: 'ti-save',
      text: 'update',
      type: 'outline-primary',
    })

    // Load the organization data
    await getAudienceById(this.$route.params.id_audience)
    this.audienceName = this.audience.name
    this.audienceDescription = this.audience.description
    this.pageTitle = this.audience.name
    this.showForm = true
  },

  mounted () {
    if (this.isNew) {
      this.$refs.inputName.$el.querySelector('input').focus()
    }
  },

  methods: {
    handleFieldName (value) {
      this.audienceName = value
    },

    handleFieldDescription (value) {
      this.audienceDescription = value
    },

    async delete () {
      const response = await deleteAudienceById(this.audience.id)

      if (!response) return

      this.$router.push({ name: 'audiences' })
    },

    async save () {
      const audience = await createAudience({
        name: this.audienceName,
        description: this.audienceDescription,
      })

      if (!audience) return

      // Go to the new audicience page
      this.$router.push({
        name: 'audience',
        params: { id_audience: audience.id },
      })
    },

    async update () {
      const audience = await updateAudienceById(this.audience.id, {
        name: this.audienceName,
        description: this.audienceDescription,
      })

      if (!audience) return

      // Go to the audicience overview page
      this.$router.push({
        name: 'audience',
        params: { id_audience: this.audience.id },
      })
    },

    submit () {
      if (this.isNew) this.save()
      else this.update()
    },
  },
}
</script>
