<template lang="pug">
.BlockShopifyCart(:style='cssVars', :class='{ "layer-minimal": !layerIsFull }')
  .BlockShopifyCart-Image(v-if='layerIsFull')
    img(v-if='source', :src='source')
    ce-image-skeleton(v-else, text='Add your image')

  .BlockShopifyCart-Info(:style='{ paddingLeft: layerIsFull ? "20px" : "0" }')
    .BlockShopifyCart-Name(
      contenteditable,
      @input='(event) => handleChange(event, "name")'
    ) {{ content.name }}

    .BlockShopifyCart-PriceWithDiscount(v-if='showDiscount')
      .BlockShopifyCart-SalePrice
        span.BlockShopifyCart-Price-Value {{ salePrice }}
        span.BlockShopifyCart-Price-Currency {{ content.currency }}
      .BlockShopifyCart-OldPrice
        span.BlockShopifyCart-Price-Value {{ content.price }}
        span.BlockShopifyCart-Price-Currency {{ content.currency }}

    .BlockShopifyCart-Price(v-else)
      span.BlockShopifyCart-Price-Value {{ content.price }}
      span.BlockShopifyCart-Price-Currency {{ content.currency }}

    .BlockShopifyCart-CartOptions(v-if='!showFallback')
      .BlockShopifyCart-Variants(v-if='areThereVariants')
        .BlockShopifyCart-VariantsLabel(
          contenteditable,
          @input='(event) => handleChange(event, "variantLabel")'
        ) {{ content.variantLabel }}
        select.BlockShopifyCart-VariantsOptions
          option(
            v-for='(variant, index) in variants',
            :key='variant.id',
            :value='variant.id'
          ) {{ variant.name }}

      .BlockShopifyCart-Quantity(v-if='!isPrestashop')
        .BlockShopifyCart-QuantityLabel.text-first-uppercase {{ $t("quantity") }}
        .BlockShopifyCart-QuantityControls
          .BlockShopifyCart-QuantityDecrement
            i.mdi.mdi-minus
          .BlockShopifyCart-QuantityValue 1
          .BlockShopifyCart-QuantityIncrement
            i.mdi.mdi-plus

    .BlockShopifyCart-BuyButton(
      contenteditable,
      @input='(event) => handleChange(event, "button")'
    ) {{ content.button }}
</template>

<script>
import { calculatePriceWithDiscount } from '@utils'

const CART_TYPE_MAP = {
  shopify_cart: 'shopify',
  prestashop_cart: 'prestashop',
}

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    showFallback: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    const isPrestashop = this.block.type === 'prestashop_cart'
    return {
      isPrestashop,
      content: { ...this.block.content },
    }
  },

  computed: {
    areThereVariants () {
      return this.variants.length > 1
    },
    cssVars () {
      return {
        '--button-background': this.block.config.colors.buttonBackground,
        '--button-text': this.block.config.colors.buttonText,
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
        '--text-color': this.block.config.colors.text,
      }
    },
    discountValue () {
      if (!this.block.config.productDiscount) {
        return 0
      }

      if (this.block.config.productDiscount.type === 'fixed_amount') {
        return this.block.config.productDiscount.value
      }

      return (
        this.content.price * (this.block.config.productDiscount.value / 100)
      )
    },
    layerIsFull () {
      return this.block.config.layer === 'full'
    },
    product () {
      return (
        this.block.config.product ||
        this.block.config[`product_${CART_TYPE_MAP[this.block.type]}`]
      )
    },
    salePrice () {
      return calculatePriceWithDiscount({
        price: this.content.price,
        discountValue: this.block.config.productDiscount.value,
        discountType: this.block.config.productDiscount.type,
      })
    },
    showDiscount () {
      return this.block.config.productDiscount?.value > 0
    },
    source () {
      return this.product?.image || ''
    },
    variants () {
      return this.product?.variants || []
    },
  },

  watch: {
    product (newProduct) {
      this.content = {
        ...this.block.content,
        ...newProduct,
      }

      this.onChange(this.content)
    },
  },

  methods: {
    handleChange (event, fieldName) {
      const value = event.target.innerText

      this.onChange({
        ...this.block.content,
        [fieldName]: value,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables

$parent: ".BlockShopifyCart"

#{$parent}
  align-items: center
  color: var(--text-color)
  display: grid
  grid-template-columns: minmax(auto, 100%) minmax(40%, max-content)
  grid-template-rows: auto auto
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-BuyButton
    align-items: center
    border-radius: 4px
    background-color: var(--button-background, #666)
    color: var(--button-text, #fff)
    font-weight: bold
    padding: 6px 20px
    text-align: center

  &-CartOptions
    display: flex
    gap: 20px
    margin-bottom: 14px

  &-Image
    img
      max-width: 100%

  &-Name
    font-size: 22px

  &-OldPrice
    opacity: 0.6
    text-decoration: line-through

  &-Quantity
    width: 30%

    &Controls
      color: $gray-dark
      align-content: center
      background-color: $white
      border: 1px solid #e7eaea
      border-radius: 4px
      box-sizing: border-box
      display: grid
      height: 36px
      grid-template-columns: 3fr 4fr 3fr
      text-align: center
      i
        margin-right: 0

    &Label
      margin-bottom: 10px

  &-Price,
  &-PriceWithDiscount
    font-size: 28px
    margin-bottom: 10px

  &-PriceWithDiscount
    display: flex
    gap: 10px

  &-SalePrice
    font-weight: bold

  &-Variants
    width: 70%

    &Label
      margin-bottom: 10px

    &Options
      box-sizing: border-box
      border: 1px solid #e7eaea
      border-radius: 4px
      height: 36px
      padding: 6px 0
      width: 100%
      option
        text-align: center

  &.layer-minimal
    grid-template-columns: 100%

    #{$parent}-BuyButton
      font-size: 20px
      padding-top: 12px
      padding-bottom: 12px

    #{$parent}-Name
      font-size: 32px
</style>
