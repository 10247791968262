import Vue from 'vue'
import dayjs from 'dayjs'
import common, { useLoader } from '@apps/Common/common.store'
import { AnalyticsService } from '@api/services/analytics.service'
import {
  transformEngagementForApexCharts,
  transformPerformanceForDataWell
} from '@/data-transformers/analytics'

// state
const state = Vue.observable({
  engagement: {},
  performance: [],
})

// setters
export const setEngagement = (engagementData = {}, interval) => {
  const engagement = transformEngagementForApexCharts(engagementData, interval)
  Vue.set(state, 'engagement', engagement)
  return engagement
}

export const setPerformance = (performanceData = {}) => {
  const performance = transformPerformanceForDataWell(performanceData)
  Vue.set(state, 'performance', performance)
  return performance
}

// actions
export const getCampaignEngagement = async (campaignId, dateParams) => {
  const { startDate, endDate } = dateParams
  dateParams.interval = 'day'

  const diff = dayjs(endDate).diff(startDate, 'month')

  if (diff >= 1 && diff < 6) {
    dateParams.interval = 'week'
  } else if (diff >= 6) {
    dateParams.interval = 'month'
  }

  const engagementData = await useLoader(
    AnalyticsService.getEngagementByCampaignId,
    campaignId,
    dateParams
  )

  if (!engagementData.length) return

  const engagement = setEngagement(engagementData, dateParams.interval)
  return engagement
}

export const getProjectEngagement = async (dateParams) => {
  const { startDate, endDate } = dateParams
  dateParams.interval = 'day'

  const diff = dayjs(endDate).diff(startDate, 'month')

  if (diff >= 1 && diff < 6) {
    dateParams.interval = 'week'
  } else if (diff >= 6) {
    dateParams.interval = 'month'
  }

  const engagementData = await useLoader(
    AnalyticsService.getEngagementByProjectId,
    common.user.project_id,
    dateParams
  )

  if (!engagementData.length) return

  const engagement = setEngagement(engagementData, dateParams.interval)
  return engagement
}

export const getProjectPerformance = async (dateParams) => {
  const performanceData = await useLoader(
    AnalyticsService.getPerformanceByProjectId,
    common.user.project_id,
    dateParams
  )

  if (!performanceData) return

  setPerformance(performanceData)
  return performance
}

export default state
