const BODY = 'body'
const BUTTON = 'button'
const CALL_ME = 'call_me'
const GALLERY = 'gallery'
const DIVIDER = 'divider'
const DYNAMIC_PHONE = 'dynamic_phone'
const HEADING = 'heading'
const IMAGE = 'image'
const PARAGRAPH = 'paragraph'
const PRESTASHOP_CART = 'prestashop_cart'
const REVIEW = 'review'
const SHOPIFY_CART = 'shopify_cart'
const SPACER = 'spacer'
const SOCIAL = 'social'

// These are sorted to be shown
export const BLOCKTYPES_ADDIBLE = [
  HEADING,
  PARAGRAPH,
  IMAGE,
  BUTTON,
  DIVIDER,
  SPACER,
  SOCIAL,
  GALLERY,
  PRESTASHOP_CART,
  SHOPIFY_CART,
  REVIEW,
  CALL_ME,
  DYNAMIC_PHONE,
]

export const BLOCKTYPES_NAMES = {
  [BODY]: BODY,
  [CALL_ME]: 'call me',
  [BUTTON]: BUTTON,
  [DIVIDER]: DIVIDER,
  [DYNAMIC_PHONE]: 'dynamic phone',
  [GALLERY]: GALLERY,
  [HEADING]: HEADING,
  [IMAGE]: IMAGE,
  [PARAGRAPH]: PARAGRAPH,
  [PRESTASHOP_CART]: 'prestashop',
  [REVIEW]: REVIEW,
  [SHOPIFY_CART]: 'shopify',
  [SPACER]: SPACER,
  [SOCIAL]: SOCIAL,
}

export const BLOCKTYPES_COMPONENTS = {
  [BUTTON]: 'CeBlockButton',
  [CALL_ME]: 'CeBlockCallme',
  [DIVIDER]: 'CeBlockDivider',
  [DYNAMIC_PHONE]: 'CeBlockDynamicPhone',
  [GALLERY]: 'CeBlockGallery',
  [HEADING]: 'CeBlockText',
  [IMAGE]: 'CeBlockImage',
  [REVIEW]: 'CeBlockReview',
  [PARAGRAPH]: 'CeBlockText',
  [PRESTASHOP_CART]: 'CeBlockShopifyCart',
  [SHOPIFY_CART]: 'CeBlockShopifyCart',
  [SPACER]: 'CeBlockSpacer',
  [SOCIAL]: 'CeBlockSocial',
}

export const BLOCKTYPES_ICONS = {
  [BUTTON]: 'mdi mdi-gesture-tap-button',
  [CALL_ME]: 'mdi mdi-card-account-phone',
  [DIVIDER]: 'mdi mdi-minus',
  [DYNAMIC_PHONE]: 'mdi mdi-phone',
  [GALLERY]: 'ti-layout-slider',
  [HEADING]: 'fas fa-heading',
  [IMAGE]: 'mdi mdi-image',
  [PARAGRAPH]: 'fas fa-paragraph',
  [PRESTASHOP_CART]: 'mdi mdi-cart',
  [REVIEW]: 'mdi mdi-star-half-full',
  [SHOPIFY_CART]: 'mdi mdi-cart',
  [SPACER]: 'mdi mdi-arrow-expand-vertical',
  [SOCIAL]: 'mdi mdi-web',
}

export const BLOCKTYPES_DEFAULTS = {
  [BUTTON]: {
    content: '<p style="text-align:center;">Add button text</p>',
    config: {
      link: '',
      fontFamily: 'Arial',
      fontSize: 16,
      alignment: 'center',
      shape: 'round',
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        text: '#ffffff',
        button: '#666',
        border: '#666',
        blockBackground: '',
      },
    },
  },
  [CALL_ME]: {
    content: {
      fallback: 'Go to the website',
      nameLabel: 'Name',
      namePlaceholder: 'Your full name',
      phoneLabel: 'Phone',
      phonePlaceholder: 'Your phone number',
      policyStart: 'I have read and accept the ',
      policyEnd: 'Privacy Statement',
      submit: 'Call me now',
    },
    config: {
      integration: null,
      fontSize: 15,
      fieldsConfig: {
        name: {
          show: true,
          required: false,
          regexp: null,
        },
        phone: {
          show: true,
          required: true,
          regexp: '^[6-7][0-9]{8,8}$',
          // regexp: '/(^[6-7][0-9]{8,8}$)|(^9(0{2}|([1-9][0-9]))[0-9]{6}$)|(^8[1-9][0-9]{7,7}$)/gi',
        },
        policy: {
          show: true,
          required: true,
          link: ''
        }
      },
      link: '',
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        blockBackground: '',
        buttonBackground: '#29b169',
        buttonText: '#fff',
        inputColor: '#e7eaea',
        text: '#333b46',
      },
    },
  },
  [DIVIDER]: {
    config: {
      thickness: 1,
      style: 'solid',
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        line: '#666',
        blockBackground: '',
      },
    },
  },
  [DYNAMIC_PHONE]: {
    content: 'Write a phone or text',
    config: {
      integration: null,
      defaultPhone: '91 222 3333',
      customText: false,
      fontFamily: 'Arial',
      alignment: 'center',
      shape: 'pill',
      size: 'medium',
      padding: {
        top: 20,
        bottom: 20,
      },
      iconStyle: 'circle',
      iconColor: 'white',
      colors: {
        text: '#ffffff',
        button: '#29B169',
        border: '#29B169',
        blockBackground: '',
      },
    },
  },
  [GALLERY]: {
    config: {
      imageList: [],
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        blockBackground: '',
      },
    },
  },
  [HEADING]: {
    content: '<h1></h1>',
    config: {
      fontFamily: 'Arial',
      fontSize: 32,
      padding: {
        top: 30,
        bottom: 30,
      },
      colors: {
        text: '#4D4D4D',
        links: '#476584',
        blockBackground: '',
      },
    },
  },
  [IMAGE]: {
    config: {
      image: null,
      link: '',
      alignment: 'center',
      fullWidth: false,
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        blockBackground: '',
      },
    },
  },
  [PARAGRAPH]: {
    content: '<p></p>',
    config: {
      fontFamily: 'Arial',
      fontSize: 15,
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        text: '#4D4D4D',
        links: '#476584',
        blockBackground: '',
      },
    },
  },
  [PRESTASHOP_CART]: {
    content: {
      button: 'Buy',
      variantLabel: 'Choose your size',
    },
    config: {
      product_prestashop: null,
      layer: 'full',
      productDiscount: null,
      productTag: '',
      link: '',
      queryParams: {},
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        buttonText: '#fff',
        buttonBackground: '#666',
        blockBackground: '',
        text: '#333B46',
      },
    },
  },
  [REVIEW]: {
    content: {
      fallback: 'Go to the website',
      heading: 'Rate us',
      titleLabel: 'Give your review a title',
      titlePlaceholder: 'Write the title of your review here.',
      reviewLabel: 'Tell us about your experience',
      reviewPlaceholder:
        'This is where you write your review. Explain what happened, and leave out offensive words. Keep your feedback honest, helpful, and constructive.',
      submit: 'Send review',
    },
    config: {
      integration: null,
      iconFigure: 'star',
      rating: 5,
      link: '',
      padding: {
        top: 20,
        bottom: 20,
      },
      colorScheme: 'color',
      colors: {
        blockBackground: '',
        buttonBackground: '#999',
        buttonText: '#fff',
        text: '#333b46',
      },
    },
  },
  [SHOPIFY_CART]: {
    content: {
      button: 'Buy',
      variantLabel: 'Choose your size',
    },
    config: {
      product: null,
      layer: 'full',
      productDiscount: null,
      productTag: '',
      link: '',
      padding: {
        top: 20,
        bottom: 20,
      },
      colors: {
        buttonText: '#fff',
        buttonBackground: '#666',
        blockBackground: '',
        text: '#333B46',
      },
    },
  },
  [SPACER]: {
    config: {
      height: 40,
      colors: {
        blockBackground: '',
      },
    },
  },
  [SOCIAL]: {
    config: {
      socialLinks: [
        { type: 'facebook', link: 'facebook.com/' },
        { type: 'instagram', link: 'instagram.com/' },
        { type: 'twitter', link: 'twitter.com/' },
        { type: 'website', link: 'website.com/' },
      ],
      iconStyle: 'circle',
      size: 'medium',
      alignment: 'center',
      spacing: 18,
      padding: {
        top: 20,
        bottom: 20,
      },
      colorScheme: 'gray',
      colors: {
        blockBackground: '',
      },
    },
  },
}
