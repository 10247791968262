import {
  isValidHttpUrl,
  isValidPrestashopWebserviceKey,
  isValidShopifyAccessToken,
} from '@utils'

export default {
  prestashop: {
    hostUrl: {
      label: 'Host Url',
      input: 'text',
      required: true,
      validation: (value) => isValidHttpUrl(value),
    },

    webserviceKey: {
      label: 'Webservice Key',
      input: 'text',
      required: true,
      validation: (value) => isValidPrestashopWebserviceKey(value),
    },
  },

  shopify: {
    api: {
      label: 'API URL',
      input: 'text',
      required: true,
      validation: (value) => isValidHttpUrl(value),
    },

    accessToken: {
      label: 'Access Token',
      input: 'text',
      required: true,
      validation: (value) => isValidShopifyAccessToken(value),
    },
  },
}
