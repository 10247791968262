<template lang="pug">
.QueryParams
  .QueryParams-Preview
    .QueryParams-KeyValue(v-for='(value, key) in queryParams') {{ key }} = {{ value }}

  // Button: Update params and values
  ja-button(
    :on-click='toggleModal',
    :text='$t("update")',
    block,
    icon='mdi mdi-pencil',
    type='outline-primary'
  )

  // Modal
  b-modal(
    ref='modal-query-params'
    centered
    modal-class='QueryParams-Modal'
    size='lg'
    scrollable
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .QueryParams-ModalHeader.text-first-uppercase
        span {{ $t("query_params") }}

    // Modal Content
    .QueryParams-ModalBody

      // Show
      ja-field-set-key-value(
        @input='handleChange',
        v-model='queryParams'
      )

</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    const queryParams = cloneDeep(this.value)

    return {
      queryParams,
      modalShow: false,
    }
  },

  methods: {
    handleChange () {
      this.onChange({ value: cloneDeep(this.queryParams) })
    },

    toggleModal () {
      this.$refs['modal-query-params'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
.QueryParams
  &-Preview
    margin-bottom: 10px
</style>
