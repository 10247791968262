<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card
    b-card-text
      form.form(@submit.prevent='update' ref='form')
        // Email
        ja-form-field(
          :field-value='user.email'
          :label='$t("email")'
          type='info'
        )

        // Nombre completo
        ja-form-field(
          :field-value='user.name'
          :label='$t("full_name")'
          id='name'
          type="text",
          required
          placeholder="name_placeholder"
        )
</template>

<script>
import state, { setUser } from '@apps/Common/common.store'
import { updateUserById } from '@apps/Accounts/Organizations/Users/store/users.store'

export default {
  name: 'Profile',

  data () {
    const pageActions = []

    pageActions.push({
      callback: () => this.update(),
      icon: 'ti-save',
      text: 'save',
      type: 'outline-primary',
    })

    return {
      pageTitle: this.$t('profile'),
      pageActions,
    }
  },

  computed: {
    user () {
      return state.user || {}
    }
  },

  methods: {
    async update () {
      const { form: formEl } = this.$refs
      formEl.reportValidity()

      if (!formEl.checkValidity()) return

      const formData = new FormData(this.$refs.form)

      const { name } = Object.fromEntries(formData)

      const updatedUser = await updateUserById(this.user.id, { name })

      setUser({
        ...state.user,
        ...updatedUser
      })
    }
  }
}
</script>
