import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TODAY = dayjs().format(DATE_FORMAT)

const CURRENT_WEEK = 'CURRENT_WEEK'
const CURRENT_MONTH = 'CURRENT_MONTH'
const CURRENT_YEAR = 'CURRENT_YEAR'
const LAST_WEEK = 'LAST_WEEK'
const LAST_MONTH = 'LAST_MONTH'
const LAST_YEAR = 'LAST_YEAR'
const PREV_7_DAYS = 'PREV_7_DAYS'
const PREV_14_DAYS = 'PREV_14_DAYS'
const PREV_30_DAYS = 'PREV_30_DAYS'
const PREV_2_MONTHS = 'PREV_2_MONTHS'
const PREV_3_MONTHS = 'PREV_3_MONTHS'
const PREV_4_MONTHS = 'PREV_4_MONTHS'
const PREV_5_MONTHS = 'PREV_5_MONTHS'
const PREV_6_MONTHS = 'PREV_6_MONTHS'
const PREV_365_DAYS = 'PREV_365_DAYS'

const TIMEFRAMES_MAPPING = {
  [CURRENT_WEEK]: 'current__week',
  [LAST_WEEK]: 'last_1_week',
  [CURRENT_MONTH]: 'current__month',
  [LAST_MONTH]: 'last_1_month',
  [CURRENT_YEAR]: 'current__year',
  [LAST_YEAR]: 'last_1_year',
  [PREV_7_DAYS]: 'prev_7_day',
  [PREV_14_DAYS]: 'prev_14_day',
  [PREV_30_DAYS]: 'prev_30_day',
  [PREV_2_MONTHS]: 'prev_2_month',
  [PREV_3_MONTHS]: 'prev_3_month',
  [PREV_4_MONTHS]: 'prev_4_month',
  [PREV_5_MONTHS]: 'prev_5_month',
  [PREV_6_MONTHS]: 'prev_6_month',
  [PREV_365_DAYS]: 'prev_365_day',
}

const getDateRange = (dateKey = '') => {
  const [action, diff, interval] = dateKey.split('_')

  let startDate = dayjs()
  let endDate = dayjs()
  const intervalIso = interval === 'week' ? 'isoWeek' : interval

  if (action === 'current') {
    startDate = startDate.startOf(intervalIso)
  }

  if (action === 'prev') {
    startDate = startDate.subtract(diff, interval).add(1, 'day')
  }

  if (action === 'last') {
    startDate = startDate.subtract(diff, interval)
    startDate = startDate.startOf(intervalIso)
    endDate = startDate.add(diff, interval).subtract(1, 'day')
  }

  return {
    startDate: startDate.format(DATE_FORMAT),
    endDate: endDate.format(DATE_FORMAT),
  }
}

export const TIMEFRAMES = Object.fromEntries(
  Object.entries(TIMEFRAMES_MAPPING).map(timeframe => [
    timeframe[0], getDateRange(timeframe[1])
  ])
)

export const TIMEFRAMES_OPTIONS = Object.entries(TIMEFRAMES_MAPPING).map(timeframe => ({
  value: timeframe[0],
  text: timeframe[1]
}))
