<template lang="pug">
li.NavMenuItem
  router-link.waves-effect.waves-dark(
    :class="[{ 'router-link-active': jaActiveLink($route.meta.sidebar.parent) }]"
    :to="to"
    exact
    aria-expanded="false"
  )
    i(:class="classIcon")
    span {{ jaGetRouteParam(name) }}
</template>

<script>

export default {
  name: 'NavMenuItem',
  props: {
    classIcon: { type: String, required: true, },
    name: { type: String, required: true, },
    slug: { type: String, required: true, },
    to: { type: String, required: true, },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

.NavMenuItem
  a
    align-items: center
    color: #394b54
    border-radius: 6px
    display: flex
    font-size: 15px
    margin-bottom: 6px
    padding: 7px 12px
    width: 100%
    &.router-link-active
      span
        font-weight: 500
      span, i
        color: $purple
  &:hover
    a
      background-color: #f3f2f8
      span, i
        color: $purple
      &.router-link-active
        background-color: $purple
        span, i
          color: white

  span
    font-weight: 400
    margin-left: 6px

  i
    border-radius: 3px
    display: inline-block
    height: 20px
    line-height: 21px
    margin-right: 2px
    text-align: center
    width: 20px
    color: #565656
    &::before
      font-size: 18px

.mini-sidebar
  .NavMenuItem
    a
      span
        color: transparent
    @media (max-width: 450px)
      a
        &.router-link-active
          color: $purple
        span
          color: inherit
    &:hover
      a
        span
          color: white
</style>
