<template lang="pug">
.ContentEditor
  .SlotEditingZone
    ce-fallback-switch
    ce-editing-zone(:rows='rows', :body='body', ref='editing-zone')

  .SlotSidePanel
    ce-side-panel(ref='side-panel')
</template>

<script>
// https://html2canvas.hertzen.com/
import html2canvas from 'html2canvas'

import state, {
  resetActiveBlock,
  resetState,
  setAmpBlockEvents,
  setBlocks,
  setBody,
  setIntegrations,
  setIntegrationTypes,
  setStateListeners,
} from '@plugins/content-editor/state'

import { getCanvasBlob } from '@plugins/content-editor/utils'

export default {
  name: 'ContentEditor',
  props: {
    ampBlockEvents: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: [Object, null],
      required: true,
    },
    integrations: {
      type: Array,
      default: () => [],
    },
    integrationTypes: {
      type: Array,
      default: () => [],
    },
    onChangeConfig: {
      type: Function,
      default: () => {},
    },
    onDeleteImage: {
      type: Function,
      default: () => {},
    },
    onGetImageLibrary: {
      type: Function,
      default: () => {},
    },
    onGetProductList: {
      type: Function,
      default: () => {},
    },
    onUploadImage: {
      type: Function,
      default: () => {},
    },
    useTakeScreenshot: {
      type: [Function, null],
      default: null,
    },
  },

  computed: {
    body: () => state.body,
    rows: () => state.blocks,
  },

  created () {
    setAmpBlockEvents(this.ampBlockEvents)
    setBlocks(this.config.rows)
    setBody(this.config.body)
    setIntegrations(this.integrations)
    setIntegrationTypes(this.integrationTypes)

    setStateListeners({
      listenerChangeState: this.onChangeConfig,
      listenerDeleteImage: this.onDeleteImage,
      listenerGetImageLibrary: this.onGetImageLibrary,
      listenerGetProductList: this.onGetProductList,
      listenerUpdateImage: this.onUploadImage,
    })

    this.$emit('update:use-take-screenshot', () => this.takeScreenshot())
  },

  beforeMount () {
    document.addEventListener('click', this.handleClickToResetActiveBlock)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.handleClickToResetActiveBlock)
    resetState()
  },

  methods: {
    handleClickToResetActiveBlock (event) {
      // TODO desactivar para modales y contenido
      const modalEl = document.querySelector('.modal')
      const editingZoneEl = this.$refs['editing-zone'].$el
      const sidePanelEl = this.$refs['side-panel'].$el

      if (
        event.target === modalEl ||
        event.target === editingZoneEl ||
        event.target === sidePanelEl ||
        modalEl?.contains(event.target) ||
        editingZoneEl.contains(event.target) ||
        sidePanelEl.contains(event.target)
      ) {
        return false
      }

      resetActiveBlock()
    },

    async takeScreenshot () {
      const editingZoneEl = this.$refs['editing-zone'].$el
      const canvas = await html2canvas(editingZoneEl, {
        allowTaint: true, // Whether to allow cross-origin images to taint the canvas
        height: 800, // The height of the canvas
        useCORS: true, // Whether to attempt to load images from a server using CORS
      })
      const blob = await getCanvasBlob(canvas)
      return {
        canvas,
        blob,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/fonts

.ContentEditor
  display: grid
  gap: 20px
  grid-template-columns: auto minmax(auto, 400px)

  .SlotEditingZone
    display: grid
    gap: 30px
    justify-items: center
    grid-template-rows: min-content

  .SlotSidePanel
    align-self: start
    height: auto
    position: sticky
    top: 80px
</style>
