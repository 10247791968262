export const AMP_EXTENSIONS = {
  accordion: {
    name: 'amp-accordion',
    version: '0.1',
  },
  bind: {
    name: 'amp-bind',
    version: '0.1',
  },
  carousel: {
    name: 'amp-carousel',
    version: '0.1',
  },
  form: {
    name: 'amp-form',
    version: '0.1',
  },
  list: {
    name: 'amp-list',
    version: '0.1',
  },
  mustache: {
    name: 'amp-mustache',
    version: '0.2',
  },
}
