<template lang="pug">
  span(:contenteditable="editable" @input="handleContentValue") {{ value }}
</template>

<script>
export default {
  name: 'Editable',
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    handleContentValue (event) {
      this.onChange(event.target.textContent)
    }
  }
}
</script>
