<template lang="pug">
.BlockText(:style='cssVars')
  ce-text-editor(
    :key='keyToForceRender',
    :value='block.content',
    :type='block.type',
    :on-change='onChange'
  )
</template>

<script>
import { sleep } from '@/utils'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      keyToForceRender: 0,
    }
  },

  computed: {
    cssVars () {
      return {
        '--font-family': this.block.config.fontFamily,
        '--font-size': this.block.config.fontSize + 'px',
        '--text-color': this.block.config.colors.text,
        '--links-color': this.block.config.colors.links,
        '--ck-color-engine-placeholder-text': '#ccc', // CKEditor Placeholder Color
        '--padding-bottom': (this.block.config.padding?.bottom ?? 30) + 'px',
        '--padding-top': (this.block.config.padding?.top ?? 30) + 'px',
      }
    },
  },

  async created () {
    // Force re-rendering component to relocate the ckeditor toolbar after the enter animation
    await sleep(500)
    ++this.keyToForceRender
  },
}
</script>

<style lang="sass" scoped>
.BlockText
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  ::v-deep
    .ck.ck-editor__editable_inline>:last-child
      margin-bottom: 0

    .ck.ck-editor__editable_inline>:first-child
      margin-top: 0

    h1, h2, h3, h4, h5, h6, p
      color: var(--text-color, inherit)
      font-family: var(--font-family, inherit)
      font-size: var(--font-size, inherit)
      margin-top: 0
      margin-bottom: 0
      overflow: hidden
    p:not(:last-child)
      margin-bottom: calc(0.6em * 1.5)
    a
      color: var(--links-color, inherit)
      &:hover
        text-decoration: underline
</style>
