<template lang="pug">
.AddBlockButton(v-show='show', :style='cssVars', @click='onClick')
  button.AddBlockButton-Button
    i.AddBlockButton-Icon.fas.fa-plus
  slot
</template>

<script>
import state from '@plugins/content-editor/state'

export default {
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    cssVars () {
      return {
        '--top': this.top,
      }
    },

    top () {
      if (!state.overedBlock) return 0

      return state.overedBlock.mouseLocation === 'top'
        ? state.overedBlock.positionStart + 'px'
        : state.overedBlock.positionEnd + 'px'
    },

    show () {
      return !!state.overedBlock
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables

.AddBlockButton
  position: absolute
  top: calc((var(--top, -#{$button-add-size}) - #{$button-add-size}/2))
  left: calc((#{$content-width} - #{$button-add-size})/2)
  z-index: 3

  &-Button
    align-items: center
    background: $purple
    border-radius: $button-add-size / 2
    border: none
    color: $white
    display: flex
    height: $button-add-size
    justify-content: center
    outline: none
    width: $button-add-size
    &:hover
      background-color: $purple-dark

  &-Icon
    display: block
    font-size: $button-add-fontsize
    height: $button-add-size
    line-height: $button-add-size
    margin-right: 0
</style>
