<template lang="pug">
ja-content(:pageTitle='project.name')
  // Projects Details
  h2 {{ $t("project_details") }}

  b-card
    b-card-text
      form.form
        // Organization
        ja-form-field#organization(
          v-if='project.organization',
          :fieldValue='project.organization.name',
          label='organization',
          type='info'
        )

        // Created Date
        ja-form-field#created_date(
          v-if='project',
          label='created_date',
          type='info',
          :fieldValue='createdAt'
        )

        // Timezone
        ja-form-field#time_zone(
          v-if='project',
          :fieldValue='timezone',
          label='time_zone',
          type='info'
        )

        // Start of week
        ja-form-field#start_week(
          v-if='project',
          :fieldValue='weekStart',
          label='start_week',
          type='info'
        )

  // Access Keys
  h2 {{ $t("access_keys") }}
  b-card
    b-card-text
      form.form
        // Token
        ja-form-field#token(
          :fieldValue='jaGenerateUuid()',
          label='token',
          type='info'
        )

        // API Key
        ja-form-field#api_key(
          :fieldValue='jaGenerateUuid()',
          label='api_key',
          type='info'
        )

  // Teams
  h2 {{ $t("teams") }}
  ja-table(
    :headButtons='teamTable.headButtons',
    :items='teams',
    :rowButtons='teamTable.rowButtons'
  )

  // Modal
  b-modal(centered, ref='modal-assign-team', v-model='modalShow')
    // Modal Title
    template(v-slot:modal-title)
      i.mdi.mdi-account-multiple
      span {{ $t("assign_team") }}

    // Modal Content
    template(v-if='teamList.length')
      form.form
        ja-form-field#team_list(
          type='select',
          :label='$t("select_team")',
          :options='teamList',
          :on-change='onChangeFieldTeam'
        )

    template(v-else)
      p {{ $t("not_available_teams") }}

    // Modal Footer
    template(v-slot:modal-footer)
      template(v-if='teamList.length')
        ja-button(
          icon='ti-save',
          :text='$t("save")',
          type='outline-primary',
          :on-click='assignTeam'
        )
      template(v-else)
        div
</template>

<script>
import Vue from 'vue'
import { formatDate } from '@utils'
import { WEEK_DAYS } from '@/const'

import state, {
  addTeamToProject,
  getProjectById,
  getProjectTeamsById,
  removeTeamFromProject,
} from './store/projects.store'

import organizationState, {
  getTeamsByOrganizationId,
} from '@apps/Accounts/Organizations/Organizations/store/organizations.store'

export default {
  name: 'ProjectOverview',

  data () {
    return {
      modalShow: false,
      selectedTeam: null,
      teamList: [],
      teamTable: {
        headButtons: [
          {
            icon: 'ti-plus',
            text: 'assign_team',
            onClick: () => this.onClickAssignTeam(),
          },
        ],
        rowButtons: [
          {
            icon: 'ti-trash',
            type: 'outline-danger',
            onClick: (team) => this.onClickUnassignTeam(team.id),
          },
        ],
      },
    }
  },

  computed: {
    createdAt () {
      return state.project?.created_at
        ? formatDate(state.project?.created_at)
        : ''
    },

    project () {
      return state.project
    },

    teams () {
      return state.teams?.items?.map(({ id, name, created_at }) => {
        const created = formatDate(created_at)
        return { id, name, created }
      }) || []
    },

    timezone () {
      return this.project?.timezone?.text || ''
    },

    weekStart () {
      return this.$t(WEEK_DAYS[this.project?.week_start || 0].text)
    },
  },

  beforeCreate () {
    getProjectById(this.$route.params.id_project)
    getProjectTeamsById(this.$route.params.id_project)
  },

  methods: {
    async assignTeam () {
      this.toggleModalAssignTeam()

      await addTeamToProject(this.$route.params.id_project, this.selectedTeam)

      await getProjectTeamsById(this.$route.params.id_project)
    },

    onChangeFieldTeam (value) {
      Vue.set(this, 'selectedTeam', value)
    },

    async onClickAssignTeam () {
      await getTeamsByOrganizationId(this.$route.params.id_organization)

      const teamList = organizationState.teams.items
        .filter((team) => !this.teams.map((t) => t.id).includes(team.id))
        .map((team) => ({
          key: team.id,
          text: team.name,
        }))

      Vue.set(this, 'teamList', teamList)

      if (teamList.length) {
        Vue.set(this, 'selectedTeam', teamList[0].key)
      }

      this.toggleModalAssignTeam()
    },

    async onClickUnassignTeam (teamId) {
      await this.unassignTeam(teamId)
      await getProjectTeamsById(this.$route.params.id_project)
    },

    toggleModalAssignTeam () {
      this.$refs['modal-assign-team'].toggle()
    },

    async unassignTeam (teamId) {
      await removeTeamFromProject(this.$route.params.id_project, teamId)
    },
  },
}
</script>
