import app from '@/main'

/**
 * @return {object} TEXTS
 */
export default () => {
  const TEXTS = {}

  TEXTS.BUTTON_BUY = app.$t('buy_now')
  TEXTS.BUTTON_CHECKOUT = app.$t('checkout')
  TEXTS.BUTTON_CONFIRM_SHIPPING = app.$t('confirm_shipping_address')
  TEXTS.BUTTON_GO_WEBSITE = app.$t('go_website')
  TEXTS.BUTTON_ORDER_SUMMARY = app.$t('order_summary')
  TEXTS.BUTTON_PAY_NOW = app.$t('pay_now')
  TEXTS.BUTTON_REMOVE = app.$t('remove')
  TEXTS.BUTTON_SHIPPING_ADDRESS = app.$t('shipping_address')
  TEXTS.BUTTON_SHOPPING_CART = app.$t('shopping_cart')
  TEXTS.LABEL_ADDRESS = app.$t('address')
  TEXTS.LABEL_CITY = app.$t('city')
  TEXTS.LABEL_COUNTRY = app.$t('country')
  TEXTS.LABEL_FIRST_NAME = app.$t('first_name')
  TEXTS.LABEL_QUANTITY = app.$t('quantity')
  TEXTS.LABEL_PHONE = app.$t('phone')
  TEXTS.LABEL_POSTAL_CODE = app.$t('postal_code')
  TEXTS.LABEL_PROVINCE = app.$t('province')
  TEXTS.LABEL_SURNAME = app.$t('surname')
  TEXTS.LABEL_VARIANT = app.$t('size')
  TEXTS.MESSAGE_ORDER_KO = app.$t('error_process_order')
  TEXTS.MESSAGE_ORDER_IP = app.$t('in_process_order')
  TEXTS.MESSAGE_ORDER_OK = app.$t('ok_process_order')
  TEXTS.PLACEHOLDER_ADDRESS = app.$t('address_placeholder')
  TEXTS.PLACEHOLDER_CITY = app.$t('city_placeholder')
  TEXTS.PLACEHOLDER_COUNTRY = app.$t('country_placeholder')
  TEXTS.PLACEHOLDER_FIRST_NAME = app.$t('first_name_placeholder')
  TEXTS.PLACEHOLDER_PHONE = app.$t('phone_placeholder')
  TEXTS.PLACEHOLDER_POSTAL_CODE = app.$t('postal_code_placeholder')
  TEXTS.PLACEHOLDER_PROVINCE = app.$t('province_placeholder')
  TEXTS.PLACEHOLDER_SURNAME = app.$t('surname_placeholder')
  TEXTS.TITLE_MAKE_PAYMENT = app.$t('make_payment')
  TEXTS.TITLE_ORDER_SUMMARY = app.$t('order_summary')

  return TEXTS
}
