<template lang="pug">
.CheckboxList
  ja-checkbox.CheckboxList-Item(
    v-for='option in options'
    v-bind='option'
    :key='option.name'
    :on-change='handleChange'
  )
</template>

<script>

export default {
  name: 'CheckboxList',

  props: {
    options: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    const values = []

    this.options.forEach(
      item => item.checked && values.push(item.value)
    )

    return {
      values
    }
  },

  created () {
    this.onChange(this.values)
  },

  methods: {
    handleChange ({ value, checked }) {
      if (!checked) {
        this.values.splice(
          this.values.findIndex(
            (item) => item === value
          ),
          1
        )
      }

      if (checked) this.values.push(value)

      this.onChange(this.values)
    }
  }
}
</script>

<style lang="sass" scoped>
.CheckboxList-Item
  margin-bottom: 10px
</style>
