<template lang="pug">
b-card.TemplateCard(
  @click='onClick',
  :class='{ "TemplateCard--selected": selected, "TemplateCard--hoverable": hoverable }'
)
  template(v-if='image')
    b-card-img(
      v-show='isImageLoaded',
      :alt='name',
      :src='imageSrc',
      :top='true',
      @load='onImageLoad'
    )

    b-skeleton-img(v-if='!isImageLoaded', card-img='top', aspect='1:1')

  .TemplateCard-ImageSkeleton(v-else)
    i.mdi.mdi-view-dashboard

  b-card-title.TemplateCard-Title(v-if='name') {{ name }}

  b-card-text.TemplateCard-Text(v-if='description') {{ description }}
  .TemplateCard-Overlay(v-if='overlayText')
    .TemplateCard-OverlayText {{ overlayText }}
</template>

<script>
export default {
  name: 'TemplateCard',

  props: {
    description: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    overlayText: {
      type: String,
      default: '',
    },
    to: {
      type: [Boolean, Object, String],
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isImageLoaded: false,
  }),

  computed: {
    hoverable () {
      return this.to || this.selectable
    },

    imageSrc () {
      return this.image
    },
  },

  methods: {
    onClick () {
      if (!this.to) return
      this.$router.push(this.to)
    },

    onImageLoad () {
      this.isImageLoaded = true
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

.TemplateCard
  position: relative
  display: flex
  max-width: 480px
  transition: all .15s ease-in-out

  &--hoverable
    &:not(&--selected)
      &:hover
        border: 1px solid $purple
        outline: 2px solid $purple
        cursor: pointer

        .TemplateCard-Text, .TemplateCard-Title
          color: $purple

        .TemplateCard-Overlay
          opacity: 1

  &--selected
    border: 3px solid $purple

  &-ImageSkeleton
    aspect-ratio: auto 1 / 1
    background-color: #ccc
    display: grid
    align-items: center
    gap: 10px
    width: 100%
    text-align: center
    i
      display: block
      font-size: 80px
      color: #787979

  &-Overlay
    align-items: flex-end
    display: flex
    height: 100%
    justify-content: center
    left: 0
    opacity: 0
    position: absolute
    top: 0
    transition: all 0.3 ease-in-out
    width: 100%

  &-OverlayText
    background: $purple
    color: white
    font-size: 20px
    padding: 30px 0
    text-align: center
    width: 100%

  &-Text
    font-size: 14px

  &-Title
    margin-top: 20px
    font-size: 20px
</style>
