import { render, staticRenderFns } from "./AddBlockButton.vue?vue&type=template&id=31d6b85d&scoped=true&lang=pug&"
import script from "./AddBlockButton.vue?vue&type=script&lang=js&"
export * from "./AddBlockButton.vue?vue&type=script&lang=js&"
import style0 from "./AddBlockButton.vue?vue&type=style&index=0&id=31d6b85d&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d6b85d",
  null
  
)

export default component.exports