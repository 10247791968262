<template lang="pug">
ja-content(
  v-if='template'
  :pageActions='pageActions'
  :pageTitle='template.name'
  :page-subtitle='template.description'
)
  b-card
    b-card-text
      form.form
        // Name
        ja-form-field(
          v-if='template.created_at',
          :field-value='template.created_at',
          label='created_at',
          type='info'
        )

        // Description
        ja-form-field(
          v-if='template.updated_at',
          :field-value='template.updated_at',
          label='updated_at',
          type='info'
        )

        // Content
        ja-form-field(label='content')
          template(v-slot:value)
            ja-template-card(
              :image='template.preview',
              :to='templateEditorRoute',
              :overlay-text='$t("edit_content")'
            )
</template>

<script>
import state, { getTemplateById, setTemplateClone } from './store/templates.store'

let loadingPromise = null

export default {
  name: 'TemplateOverview',

  data () {
    const pageActions = []

    return {
      pageActions,
    }
  },

  computed: {
    template: () => state.template,

    templateEditorRoute () {
      return {
        name: 'template-content-editor',
        params: { id_template: this.$route.params.id_template },
      }
    },
  },

  beforeCreate () {
    loadingPromise = getTemplateById(this.$route.params.id_template)
  },

  async created () {
    await loadingPromise

    this.pageActions.push({
      callback: this.clone,
      icon: 'ti-layers',
      text: 'clone',
      type: 'outline-primary',
    })
  },

  methods: {
    clone () {
      setTemplateClone()
      this.$router.push({ name: 'template-new' })
    },
  },
}
</script>
