// Syles
import './styles/styles'

// Components "Elements"
import Alert from './components/elements/Alert'
import ApexChart from './components/elements/ApexChart'
import AppLauncher from './components/elements/AppLauncher'
import Badge from './components/elements/Badge'
import Breadcrumb from './components/elements/Breadcrumb'
import Button from './components/elements/Button'
import Card from './components/elements/Card'
import Chartist from './components/elements/Chartist'
import Checkbox from './components/elements/Checkbox'
import CheckboxList from './components/elements/CheckboxList'
import DataBar from './components/elements/DataBar'
import DataBlockStats from './components/elements/DataBlockStats'
import DataOne from './components/elements/DataOne'
import DataTable from './components/elements/DataTable'
import DataWell from './components/elements/DataWell'
import Editable from './components/elements/Editable'
import Engagement from './components/elements/Engagement'
import FieldSetKeyValue from './components/elements/FieldSetKeyValue'
import Filters from './components/elements/Filters'
import FormField from './components/elements/FormField'
import Menu from './components/elements/Menu'
import NavMenu from './components/elements/NavMenu'
import NavMenuItem from './components/elements/NavMenuItem'
import Pagination from './components/elements/Pagination'
import Performance from './components/elements/Performance'
import PermissionGroup from './components/elements/PermissionGroup'
import PermissionItem from './components/elements/PermissionItem'
import PermissionSelector from './components/elements/PermissionSelector'
import Preloader from './components/elements/Preloader'
import ProjectsTree from './components/elements/ProjectsTree'
import SearchBox from './components/elements/SearchBox'
import SmartCheck from './components/elements/SmartCheck'
import Subtitle from './components/elements/Subtitle'
import Table from './components/elements/Table'
import TableTd from './components/elements/TableTd'
import TemplateCard from './components/elements/TemplateCard'
import TemplateDeck from './components/elements/TemplateDeck'
import Timeframe from './components/elements/Timeframe'
import Timeline from './components/elements/Timeline'
import TimelineItem from './components/elements/TimelineItem'
import Title from './components/elements/Title'
import ToolbarLogo from './components/elements/ToolbarLogo'
import UserMenu from './components/elements/UserMenu'

// Components "Layout"
import Content from './components/layout/Content'
import Header from './components/layout/Header'
import NavHeader from './components/layout/NavHeader'
import Sidebar from './components/layout/Sidebar'
import Topbar from './components/layout/Topbar'
import TopbarItem from './components/layout/TopbarItem'

// Components "Template"
import Page from './components/templates/Page'

// Mixins
import utilsMixin from './mixins/utlis.mixin'

export default {
  install (Vue, options) {
    Vue.mixin(utilsMixin)
    // Elements
    Vue.component('JaAlert', Alert)
    Vue.component('JaApexChart', ApexChart)
    Vue.component('JaAppLauncher', AppLauncher)
    Vue.component('JaBadge', Badge)
    Vue.component('JaBreadcrumb', Breadcrumb)
    Vue.component('JaButton', Button)
    Vue.component('JaCard', Card)
    Vue.component('JaChartist', Chartist)
    Vue.component('JaCheckbox', Checkbox)
    Vue.component('JaCheckboxList', CheckboxList)
    Vue.component('JaDataBar', DataBar)
    Vue.component('JaDataBlockStats', DataBlockStats)
    Vue.component('JaDataOne', DataOne)
    Vue.component('JaDataTable', DataTable)
    Vue.component('JaDataWell', DataWell)
    Vue.component('JaEditable', Editable)
    Vue.component('JaEngagement', Engagement)
    Vue.component('JaFieldSetKeyValue', FieldSetKeyValue)
    Vue.component('JaFilters', Filters)
    Vue.component('JaFormField', FormField)
    Vue.component('JaMenu', Menu)
    Vue.component('JaNavMenu', NavMenu)
    Vue.component('JaNavMenuItem', NavMenuItem)
    Vue.component('JaPagination', Pagination)
    Vue.component('JaPerformance', Performance)
    Vue.component('JaPermissionGroup', PermissionGroup)
    Vue.component('JaPermissionItem', PermissionItem)
    Vue.component('JaPermissionSelector', PermissionSelector)
    Vue.component('JaPreloader', Preloader)
    Vue.component('JaProjectsTree', ProjectsTree)
    Vue.component('JaSearchBox', SearchBox)
    Vue.component('JaSmartCheck', SmartCheck)
    Vue.component('JaSubtitle', Subtitle)
    Vue.component('JaTable', Table)
    Vue.component('JaTableTd', TableTd)
    Vue.component('JaTemplateCard', TemplateCard)
    Vue.component('JaTemplateDeck', TemplateDeck)
    Vue.component('JaTitle', Title)
    Vue.component('JaTimeframe', Timeframe)
    Vue.component('JaTimeline', Timeline)
    Vue.component('JaTimelineItem', TimelineItem)
    Vue.component('JaToolbarLogo', ToolbarLogo)
    Vue.component('JaUserMenu', UserMenu)
    // Layout
    Vue.component('JaPage', Page)
    Vue.component('JaContent', Content)
    Vue.component('JaHeader', Header)
    Vue.component('JaNavHeader', NavHeader)
    Vue.component('JaSidebar', Sidebar)
    Vue.component('JaTopbar', Topbar)
    Vue.component('JaTopbarItem', TopbarItem)
  },
}
