export default {
  enabled: true,
  items: [
    {
      name: ':id_organization',
      classIcon: 'mdi mdi-city',
      parent: '/organizations',
      parentId: 'id_organization',
      slug: 'organization',
    },
    {
      name: 'usage',
      classIcon: 'mdi mdi-chart-donut',
      parent: '/organizations',
      parentId: 'id_organization',
      url: '/usage',
      slug: 'organization-usage',
    },
    {
      name: 'plan_billing',
      classIcon: 'mdi mdi-credit-card',
      parent: '/organizations',
      parentId: 'id_organization',
      url: '/plan',
      slug: 'organization-plan',
    },
    {
      name: 'projects',
      classIcon: 'mdi mdi-briefcase',
      parent: '/organizations',
      parentId: 'id_organization',
      url: '/projects',
      slug: 'projects',
    },
    {
      name: 'teams',
      classIcon: 'mdi mdi-account-multiple',
      parent: '/organizations',
      parentId: 'id_organization',
      url: '/teams',
      slug: 'teams',
    },
    {
      name: 'users',
      classIcon: 'mdi mdi-account',
      parent: '/organizations',
      parentId: 'id_organization',
      url: '/users',
      slug: 'users',
    },
  ],
}
