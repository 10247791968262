<template lang="pug">
nav.Topbar.navbar.top-navbar.navbar-expand-md
  ja-topbar-item(
    v-for="(item, index) in items"
    :key="index"
    :classIcon="item.classIcon"
    :name="item.name"
    :slug="item.slug"
    :to="item.to"
  )
</template>

<script>
export default {
  name: 'Topbar',
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  }
}
</script>
