<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  // User Settings
  b-card
    b-card-text
      form.form
        // Language Selector
        ja-form-field#language(
          label='language',
          type='select',
          :fieldValue='fieldLanguage',
          :options='languages',
          :on-change='handleChangeLanguage'
        )
</template>

<script>
export default {
  data () {
    const pageTitle = this.$t('settings')

    const fieldLanguage = store.get('language') || 'en'

    const languages = [
      {
        text: 'English',
        key: 'en',
      },
      {
        text: 'Spanish',
        key: 'es',
      },
    ]

    const pageActions = [
      {
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      },
    ]

    return {
      fieldLanguage,
      languages,
      pageActions,
      pageTitle,
    }
  },

  methods: {
    handleChangeLanguage (value) {
      this.$set(this, 'fieldLanguage', value)
    },

    save () {
      store.set('language', this.fieldLanguage)
      window.location.reload()
    },
  },
}
</script>

<style lang="sass"></style>
