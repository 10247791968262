<template lang="pug">
aside.Sidebar.left-sidebar
  .slimScrollDiv
    .scroll-sidebar
      .Sidebar-actions
      nav.sidebar-nav
        #sidebarnav
          ja-nav-menu(v-for="(navMenuItems, index) in groupNavMenuItems" :navMenuItems="navMenuItems" :key="index")
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Sidebar',
  computed: {
    groupNavMenuItems () {
      return [
        { ...this.$route.meta.sidebar }
      ]
    }
  },
  beforeMount () {
    this.$eventBus.$on('toggle-sidebar', this.toggle)
    this.$eventBus.$on('toggle-sidebar-m', this.toggleMobile)
  },
  methods: {
    /**
     * Toggle Sidebar
     * material-pro/horizontal/js/custom.js:45
     */
    toggle () {
      if ($('body').hasClass('mini-sidebar')) {
        $('body').trigger('resize')
        $('.scroll-sidebar, .slimScrollDiv').css('overflow', 'hidden').parent().css('overflow', 'visible')
        $('body').removeClass('mini-sidebar')
        $('.navbar-brand span').show()
      } else {
        $('body').trigger('resize')
        $('.scroll-sidebar, .slimScrollDiv').css('overflow-x', 'visible').parent().css('overflow', 'visible')
        $('body').addClass('mini-sidebar')
        $('.navbar-brand span').hide()
      }
    },
    /**
     * Toggle Nav
     * material-pro/horizontal/js/custom.js:45
     */
    toggleMobile () {
      $('body').toggleClass('show-sidebar')
      $('.nav-toggler i').toggleClass('mdi-menu')
      $('.nav-toggler i').toggleClass('mdi-close')
    }
  }
}
</script>

<style lang="sass" scoped>
.Sidebar
  &-actions
    // padding 16px
    .btn
      span
        margin-left: 7px
</style>
