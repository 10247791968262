<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageActions='pageActions')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        ja-form-field(
          v-for='(field, fieldKey) in fields',
          :key='fieldKey',
          :fieldValue='field',
          :on-change='(value) => handleFieldChange(fieldKey, value)',
          :label='fieldKey',
          type='text'
        )
</template>

<script>
import state, {
  createSmtpProvider,
  deleteSmtpProviderById,
  getSmtpProviderById,
  initProvider,
  updateSmtpProviderById,
} from './store/smtp-providers.store'

export default {
  name: 'EmailProviderEdit',

  data () {
    const isNew = !this.$route.params.id_smtp_provider
    const pageActions = []
    const showForm = false

    return {
      isNew,
      pageActions,
      showForm,
    }
  },

  computed: {
    smtpProvider () {
      return state.smtpProvider
    },

    fields () {
      return this.isNew
        ? this.smtpProvider.provider_type?.config || {}
        : this.smtpProvider.config
    },

    pageTitle () {
      return this.$t(this.smtpProvider.name)
    },
  },

  async created () {
    const providerName = this.$route.params.provider_name

    if (this.isNew) {
      await initProvider(providerName)

      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push(
      {
        callback: this.delete,
        icon: 'ti-trash',
        text: 'delete',
        type: 'outline-danger',
      },
      {
        callback: this.update,
        icon: 'ti-save',
        text: 'update',
        type: 'outline-primary',
      }
    )

    await getSmtpProviderById(this.$route.params.id_smtp_provider)

    this.showForm = true
  },

  methods: {
    handleFieldChange (field, value) {
      this.$set(this.smtpProvider.config, field, value)
    },

    async delete () {
      await deleteSmtpProviderById(this.smtpProvider.id)

      this.$router.push({
        name: 'smtp-providers',
      })
    },

    async save () {
      await createSmtpProvider(this.smtpProvider)

      this.$router.push({
        name: 'smtp-providers',
      })
    },

    async update () {
      await updateSmtpProviderById(this.smtpProvider.id, this.smtpProvider)
    },

    submit () {
      if (this.isNew) return this.save()
      else this.update()
    },
  },
}
</script>

<style lang="sass" scoped></style>
