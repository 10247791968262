<template lang="pug">
ja-content(:pageTitle='pageTitle')
  ja-table(
    v-if='campaigns.length',
    :items='campaigns',
    :pagination='pagination',
    :on-change-page='handleChangePage'
  )
</template>

<script>
import state, {
  getProjectCampaigns,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

const statusLabels = {
  draft: { value: 'draft', type: 'badge' },
  ongoing: { value: 'ongoing', variant: 'info', type: 'badge' },
  completed: { value: 'completed_f', variant: 'primary', type: 'badge' },
  canceled: { value: 'canceled_f', variant: 'danger', type: 'badge' },
  scheduled: { value: 'scheduled_f', variant: 'info', type: 'badge' },
}

const statusIcons = {
  draft: 'mdi mdi-email-edit-outline',
  ongoing: 'mdi mdi-email-send-outline',
  completed: 'mdi mdi-email-check-outline',
  canceled: 'mdi mdi-email-off-outline',
  scheduled: 'mdi mdi-send-clock',
}

const formatCampaigns = (campaigns) => {
  return campaigns.map((campaign) => {
    const editor =
      campaign.updated_by_user?.name || campaign.created_by_user.name
    const info = `Edited ${campaign.updated_at} by ${editor}`

    const {
      id,
      description,
      name,
      status,
      stats,
    } = campaign

    const sentEmails = stats.total.managed || (stats.total.processed + stats.total.dropped)

    return {
      campaign: {
        // checkbox: { action: null },
        icon: statusIcons[status],
        info,
        subtitle: description,
        type: 'smart-check',
        title: name,
        to: { name: 'campaign', params: { id_campaign: id } },
      },
      status: statusLabels[status],
      sent_emails: sentEmails,
      opens: stats.unique.open,
      clicks: stats.unique.click,
      yamps: stats.unique.yamps,
    }
  })
}

export default {
  name: 'CampaignsList',

  computed: {
    bucket () {
      return this.$route.params.bucket || ''
    },

    campaigns () {
      const campaigns = state?.campaigns?.items || []
      if (!campaigns.length) return campaigns
      return formatCampaigns(campaigns)
    },

    pagination () {
      return state?.campaigns?.pagination
    },

    pageTitle () {
      return this.bucket
        ? this.$t(`campaigns_${this.bucket}`)
        : this.$t('campaigns')
    },
  },

  beforeCreate () {
    const bucket = this.$route.params.bucket
    getProjectCampaigns({ bucket })
  },

  methods: {
    handleChangePage (page) {
      getProjectCampaigns({
        bucket: this.bucket,
        pagination: { page }
      })
    },
  },
}
</script>

<style lang="sass" scoped></style>
