<template lang="pug">
.TopbarItem.search-box
  router-link.TopbarItem-link.waves-effect.waves-dark(
    :class='[{ "router-link-active": jaActiveLink($route.meta.parent) }]',
    :to='to'
  )
    i(:class='classIcon')
    span.TopbarItem-link-name {{ $t(name) }}
</template>

<script>
export default {
  name: 'TopbarItem',
  props: {
    classIcon: { type: String, required: true },
    name: { type: String, required: true },
    slug: { type: String, required: true },
    to: { type: String, required: true },
  },
}
</script>

<style lang="sass" scoped>
.TopbarItem
  &-link
    color: rgba(255, 255, 255, 0.6)
    display: flex
    flex-direction: column
    line-height: 1.25
    padding-bottom: 5px
    padding-left: 14px
    padding-right: 14px
    padding-top: 4px
    text-align: center

    &-name
      font-size:13px
      text-transform: capitalize

    & > i
      margin-right: 0
      font-size:24px

    &:hover,
    &:focus,
    &.router-link-active
      color: white

  @media (max-width: 991.98px)
    padding-top: 6px

  @media (max-width: 450px)
    &-link
      padding-left: 6px
      padding-right: 6px
</style>
