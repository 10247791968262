<template lang="pug">
.table-responsive
  table.Table.table.table-bordered.table-hover(aria-busy='false', role='table')
    // Table Head
    thead(v-if='head.enabled')
      tr
        // Fields
        th(
          v-for='(column, index) in columns',
          :key='`head_${column.key}`',
          :class='[column.class || ""]'
        )
          span {{ $t(column.label) }}

        // Action Buttons
        th.TableHeadButtons(v-if='headButtons')
          ja-button.float-right(
            v-for='button in headButtons',
            :icon='button.icon',
            :text='button.text',
            :type='button.type || "outline-secondary"',
            :key='jaGenerateUuid()',
            :on-click='button.onClick'
          )

    // Table Body
    tbody
      // Table Rows
      tr(
        v-for='(row, index) in items'
        :key='`row_${index}`'
        @click='() => handleClickRow(row)'
        :class='{ "clickable-row": !!onClickRow }'
      )
        // Table Cells
        ja-table-td(
          v-for='(cellValue, cellName) in row',
          v-if='showField(cellName)',
          :key='`${index}_${cellName}`',
          :cell='cellValue',
          :name='cellName',
          :icon='iconField'
          :on-change='onChangeCell'
          :on-click='onClickCell'
        )
        td.TableRowButtons(v-if='rowButtons')
          ja-button.float-right(
            v-for='button in rowButtons',
            :icon='button.icon',
            :text='button.text',
            :type='button.type || "outline-secondary"',
            :key='jaGenerateUuid()',
            :on-click='() => button.onClick(row)'
          )

  ja-pagination(
    v-if='pagination',
    :options='pagination',
    :on-change-page='onChangePage'
  )
</template>

<script>
import { some, forEach, uniq } from 'lodash'

export default {
  name: 'Table',
  props: {
    fields: {
      type: [Array, null],
      default: null,
    },
    headButtons: {
      type: [Array, null],
      default: null,
    },
    head: {
      type: [Object, null],
      default: () => ({ enabled: true }),
    },
    iconField: {
      type: [String, null],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    onChangePage: {
      type: Function,
      default: () => {},
    },
    onChangeCell: {
      type: Function,
      default: () => {},
    },
    onClickCell: {
      type: Function,
      default: () => {},
    },
    onClickRow: {
      type: [Function, null],
      default: null,
    },
    rowButtons: {
      type: [Array, null],
      default: null,
    },
    pagination: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    columns () {
      const fields = []
      const columns = []

      if (this.fields) {
        columns.push(
          ...this.fields.map((field) => {
            if (typeof field === 'string') {
              return { key: field, label: field }
            } else {
              return field
            }
          })
        )
      } else {
        this.items.forEach((item) => {
          forEach(item, (value, key) => {
            fields.push(key)
          })
        })

        uniq(fields).forEach((field) => {
          columns.push({ key: field, label: field })
        })
      }

      return columns
    },
  },
  methods: {
    handleClickCell (cellName, cellValue) {
      if (this.onClickCell) this.onClickCell({ name: cellName, value: cellValue })
    },

    handleClickRow (row) {
      if (this.onClickRow) this.onClickRow(row)
    },

    showField (field) {
      return some(this.columns, { key: field })
    },
  },
}
</script>

<style lang="sass" scoped>
.Table
  background-color: #fff
  border-radius: 4px
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05)
  color: #3d434a
  overflow: auto
  margin-bottom: 30px

  tr
    &.clickable-row
      cursor: pointer
    td:first-child, th:first-child
      padding-left: 20px

  thead
    background-color: #f8f9fb
    font-size: 15px
    font-weight: 600
    th
      vertical-align: middle
      padding-bottom: 8px
      padding-top: 8px

  tbody
    tr
      transition: all .25s ease-in-out
      .TableRowButtons
        .Button
          opacity: 0
      &:hover
        background-color: #f1f1f1
        .TableRowButtons
          .Button
            opacity: 1
      td
        color: #67757c
        border-left: none
        border-right: none
        font-size: 14px
        font-weight: 400
        vertical-align: middle

.TableHeadButtons
  width: 200px
</style>
