export const SOCIAL = {
  facebook: {
    icon: 'mdi mdi-facebook',
    baseUrl: 'facebook.com/',
  },
  linkedin: {
    icon: 'mdi mdi-linkedin',
    baseUrl: 'linkedin.com/',
  },
  instagram: {
    icon: 'mdi mdi-instagram',
    baseUrl: 'instagram.com/',
  },
  messenger: {
    icon: 'mdi mdi-facebook-messenger',
    baseUrl: 'm.me/',
  },
  twitter: {
    icon: 'mdi mdi-twitter',
    baseUrl: 'twitter.com/',
  },
  youtube: {
    icon: 'mdi mdi-youtube',
    baseUrl: 'youtube.com/',
  },
  website: {
    icon: 'mdi mdi-web',
    baseUrl: 'website.com/',
  },
  whatsapp: {
    icon: 'mdi mdi-whatsapp',
    baseUrl: 'wa.me/',
  },
}

export const SOCIAL_SIZES = {
  small: '24px',
  medium: '32px',
  large: '40px',
}
