export default {
  en: {
    all_f: 'All',
    all: 'All',
    birthdate: 'Birthdate',
    canceled: 'Canceled',
    canceled_f: 'Canceled',
    canceled_f_p: 'Canceled',
    canceled_p: 'Canceled',
    completed: 'Completed',
    completed_f: 'Completed',
    completed_f_p: 'Completed',
    completed_p: 'Completed',
    contacts: 'Contacts',
    content_editor: 'Content Editor',
    created_at: 'Created at',
    default: 'Default',
    delete: 'Delete',
    description: 'Description',
    draft: 'Draft',
    drafts: 'Drafts',
    edit: 'Edit',
    ended_f_p: 'Ended',
    ended_f: 'Ended',
    ended_p: 'Ended',
    ended: 'Ended',
    first_name: 'First name',
    friday: 'Friday',
    forgot_password: 'Forgot your password?',
    get_started_yamp: 'Get started with Yamp',
    go_to_login: 'go to login',
    kpis: 'KPIs',
    language: 'Language',
    last_name: 'Last Name',
    location: 'Ubicación',
    login: 'Login',
    login_yamp: 'Login to Yamp',
    login_invite_message: 'Login and you\'ll immediately be added to the organization you was invited. Then you\'ll see it in your user panel.',
    metrics: 'Metrics',
    monday: 'Monday',
    next: 'next',
    none: 'None',
    not_have_account: 'If you still do not have an account',
    of: 'of',
    ongoing: 'Ongoing',
    overview: 'Overview',
    performance: 'Performance',
    phone: 'Phone',
    preview: 'Preview',
    previous: 'previous',
    properties: 'Properties',
    register: 'register',
    request_password_message: 'Don\'t worry! We\'ll send you a link to reset your password.',
    reset_password: 'Reset password',
    reset_password_message: 'Your password has been updated. You can login again.',
    saturday: 'Saturday',
    save: 'Save',
    scheduled: 'Scheduled',
    scheduled_f: 'Scheduled',
    scheduled_f_p: 'Scheduled',
    scheduled_p: 'Scheduled',
    sends: 'Sends',
    settings: 'Settings',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    status: 'Status',
    summary: 'Summary',
    sunday: 'Sunday',
    thursday: 'Thursday',
    to_use_another_account: 'To use another account please',
    try_access_as: 'You\'re trying to access as',
    tuesday: 'Tuesday',
    update: 'Update',
    updated_at: 'Last updated',
    view: 'View',
    wednesday: 'Wednesday',
  },
  es: {
    all_f: 'Todas',
    all: 'Todos',
    birthdate: 'Fecha de nacimiento',
    canceled: 'Cancelado',
    canceled_f: 'Cancelada',
    canceled_f_p: 'Canceladas',
    canceled_p: 'Cancelados',
    completed: 'Completado',
    completed_f: 'Completada',
    completed_f_p: 'Completadas',
    completed_p: 'Completados',
    contacts: 'Contactos',
    content_editor: 'Editor de Contenido',
    created_at: 'Fecha de creación',
    default: 'Por defecto',
    delete: 'Eliminar',
    description: 'Descripción',
    draft: 'Borrador',
    drafts: 'Borradores',
    edit: 'Modificar',
    ended_f_p: 'Finalizadas',
    ended_f: 'Finalizada',
    ended_p: 'Finalizados',
    ended: 'Finalizado',
    first_name: 'Nombre',
    friday: 'Viernes',
    forgot_password: '¿Has olvidado tu contraseña?',
    get_started_yamp: 'Comienza con Yamp',
    go_to_login: 'iniciar sesión',
    kpis: 'KPIs',
    language: 'Idioma',
    last_name: 'Apellido',
    location: 'Ubicación',
    login: 'Iniciar sesión',
    login_yamp: 'Accede a Yamp',
    login_invite_message: 'Inicia sesión para confirmar el acceso a la organización a la que fuiste invitado. Después podrás entrar en sus proyectos desde tu panel de usuario.',
    metrics: 'Métrica',
    monday: 'Lunes',
    next: 'siguiente',
    none: 'Ninguna',
    not_have_account: 'Si aún no tienes cuenta puedes',
    of: 'de',
    ongoing: 'En curso',
    overview: 'Vista General',
    performance: 'Rendimiento',
    phone: 'Teléfono',
    preview: 'Previsualizar',
    previous: 'anterior',
    properties: 'Propiedades',
    register: 'registrarte',
    request_password_message: 'Sin problema. Te enviaremos un enlace para restablecer tu contraseña.',
    reset_password: 'Reestablecer contraseña',
    reset_password_message: 'Hemos actualizado tu contraseña. Ya puedes iniciar sesión de nuevo.',
    saturday: 'Sábado',
    save: 'Guardar',
    scheduled: 'Programado',
    scheduled_f: 'Programada',
    scheduled_f_p: 'Programadas',
    scheduled_p: 'Programados',
    sends: 'Envíos',
    settings: 'Configuración',
    sign_in: 'Iniciar sesión',
    sign_up: 'Registrarse',
    status: 'Estado',
    summary: 'Resumen',
    sunday: 'Domingo',
    thursday: 'Jueves',
    to_use_another_account: 'Para usar otra cuenta puedes',
    try_access_as: 'Has accedido como',
    tuesday: 'Martes',
    update: 'Actualizar',
    updated_at: 'Última actualización',
    view: 'Ver',
    wednesday: 'Miércoles',
  },
}
