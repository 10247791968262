// TODO https://bootstrapious.com/p/timeline
<template lang="pug">
.TimelineItem
  h3.TimelineItem-Date {{ date }}
  ul.TimelineEventList
    li.TimelineEvent(
      v-for="{ icon, name, time }, index in events"
      :key="`tlie_${index}`"
    )
      .TimelineEvent-Icon
        i(:class="icon")
      .TimelineEvent-Arrow
      .TimelineEvent-EventName {{ name }}
      .TimelineEvent-EventTime {{ time }}
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    date: { type: String, required: true },
    events: { type: Array, required: true },
  }
}
</script>

<style lang="sass" scoped>
.TimelineItem
  &-Date
    color: #b957f6
// Timeline holder
.TimelineEventList
  list-style-type: none
  padding-left: 1.5rem
  position: relative

  // Timeline vertical line
  &:before
    content: ' '
    background: #fff
    display: inline-block
    position: absolute
    left: 21px
    width: 4px
    height: 100%
    z-index: 400
    border-radius: 1rem

  // Timeline Item
.TimelineEvent
  background: #fff
  border-radius: 5px
  box-shadow: 0 1px 1px rgba(0,0,0,.10)
  margin-left: 24px
  margin-top: 16px
  padding: 12px 16px
  flex-direction: column

  // Timeline Icon
  &-Icon
    align-items: center
    background: #fff
    border-radius: 50%
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15)
    color: #a1a1a1
    display: flex
    justify-content: center
    height: 24px
    left: 11px
    padding-top: 2px
    position: absolute
    width: 24px
    z-index: 400
    i
      margin: 0

  // Timeline Item Arrow
  &-Arrow
    border-top: 0.5rem solid transparent
    border-right: 0.5rem solid #fff
    border-bottom: 0.5rem solid transparent
    display: block
    left: 40px
    margin-top: 5px
    position: absolute
  &-EventName,
  &-EventTime
    font-size: 15px
    font-family: Roboto

</style>
