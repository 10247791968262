<template lang="pug">
.BlockGallery-CarouselContainer(
  v-if='areThereSomeImage',
  v-html='templateGallery'
)
</template>

<script>
import pug from 'pug'
import CONFIG_CONST from '@config/config.const'
import state from '@plugins/content-editor/state'
import pugGallery from '!!raw-loader!./templates/gallery.pug'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    areThereSomeImage () {
      return !!this.imageList.length
    },

    imageList () {
      return this.block.config.imageList?.filter((image) => image) || []
    },

    templateGallery () {
      const AMP_EVENTS = state.ampBlockEvents.GALLERY
      const { BASE_URL_API } = CONFIG_CONST
      const blockId = this.block.id

      const templateVars = {
        AMP_EVENTS,
        BASE_URL_API,
        blockId,
        areThereSomeImage: this.areThereSomeImage,
        imageList: this.imageList,
      }
      return pug.render(pugGallery, templateVars)
    },
  },
}
</script>
