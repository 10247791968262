<template lang="pug">
.BlockGallery(:style='cssVars')
  template(v-if='areThereSomeImage')
    img.BlockGallery-Fallback(v-if='showFallback', :src='srcImageFallback')

    iframe.BlockGallery-Iframe(
      v-if='!showFallback',
      @load='onIframeLoaded',
      :srcdoc='srcdoc',
      height='428px',
      width='100%',
      scrolling='no',
      ref='iframe'
    )

    .BlockGallery-AmpContent(ref='ampContent')
      // AMP Carousel
      ce-preview-block-gallery(:block='block')

  template(v-else)
    ce-image-skeleton(text='Add your images', :is-gallery='true')
</template>

<script>
import { useTemplate } from '@plugins/content-editor/utils'
import { AMP_EXTENSIONS } from '@plugins/content-editor/const'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    showFallback: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      content: '',
    }
  },
  computed: {
    areThereSomeImage () {
      return this.block.config.imageList.length
    },
    cssVars () {
      return {
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
      }
    },
    srcdoc () {
      return useTemplate({
        format: 'amp',
        content: this.content,
        extensions: [AMP_EXTENSIONS.carousel],
      })
    },
    srcImageFallback () {
      return this.block.config.imageList[0].src
    },
  },

  mounted () {
    this.updateContent()
  },

  updated () {
    this.updateContent()
  },

  methods: {
    onIframeLoaded () {
      this.$refs.iframe.contentDocument.addEventListener('click', () =>
        this.handleIframeClick()
      )
    },

    handleIframeClick () {
      this.$el.parentElement.click()
    },

    updateContent () {
      if (this.areThereSomeImage) {
        this.content = this.$refs.ampContent.innerHTML
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.BlockGallery
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-AmpContent
    display: none

  &-Fallback
    display: block
    width: 100%

  &-Iframe
    overflow: hidden
    border: 0
</style>
