<template lang="pug">
.CampaignScheduler
  // Modal
  b-modal(
    ref='modal-campaign-scheduler',
    centered,
    modal-class='CampaignScheduler-Modal',
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .CampaignScheduler-ModalHeader.text-first-uppercase
        span {{ $t("setup_schedule") }}

    // Modal Content
    form.CampaignScheduler-Form

      // Date Picker
      .form-group
        label.form-label.form-label-lg {{ $t("delivery_date") }}
        b-form-datepicker(v-model='value.scheduleDate' :min='minDate' start-weekday='1')

      // Time Picker
      .form-group
        label.form-label.form-label-lg {{ $t("delivery_time") }}
        b-form-timepicker(
          v-model='value.scheduleTime'
          required
          minutes-step='5'
        )

      p
        strong {{ $t("campaign_send_audience_number", { number: Intl.NumberFormat().format(contacts) }) }}

      p {{ $t("split_audiences_batches") }}

      .row
        // Batches Selector
        .col-4
          .form-group
            label.form-label.form-label-lg {{ $t("batches") }}
            b-form-select(v-model="value.batches" :options="batchesOptions" )

        // Batch Items
        .col-4
          .form-group
            label.form-label.form-label-lg {{ $t("contacts_batch") }}
            div {{ Intl.NumberFormat().format(contactsByBatch) }}

        // Frequency
        .col-4
          .form-group
            label.form-label.form-label-lg {{ $t("frequency") }}
            b-form-select(v-model="value.batchMinutes" :options="batchMinutesOptions" )

    // Modal Footer
    template(v-slot:modal-footer)
      .CampaignScheduler-ModalFooter
        ja-button(
          :disabled='isSchedulerModalSubmitDisabled',
          :on-click='onSave',
          :text='$t("schedule_campaign")',
          type='primary'
        )
</template>

<script>
const now = new Date()
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
const minDate = new Date(today)

const batchMinutesOptions = [
  { value: 1, text: '1 minutes' },
  { value: 2, text: '2 minutes' },
  { value: 3, text: '3 minutes' },
  { value: 4, text: '4 minutes' },
  { value: 5, text: '5 minutes' },
  { value: 10, text: '10 minutes' },
  { value: 15, text: '15 minutes' },
  { value: 20, text: '20 minutes' },
  { value: 30, text: '30 minutes' },
  { value: 60, text: '1 hour' },
  { value: 120, text: '2 hours' },
  { value: 180, text: '3 hours' },
  { value: 240, text: '4 hours' },
  { value: 300, text: '5 hours' },
  { value: 360, text: '6 hours' },
  { value: 480, text: '8 hours' },
  { value: 720, text: '12 hours' },
]

export default {
  name: 'CampaignScheduler',

  props: {
    contacts: {
      type: [Number, String],
      default: 0
    },

    value: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      default: () => {}
    },
  },

  data () {
    return {
      batchMinutesOptions,
      minDate,
      modalShow: false,
    }
  },

  computed: {
    batchesOptions () {
      const batchesOptions = []
      let maxValue = 1

      if (this.contacts) {
        maxValue = this.contacts < 60 ? this.contacts : 60
      }

      batchesOptions.push(...Array.from({ length: maxValue }, (_, i) => i + 1))

      return batchesOptions
    },

    contactsByBatch () {
      const { batches } = this.value
      return batches
        ? Math.round(this.contacts / batches)
        : this.contacts
    },

    isSchedulerModalSubmitDisabled () {
      const { scheduleDate, scheduleTime } = this.value
      return !scheduleDate || !scheduleTime
    },
  },

  created () {
    this.setBatchItems()
  },

  updated () {
    this.setBatchItems()
  },

  methods: {
    open () {
      this.$refs['modal-campaign-scheduler'].toggle()
    },

    setBatchItems () {
      this.$set(this.value, 'batchItems', this.contactsByBatch)
    }
  },
}
</script>

<style lang="sass" scoped>

</style>
