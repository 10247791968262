import ApiClient from '@api/client'

const client = new ApiClient({ path: '/users' })

export const UserService = {
  /**
   * Get User By Id
   * @param {Object} params
   * @param {String} params.id
   * @return {Object} user
   */
  async getById ({ id }) {
    return await client.get(`/${id}`)
  },

  /**
   * Request New Password
   * @param {String} email User Email
   */
  async requestNewPassword (email) {
    return await client.post('/request-password', { email })
  },

  /**
   * Reset Password
   * @param {Object} params
   * @param {String} params.password New Password
   * @param {String} params.token Auth Token
   */
  async resetPassword ({ token, password }) {
    return await client.post('/reset-password', { token, password })
  },
}
