export default {
  en: {
    access_key_id: 'Access Key Id',
    add_email_sender: 'Add Email Sender',
    add_webhook: 'Add Webhook',
    amp_validated: 'AMP Validated',
    aws_ses_api: 'AWS SES API',
    aws_ses_smtp: 'AWS SES SMTP',
    configurations: 'Configurations',
    configure: 'Configure',
    email_sender: 'Email Sender',
    'email-senders': 'Email Senders',
    host_name: 'Server Name',
    integrations: 'Integrations',
    new_email_sender: 'New Email Sender',
    new_smtp_provider: 'New SMTP Provider',
    new_webhook: 'New Webhook',
    region: 'Region',
    secret_access_key: 'Secret Access Key',
    sendgrid: 'Sendgrid',
    smtp_pass: 'SMTP Password',
    smtp_port: 'SMTP Port',
    smtp_user: 'SMTP Username',
    smtp_provider: 'SMTP Provider',
    'smtp-providers': 'SMTP Providers',
  },

  es: {
    access_key_id: 'Access Key Id',
    add_email_sender: 'Nuevo Remitente',
    add_webhook: 'Nuevo Webhook',
    amp_validated: 'AMP Validado',
    aws_ses_api: 'AWS SES API',
    aws_ses_smtp: 'AWS SES SMTP',
    configurations: 'Configurationes',
    configure: 'Configurar',
    email_sender: 'Remitente',
    'email-senders': 'Remitentes',
    host_name: 'Server Name',
    integrations: 'Integraciones',
    new_email_sender: 'Nuevo Remitente',
    new_smtp_provider: 'Nuevo Proveedor SMTP',
    new_webhook: 'Nuevo Webhook',
    region: 'Region',
    secret_access_key: 'Secret Access Key',
    sendgrid: 'Sendgrid',
    smtp_pass: 'SMTP Password',
    smtp_port: 'SMTP Port',
    smtp_user: 'SMTP Username',
    smtp_provider: 'Proveedor SMTP',
    'smtp-providers': 'Proveedores SMTP',
  },
}
