import Vue from 'vue'
import { ContactsService } from '../service/contacts.service'
import { setQueryParam, useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  contact: {},
})

// actions
export const getContactById = async (id) => {
  const contact = await useLoader(ContactsService.getById, id)
  if (!contact) return
  Vue.set(state, 'contact', contact)
  setQueryParam('id_contact', state.contact.email)
  setQueryParam('id_audience', state.contact.audience?.name)
  return contact
}

export const createContact = async (data) => {
  return await useLoader(ContactsService.create, data)
}

export const deleteContactById = async (id) => {
  return await useLoader(ContactsService.delete, id)
}

export const updateContactById = async (id, data) => {
  return await useLoader(ContactsService.update, id, data)
}

export default state
