<template lang="pug">
.PanelHeader
  .PanelHeader-Title.text-first-uppercase {{ title }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@plugins/content-editor/styles/variables"

.PanelHeader
  background: $gray-light-bg
  border-bottom: 1px solid $gray-light-border
  border-top-left-radius: 5px
  border-top-right-radius: 5px
  font-size: 1.2em
  padding: .6em 1.2em

  &-Title
    color: $gray-dark
</style>
