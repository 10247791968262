<template lang="pug">
.AppLauncher.nav-item
  // Launcher
  #AppLauncher.AppLauncher-Launcher.dropdown-toggle
    i.mdi.mdi-view-grid(@click='toggleShow', ref='launcher')

  // App Grid
  Transition(name='scale-up')
    .AppLauncher-AppGrid.dropdown-menu.dropdown-menu-right(v-show='show')
      // Yamp
      router-link.AppLauncher-App.dropdown-item(:to='{ name: "campaigns" }')
        i.mdi.mdi-email
        span Yamp Mail

      // Organizations
      router-link.AppLauncher-App.dropdown-item(:to='{ name: "organizations" }')
        i.mdi.mdi-city
        span Organizations

  // Tooltip
  b-tooltip(
    target='AppLauncher'
    triggers='hover'
    :delay='{ show: 1000, hide: 50 }'
  ) Yamp Modules
</template>

<script>

export default {
  name: 'AppLauncher',

  data () {
    return { show: false }
  },

  mounted () {
    document.addEventListener('click', (event) => {
      if (event.target !== this.$refs.launcher) {
        this.show = false
      }
    })
  },

  methods: {
    toggleShow () {
      this.show = !this.show
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

.AppLauncher
  color: white
  &-App
    &:hover, &:active
      color: $purple
      background-color: #f3f2f8
    i, span
      text-align: center
      display: block
    i
      font-size: 24px
      margin-right: 0

  &-AppGrid
    display: flex
    margin-right: 10px
    padding: 8px

  &-Launcher
    cursor: pointer
    padding: 10px
    i
      display: block
      margin-right: 0
      font-size: 20px

.scale-up-enter-active,
.scale-up-leave-active
  transition: all 0.25s ease
  transform-origin: right 0px

.scale-up-enter-active
  opacity: 1
  transform: scale(1)

.scale-up-enter,
.scale-up-enter-from,
.scale-up-leave-active,
.scale-up-leave-to
  opacity: 0
  transform: scale(0)
</style>
