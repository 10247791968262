import ApiClient from '@api/client'

const client = new ApiClient({ path: '/connectors' })

export const ShopifyService = {
  /**
   * Get Shopify Products
   * @return {Array} productList
   */
  async getProducts (project_id, { integration_id, query, variables }) {
    return await client.post('/', {
      project_id,
      integration_id,
      response: 'form',
      action: 'run_graphql',
      params: JSON.stringify({
        operationName: 'GetProducts',
        query,
        variables,
      }),
    })
  },
}

export const PrestashopService = {
  /**
   * Get Shopify Products
   * @return {Array} productList
   */
  async getProducts (project_id, { integration_id, filter }) {
    const productFields = [
      'cache_default_attribute',
      'date_upd',
      'id_default_image',
      'id',
      'link_rewrite',
      'name',
      'price',
      'quantity',
    ]

    const params = encodeURIComponent(
      JSON.stringify({
        display: `[${productFields.join()}]`,
        'filter[name]': `%[${filter}]%`,
        limit: 10,
      })
    )

    return await client.post('/', {
      project_id,
      integration_id,
      response: 'form',
      action: 'get_products',
      params,
    })
  },
}
