<template lang="pug">
.SidePanel
  ce-panel-header(:title='title')
  ce-panel-body(:config='config', :on-change='handleChange')
</template>

<script>
import state, { setActiveBlockConfig } from '@plugins/content-editor/state'
import { BLOCKTYPES_NAMES } from '@plugins/content-editor/const'

export default {
  props: {
    onUpload: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    config () {
      return state.activeBlock?.config || {}
    },
    title () {
      return BLOCKTYPES_NAMES[state.activeBlock?.type] || 'Edit or Add Content'
    },
  },

  methods: {
    async handleChange (data) {
      if (data.file) {
        data.src = await this.onUpload(data.file)
        delete data.file
      }
      setActiveBlockConfig(data)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@plugins/content-editor/styles/variables"
@import "@plugins/content-editor/styles/mixins"

.SidePanel
  @include card-shadow
  border-radius: 5px
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
</style>
