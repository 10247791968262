import Vue from 'vue'
import Router from 'vue-router'
import LayoutDefault from '@/layouts/Default'
import LayoutAuth from '@/layouts/Auth.vue'
import accountsRoutes from '@apps/Accounts/accounts.routes'
import dymalRoutes from '@apps/Dymal/dymal.routes'
import authRoutes from '@apps/Common/Auth/auth.routes'
import { cleanErrorMessages, loginGuard, lastRouteGuard } from '@guards'

Vue.use(Router)

const router = new Router({
  mode: 'history',

  base: process.env.BASE_URL,

  routes: [
    // Home
    {
      name: 'home',
      path: '/',
      redirect: '/campaigns',
    },

    // Apps
    {
      path: '',
      component: LayoutDefault,
      children: [...accountsRoutes, ...dymalRoutes],
    },

    // Auth, Invite, Login
    {
      path: '',
      component: LayoutAuth,
      children: [...authRoutes],
    },

    { path: '*', redirect: '/' },
  ],
})

router.beforeEach(cleanErrorMessages)
router.beforeEach(loginGuard)
router.beforeEach(lastRouteGuard)

export default router
