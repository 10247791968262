<template lang="pug">
div
  ckeditor.TextEditor(
    :editor='editorClass',
    :config='editorConfig',
    v-model='editorData',
    @input='onEditorInput',
    @ready='onEditorReady'
  )
</template>

<script>
// CKEditor - Inline Build
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor'

import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'

const BUTTON_TOOLBAR = [
  'bold',
  'italic',
  'underline',
  'alignment',
]

const HEADING_TOOLBAR = [
  'heading',
  'bold',
  'italic',
  'underline',
  'alignment',
  '|',
  'link',
]

const PARAGRAPH_TOOLBAR = [
  'bold',
  'italic',
  'underline',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
  'link',
  '|',
  'undo',
  'redo',
]

const heading = {
  options: [
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading3',
    },
  ],
}

export default {
  name: 'TextEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    const isButton = this.type === 'button'
    const isHeading = this.type === 'heading'
    const isParagraph = this.type === 'paragraph'

    let editor
    const editorClass = InlineEditor
    const editorConfig = {
      placeholder: this.type,
      plugins: [
        AlignmentPlugin,
        BoldPlugin,
        EssentialsPlugin,
        HeadingPlugin,
        ItalicPlugin,
        LinkPlugin,
        ListPlugin,
        ParagraphPlugin,
        UnderlinePlugin,
      ],
    }

    if (isHeading) {
      editorConfig.heading = heading
      editorConfig.toolbar = HEADING_TOOLBAR
    } else if (isButton) {
      editorConfig.toolbar = BUTTON_TOOLBAR
    } else if (isParagraph) {
      editorConfig.toolbar = PARAGRAPH_TOOLBAR
    }

    return {
      editor,
      editorClass,
      editorConfig,
      isHeading,
    }
  },

  computed: {
    editorData: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  methods: {
    onEditorInput (value, eventInfo, editor) {
      this.onChange(value)
    },
    onEditorReady (editor) {
      this.editor = editor
      // this.editor.execute('alignment', { value: 'center' })
      this.editor.editing.view.document.on('enter', this.onEditorEnter) // other events: 'keydown', 'keyup'
      // TODO: listen change heading type to resize the font
    },
    onEditorEnter (eventInfo, keyEventData) {
      if (!this.isParagraph && !keyEventData.isSoft) {
        this.editor.execute('shiftEnter')
        eventInfo.stop()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.TextEditor
  border: none !important
  box-shadow: none !important
  padding: 0 !important
</style>
