<template lang="pug">
.Login
  .alert.alert-info(v-if='showInviteMessage') {{ $t('login_invite_message') }}

  .alert.alert-info(v-if='showResetPasswordMessage') {{ $t('reset_password_message') }}

  form#loginform.form-horizontal.form-material(
    @submit.prevent='handleSubmit'
  )
    h3.auth-title.m-b-20 {{ $t('login_yamp') }}

    .form-group
      .col-xs-12
        input#email.form-control(
          v-model='email',
          type='text',
          required='',
          placeholder='Email'
        )

    .form-group
      .col-xs-12
        input#password.form-control(
          v-model='password',
          type='password',
          required='',
          placeholder='Password'
        )

    .form-group.text-center.m-t-20
      .col-xs-12
        button.btn.btn-primary.btn-lg.btn-block.waves-effect.waves-light.auth-button(
          type='submit'
        ) {{ $t('login') }}

    .form-group
      .ml-auto
        p.text-muted
          i.fas.fa-lock.m-r-5
          router-link(:to='{ name: "forgot-password" }') Forgot password?

    .form-group
      .ml-auto
        p.text-muted
          span {{ $t('not_have_account')}}
          span &nbsp;
          router-link(:to='{ name: "register" }') {{ $t('register')}}
</template>

<script>
import { login } from '@apps/Common/common.store'
import { registerUserOrganization } from '@apps/Accounts/Organizations/Users/store/users.store'

export default {
  name: 'Login',

  data () {
    return {
      email: '',
      password: '',
      remember: false
    }
  },

  computed: {
    token () {
      return this.$route.params.token || false
    },

    showInviteMessage () {
      return !!this.token
    },

    showResetPasswordMessage () {
      return !!this.$route.query.resetpass
    }
  },

  methods: {
    goToLastRoute () {
      const userId = store.get('user_id')
      const userLastRoute = `last_route_${userId}`

      const lastRoute = store.get(userLastRoute)
        ? JSON.parse(store.get(userLastRoute))
        : false

      if (lastRoute) {
        this.$router.push(lastRoute)
        return
      }

      this.$router.push({ name: 'home' })
    },

    async handleSubmit (_event) {
      const userLogin = await login({
        email: this.email,
        password: this.password,
      })

      if (!userLogin) return

      if (this.token) {
        await registerUserOrganization({
          token: this.token
        })

        this.$router.push({ name: 'project-selection' })
        return
      }

      this.goToLastRoute()
    },
  }
}
</script>
