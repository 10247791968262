import Vue from 'vue'
import { TemplatesService } from '../service/templates.service'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'
import { formatDate } from '@utils'

// state
const state = Vue.observable({
  template: null,
  templateClone: null,
  templates: [],
})

// actions
export const createTemplate = async (data) => {
  return await useLoader(TemplatesService.create, {
    ...data,
    project_id: common.user.project_id,
  })
}

export const getTemplateById = async (id) => {
  const template = await useLoader(TemplatesService.getById, id)

  if (!template) return

  if (template.config) {
    template.config = JSON.parse(template.config)
    template.config.rows = template.config.rows.filter((row) => row)
  }

  template.created_at = formatDate({ date: template.created_at })
  template.updated_at = formatDate({ date: template.updated_at })

  Vue.set(state, 'template', template)
  setQueryParam('id_template', template.name)

  return template
}

export const getTemplatesByProject = async () => {
  const templates = await useLoader(
    TemplatesService.getTemplateByProject,
    common.user.project_id
  )

  if (!templates) return

  Vue.set(state, 'templates', templates?.items || [])
  return templates.items
}

export const removeTemplateById = async (id) => {
  return await useLoader(TemplatesService.delete, id)
}

export const resetTemplateClone = () => {
  Vue.set(state, 'templateClone', null)
}

export const saveTemplatePreview = async (id, data) => {
  return await useLoader(TemplatesService.savePreview, id, data)
}

export const setTemplateClone = () => {
  Vue.set(state, 'templateClone', state.template)
}

export const setTemplateConfig = (config) => {
  Vue.set(state.template, 'config', config)
}

export const updateTemplateById = async (id, data) => {
  return await useLoader(TemplatesService.update, id, {
    ...data,
    project_id: common.user.project_id,
  })
}

export default state
