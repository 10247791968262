<template lang="pug">
.InputText
  .InputText-Label(v-if='label') {{ label }}
  input.InputText-Input(
    :class='{ "fill-column": !label }',
    :value='value',
    :placeholder='placeholder',
    @input='handleChange'
  )
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Array], // TODO: Allow only type "String"
      default: '',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    const placeholder = 'Escribe tu ' + this.$t(this.field)

    return {
      placeholder,
    }
  },

  methods: {
    handleChange (event) {
      let value = event.target.value

      if (Array.isArray(this.value)) {
        value = value
          ? event.target.value.split(',').map((val) => val.trim())
          : []
      }

      const data = { value }
      if (this.label) data.field = this.field

      this.onChange(data)
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.InputText
  align-items: center
  display: grid
  grid-auto-columns: auto
  grid-template-columns: auto 1fr
  column-gap: 20px

  &-Input
    @include input

    &.fill-column
      grid-column: 1 / span 2

  &:not(:first-child)
    margin-top: 8px
</style>
