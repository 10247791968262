<template lang="pug">
.Range
  .Range-Header
    .Range-Label(v-if='label') {{ $t(label) }}
    .Range-Value {{ value }}px
  input.Range-Input(
    type='range',
    :min='options.min',
    :max='options.max',
    :value='selected',
    @input='handleChange'
  )
</template>

<script>
import { SELECT_DEFAULTS } from '@plugins/content-editor/const'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [Number, String],
      default: 1,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    selected: {
      get () {
        if (!this.value) return SELECT_DEFAULTS[this.field]
        return this.value
      },
    },
  },

  methods: {
    handleChange (event) {
      const value = event.target.value

      const data = { value }
      if (this.label) data.field = this.field

      this.onChange(data)
    },
  },
}
</script>

<style lang="sass" scoped>
.Range
  &-Header
    display: flex
    justify-content: space-between

  margin-top: 15px
  &:first-child
    margin-top: 5px

  &-Input
    box-sizing: border-box
    display: block
    width: 100%

  &-Label
    margin-bottom: 5px
    text-transform: capitalize
</style>
