<template lang="pug">
.DataWell.card
  .card-body
    .DataWell-Label {{ $t(dataLabel) }}
    .DataWell-Value {{ formatValue(dataValue) }}

  .card-footer(v-if='showFooterSlot')
    div(v-if='$slots.footer')
      slot(name="footer")

    template(v-if='showAmpHtml')
      div
        i.mdi.mdi-xml
        span {{ formatValue(htmlValue) }}

      div
        i.mdi.mdi-flash
        span {{ formatValue(ampValue) }}

    .DataWell-ExtraData(v-if='extraData.length')
      template(v-for='eData in extraData')
        div {{ $t(eData.label) }}:
        div {{ formatValue(eData.value) }}
</template>

<script>
export default {
  name: 'DataWell',

  props: {
    icon: {
      type: [String, null],
      required: false,
      default: null,
    },
    dataLabel: {
      type: String,
      required: true,
    },
    dataValue: {
      type: [String, Number],
      required: true,
    },
    ampValue: {
      type: [String, Number, null],
      default: null,
    },
    htmlValue: {
      type: [String, Number, null],
      default: null,
    },
    extraData: {
      type: Array,
      default: () => [],
    }
  },

  computed: {
    showAmpHtml () {
      return (this.ampValue !== null || this.htmlValue !== null)
    },

    showFooterSlot () {
      if (this.showAmpHtml || this.extraData.length) return true
      return !!this.$slots.footer
    },
  },

  methods: {
    formatValue (value) {
      if (typeof value === 'number') return Intl.NumberFormat().format(value)
      return value
    },
  }
}
</script>

<style lang="sass" scoped>
.DataWell

  &-ExtraData
    display: grid
    grid-template-columns: max-content 1fr
    column-gap: 10px

  &-Label
    text-transform: capitalize

  &-Value
    font-size: 28px
</style>
