<template lang="pug">
.NavHeader.row.page-titles
  // Breadcrumb
  .col-9.align-self-center
    ja-breadcrumb(v-if='breadcrumb', :levels='breadcrumb', :vars='vars')

  // Action
  .col-3
    ja-button.float-right(
      v-if='navbutton',
      :icon='navbutton.icon',
      :text='navbutton.text',
      :to='navbutton.to',
      :type='navbutton.type'
    )
</template>

<script>
export default {
  name: 'NavHeader',
  props: {
    breadcrumb: {
      type: [Array, null],
      default: null,
    },
    navbutton: {
      type: [Object, Boolean, null],
      default: null,
    },
    vars: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="stylus" scoped>
.NavHeader
  min-height 51px
  padding 14px 10px
</style>
