/* eslint import/no-webpack-loader-syntax: off */
import CleanCSS from 'clean-css'
import juice from 'juice'
import config from '@config/config.const'
import { replacer } from './replacer'
import stylesFundationEmailHtml from '!!raw-loader!@plugins/content-editor/styles/fundation-emails.css'
import stylesFundationEmailAmp from '!!raw-loader!@plugins/content-editor/styles/fundation-emails-amp.css'
import stylesTemplate from '!!raw-loader!@plugins/content-editor/styles/template.css'
import stylesBlockButton from '!!raw-loader!@plugins/content-editor/styles/blocks/button.css'
import stylesBlockCallme from '!!raw-loader!@plugins/content-editor/styles/blocks/callme.css'
import stylesBlockDynamicPhone from '!!raw-loader!@plugins/content-editor/styles/blocks/dynamic-phone.css'
import stylesBlockGallery from '!!raw-loader!@plugins/content-editor/styles/blocks/gallery.css'
import stylesBlockReview from '!!raw-loader!@plugins/content-editor/styles/blocks/review.css'
import stylesBlockSocial from '!!raw-loader!@plugins/content-editor/styles/blocks/social.css'
import stylesBlockShopifyCart from '!!raw-loader!@plugins/content-editor/styles/blocks/shopify-cart.css'

const useAmpTemplate = ({
  background = 'transparent',
  content = '',
  extensions = [],
  title = '',
}) => {
  const AMP_SCRIPT_BASE_SRC = 'https://cdn.ampproject.org/v0/'

  const { styles } = new CleanCSS({}).minify(
    stylesFundationEmailAmp +
      stylesTemplate +
      stylesBlockButton +
      stylesBlockCallme +
      stylesBlockDynamicPhone +
      stylesBlockGallery +
      stylesBlockReview +
      replacer(stylesBlockReview, { baseUrl: config.BASE_URL_PUBLIC }) +
      stylesBlockSocial +
      replacer(stylesBlockShopifyCart, { baseUrl: config.BASE_URL_PUBLIC })
  )

  const juicedContent = juice(content, {
    removeStyleTags: true,
  })

  const getAmpExtensionScript = ({ name, version }) =>
    `<script async custom-${
      name.includes('mustache') ? 'template' : 'element'
    }="${name}" src="${AMP_SCRIPT_BASE_SRC}${name}-${version}.js"></script>`

  const extensionsTemplate = extensions.reduce(
    (template, { name, version }) => {
      return (
        template +
        getAmpExtensionScript({
          name,
          version,
        })
      )
    },
    ''
  )

  let templateContent = `
    <!doctype html>
    <html ⚡4email data-css-strict>
    <head>
      <meta charset="UTF-8" />
      <script async src="https://cdn.ampproject.org/v0.js"></script>
      ${extensionsTemplate}
      <style amp4email-boilerplate>body{visibility:hidden}</style>
      <style amp-custom>${styles}</style>
    </head>
    <body style="background-color:${background};">
      ${juicedContent}
    </body>
    </html>
  `

  templateContent = templateContent.replace(/!important/gm, '')

  return templateContent
}

const useHtmlTemplate = ({ background, content, title }) => {
  const styles =
    stylesFundationEmailHtml +
    stylesTemplate +
    stylesBlockButton +
    stylesBlockDynamicPhone +
    stylesBlockShopifyCart +
    stylesBlockSocial

  const juicedContent = juice(content, {
    extraCss: styles,
    applyAttributesTableElements: false,
    // removeStyleTags: true,
  })

  const templateContent = `
    <!doctype html>
    <html>
      <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>${title}</title>
      </head>
      <body style="background-color:${background};">
        ${juicedContent}
      </body>
    </html>
  `

  return templateContent
}

export const useTemplate = ({
  format = 'html',
  background,
  content,
  extensions,
  title,
}) => {
  const FORMAT_TEMPLATE = {
    html: useHtmlTemplate,
    amp: useAmpTemplate,
  }

  const template = FORMAT_TEMPLATE[format]({
    background,
    content,
    extensions,
    title,
  })
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/\s+/gm, ' ')
    .replace(/> </gm, '><')
    .trim()

  return template
}
