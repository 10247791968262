<template lang="pug">
  .search-box.nav-item.hidden-sm-down
    button.search-box-icon.nav-link.hidden-sm-down.waves-effect.waves-dark(@click="show = !show")
      i.ti-search
    form.app-search(:class="show && 'search-show'")
      input.form-control(type="text" placeholder="Search & enter")
      a.srh-btn(@click="show = !show")
        i.ti-close
</template>

<script>
export default {
  name: 'SearchBox',
  data: () => ({
    show: false
  })
}
</script>

<style lang="scss" scoped>
.search-box {
  &-icon {
    background: none;
    border: none;
    color: rgba($color: #fff, $alpha: .6);
    font-size: 19px;

    &:focus, &:hover {
      color: #fff;
      outline: none;
    }
  }
}
.search-show {
  display:block !important;

  .app-search input {
    height: 75px;
  }
}
</style>
