export default {
  enabled: true,
  items: [
    {
      name: 'all_f',
      classIcon: 'mdi mdi-checkbox-blank-circle-outline',
      url: '/campaigns',
      slug: 'campaigns',
    },
    {
      name: 'drafts',
      classIcon: 'mdi mdi-circle-edit-outline',
      url: '/campaigns/bucket/draft',
      slug: 'campaigns-draft',
    },
    {
      name: 'ongoing',
      classIcon: 'mdi mdi-progress-check',
      url: '/campaigns/bucket/ongoing',
      slug: 'campaigns-ongoing',
    },
    {
      name: 'ended_f_p',
      classIcon: 'mdi mdi-checkbox-marked-circle-outline',
      url: '/campaigns/bucket/completed',
      slug: 'campaigns-completed',
    },
    // {
    //   name: 'canceled_f_p',
    //   classIcon: 'mdi mdi-close-circle-outline ',
    //   url: '/campaigns/bucket/canceled',
    //   slug: 'campaigns-canceled',
    // },
  ],

  // items: [
  //   { name: 'all_f', classIcon: 'mdi mdi-email-multiple-outline', url: '/campaigns', slug: 'campaigns-all', },
  //   { name: 'ongoing', classIcon: 'mdi mdi-email-send-outline', url: '/campaigns/bucket/ongoing', slug: 'campaigns-ongoing', },
  //   { name: 'completed_f_p', classIcon: 'mdi mdi-email-check-outline', url: '/campaigns/bucket/completed', slug: 'campaigns-completed', },
  //   { name: 'drafts', classIcon: 'mdi mdi-email-edit-outline', url: '/campaigns/bucket/drafts', slug: 'campaigns-drafts', },
  //   { name: 'canceled_f_p', classIcon: 'mdi mdi-email-off-outline ', url: '/campaigns/bucket/canceled', slug: 'campaigns-canceled', },
  // ],
}
