import { concat } from 'lodash'
import activity from './Activity/activity.routes'
import analytics from './Analytics/analytics.routes'
import audiences from './Audiences/audiences.routes'
import campaigns from './Campaigns/campaigns.routes'
import configurations from './Configurations/configurations.routes'
import templates from './Templates/templates.routes'

const routes = concat(
  activity,
  analytics,
  audiences,
  campaigns,
  configurations,
  templates
)

export default routes
