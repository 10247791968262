<template lang="pug">
div
  // Campaign Defaults
  h2(class="mb-4") {{ $t('campagin_defaults') }}

  // Sender
  b-card
    b-card-title {{ $t('sender') }}
    b-card-text
      form.form

        // DEFAULT From Name
        ja-form-field(
          id="name",
          label="default_name",
          type="text",
        )
        // DEFAULT From Email
        ja-form-field(
          id="email",
          label="default_email",
          type="email",
        )
  // GDPR
  b-card
    b-card-title {{ $t('gdpr') }}
    b-card-text
        // DEFAULT Label Text
        ja-form-field(
          id="gdpr_label",
          label="label_text",
          type="text",
        )
        // DEFAULT Legal Text
        ja-form-field(
          id="gdpr_legal",
          label="legal_text",
          type="textarea",
        )
        // DEFAULT Policy URL
        ja-form-field(
          id="gdpr_url",
          label="policy_url",
          type="text",
        )
</template>

<script>
export default {
  name: 'AudienceList',
}
</script>
