<template lang="pug">
.BlockButton
  a.BlockButton-Button(
    :href='link',
    :style='style',
    :target='link ? "_blank" : false'
    v-html='text'
  )
</template>

<script>
import { addQueryParams } from '@/utils'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  data () {
    const link = this.block.config.link
      ? addQueryParams(this.block.config.link, { ybte: `12__${this.block.id}` })
      : false

    const style = this.generateButtonStyle(this.block.config)

    const contentEl = document.createElement('div')
    contentEl.innerHTML = this.block.content

    const text = contentEl.firstElementChild.innerHTML

    return {
      link,
      style,
      text,
    }
  },

  methods: {
    generateButtonStyle ({
      alignment,
      bold,
      colors,
      fontFamily,
      fontSize,
      shape,
    }) {
      const style = {
        display: 'inline-block',
        backgroundColor: colors.button,
        color: colors.text,
        fontFamily: `"${fontFamily}", Helvetica, Roboto, Arial, sans-serif;`,
        fontSize: fontSize + 'px',
      }

      const borderRadius = {
        pill: '80px',
        round: '5px',
        square: '0px',
      }

      const float = {
        left: 'left',
        right: 'right',
        center: 'none',
      }

      if (alignment === 'full') {
        style.display = 'block'
      }

      if (bold) {
        style.fontWeight = 'bold'
      }

      if (colors.border) {
        style.borderColor = colors.border
        style.borderWidth = '2px'
        style.borderStyle = 'solid'
      }

      style.borderRadius = borderRadius[shape]
      style.float = float[alignment]

      return style
    },
  },
}
</script>

<style lang="sass" scoped></style>
