// Components
import AnalyticsDashboard from './Dashboard/Dashboard'

// Topbar Links
import topbar from '../dymal.topbar'

// Breacrumb Level
const breadcrumbLevel = ({
  hasParent = false,
  hasTo = false,
  name = '',
  plural = true,
}) => ({ hasParent, hasTo, name, plural })

export default [
  // Template List (Default)
  {
    name: 'analytics',
    path: '/analytics',
    component: AnalyticsDashboard,
    meta: {
      breadcrumb: [breadcrumbLevel({ name: 'analytics', plural: false })],
      pageTitle: 'templates',
      sidebar: { enabled: false },
      topbar,
    },
  },
]
