<template lang="pug">
.Select
  select.Select-Select(
    :class='{ "fill-column": !label }',
    :value='selected',
    @change='handleChange'
  )
    option(v-for='(option, index) in options', :key='index') {{ option }}
</template>

<script>
import { SELECT_DEFAULTS } from '@plugins/content-editor/const'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Boolean],
      default: '',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    selected: {
      get () {
        if (!this.value) return SELECT_DEFAULTS[this.field]
        return this.value
      },
    },
  },

  methods: {
    handleChange (event) {
      this.onChange({
        value: event.target.value,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.Select
  &-Select
    @include input
</style>
