import ApiClient from '@api/client'

const client = new ApiClient({ path: '/analytics' })

export const AnalyticsService = {
  /**
   * Get Engagement by Campaign
   * @param {String|Number} campaignId
   * @param {Object} params
   * @param {String} params.interval
   * @param {String} params.startDate
   * @param {String} params.endDate
   * @return {Array} campaign engagement by interval
   */
  async getEngagementByCampaignId (campaignId, { interval, startDate, endDate }) {
    return await client.get('/engagement', {
      params: {
        filter_type: 'campaign',
        filter_id: campaignId,
        start_date: startDate,
        end_date: endDate,
        interval,
      }
    })
  },

  /**
   * Get Engagement by Project
   * @param {String|Number} projectId
   * @param {Object} params
   * @param {String} params.interval
   * @param {String} params.startDate
   * @param {String} params.endDate
   * @return {Array} project engagement by interval
   */
  async getEngagementByProjectId (projectId, { interval, startDate, endDate }) {
    return await client.get('/engagement', {
      params: {
        filter_type: 'project',
        filter_id: projectId,
        start_date: startDate,
        end_date: endDate,
        interval,
      }
    })
  },

  /**
   * Get Plan Usage by Organization
   * @param {String|Number} organizationId
   * @return {Array} organizatoin plans (monthly subscription usage)
   */
  async getPlanUsageByOrganizationId (organizationId) {
    return await client.get('/plan-usage/subscriptions/projects', {
      params: { organization_id: organizationId }
    })
  },

  /**
   * Get Plan Usage by Billing Cycle (subscription)
   * @param {String|Number} subscriptionId
   * @return {Object} Billing Cycle
   */
  async getPlanUsageByBillingCycle (subscriptionId) {
    return await client.get('/plan-usage/projects/campaigns', {
      params: { subscription_id: subscriptionId }
    })
  },

  /**
   * Get Performance by Project
   * @param {String|Number} projectId
   * @param {Object} params
   * @param {String} params.startDate
   * @param {String} params.endDate
   * @return {Array} project metrics
   */
  async getPerformanceByProjectId (projectId, { startDate, endDate }) {
    return await client.get('/performance', {
      params: {
        filter_type: 'project',
        filter_id: projectId,
        start_date: startDate,
        end_date: endDate,
      }
    })
  },
}
