import state, { cleanErrorList } from '@apps/Common/common.store'

/**
 * Clean Error Messages
 */
export const cleanErrorMessages = async (to, from, next) => {
  if (state.errors.length && to.name !== from.name) {
    cleanErrorList()
  }

  next()
}
