import Vue from 'vue'
import { EmailSendersService } from '../service/email-senders.service'
import projectState, {
  getProjectSmtpProviders,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  emailSender: null,
})

// actions
export const createEmailSender = async (data) => {
  return await useLoader(EmailSendersService.create, {
    ...data,
    project_id: common.user.project_id,
  })
}

export const deleteEmailSenderById = async (id) => {
  return await useLoader(EmailSendersService.delete, id)
}

export const getEmailSenderById = async (id) => {
  const emailSender = await useLoader(EmailSendersService.getById, id)
  if (!emailSender) return
  Vue.set(state, 'emailSender', emailSender)
  setQueryParam('id_email-sender', state.emailSender.email)
  return emailSender
}

export const initEmailSender = async () => {
  await getProjectSmtpProviders()

  const email_provider_id = projectState.smtpProviders[0].id

  Vue.set(state, 'emailSender', {
    config: {},
    email: '',
    name: '',
    email_provider_id,
    amp_validated: 0,
  })
}

export const updateEmailSenderById = async (id, data) => {
  return await useLoader(EmailSendersService.update, id, data)
}

export default state
