<template lang="pug">
.BlockDynamicPhone
  form.BlockDynamicPhone-TrackingForm(
    :action-xhr='endpointTracking',
    :id='`formTrackingClickPhone_${block.id}`',
    method='post'
  )
    input(hidden, name='campaign_id', type='text', value='%campaign_id%')
    input(hidden, name='audience_id', type='text', value='%audience_id%')
    input(hidden, name='contact_id', type='text', value='%contact_id%')
    input(hidden, name='target', type='text', :value='block.id')

  template(v-if='format === "html"')
    a.BlockDynamicPhone-Button(
      :href='link',
      :target='link ? "_blank" : false',
      :style='style'
    )
      img.image(:src='src', :width='iconSize', :height='iconSize')
      span {{ text }}

  template(v-if='format === "amp"')
    .BlockDynamicPhone-AmpList(v-html='phoneTemplate')
</template>

<script>
import pug from 'pug'
import CONFIG_CONST from '@config/config.const'
import state from '@plugins/content-editor/state'
import { DYNAMIC_PHONE_SIZES } from '@plugins/content-editor/const'
import phonePugTemplate from '!!raw-loader!@plugins/content-editor/components/preview/blocks/DynamicPhone/templates/phone.pug'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
  },

  data () {
    const { BASE_URL_API, BASE_URL_PUBLIC } = CONFIG_CONST
    const {
      customText,
      defaultPhone,
      iconColor,
      iconStyle,
      integration,
      size,
    } = this.block.config

    const endpointTracking = `${BASE_URL_API}/yamp-blocks-events/${state.ampBlockEvents.DYNAMIC_PHONE.CLICK_PHONE}/register`

    const urlIntegration = new URL(`${BASE_URL_API}/connectors`)
    urlIntegration.searchParams.set('integration_id', integration?.id)
    urlIntegration.searchParams.set('response', 'list')
    urlIntegration.searchParams.set('project_id', this.projectId)

    const endpointIntegration = urlIntegration.toString()

    const blockId = this.block.id
    const link = `tel:${defaultPhone}`
    const iconSize = DYNAMIC_PHONE_SIZES[size].icon
    const src = `${BASE_URL_PUBLIC}/amp/phone/phone_${iconStyle}_${iconColor}.png`
    const styleAmp = this.getStyleAmp()
    const text = customText ? this.block.content : defaultPhone

    const phoneTemplateVars = {
      blockId,
      customText,
      endpointIntegration,
      iconSize,
      src,
      styleAmp,
      text,
    }

    const phoneTemplate = pug.render(phonePugTemplate, phoneTemplateVars)

    return {
      BASE_URL_API,
      endpointIntegration,
      endpointTracking,
      iconSize,
      link,
      phoneTemplate,
      src,
      text,
    }
  },

  computed: {
    style () {
      const { config } = this.block

      const style = {
        backgroundColor: config.colors.button,
        borderColor: config.colors.border,
        color: config.colors.text,
        fontFamily: `"${config.fontFamily}", Helvetica, Roboto, Arial, sans-serif;`,
        fontSize: DYNAMIC_PHONE_SIZES[config.size].font,
      }

      const borderRadius = {
        pill: '80px',
        round: '5px',
        square: '0px',
      }

      const float = {
        left: 'left',
        right: 'right',
        center: 'none',
      }

      if (config.alignment === 'full') {
        style.display = 'block'
      }

      style.float = float[config.alignment]
      style.borderRadius = borderRadius[config.shape]

      return style
    },
  },

  methods: {
    getStyleAmp () {
      const { config } = this.block

      const borderRadius = {
        pill: '80px',
        round: '5px',
        square: '0px',
      }

      const float = {
        left: 'left',
        right: 'right',
        center: 'none',
      }

      const style = {
        'background-color': config.colors.button,
        'border-color': config.colors.border,
        'border-radius': borderRadius[config.shape],
        color: config.colors.text,
        display: 'inline-flex',
        float: float[config.alignment],
        'font-family': `"${config.fontFamily}", Helvetica, Roboto, Arial, sans-serif;`,
        'font-size': DYNAMIC_PHONE_SIZES[config.size].font,
      }

      if (config.alignment === 'full') {
        style.display = 'block'
      }

      return style
    },
  },
}
</script>

<style lang="sass" scoped></style>
