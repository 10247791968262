<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        // Name
        ja-form-field#name(
          :fieldValue='fieldName',
          :on-change='handleFieldName',
          ref='inputName',
          label='name',
          type='text',
          placeholder='name_team_placeholder'
        )
</template>

<script>
import state, {
  updateTeamById,
  deleteTeamById,
  createTeam,
  getTeamById,
} from '@apps/Accounts/Organizations/Teams/store/teams.store'

import { getOrganizationById } from '@apps/Accounts/Organizations/Organizations/store/organizations.store'

export default {
  name: 'ProjectEdit',

  data () {
    return {
      fieldName: '',
      projectName: '',
      pageActions: [],
      pageTitle: this.$t('new_team'),
      showForm: false,
    }
  },

  computed: {
    team () {
      return state.team
    },
    isNew () {
      return !this.$route.params.id_team
    },
  },

  async created () {
    // Add action buttons to create a new team
    if (this.isNew) {
      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      // Load the project data
      await getOrganizationById(this.$route.params.id_organization)

      return
    }

    // Add action buttons to edit an team
    this.pageActions.push({
      callback: this.delete,
      icon: 'ti-trash',
      text: 'delete',
      type: 'outline-danger',
    })
    this.pageActions.push({
      callback: this.update,
      icon: 'ti-save',
      text: 'update',
      type: 'outline-primary',
    })

    // Load the project data
    await getTeamById(this.$route.params.id_team)

    // Load the organization data
    this.fieldName = this.team?.name
    this.pageTitle = this.team?.name
    this.showForm = true
  },

  mounted () {
    if (this.isNew) {
      this.$refs.inputName.$el.querySelector('input').focus()
    }
  },

  methods: {
    handleFieldName (value) {
      this.teamName = value
    },

    async delete () {
      await deleteTeamById(this.team.id)
      this.$router.push({ name: 'teams' })
    },

    async save () {
      const team = await createTeam({
        name: this.teamName,
        organization_id: this.$route.params.id_organization,
      })

      if (!team) return

      // Go to the new organization page
      this.$router.push({ name: 'team', params: { id_team: team.id } })
    },

    async update () {
      await updateTeamById(this.team.id, {
        name: this.teamName,
      })

      this.$router.push({ name: 'team', params: { id_team: this.team.id } })
    },

    submit () {
      if (this.isNew) this.save()
      else this.update()
    },
  },
}
</script>
