import { i18n, router } from './config'
import ja from './plugins/ja'
import contentEditor from './plugins/content-editor'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.use(CKEditor)

// ApexCharts
Vue.use(VueApexCharts)
Vue.component('Apexchart', VueApexCharts)

// Config
Vue.config.productionTip = false

// JA
Vue.use(ja)

// ContentEditor
Vue.use(contentEditor)

// Event Bus
Vue.prototype.$eventBus = new Vue()

export default new Vue({
  router,
  store,
  i18n,
  template: '<router-view></router-view>',
}).$mount('#app')
