<template lang="pug">
.PermissionItem
  i.PermissionItem-Icon(:class='[iconClass, modeClass]' )
</template>

<script>
import MODULE_ICONS from '@/plugins/ja/const/module-icons'

export default {
  name: 'PermissionItem',

  props: {
    id: {
      type: [Number, String],
      required: true
    },
    module: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
  },

  data () {
    const iconClass = MODULE_ICONS[this.module]
    const modeClass = this.mode === 'read-write' ? 'ModeWrite' : ''

    return {
      iconClass,
      modeClass,
    }
  },
}
</script>

<style lang="sass" scoped>
.PermissionItem

  &-Icon
    display: block
    font-size: 20px
    margin-right: 0

    &.ModeWrite
      color: #6330e3
</style>
