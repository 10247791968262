<template lang="pug">
.BlockReview
  template(v-if='isAmp')
    amp-state(:id='`review_${block.id}`')
      component(is='script', type='application/json') {{ scriptState }}

    amp-bind-macro(
      :id='`paramsToString_${block.id}`',
      :expression='`keys(review_${block.id}).reduce((reviewString, key, index, arr) => reviewString + \'"\' + key + \'":"\' + review_${block.id}[key] + \'"\' + (index < arr.length - 1 ? \',\' : \'}\'), \'{"email": "%email%",\')`'
    )

    .BlockReview-FormContainer(v-html='formTemplate')

  template(v-if='!isAmp')
    ce-preview-block-button(:block='buttonConfig')
</template>

<script>
import pug from 'pug'
import CONFIG_CONST from '@config/config.const'
import state from '@plugins/content-editor/state'
import formPugTemplate from '!!raw-loader!@plugins/content-editor/components/preview/blocks/Review/templates/form.pug'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
  },

  data () {
    const isAmp = this.format === 'amp'
    const AMP_EVENTS = state.ampBlockEvents.REVIEW
    const { BASE_URL_API } = CONFIG_CONST
    const blockId = this.block.id
    const { colorScheme, rating } = this.block.config

    const scriptState = JSON.stringify({
      rating: '',
      review: '',
      title: '',
    })

    const endpoint = {
      url: `${BASE_URL_API}/connectors`,
      method: 'post',
      integration_id: this.block.config.integration?.id || '',
      project_id: this.projectId,
      response: 'form',
    }

    const submitStyle = {
      'background-color': this.block.config.colors.buttonBackground,
      color: this.block.config.colors.buttonText,
    }
    const textStyle = { color: this.block.config.colors.text }

    const formTemplateVars = {
      ...this.block.content,
      AMP_EVENTS,
      BASE_URL_API,
      blockId,
      colorScheme,
      endpoint,
      rating,
      submitStyle,
      textStyle,
    }

    const formTemplate = pug.render(formPugTemplate, formTemplateVars)

    return {
      isAmp,
      formTemplate,
      scriptState,
    }
  },

  computed: {
    buttonConfig () {
      const { colors, link } = this.block.config

      const config = {
        alignment: 'full',
        bold: true,
        colors: {
          border: colors.border,
          button: colors.buttonBackground,
          text: colors.buttonText,
        },
        fontSize: 18,
        shape: 'round',
      }

      if (link) {
        config.link = link
      }

      const content = this.block.content.fallback || this.block.content.submit

      return {
        config,
        content,
      }
    },
  },
}
</script>

<style lang="sass">
.BlockReview
</style>
