<template lang="pug">
content-preview-page(
  v-if='campaignContent',
  :title='campaign.name',
  :config='campaignContent.config',
  :description='campaign.description'
)
</template>

<script>
import ContentPreviewPage from '@apps/Dymal/ContentEditor/ContentPreview.vue'
import state, { getCampaignById } from './store/campaigns.store'

export default {
  name: 'CampaignContentEditor',

  components: { ContentPreviewPage },

  computed: {
    campaign: () => state.campaign,
    campaignContent: () => state.campaign?.campaign_content,
  },

  async created () {
    if (!this.campaign) {
      await getCampaignById(this.$route.params.id_campaign)
    }
  },
}
</script>
