<template lang="pug">
.NavMenu
  h5.NavMenu-Title(v-if='navMenuItems.title') {{ navMenuItems.title }}
  ul.NavMenu-Items.sidebarnav
    ja-nav-menu-item(
      v-for='(item, index) in navMenuItems.items',
      :key='index',
      :to='composeTo(item)',
      :name='item.name',
      :slug='item.slug',
      :classIcon='item.classIcon'
    )
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    navMenuItems: {
      type: Object,
      required: true,
    },
  },
  methods: {
    composeTo (item) {
      return item.parent
        ? `${item.parent}/${this.$route.params[item.parentId]}${item.url || ''}`
        : item.url
    },
  },
}
</script>

<style lang="sass" scoped>
.NavMenu
  padding: 0 0 32px

  &-Items
    padding: 0px
    list-style-type: none

  &-Title
    color: #98949a
    padding-left: 10px
    padding-bottom: 6px
    font-weight: 600
    font-size: 14px
    text-transform: uppercase
</style>
