<template lang="pug">
.ImagePicker
  .ImagePicker-Preview(v-if='value')
    template(v-if='isImageList')
      b-carousel.ImagePicker-Carousel(
        v-if='areThereAnyImage',
        :interval='0',
        controls,
        indicators
      )
        b-carousel-slide.ImagePicker-Carousel-Item(
          v-for='image in value',
          :img-src='image.src',
          :key='image.id'
        )

    template(v-else)
      img.ImagePicker-Image(v-if='value.src', :src='value.src')

  .ImagePicker-Buttons
    button.ImagePicker-ButtonUpload.btn.btn-outline-primary(
      @click='clickInputImage'
    ) Upload

    button.ImagePicker-ButtonSelect.btn.btn-outline-primary(
      @click='openModalImageLibrary'
    ) Select from Library

  input(
    @change='handleInputUpload',
    type='file',
    name='image-upload',
    ref='input-image',
    style='display: none'
  )

  // Modal
  b-modal(
    ref='modal-image-library',
    centered,
    size='xl',
    scrollable,
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .ImagePicker-LibraryHeader
        i.mdi.mdi-image-multiple
        span {{ $t("image_library") }}

    // Modal Content
    ce-image-library(
      v-model='selectedImages',
      :images='imageLibrary',
      :multi-select='isImageList'
    )

    // Modal Footer
    template(v-slot:modal-footer)
      .ImagePicker-LibraryFooter
        ja-button(
          :on-click='clickInputImage',
          :text='$t("upload")',
          type='outline-primary'
        )
        ja-button(
          :disabled='!areThereAnySelected',
          :on-click='handleModalDelete',
          :text='$t("delete")',
          type='danger'
        )
        ja-button(
          :disabled='!areThereAnySelected',
          :on-click='handleModalSelect',
          :text='$t("select_image")',
          type='primary'
        )
</template>

<script>
import { cloneDeep } from 'lodash'

import {
  actionGetImageLibrary,
  actionDeleteImage,
  actionUploadImage,
} from '@plugins/content-editor/state'

const transformImage = (image) => {
  return {
    date: image.created_at,
    id: image.id,
    name: image.name,
    src: image.path,
  }
}

export default {
  props: {
    field: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, Object, null],
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      selectedImages: [],
      imageLibrary: [],
      modalShow: false,
    }
  },

  computed: {
    isImageList () {
      return this.field === 'imageList'
    },

    areThereAnyImage () {
      return this.value?.length
    },

    areThereAnySelected () {
      return this.selectedImages.length
    },
  },

  watch: {
    modalShow () {
      this.initSelectedImages()
    },
    value () {
      this.initSelectedImages()
    },
  },

  created () {
    this.initSelectedImages()
  },

  methods: {
    clickInputImage () {
      this.$refs['input-image'].click()
    },

    async handleInputUpload (event) {
      const file = event.target.files[0]
      const uploadedFile = await actionUploadImage(file)
      const formatedImage = transformImage(uploadedFile)
      let value

      if (this.modalShow) {
        this.imageLibrary.push(formatedImage)
        return
      }

      if (this.isImageList) {
        value = cloneDeep(this.value)
        value.push(formatedImage)
      } else {
        value = formatedImage
      }

      this.onChange({ value })
    },

    async handleModalDelete () {
      let matchDeletedWithSaved = false
      let newValue = cloneDeep(this.value)

      if (this.areThereAnyImage) {
        this.selectedImages.forEach((image) => {
          if (this.isImageList) {
            const newValueIndex = newValue.findIndex(
              (newValueImage) => newValueImage.id === image.id
            )
            if (newValueIndex > -1) {
              newValue.splice(newValueIndex, 1)
              matchDeletedWithSaved = true
            }
          } else {
            if (image.id === newValue.id) {
              newValue = null
              matchDeletedWithSaved = true
            }
          }
        })
      }

      if (matchDeletedWithSaved) {
        this.saveValue(newValue)
      }

      await Promise.all(
        this.selectedImages.map((image) => actionDeleteImage(image.id))
      )

      this.initSelectedImages()
      this.loadImageLibrary()
    },

    handleModalSelect () {
      let value

      if (this.isImageList) {
        value = this.selectedImages
      } else {
        value = this.selectedImages[0]
      }

      this.saveValue(value)
    },

    initSelectedImages () {
      this.selectedImages = []

      if (this.isImageList) {
        this.selectedImages.push(...this.value.map((image) => image))
      } else if (this.value) {
        this.selectedImages.push(this.value)
      }
    },

    async loadImageLibrary () {
      const images = await actionGetImageLibrary()
      this.imageLibrary = []

      if (images.length) {
        this.imageLibrary.push(...images.map((image) => transformImage(image)))
      }
    },

    async openModalImageLibrary () {
      await this.loadImageLibrary()
      this.toggleImageLibrary()
    },

    saveValue (value) {
      this.onChange({ value })
      this.toggleImageLibrary()
    },

    toggleImageLibrary () {
      this.$refs['modal-image-library'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables

.ImagePicker

  &-Buttons
    display: flex
    gap: 10px
    button
      flex: 1

  &-Carousel,
  &-Image
    margin-bottom: 20px

  &-Image
    display: block
    max-width: 100%
    background-color: $gray-dark

  &-LibraryHeader,
  &-LibraryFooter .Button
    text-transform: capitalize

  &-LibraryFooter
    display: flex
    flex-shrink: 0
    flex-wrap: wrap
    justify-content: flex-end
    gap: 10px
</style>
