<template lang="pug">
ja-content(v-if='user', :pageActions='pageActions',  :pageTitle='user.name')

  // User Details
  h2 {{ $t("user_details") }}

  b-card
    b-card-text

      // Full name
      ja-form-field#name(
        :fieldValue='user.name'
        label='name'
        type='info'
      )

      // Email
      ja-form-field#email(
        :fieldValue='user.email',
        label='email',
        type='info'
      )
</template>

<script>
import state, {
  getUserByUserIdAndOrganizationId,
  removeUserFromOrganization,
} from '@apps/Accounts/Organizations/Users/store/users.store'

export default {
  name: 'UserOverview',

  data () {
    const pageActions = []

    pageActions.push({
      callback: () => this.remove(),
      icon: 'ti-trash',
      text: 'delete',
      type: 'outline-danger',
    })

    return {
      pageActions
    }
  },

  computed: {
    user () {
      return state.user
    },
  },

  beforeCreate () {
    const {
      id_organization,
      id_user,
    } = this.$route.params

    getUserByUserIdAndOrganizationId(
      id_user,
      id_organization
    )
  },

  methods: {
    async remove () {
      const {
        id_organization: organization_id,
        id_user: user_id,
      } = this.$route.params

      await removeUserFromOrganization({
        organization_id,
        user_id,
      })

      // Go to users users organization list
      this.$router.push({
        name: 'users',
        params: { id_organization: organization_id }
      })
    }
  }
}
</script>
