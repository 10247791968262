import ApiClient from '@api/client'

const client = new ApiClient({ path: '/yamp-blocks' })

export const AmpBlockService = {
  /**
   * Get All AMP Blocks
   * @return {Array} ampBlocks
   */
  async getAll () {
    return await client.get()
  },
}
