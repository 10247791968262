<template lang="pug">
.ContentTable
  TransitionGroup(name='transition-fade')
    ce-content-block(
      v-for='block in rows',
      v-show='block.id',
      :key='block.id',
      :block='block'
    )
</template>

<script>
export default {
  name: 'ContentTable',
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="sass" scoped>
.ContentTable
  width: 600px
  display: flex
  flex-direction: column

.transition-fade-enter-active,
.transition-fade-leave-active
  transition-duration: .25s
  transition-property: all
  transition-timing-function: cubic-bezier(.4,1,.75,.9)
  transform-origin: center 0px

.transition-fade-enter-active
  opacity: 1
  transform: scale(1)

.transition-fade-enter,
.transition-fade-enter-from,
.transition-fade-leave-to,
.transition-fade-leave-active
  opacity: 0
  transform: scale(0)
</style>
