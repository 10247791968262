export const queryGetProducts = `#graphql
  query GetProducts($query: String!) {
    shop {
      currencyCode
      primaryDomain {
        url
      }
    }
    products(first: 10, query: $query) {
      edges {
        cursor
        node {
          createdAt
          featuredImage {
            src
          }
          handle
          id
          productType
          title
          updatedAt
          variants(first: 10) {
            edges {
              node {
                id
                inventoryQuantity
                displayName
                price
                sku
                title
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
