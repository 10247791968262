<template lang="pug">
.SocialLinks
  .SocialLinks-Item(v-for='item in items')
    .SocialLinks-Label
      i(:class='getIconClass(item.type)')
      span {{ item.type }}
    .SocialLinks-Input
      input(
        :name='item.type',
        :value='item.link',
        @change='(event) => handleChange(event, item.type)'
      )
</template>

<script>
import { cloneDeep } from 'lodash'
import { SOCIAL } from '@plugins/content-editor/const'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    const items = cloneDeep(this.value)

    return { items }
  },

  methods: {
    getIconClass (key) {
      return SOCIAL[key].icon
    },

    handleChange (event, type) {
      const { value } = event.target
      const itemIndex = this.items.findIndex((item) => item.type === type)

      this.items[itemIndex].link = value

      this.onChange({
        value: cloneDeep(this.items),
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.SocialLinks
  &-Input
    margin-bottom: 20px
    input
      @include input
  &-Label
</style>
