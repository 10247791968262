<template lang="pug">
.Tooltip(role='tooltip', :data-show='show')
  Transition(duration='300', name='transition-fade')
    .Tooltip-Container(v-show='show')
      .Tooltip-Arrow(data-popper-arrow)
      .Tooltip-Body
        slot
</template>

<script>
import { createPopper } from '@popperjs/core'

const POPPER_OPTIONS = {
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 14],
      },
    },
  ],
}

export default {
  props: {
    target: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return { popperInstance: null }
  },

  watch: {
    async show (newValue) {
      if (!newValue) return
      this.popperInstance.update()
    },
  },

  mounted () {
    const targetEl = document.getElementById(this.target)
    this.popperInstance = createPopper(targetEl, this.$el, POPPER_OPTIONS)
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.Tooltip
  z-index: 3

  &-Container
    background: $white
    border-radius: 5px
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%)
    padding: $popper-padding
    position: relative

  &[data-show]
    display: inline-block

  &-Arrow, &-Arrow::before
    background: $white
    height: $popper-arrow-size
    position: absolute
    width: $popper-arrow-size

  &-Arrow
    visibility: hidden

    &::before
      content: ''
      visibility: visible
      transform: rotate(45deg)

  &[data-popper-placement^='top'] &-Arrow
    bottom: $popper-arrow-placement
  &[data-popper-placement^='bottom'] &-Arrow
    top: $popper-arrow-placement
  &[data-popper-placement^='left'] &-Arrow
    right: $popper-arrow-placement
  &[data-popper-placement^='right'] &-Arrow
    left: $popper-arrow-placement

  &-Body
    width: $content-width*0.9

.transition-fade-enter-active,
.transition-fade-leave-active
  transition-duration: .25s
  transition-property: all
  transition-timing-function: cubic-bezier(.4,1,.75,.9)
  transform-origin: center 0px

.transition-fade-enter-active
  opacity: 1
  transform: scale(1)

.transition-fade-enter,
.transition-fade-enter-from,
.transition-fade-leave-to,
.transition-fade-leave-active
  opacity: 0
  transform: scale(0)
</style>
