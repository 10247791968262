<template lang="pug">
.ImageSkeleton
  i.fas.fa-images(v-if='isGallery')
  i.fas.fa-image(v-else)
  span {{ text }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    isGallery: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.ImageSkeleton
  @include image-skeleton
</style>
