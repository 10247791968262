<template lang="pug">
.TeamPermissionManager
  // Modal
  b-modal(
    ref='modal-permission-manager',
    centered,
    modal-class='TeamPermissionManager-Modal',
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .TeamPermissionManager-ModalHeader.text-first-uppercase
        i.mdi.mdi-briefcase
        span {{ project.name }}: {{ $t("permission_management") }}

    // Modal Content
    form.TeamPermissionManager-Form

      ja-form-field(
        v-for="module in modules"
        type='select'
        :id='module'
        :key='module'
        :label='module'
        :fieldValue='getPermissionModeByModule(module)'
        :options='modeOptions'
        :on-change='handleChangePermissionMode'
      )

    // Modal Footer
    template(v-slot:modal-footer)
      .TeamPermissionManager-ModalFooter
        ja-button(
          :disabled='false',
          :on-click='handleSave',
          :text='$t("save")',
          type='primary'
        )
</template>

<script>
import APP_MODULES_SORTED from '@/const/app-modules'

export default {
  name: 'TeamPermissionManager',

  props: {
    appModules: {
      type: Array,
      default: () => []
    },

    project: {
      type: Object,
      default: () => {}
    },

    permissions: {
      type: Array,
      default: () => []
    },

    team: {
      type: String,
      required: true
    },

    onSave: {
      type: Function,
      default: () => {}
    },
  },

  data () {
    const modalShow = false
    const modules = [...APP_MODULES_SORTED]
    const modulesPermissionsUpdated = {}

    const modeOptions = [
      { key: '', text: 'No access' },
      { key: 'read-only', text: 'Can View' },
      { key: 'read-write', text: 'Can Edit' },
    ]

    return {
      modalShow,
      modeOptions,
      modules,
      modulesPermissionsUpdated,
    }
  },

  computed: {
    modulesPermissions () {
      return this.modules.reduce((acc, module) => {
        const permission = this.permissions.find(permission => permission.module === module)
        const permissionMode = permission ? permission.mode : ''
        return { ...acc, [module]: permissionMode }
      }, {})
    },
  },

  methods: {
    getPermissionModeByModule (module) {
      const permission = this.permissions.find((permission) => permission.module === module)
      return permission ? permission.mode : false
    },

    handleChangePermissionMode (permissionMode, module) {
      this.$set(this.modulesPermissionsUpdated, module, permissionMode)
    },

    handleSave () {
      const updates = []

      Object.entries(this.modulesPermissionsUpdated).forEach(([module, permissionMode]) => {
        if (this.modulesPermissionsUpdated[module] === this.modulesPermissions[module]) {
          return
        }

        const appModule = this.appModules.find(appModule => appModule.name === module)

        const permission = {
          delete: !permissionMode,
          module: appModule.name,
          moduleId: appModule.id,
          mode: permissionMode || this.modulesPermissions[module],
          projectId: this.project.id
        }

        updates.push(permission)
      })

      this.onSave(updates)
    },

    toggle () {
      this.$refs['modal-permission-manager'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>

</style>
