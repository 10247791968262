<template lang="pug">
.BlockCallme(:style='cssVars')
  template(v-if='!showFallback')
    .BlockCallme-FormGroup(v-if='showName')
      .BlockCallme-Label(
        contenteditable,
        @input='(event) => handleChange(event, "nameLabel")'
      ) {{ content.nameLabel }}
      .BlockCallme-Input(
        contenteditable,
        @input='(event) => handleChange(event, "namePlaceholder")'
      ) {{ content.namePlaceholder }}

    .BlockCallme-FormGroup
      .BlockCallme-Label(
        contenteditable,
        @input='(event) => handleChange(event, "phoneLabel")'
      ) {{ content.phoneLabel }}
      .BlockCallme-Input(
        contenteditable,
        @input='(event) => handleChange(event, "phonePlaceholder")'
      ) {{ content.phonePlaceholder }}

    .BlockCallme-PolicyGroup(v-if='showPolicy')
      .BlockCallme-PolicyCheck

      .BlockCallme-PolicyText
        span(
          contenteditable,
          @input='(event) => handleChange(event, "policyStart")'
        ) {{ content.policyStart }}

        a(
          contenteditable,
          @input='(event) => handleChange(event, "policyEnd")'
          :href='config.fieldsConfig.policy.link'
          target='_blank'
        ) {{ content.policyEnd }}

    .BlockCallme-Submit(
      contenteditable,
      @input='(event) => handleChange(event, "submit")'
    ) {{ content.submit }}

  template(v-if='showFallback')
    .BlockCallme-Submit(
      contenteditable,
      @input='(event) => handleChange(event, "fallback")'
    ) {{ content.fallback }}
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    showFallback: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      content: { ...this.block.content },
    }
  },

  computed: {
    config () {
      return this.block.config
    },
    cssVars () {
      return {
        '--button-background': this.config.colors.buttonBackground,
        '--button-text': this.config.colors.buttonText,
        '--font-size': this.config.fontSize + 'px',
        '--input-color': this.config.colors.inputColor,
        '--padding-bottom': this.config.padding.bottom + 'px',
        '--padding-top': this.config.padding.top + 'px',
        '--text-color': this.config.colors.text,
      }
    },
    showName () {
      return !!this.config.fieldsConfig?.name?.show
    },
    showPolicy () {
      return !!this.config.fieldsConfig?.policy?.show
    }
  },

  methods: {
    handleChange (event, fieldName) {
      const value = event.target.innerText

      this.onChange({
        ...this.block.content,
        [fieldName]: value,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/mixins

.BlockCallme
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-Input,
  &-Label,
  &-PolicyText
    cursor: text

  &-Input
    background-color: $white
    border-radius: 4px
    border: 3px solid var(--input-color, $gray-light-border)
    color: #999
    font-size: 18px
    padding: 12px 14px

  &-FormGroup,
  &-PolicyGroup
    margin-bottom: 20px

  &-PolicyGroup
    display: flex
    align-items: center
    gap: 8px

  &-PolicyText
    font-size: 14px

  &-Label,
  &-PolicyText
    color: var(--text-color, $gray-dark)

  &-Label
    font-size: var(--font-size, inherit)
    font-weight: bold
    margin-bottom: 10px

  &-Submit
    @include submit-button

  &-PolicyCheck
    background-color: white
    border-radius: 4px
    border: 1px solid #999
    height: 15px
    min-width: 15px
    width: 15px

  &-PolicyText a
    color: var(--text-color, $gray-dark)
    text-decoration: underline
</style>
