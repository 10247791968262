const BLACK = '#000000'
const BLUE_LIGHT = '#e9f0ff'
const BLUE = '#3b3bff'
const GREEN = '#2db92d'
const LIME_LIGHT = '#e2f4e6'
const PINK_LIGHT = '#f4e2e7'
const PINK = '#ffa7b6'
const ORANGE = '#ffa500'
const RED = '#ff3b3b'
const TRANSPARENT = 'transparent'
const VIOLET_LIGHT = '#efe2f4'
const VIOLET = '#7c48b3'
const YELLOW = '#ffff00'
const WHITE = '#ffffff'

export const SWATCHES = [
  TRANSPARENT,
  BLACK,
  WHITE,
  LIME_LIGHT,
  PINK_LIGHT,
  VIOLET_LIGHT,
  BLUE_LIGHT,
  PINK,
  BLUE,
  GREEN,
  YELLOW,
  ORANGE,
  RED,
  VIOLET,
]
