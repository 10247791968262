<template lang="pug">
.Pagination
  .Pagination-InfoItems {{ infoItems }}

  .Pagination-ButtonList
    ja-button.Pagination-Button(
      v-if='prevPage',
      :on-click='() => handleClickButton(prevPage)',
      :text='$t("previous")'
      type='outline-secondary'
    )

    .Pagination-InfoPages(v-if='prevPage || nextPage') {{ infoPages }}

    ja-button.Pagination-Button(
      v-if='nextPage',
      type='outline-secondary'
      :on-click='() => handleClickButton(nextPage)',
      :text='$t("next")'
    )
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    options: {
      type: Object,
      required: true,
    },

    onChangePage: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    currentPage () {
      return this.options.current_page
    },

    firstPage () {
      return this.options.first_page
    },

    lastPage () {
      return this.options.last_page
    },

    perPage () {
      return this.options.per_page
    },

    infoItems () {
      const firstItem = (this.currentPage - 1) * this.perPage + 1
      const lastItem = this.nextPage
        ? this.currentPage * this.perPage
        : this.totalItems

      return `Showing ${firstItem} to ${lastItem} of ${this.totalItems} entries`
    },

    infoPages () {
      return `${this.currentPage} / ${this.totalPages}`
    },

    nextPage () {
      return this.totalPages === this.currentPage ? 0 : this.currentPage + 1
    },

    prevPage () {
      return this.currentPage - 1
    },

    totalItems () {
      return this.options.total_items
    },

    totalPages () {
      return Math.ceil(this.totalItems / this.perPage)
    },
  },

  methods: {
    handleClickButton (value) {
      this.onChangePage(value)
    },
  },
}
</script>

<style lang="sass" scoped>
$purple: #6330e3

.Pagination
  align-items: center
  display: grid
  grid-template-columns: max-content max-content
  justify-content: space-between

  &-ButtonList
    align-items: center
    display: flex
    gap: 10px

  &-Button
    &:active,
    &:focus,
    &:hover
      outline: none
      box-shadow: none

</style>
