import Vue from 'vue'
import { CampaignService } from '../service/campaigns.service'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'
import { JobsService } from '@api/services/jobs.service'
import { transformPerformanceForDataWell } from '@/data-transformers/analytics'

// state
const state = Vue.observable({
  campaign: {},
  audiences: [],
  ampBlocksStats: [],
  performance: [],
})

// getters
export const getters = {
  campaign: () => state.campaign,
  audiences: () => state.audiences,
}

// actions
export const addAudienceToCampaign = async (campaignId, audienceId) => {
  return await useLoader(
    CampaignService.addAudience,
    campaignId,
    audienceId
  )
}

export const createCampaign = async (data) => {
  return await useLoader(CampaignService.create, {
    ...data,
    project_id: common.user.project_id,
  })
}

export const createCampaignContent = async (data) => {
  return await useLoader(CampaignService.createContent, data)
}

export const createCampaignScheduler = async (data) => {
  return await useLoader(JobsService.create, {
    ...data,
    name: `campaign_scheduler_${state.campaign.id}`,
    queue: 'campaigns',
    action: 'send_campaign',
  })
}

export const deleteCampaignById = async (id) => {
  return await useLoader(CampaignService.delete, id)
}

export const getCampaignById = async (id) => {
  const campaign = await useLoader(CampaignService.getById, id)
  const performance = transformPerformanceForDataWell(campaign.stats)

  if (campaign.campaign_content?.config) {
    campaign.campaign_content.config = JSON.parse(
      campaign.campaign_content.config
    )
    campaign.campaign_content.config.rows =
      campaign.campaign_content.config.rows.filter((row) => row)
  }

  Vue.set(state, 'performance', performance)
  Vue.set(state, 'campaign', campaign)
  Vue.set(state, 'audiences', campaign.audiences)

  setQueryParam('id_campaign', state.campaign.name)

  return campaign
}

export const getAmpBlockStatsByCampaign = async (id) => {
  const ampBlocksStats = await useLoader(
    CampaignService.getAmpBlockStatsByCampaign,
    id
  )

  Vue.set(state, 'ampBlocksStats', ampBlocksStats)

  return ampBlocksStats
}

export const removeAudienceFromCampaign = async (campaignId, audienceId) => {
  return await useLoader(
    CampaignService.removeAudience,
    campaignId,
    audienceId
  )
}

export const saveCampaignContentPreview = async (id, data) => {
  return await useLoader(
    CampaignService.updateContentPreview,
    id,
    data
  )
}

export const scheduleCampaign = async (campaignId) => {
  return await useLoader(CampaignService.schedule, campaignId)
}

export const updateCampaignById = async (id, data) => {
  const campaignUpdated = await useLoader(CampaignService.update, id, {
    ...data,
    project_id: common.user.project_id,
  })

  Vue.set(state, 'campaign', { ...state.campaign, ...campaignUpdated })

  return state.campaign
}

export const updateCampaignContentById = async (id, data) => {
  return await useLoader(CampaignService.updateContent, id, data)
}

export default state
