<template lang="pug">
content-editor-page(
  v-if='isDataloaded',
  :title='campaign.name',
  :config='campaignContent.config',
  :ampBlockEvents='ampBlockEvents',
  :integrations='integrations',
  :integration-types='integrationTypes',
  :on-delete-image='deleteImage',
  :on-get-image-library='getImageLibrary',
  :on-get-product-list='getProductList',
  :on-save-content='saveContent',
  :on-save-preview='savePreview',
  :on-upload-image='uploadImage'
)
</template>

<script>
import ContentEditorPage from '@apps/Dymal/ContentEditor/ContentEditor'

import state, {
  getCampaignById,
  saveCampaignContentPreview,
  updateCampaignContentById,
} from './store/campaigns.store'

import projectState, {
  deleteProjectMedia,
  getProjectMedia,
  getProjectIntegrations,
  getProductList,
  uploadProjectMedia,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

import integrationState, {
  getIntegrationTypes,
} from '@apps/Dymal/Configurations/Integrations/store/integrations.store'

import ampBlockState, { getAmpBlocks } from '@/store/yamp-blocks.store'

export default {
  name: 'CampaignContentEditor',

  components: { ContentEditorPage },

  data () {
    return {
      isDataloaded: false,
    }
  },

  computed: {
    campaign () {
      return state.campaign
    },
    campaignContent () {
      return state.campaign?.campaign_content
    },
    ampBlockEvents () {
      return ampBlockState.ampBlockEventsMap
    },
    integrations () {
      return projectState.integrations
    },
    integrationTypes () {
      return integrationState.integrationTypes
    },
  },

  created () {
    const promises = []

    if (!state.campaign.campaign_content) {
      promises.push(getCampaignById(this.$route.params.id_campaign))
    }

    if (!ampBlockState.ampBlockEventsMap) {
      promises.push(getAmpBlocks())
    }

    if (!projectState.integrations.length) {
      promises.push(getProjectIntegrations())
    }

    if (!integrationState.integrationTypes.length) {
      promises.push(getIntegrationTypes())
    }

    Promise.all(promises).then(() => {
      this.isDataloaded = true
    })
  },

  methods: {
    async deleteImage (id) {
      return await deleteProjectMedia(id)
    },

    async getImageLibrary () {
      return await getProjectMedia()
    },

    async getProductList (data) {
      return await getProductList(data)
    },

    async saveContent ({ config, content_amp, content_html }) {
      const data = {
        campaign_id: this.campaignContent.campaign_id,
        campaign_template_id: this.campaignContent.campaign_template_id,
        config: JSON.stringify(config),
        content_html,
        content_amp,
      }

      await updateCampaignContentById(this.campaignContent.id, data)
    },

    async savePreview (data) {
      return await saveCampaignContentPreview(this.campaignContent.id, data)
    },

    async uploadImage (data) {
      return await uploadProjectMedia(data)
    },
  },
}
</script>
