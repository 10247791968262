<template lang="pug">
ja-content(:pageTitle='title', :pageActions='pageActions')
  ce-content-editor(
    v-if='syncConfig',
    :config='syncConfig',
    :ampBlockEvents='ampBlockEvents',
    :integrations='integrations',
    :integration-types='integrationTypes',
    :on-change-config='handleChangeConfig',
    :on-delete-image='handleDeleteImage',
    :on-upload-image='handleUploadImage',
    :on-get-image-library='onGetImageLibrary',
    :on-get-product-list='onGetProductList',
    :use-take-screenshot.sync='takeScreenshot'
  )

  .ContentCompilers(v-if='isUpdating')
    ce-content-preview(
      v-if='syncConfig',
      :config='syncConfig',
      :on-compile-content='(value) => handleCompileContent({ format: "amp", value })',
      :projectId='projectId',
      format='amp',
      class=''
    )
    ce-content-preview(
      v-if='syncConfig',
      :config='syncConfig',
      :on-compile-content='(value) => handleCompileContent({ format: "html", value })',
      :projectId='projectId',
      format='html',
      class=''
    )
</template>

<script>
import state, { setConfig } from './store/content-editor.store'

import common, { setLoading } from '@apps/Common/common.store'

export default {
  name: 'ContentEditorPage',

  props: {
    ampBlockEvents: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    integrations: {
      type: Array,
      default: () => [],
    },
    integrationTypes: {
      type: Array,
      default: () => [],
    },
    onDeleteImage: {
      type: Function,
      default: () => {},
    },
    onGetImageLibrary: {
      type: Function,
      default: () => {},
    },
    onGetProductList: {
      type: Function,
      default: () => {},
    },
    onSaveContent: {
      type: Function,
      default: () => {},
    },
    onSavePreview: {
      type: Function,
      default: () => {},
    },
    onUploadImage: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },

  data () {
    const pageActions = [
      {
        callback: this.update,
        icon: 'ti-save',
        text: 'update',
        type: 'outline-primary',
      },
    ]

    const compiledContent = {
      amp: '',
      html: '',
    }

    return {
      compiledContent,
      isUpdating: false,
      pageActions,
      takeScreenshot: null,
    }
  },

  computed: {
    projectId: () => common.user.project_id,
    syncConfig: () => state.config,
  },

  created () {
    setConfig(this.config)
  },

  methods: {
    async update () {
      try {
        this.isUpdating = true
        await setTimeout(() => {}, 1000)

        await this.onSaveContent({
          config: this.syncConfig,
          content_amp: this.compiledContent.amp,
          content_html: this.compiledContent.html,
        })
        await this.savePreview()

        this.isUpdating = false
      } catch (err) {
        console.error(err)
      }
    },

    handleCompileContent ({ format, value }) {
      this.compiledContent[format] = value
    },

    handleChangeConfig ({ changedBlock, updatedConfig }) {
      setConfig(updatedConfig)
    },

    async handleDeleteImage (id) {
      setLoading(true)
      return await this.onDeleteImage(id)
    },

    async handleUploadImage (file) {
      setLoading(true)
      const data = new FormData()
      data.append('image', file)
      data.append('name', file.name)
      const uploadedFile = await this.onUploadImage(data)
      return uploadedFile
    },

    async savePreview () {
      setLoading(true)
      const data = new FormData()
      const { blob } = await this.takeScreenshot()
      data.append('preview', blob)
      await this.onSavePreview(data)
    },
  },
}
</script>

<style lang="sass" scoped>
.ContentCompilers
  display: none
</style>
