<template lang="pug">
.ContentCompiler
  .ContentCompiler-Content(ref='content-html', v-show='false')
    .center
      table(
        width='100%',
        align='center',
        :style='{ backgroundColor: body.config.colors.backdrop, fontFamily: body.config.fontFamily || "sans-serif" }'
      )
        tbody
          tr
            td(align='center')
              table.container(
                align='center',
                :style='{ backgroundColor: body.config.colors.canvas, maxWidth: "600px" }'
              )
                tbody
                  tr(v-for='row in rows', :key='row.id')
                    td
                      // Block: Button
                      template(v-if='isButtonBlock(row.type)')
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-button(:block='row')

                      // Block: CallMe Form
                      template(v-if='isCallmeBlock(row.type)')
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-callme(
                                  :block='row',
                                  :format='format',
                                  :projectId='projectId'
                                )

                      // Block: Divider
                      template(v-if='isDividerBlock(row.type)')
                        table.row
                          tr
                            th.row-h-spacing(:style='generateBlockStyle(row)')
                              table.divider(:style='generateDividerStyle(row)')
                                tbody
                                  tr
                                    td

                      // Block: Dynamic Phone
                      template(v-if='isDynamicPhone(row.type)')
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-dynamic-phone(
                                  :block='row',
                                  :format='format',
                                  :projectId='projectId'
                                )

                      // Block: Image
                      template(
                        v-if='isImageBlock(row.type) && row.config.image'
                      )
                        table.row
                          tbody
                            tr
                              th(
                                :class='{ "row-h-spacing": !row.config.fullWidth }',
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-image(
                                  :block='row',
                                  :format='format'
                                )
                              th.expander

                      // Block: Gallery
                      template(
                        v-if='isGalleryBlock(row.type) && row.config.imageList.length'
                      )
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-gallery(
                                  v-if='isAmp',
                                  :block='row'
                                )
                                ce-preview-block-image(
                                  v-else,
                                  :block='convertGalleryToImage(row)'
                                )
                              th.expander

                      // Block: ShopifyCart
                      template(
                        v-if='isShopifyCartBlock(row.type) && row.config.product'
                      )
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-shopify-cart(
                                  :block='row',
                                  :format='format',
                                  :projectId='projectId'
                                )

                      // Block: PrestashopCart
                      template(
                        v-if='isPrestashopCartBlock(row.type) && row.config.product_prestashop'
                      )
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-prestashop-cart(
                                  :block='row',
                                  :format='format',
                                  :projectId='projectId'
                                )

                      // Block: Review Form
                      template(v-if='isReviewBlock(row.type)')
                        table.row
                          tbody
                            tr
                              th.row-h-spacing(
                                :style='generateBlockStyle(row)'
                              )
                                ce-preview-block-review(
                                  :block='row',
                                  :format='format',
                                  :projectId='projectId'
                                )

                      // Block: Spacer
                      template(v-if='isSpacerBlock(row.type)')
                        table.spacer
                          tbody
                            tr
                              td(
                                :height='row.config.height',
                                :style='generateBlockStyle(row)'
                              )

                      // Block: Social
                      template(v-if='isSocialBlock(row.type)')
                        table.row
                          tbody
                            tr
                              td(
                                :style='generateBlockStyle(row)',
                                :align='row.config.alignment === "full" ? "center" : row.config.alignment'
                              )
                                ce-preview-block-social(
                                  :block='row',
                                  :format='format'
                                )

                      // Block: Text
                      template(v-if='isTextBlock(row.type)')
                        table.row
                          tbody
                            tr
                              component(
                                is='style',
                                type='text/css',
                                v-html='generateTextStyle(row)'
                              )
                              th.row-h-spacing.row-full(
                                v-html='row.content || ""',
                                :class='[row.id]',
                                :style='generateBlockStyle(row)'
                              )
                              th.expander

  slot(:srcdoc='srcdoc')
</template>

<script>
import { useTemplate, validateAmpContent } from '@plugins/content-editor/utils'
import { AMP_EXTENSIONS } from '@plugins/content-editor/const'

let ampExtensionsToInclude = []

export default {
  props: {
    body: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    onCompileContent: {
      type: Function,
      default: () => {},
    },
    projectId: {
      type: [String, Number],
      default: 0,
    },
  },

  data: () => ({
    srcdoc: '',
  }),

  computed: {
    isAmp () {
      return this.format === 'amp'
    },
  },

  mounted () {
    this.updateIframe()
  },

  updated () {
    this.updateIframe()
  },

  methods: {
    isButtonBlock: (type) => ['button'].includes(type),
    isDividerBlock: (type) => ['divider'].includes(type),
    isImageBlock: (type) => ['image'].includes(type),
    isSocialBlock: (type) => ['social'].includes(type),
    isSpacerBlock: (type) => ['spacer'].includes(type),
    isTextBlock: (type) => ['heading', 'paragraph'].includes(type),

    isCallmeBlock: (type) => {
      if (!['call_me'].includes(type)) return false
      ampExtensionsToInclude.push(AMP_EXTENSIONS.bind)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.form)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.mustache)
      return true
    },

    isDynamicPhone: (type) => {
      if (!['dynamic_phone'].includes(type)) return false
      ampExtensionsToInclude.push(AMP_EXTENSIONS.bind)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.form)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.list)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.mustache)
      return true
    },

    isGalleryBlock: (type) => {
      if (!['gallery'].includes(type)) return false
      ampExtensionsToInclude.push(AMP_EXTENSIONS.bind)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.form)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.carousel)
      return true
    },

    isShopifyCartBlock: (type) => {
      if (!['shopify_cart'].includes(type)) return false
      ampExtensionsToInclude.push(AMP_EXTENSIONS.accordion)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.bind)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.form)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.mustache)
      return true
    },

    isPrestashopCartBlock: (type) => {
      if (!['prestashop_cart'].includes(type)) return false
      ampExtensionsToInclude.push(AMP_EXTENSIONS.accordion)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.bind)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.form)
      return true
    },

    isReviewBlock: (type) => {
      if (!['review'].includes(type)) return false
      ampExtensionsToInclude.push(AMP_EXTENSIONS.bind)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.form)
      ampExtensionsToInclude.push(AMP_EXTENSIONS.mustache)
      return true
    },

    updateIframe () {
      ampExtensionsToInclude = [...new Set(ampExtensionsToInclude)] // remove duplicates

      const content = this.$refs['content-html']?.innerHTML || ''

      const emailContent = useTemplate({
        content,
        format: this.format,
        background: this.body.config.colors.backdrop || '',
        extensions: ampExtensionsToInclude,
      })

      if (this.isAmp) {
        validateAmpContent(emailContent)
      }

      this.srcdoc = emailContent
      this.onCompileContent(emailContent)
    },

    convertGalleryToImage (row) {
      return {
        ...row,
        config: {
          ...row.config,
          image: row.config.imageList[0],
        },
      }
    },

    generateBlockStyle ({ config }) {
      const style = {
        backgroundColor: config.colors.blockBackground,
        color: config.colors.text,
      }

      if (config.padding) {
        style.paddingBottom = config.padding.bottom + 'px'
        style.paddingTop = config.padding.top + 'px'
      }

      return style
    },

    generateDividerStyle ({ config }) {
      return {
        borderTopColor: config.colors.line,
        borderTopWidth: config.thickness + 'px',
        borderTopStyle: config.style,
      }
    },

    generateTextStyle ({ id, config }) {
      return `
        .${id} h1, .${id} h2, .${id} h3, .${id} h4, .${id} h5, .${id} h6, .${id} p {
          color: ${config.colors.text};
          font-size: ${config.fontSize}px;
          font-family: ${
            config.fontFamily || ''
          }, Helvetica, Roboto, Arial, sans-serif;
        }
        .${id} a {
          color: ${config.colors.links};
        }
      `
    },
  },
}
</script>

<style lang="sass" scoped>
.ContentCompiler
  height: 100%
</style>
