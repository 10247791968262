import { BLOCKTYPES_DEFAULTS } from '@plugins/content-editor/const'
import { cloneDeep } from 'lodash'
import amphtmlValidator from 'amphtml-validator'

export const generateUuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export const generateBlockId = () => {
  let dt = new Date().getTime()
  return 'bxxxyxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const useBlockDefaults = (type) => {
  return cloneDeep(BLOCKTYPES_DEFAULTS[type])
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const validateAmpContent = async (content) => {
  const validator = await amphtmlValidator.getInstance()
  const { errors, status } = validator.validateString(content, 'AMP4EMAIL')

  const isValid = status === 'PASS'
  !isValid && console.warn('AMP Validator Errors:', errors)

  return isValid
}

export const getCanvasBlob = (canvas) => {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob)
    })
  })
}
