import ApiClient from '@api/client'

const client = new ApiClient({ path: '/jobs' })

export const JobsService = {
  /**
   * Create Job
   * @param {Object} data
   * @return {Object} job
   */
  async create (data) {
    return await client.post('/', data)
  },

  /**
   * Get Job By Id
   * @param {Object} params
   * @param {String} params.id
   * @return {Object} job
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },

  /**
   * Delete Job
   * @param {String} id
   * @return {Object} job
   */
  async remove (id) {
    return await client.delete(`/${id}`)
  },

  /**
   * Update Job
   * @param {String} id
   * @return {Object} job
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
