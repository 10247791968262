<template lang="pug">
.BlockShopifyCart
  .BlockShopifyCartState(v-if='isAmp')
    amp-state#appliedDiscountState
      script(type='application/json') {{ appliedDiscountState }}

    amp-state(:id='`cart_${block.id}`')
      script(type='application/json') {{ cartState }}

    amp-state#order
      script(type='application/json') {}

    amp-state(:id='`productOrderStep_${block.id}`')
      script(type='application/json') 1

    amp-state(:id='`salePrice_${block.id}`')
      script(type='application/json') {{ salePrice }}

    amp-state(:id='`tags_${block.id}`')
      script(type='application/json') "{{ config.productTag }}"

    amp-bind-macro(
      :id='`findProduct_${block.id}`'
      arguments='productId',
      :expression='`cart_${block.id}.filter(product => product.id == productId)[0] || false`'
    )

    amp-bind-macro#calculateAddQuantity(
      arguments='quantity',
      expression='quantity <= 99 ? quantity + 1 : 99'
    )

    amp-bind-macro(
      :id='`addToCart_${block.id}`'
      arguments='productId',
      :expression='`cart_${block.id}.map(prod => prod.id == productId ? { id:productId, quantity:calculateAddQuantity(prod.quantity), price:prod.price, variant_id:prod.variant_id, name:prod.name} : prod)`'
    )

    amp-bind-macro#appliedDiscountToString(
      expression=`keys(appliedDiscountState).reduce((initString, key, index, arr) => initString + '"' + key + '":"' + appliedDiscountState[key] + '"' + (index < arr.length - 1 ? ',' : '}'), '{')`
    )

    amp-bind-macro#calculateSubstractQuantity(
      arguments='quantity',
      expression='quantity > 1 ? quantity - 1 : 1'
    )

    amp-bind-macro(
      :id='`productToString_${block.id}`'
      arguments='productId',
      :expression='productToString'
    )

    amp-bind-macro#cartToString(
      :expression='`cart_${block.id}.reduce((cartString, product, index, arr) => cartString + productToString(product.id) + (index < arr.length - 1 ? "," : "]"), "[")`'
    )

    amp-bind-macro#orderParamsToString(
      :expression='orderParamsToString'
    )

  .BlockShopifyCart-Step1(v-html='templateProductInfo')

  template(v-if='isAmp')
    .BlockShopifyCart-Step2(v-html='templateCartSummary')
</template>

<script>
import pug from 'pug'
import { calculatePriceWithDiscount } from '@utils'
import CONFIG_CONST from '@config/config.const'
import state, { getIntegration } from '@plugins/content-editor/state'
import pugCartSummary from '!!raw-loader!./templates/cart-summary.pug'
import pugProductInfo from '!!raw-loader!./templates/product-info.pug'
import getTexts from './texts'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      default: 'html',
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
  },

  data () {
    const AMP_EVENTS = state.ampBlockEvents.PRESTASHOP_CART
    const { BASE_URL_API } = CONFIG_CONST

    const blockId = this.block.id
    const config = this.block.config
    const content = this.block.content
    const product = this.block.config.product_prestashop || {}
    const { currency, id: productId, siteUrl, image: source } = product

    const TEXTS = getTexts()
    TEXTS.LABEL_VARIANT = content.variantLabel
    TEXTS.BUTTON_BUY = content.button

    const layerIsFull = this.block.config.layer === 'full'
    const isAmp = this.format === 'amp'

    let link = (isAmp || !config.link)
      ? `${siteUrl}/index.php?controller=cart&add=1&id_product=${productId}&qty=1`
      : (config.link || content.url)

    if (Object.keys(config.queryParams).length) {
      const url = new URL(link)
      const concatChar = url.searchParams.size ? '&' : '?'
      const queryParamsLink = new URLSearchParams(config.queryParams)
      link += `${concatChar}${queryParamsLink.toString()}`
    }

    const projectId = this.projectId
    const integrationId = getIntegration('prestashop').id

    const salePrice = config.productDiscount
      ? calculatePriceWithDiscount({
        price: content.price,
        discountType: config.productDiscount.type,
        discountValue: config.productDiscount.value
      })
      : false

    const productList = [
      {
        id: productId,
        name: content.name,
        quantity: 1,
        price: content.price,
      },
    ]

    const appliedDiscountState = JSON.stringify(
      config.productDiscount
        ? {
            value_type: config.productDiscount.type,
            value: config.productDiscount.value,
            amount: config.productDiscount.value,
            title: config.productDiscount.name,
          }
        : {}
    )

    const cartState = JSON.stringify(productList)

    const shippingAddressState = JSON.stringify({ country: 'spain' })

    const buttonStyles = {
      'background-color': config.colors.buttonBackground,
      border: 'none',
      color: config.colors.buttonText,
    }

    const templateVars = {
      AMP_EVENTS,
      BASE_URL_API,
      blockId,
      buttonStyles,
      content,
      currency,
      integrationId,
      isAmp,
      link,
      layerIsFull,
      productId,
      projectId,
      productList,
      salePrice,
      source,
      siteUrl,
      TEXTS,
    }

    const templateCartSummary = pug.render(pugCartSummary, templateVars)
    const templateProductInfo = pug.render(pugProductInfo, templateVars)

    return {
      appliedDiscountState,
      config,
      cartState,
      isAmp,
      salePrice,
      shippingAddressState,
      templateCartSummary,
      templateProductInfo,
    }
  },

  computed: {
    orderParamsToString () {
      return `
        '{"draft_order":{ "email": "%email%", "line_items":' + line_items + ', "tags": "' + tags_${this.block.id} + '"}}'
      `
    },
    productToString () {
      const productStringStart = this.salePrice
        ? "'{\"applied_discount\":' + applied_discount + ','"
        : "'{'"

      return `
        keys(findProduct_${this.block.id}(productId)).reduce(
          (productString, productKey, index, arr) =>
            productString
            + '"' + productKey + '":"' + findProduct_${this.block.id}(productId)[productKey] + '"'
            + (index < arr.length - 1 ? ',' : '}'),
          ${productStringStart}
        )
      `
    },
  },
}
</script>
