<template lang="pug">
ja-content(:pageTitle='$t("templates")')
  ja-template-deck(
    v-if='templates.length',
    selectableItem,
    :items='templates',
    :on-click='(item) => jaDetailView("template", item)'
  )
</template>

<script>
import state, { getTemplatesByProject } from './store/templates.store'

export default {
  name: 'TemplateList',

  computed: {
    templates: () => state.templates,
  },

  beforeCreate () {
    getTemplatesByProject()
  },
}
</script>
