import Vue from 'vue'
import { OrganizationService } from '../service/organizations.service'
import { AnalyticsService } from '@api/services/analytics.service'
import { setQueryParam, useLoader } from '@apps/Common/common.store'
import {
  transformBillingCycleForTable,
  transformOrganizationDataUsageForApexChart,
  transformOrganizationDataUsageForTable,
  transformBillingCycleForApexChart,
  transformBillingCycleForSummary,
} from '@/data-transformers/plan-usage'

// state
const state = Vue.observable({
  dataUsageOrganization: {},
  dataUsageBillingCycle: {},
  organization: null,
  organizations: {},
  project: null,
  projects: {},
  stripe: null,
  team: {},
  teams: {},
  user: {},
  users: {},
})

// actions
export const createOrganization = async (data) => {
  return await useLoader(OrganizationService.create, data)
}

export const deleteOrganizationById = async (id) => {
  return await useLoader(OrganizationService.delete, id)
}

export const getOrganizations = async ({ pagination } = {}) => {
  const organizations = await useLoader(OrganizationService.getAll, pagination)
  if (!organizations?.items) return
  organizations.items.sort((item1, item2) => item2.id - item1.id)
  Vue.set(state, 'organizations', organizations)
  return organizations
}

export const getOrganizationById = async (id, reload) => {
  const organization = await useLoader(OrganizationService.getById, id)
  if (!organization) return

  Vue.set(state, 'organization', organization)
  Vue.set(state, 'dataUsageBillingCycle', transformBillingCycleForSummary(state.organization.subscription))
  setQueryParam('id_organization', state.organization.name)

  return organization
}

export const getOrganizationDataUsage = async (id) => {
  const dataUsage = await useLoader(AnalyticsService.getPlanUsageByOrganizationId, id)
  if (!dataUsage) return

  Vue.set(state, 'dataUsageOrganization', {
    ...transformOrganizationDataUsageForApexChart(dataUsage),
    billingCycles: transformOrganizationDataUsageForTable(dataUsage)
  })

  const currentBillingCycle = dataUsage.find(
    billingCycle => billingCycle.id === state.organization.subscription.id
  )

  Vue.set(state, 'dataUsageBillingCycle', transformBillingCycleForSummary(currentBillingCycle))

  return dataUsage
}

export const getBillingCylcle = async (subscriptionId) => {
  const billingCycle = await useLoader(AnalyticsService.getPlanUsageByBillingCycle, subscriptionId)
  if (!billingCycle) return

  const extendedBillingCycle = transformBillingCycleForSummary(billingCycle)

  Vue.set(state, 'dataUsageBillingCycle', {
    ...extendedBillingCycle,
    ...transformBillingCycleForApexChart(billingCycle),
    projects: transformBillingCycleForTable(billingCycle)
  })

  setQueryParam('id_billing_cycle', extendedBillingCycle.name)

  return extendedBillingCycle
}

export const getProjectsByOrganizationId = async (id, { pagination } = {}) => {
  const projects = await useLoader(
    OrganizationService.getProjectsByOrganizationId,
    id,
    pagination
  )

  if (!projects?.items) return

  projects.items.sort((item1, item2) => item2.id - item1.id)

  Vue.set(state, 'projects', projects)
  Vue.set(state, 'organization', projects.organization)
  setQueryParam('id_organization', state.organization.name)

  return projects
}

export const getStripeCustomerPortalSession = async (id, returnUrl) => {
  const stripeCustomerPortal = await useLoader(OrganizationService.getStripeCustomerPortalSession, id, returnUrl)
  Vue.set(state, 'stripe', { ...state.stripe, ...stripeCustomerPortal })
  return stripeCustomerPortal
}

export const getTeamsByOrganizationId = async (id, { pagination } = {}) => {
  const teams = await useLoader(
    OrganizationService.getTeamsByOrganizationId,
    id,
    pagination
  )

  if (!teams) return

  Vue.set(state, 'teams', teams)
  Vue.set(state, 'organization', teams.organization)
  setQueryParam('id_organization', state.organization.name)

  return teams
}

export const getUsersByOrganizationId = async (id, { pagination } = {}) => {
  const users = await useLoader(
    OrganizationService.getUsersByOrganizationId,
    id,
    pagination
  )

  if (!users) return

  Vue.set(state, 'users', users)
  Vue.set(state, 'organization', users.organization)
  setQueryParam('id_organization', state.organization.name)

  return users
}

export const updateOrganizationById = async (id, data) => {
  const organization = await useLoader(OrganizationService.update, id, data)
  Vue.set(state, 'organization', organization)
  return organization
}

export default state
