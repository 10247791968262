<template lang="pug">
td.TableTd
  // Icon
  i.TableTd-Icon(v-if="icon" :class="icon")

  // Content
  template(v-for="item in items")

    // Text
    template(v-if="typeof item === 'string' || typeof item === 'number'")
      span {{ item }}

    // Text
    template(v-else-if="item.type === 'badge'")
      ja-badge(:value="item.value" :variant="item.variant || ''")

    // Selector
    template(v-else-if="item.type === 'selector'")
      b-form-select(
        v-model="item.value"
        :key="jaGenerateUuid()",
        :options="item.options",
        @change="(value) => handleChange({ value, item })"
      )

    //SmartCheck
    template(v-else-if="item.type === 'smart-check'")
      ja-smart-check(
        :checkbox="item.checkbox",
        :info="item.info",
        :icon="item.icon",
        :subtitle="item.subtitle",
        :title="item.title",
        :to="item.to",
        :key="jaGenerateUuid()",
      )

    // Checkbox
    template(v-else-if="item.type === 'checkbox'")
      ja-checkbox(
        :icon="item.value",
        :label="item.label",
        :value="item.value",
        :key="jaGenerateUuid()",
        :on-change="(value) => handleChange({ value, item })"
      ) {{ item.label }}

    // Permission Group
    template(v-else-if="item.type === 'permission-group'")
      ja-permission-group(
        :on-click="() => handleClick(item)"
        :permissions="item.items"
        :key="jaGenerateUuid()"
      )
</template>

<script>
export default {
  name: 'TableTd',

  props: {
    cell: {
      type: [Array, Object, Number, String],
      required: true,
    },
    icon: {
      type: [String, null],
      default: null
    },
    name: {
      type: [String, null],
      default: null
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    onClick: {
      type: Function,
      default: () => {}
    },
  },

  computed: {
    items () {
      const items = []

      if (!this.jaIsArray(this.cell)) {
        if (typeof this.cell === 'object' && this.name) {
          items.push({
            ...this.cell,
            name: this.name,
          })
        } else {
          items.push(this.cell)
        }
      } else {
        items.push(...this.cell)
      }

      return items
    }
  },

  methods: {
    handleChange (data) {
      this.onChange(data)
    },
    handleClick (item) {
      this.onClick(item)
    },
  }
}
</script>

<style lang="sass" scoped>
.TableTd
  &-Icon
    font-size: 20px
    color: #ccc
</style>
