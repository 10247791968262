import Vue from 'vue'
import { SmtpProvidersService } from '../service/smtp-providers.service'
import common, { setQueryParam, useLoader } from '@apps/Common/common.store'

// state
const state = Vue.observable({
  smtpProvider: {},
  smtpProviderTypes: [],
})

// actions
export const getSmtpProviderById = async (id) => {
  const smtpProvider = await useLoader(SmtpProvidersService.getById, id)
  if (!smtpProvider) return
  Vue.set(state, 'smtpProvider', smtpProvider)
  setQueryParam('id_smtp-provider', state.smtpProvider.name)
  return smtpProvider
}

export const findSmtpProviderType = (smtpProviderTypeName) => {
  return state.smtpProviderTypes.find(
    (smtpProviderType) => smtpProviderType.name === smtpProviderTypeName
  )
}

export const initProvider = async (name) => {
  await getSmtpProviderTypes()

  const provider_type = findSmtpProviderType(name)

  Vue.set(state, 'smtpProvider', {
    config: {},
    name,
    provider_type,
  })
}

export const getSmtpProviderTypes = async () => {
  const smtpProviderTypes = await useLoader(SmtpProvidersService.getTypes)
  if (!smtpProviderTypes) return
  Vue.set(state, 'smtpProviderTypes', smtpProviderTypes)
  return smtpProviderTypes
}

export const createSmtpProvider = async (data) => {
  return await useLoader(SmtpProvidersService.create, {
    ...data,
    type_id: data.provider_type.id,
    project_id: common.user.project_id,
  })
}

export const deleteSmtpProviderById = async (id) => {
  return await useLoader(SmtpProvidersService.delete, id)
}

export const updateSmtpProviderById = async (id, data) => {
  return await useLoader(SmtpProvidersService.update, id, {
    ...data,
    type_id: data.provider_type.id,
    project_id: common.user.project_id,
  })
}

export default state
