<template lang="pug">
.BlockButton(:style='cssVars')
  .BlockButton-Button
    ce-text-editor(
      :key='keyToForceRender',
      :value='block.content',
      :type='block.type',
      :on-change='onChange'
    )
</template>

<script>
import { sleep } from '@/utils'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      keyToForceRender: 0,
    }
  },

  computed: {
    width () {
      return this.block.config.alignment === 'full' ? '100%' : 'auto'
    },

    cssVars () {
      const alignment = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
      }

      const shape = {
        pill: '80px',
        round: '5px',
        square: '0px',
      }

      return {
        '--font-family': this.block.config.fontFamily,
        '--font-size': this.block.config.fontSize + 'px',
        '--alignment': alignment[this.block.config.alignment],
        '--shape': shape[this.block.config.shape],
        '--width': this.width,
        '--button-color': this.block.config.colors.button,
        '--border-color':
          this.block.config.colors.border || this.block.config.colors.button,
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
        '--text-color': this.block.config.colors.text,
      }
    },
  },

  async created () {
    // Force re-rendering component to relocate the ckeditor toolbar after the enter animation
    await sleep(500)
    ++this.keyToForceRender
  },
}
</script>

<style lang="sass" scoped>
.BlockButton
  cursor: default
  display: flex
  justify-content: var(--alignment, center)
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-Button
    background-color: var(--button-color)
    border-radius: var(--shape)
    border: 2px solid var(--border-color)
    box-sizing: border-box
    color: var(--text-color)
    cursor: text
    font-family: var(--font-family)
    font-size: var(--font-size)
    padding: .15em 1.75em
    text-align: center
    width: var(--width)
    min-width: 240px
</style>
