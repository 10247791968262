<template lang="pug">
.PageContent
  .PageContent-Header
    ja-title(
      v-if='pageTitle',
      :is-title-editable='isPageTitleEditable',
      :on-change-title='onChangePageTitle',
      :actions='pageActions',
      :title='pageTitle'
    )

    ja-subtitle(v-if='pageSubtitle', :subtitle='pageSubtitle')

  .PageContent-Body
    slot
</template>

<script>
export default {
  name: 'Content',
  props: {
    isPageTitleEditable: {
      type: Boolean,
      default: false,
    },
    onChangePageTitle: {
      type: Function,
      default: () => {},
    },
    pageActions: {
      type: Array,
      default: () => [],
    },
    pageSubtitle: {
      type: [String, null],
      default: null,
    },
    pageTitle: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style lang="stylus" scoped>
.PageContent

  &-Header
    margin-bottom: 1.6em
</style>
