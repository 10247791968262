// Components
import OrganizationBillingCycle from './Organizations/OrganizationBillingCycle.vue'
import OrganizationEdit from './Organizations/OrganizationEdit'
import OrganizationList from './Organizations/OrganizationList'
import OrganizationOverview from './Organizations/OrganizationOverview'
import OrganizationPlan from './Organizations/OrganizationPlan'
import OrganizationUsage from './Organizations/OrganizationUsage'
import ProjectList from './Projects/ProjectList'
import ProjectOverview from './Projects/ProjectOverview'
import ProjectEdit from './Projects/ProjectEdit'
import TeamList from './Teams/TeamList'
import TeamOverview from './Teams/TeamOverview'
import TeamEdit from './Teams/TeamEdit'
import UserList from './Users/UserList'
import UserOverview from './Users/UserOverview'
import UserInvite from './Users/UserInvite'

// Sidebar Links
import sidebar from './organizations.sidebar'

// Topbar Links
import topbar from '../accounts.topbar'

// Parent
const parent = 'organizations'

// Breacrumb Level
const breadcrumbLevel = ({
  hasParent = false,
  hasTo = false,
  isId = false,
  name = '',
  plural = true,
}) => ({ hasParent, hasTo, name, plural })

// Breacrumb Root
const breadcrumbRoot = breadcrumbLevel({
  hasParent: true,
  hasTo: true,
  name: 'organization',
})

export default [
  // Organization List (Default)
  {
    name: 'organizations',
    path: '/organizations',
    component: OrganizationList,
    meta: {
      breadcrumb: [breadcrumbLevel({ name: 'organization' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_organization',
        to: '/organizations/new',
        type: 'outline-secondary'
      },
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Organization New
  {
    name: 'organization-new',
    path: '/organizations/new',
    component: OrganizationEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'new_organization' },
      ],
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Organization Details
  {
    name: 'organization',
    path: '/organizations/:id_organization',
    component: OrganizationOverview,
    meta: {
      breadcrumb: [breadcrumbRoot],
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_organization',
        to: '/organizations/:id_organization/manage',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },
  // Organization Edit
  {
    name: 'organization-manage',
    path: '/organizations/:id_organization/manage',
    component: OrganizationEdit,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'manage_organization' }],
      parent,
      sidebar,
      topbar,
    },
  },
  // Organization PLan & Billing
  {
    name: 'organization-plan',
    path: '/organizations/:id_organization/plan',
    component: OrganizationPlan,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'plan_billing' }],
      parent,
      sidebar,
      topbar,
    },
  },
  // Organization Usage
  {
    name: 'organization-usage',
    path: '/organizations/:id_organization/usage',
    component: OrganizationUsage,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'usage' }],
      parent,
      sidebar,
      topbar,
    },
  },
  // Organization Billing Cycle
  {
    name: 'billing-cycle',
    path: '/organizations/:id_organization/usage/:id_billing_cycle',
    component: OrganizationBillingCycle,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        { name: 'usage', hasTo: true },
        { name: 'billing_cycle', isId: true },
      ],
      parent,
      sidebar,
      topbar,
    },
  },
  // Organization Projects list
  {
    name: 'projects',
    path: '/organizations/:id_organization/projects',
    component: ProjectList,
    meta: {
      breadcrumb: [breadcrumbRoot, breadcrumbLevel({ name: 'project' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_project',
        to: '/organizations/:id_organization/projects/new',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },
  // Project New
  {
    name: 'project-new',
    path: '/organizations/:id_organization/projects/new',
    component: ProjectEdit,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'project', hasTo: true }),
        { name: 'new_project' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },
  // Project Manage
  {
    name: 'project-manage',
    path: '/organizations/:id_organization/projects/:id_project/manage',
    component: ProjectEdit,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'project', hasParent: true, hasTo: true }),
        { name: 'manage_project' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },
  // Project Overview
  {
    name: 'project',
    path: '/organizations/:id_organization/projects/:id_project',
    component: ProjectOverview,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'project', hasParent: true, hasTo: true }),
      ],
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_project',
        to: '/organizations/:id_organization/projects/:id_project/manage',
        type: 'outline-secondary'
      },
      parent,
      sidebar: { ...sidebar, parent: 'projects' },
      topbar,
    },
  },
  // Organization Teams
  {
    name: 'teams',
    path: '/organizations/:id_organization/teams',
    component: TeamList,
    meta: {
      breadcrumb: [breadcrumbRoot, breadcrumbLevel({ name: 'team' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_team',
        to: '/organizations/:id_organization/teams/new',
        type: 'outline-secondary'
      },
      parent,
      sidebar,
      topbar,
    },
  },

  // Team New
  {
    name: 'team-new',
    path: '/organizations/:id_organization/teams/new',
    component: TeamEdit,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'team', hasTo: true }),
        { name: 'new_team' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },

  // Team Edit
  {
    name: 'team-manage',
    path: '/organizations/:id_organization/teams/:id_team/manage',
    component: TeamEdit,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'team', hasParent: true, hasTo: true }),
        { name: 'manage_team' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },

  // Team Overview
  {
    name: 'team',
    path: '/organizations/:id_organization/teams/:id_team',
    component: TeamOverview,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'team', hasParent: true, hasTo: true }),
      ],
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_team',
        to: '/organizations/:id_organization/teams/:id_team/manage',
        type: 'outline-secondary'
      },
      parent,
      sidebar: { ...sidebar, parent: 'teams' },
      topbar,
    },
  },

  // Organization Users
  {
    name: 'users',
    path: '/organizations/:id_organization/users',
    component: UserList,
    meta: {
      breadcrumb: [breadcrumbRoot, breadcrumbLevel({ name: 'user' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'invite_user',
        to: '/organizations/:id_organization/users/invite',
        type: 'outline-primary'
      },
      parent,
      sidebar,
      topbar,
    },
  },

  // User Invite
  {
    name: 'user-invite',
    path: '/organizations/:id_organization/users/invite',
    component: UserInvite,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'user', hasTo: true }),
        { name: 'invite_user' },
      ],
      parent,
      sidebar,
      topbar,
    },
  },

  {
    name: 'user',
    path: '/organizations/:id_organization/users/:id_user',
    component: UserOverview,
    meta: {
      breadcrumb: [
        breadcrumbRoot,
        breadcrumbLevel({ name: 'user', hasParent: true, hasTo: true }),
      ],
      parent,
      sidebar: { ...sidebar, parent: 'users' },
      topbar,
    },
  },
]
