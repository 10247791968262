import Login from './Login.vue'
import ForgotPassword from './ForgotPassword.vue'
import ProjectSelection from './ProjectSelection.vue'
import Register from './Register.vue'
import RegisterOrganization from './RegisterOrganization.vue'
import ResetPassword from './ResetPassword.vue'

export default [
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      isAuth: true,
    },
  },

  {
    name: 'login-invite',
    path: '/login/:token',
    component: Login,
    meta: {
      isAuth: true,
    },
  },

  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      isAuth: true,
    },
  },

  {
    name: 'project-selection',
    path: '/project-selection',
    component: ProjectSelection,
    meta: {
      isAuth: false,
    },
  },

  {
    name: 'register',
    path: '/register',
    component: Register,
    meta: {
      isAuth: true,
    },
  },

  {
    name: 'register-invite',
    path: '/register/:token',
    component: Register,
    meta: {
      isAuth: true,
    },
  },

  {
    name: 'register-organization',
    path: '/register-organization',
    component: RegisterOrganization,
    meta: {
      isAuth: false
    }
  },

  {
    name: 'reset-password',
    path: '/reset-password/:token',
    component: ResetPassword,
    meta: {
      isAuth: true,
    },
  },
]
