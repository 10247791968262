<template lang="pug">
.DataOne.card
  .card-body
    .d-flex.flex-row
      .DataOne-Icon.round.round-lg.text-white.d-inline-block.text-center.rounded-circle
        i(:class='[icon, "mr-0"]')
      .DataOne-Text.ml-3.align-self-center
        h3.mb-0 {{ value }}
        h5.text-muted {{ $t(name) }}
</template>

<script>
export default {
  name: 'DataOne',
  props: {
    icon: {
      type: [String, null],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.DataOne
  text-transform: capitalize

  &-Icon
    background: #9175e7
    line-height: 62px
    min-height: 60px
    min-width: 60px
</style>
