import ApiClient from '@api/client'

const commons = new ApiClient({ path: '' })

export const CommonsService = {
  /**
   * Get Time Zones
   * @return {Array} time zones list
   */
  async getTimeZones () {
    return await commons.get('/timezones')
  },
}
