<template lang="pug">
ja-content(
  :page-actions='pageActions',
  :page-title='campaign.name',
  :page-subtitle='campaign.description'
)
  // Campaign Settings
  b-card
    b-card-text
      form.form
        // To (email recipients)
        ja-form-field#to(label='to', :field-value='audience.name', type='info')

        // From Name
        ja-form-field#fromName(
          label='sender_name',
          :field-value='campaign.from_name',
          type='info'
        )

        // From Email
        ja-form-field#from(
          label='sender_email',
          :field-value='campaign.from',
          type='info'
        )

        // Subject
        ja-form-field#subject(
          label='subject',
          :field-value='campaign.subject',
          type='info'
        )

        // Content
        ja-form-field(label='content', v-if='campaignContent')
          template(v-slot:value)
            ja-template-card(
              :image='campaignContent.preview',
              :overlay-text='$t("edit_content")',
              :to='isCampaignEditable && contentEditorRoute'
            )

  campaign-scheduler(
    ref='campaign-scheduler'
    v-model='scheduleSettings'
    :contacts='contacts'
    :on-save='scheduleCampaign'
  )
</template>

<script>
import state, {
  createCampaignScheduler,
  getCampaignById,
  scheduleCampaign,
  updateCampaignById
} from './store/campaigns.store'

import { setNavbuttonEnabled } from '@apps/Common/common.store'
import CampaignScheduler from './components/CampaignScheduler.vue'
import dayjs from 'dayjs'

export default {
  name: 'CampaignOverview',

  components: { CampaignScheduler },

  data () {
    const scheduleDate = dayjs().format('YYYY-MM-DD')
    const scheduleTime_dayjs = dayjs().add(20, 'minute')
    let scheduleTimeMinutes = scheduleTime_dayjs.format('mm')
    scheduleTimeMinutes = Number(scheduleTimeMinutes)
    scheduleTimeMinutes = Math.floor(scheduleTimeMinutes / 10) * 10

    const scheduleTime = `${scheduleTime_dayjs.format('HH')}:${scheduleTimeMinutes}:00`

    const scheduleSettings = {
      batches: 1,
      batchMinutes: 5,
      batchItems: 1,
      scheduleDate,
      scheduleTime,
    }

    return {
      directlySent: false,
      scheduleSettings,
    }
  },

  computed: {
    campaign: () => state.campaign,

    campaignContent: () => state.campaign?.campaign_content,

    audience () {
      if (!this.campaign.audiences?.length) return {}
      return this.campaign.audiences[0]
    },

    contacts () {
      return this.audience.contacts || 0
    },

    contentEditorRoute () {
      return {
        name: 'campaign-content-editor',
        params: { id_campaign: this.$route.params.id_campaign },
      }
    },

    isCampaignEditable () {
      return !this.directlySent && ['draft', 'scheduled'].includes(this.campaign.status)
    },

    isCampaignScheduled () {
      return this.campaign.status === 'scheduled'
    },

    pageActions () {
      const pageActions = []

      const buttonStartCampaign = {
        callback: this.startCampaign,
        icon: 'ti-control-play',
        text: 'send',
        type: 'outline-primary',
      }

      const buttonOpenCampaignScheduler = {
        callback: this.toggleCampaignScheduler,
        icon: 'mdi mdi-calendar-clock',
        text: this.isCampaignScheduled ? 'reschedule' : 'schedule',
        type: 'outline-primary',
      }

      const buttonGoToCampaignReport = {
        callback: this.goToCampaignReport,
        icon: 'mdi mdi-file-chart',
        text: 'report',
        type: 'outline-primary',
      }

      if (!this.isCampaignEditable) {
        pageActions.push(buttonGoToCampaignReport)
        return pageActions
      }

      if (!this.isCampaignScheduled) {
        pageActions.push(buttonStartCampaign)
      }

      pageActions.push(buttonOpenCampaignScheduler)

      return pageActions
    },
  },

  async created () {
    const campaign = await getCampaignById(this.$route.params.id_campaign)

    if (!this.isCampaignEditable) {
      setNavbuttonEnabled(false)
      return
    }

    // Recover campaign.jon settings
    if (campaign.job) {
      const {
        batches,
        interval_minutes: batchMinutes,
        batch_items: batchItems,
        start_at,
      } = this.campaign.job

      const startAt = dayjs(start_at)
      const scheduleDate = startAt.format('YYYY-MM-DD')
      const scheduleTime = startAt.format('HH:mm:ss')

      this.$set(this, 'scheduleSettings', {
        batches,
        batchMinutes,
        batchItems,
        scheduleDate,
        scheduleTime,
      })
    }
  },

  beforeDestroy () {
    setNavbuttonEnabled(true)
  },

  methods: {
    async startCampaign () {
      const start_at = new Date().toISOString()

      await this.submitCampaign({
        start_at,
        interval_minutes: 10,
        batch_items: this.audience.contacts,
        batches: 1,
      })

      this.$set(this, 'directlySent', true)
    },

    async scheduleCampaign () {
      const {
        scheduleDate,
        scheduleTime,
        batchMinutes: interval_minutes,
        batchItems: batch_items,
        batches,
      } = this.scheduleSettings

      const start_at = new Date(`${scheduleDate} ${scheduleTime}`).toISOString()

      await this.submitCampaign({
        start_at,
        interval_minutes,
        batch_items,
        batches,
      })
    },

    async submitCampaign (scheduleSettings) {
      const campaignScheduler = await createCampaignScheduler(scheduleSettings)

      await updateCampaignById(this.campaign.id, {
        ...this.campaign,
        job_id: campaignScheduler.id
      })

      await scheduleCampaign(this.campaign.id)

      this.toggleCampaignScheduler()

      this.$router.push({
        name: 'campaign',
        params: {
          id_campaign: this.campaign.id
        },
        query: { status: 'scheduled' }
      })
    },

    goToCampaignReport () {
      this.$router.push({
        name: 'campaign-report',
        params: { id_campaign: this.campaign.id },
      })
    },

    toggleCampaignScheduler () {
      this.$refs['campaign-scheduler'].open()
    },
  },
}
</script>
