<template lang="pug">
.PanelBody
  ce-input-wrapper(
    v-if='areThereAnyConfig',
    v-for='(value, key, index) in config',
    :key='index',
    :field='key',
    :inputs='value',
    :on-change='onChange'
  )

  .PanelBody-Empty(v-if='!areThereAnyConfig')
    p To edit content, hover over the canvas and select a block.
    p To add content, hover over the canvas to find the ➕ button.
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    areThereAnyConfig () {
      return Object.keys(this.config).length > 0
    },
  },
}
</script>

<style lang="sass">
@import "@plugins/content-editor/styles/variables"

.PanelBody
  height: 100%
  width: 100%
  background-color: $white
  border-bottom-left-radius: 5px
  border-bottom-right-radius: 5px
  padding-bottom: 2vh

  &-Empty
    margin: 32px 22px 0
</style>
