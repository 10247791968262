<template lang="pug">
.AddBlockTool
  .AddBlockTool-Overlay(v-if='showTooltip', ref='overlay')

  ce-add-block-button(
    :on-click='handleClickAddBlockButton',
    :id='targetTooltip'
  )

  ce-tooltip(:show='showTooltip', :target='targetTooltip')
    ce-new-block-button-list(
      :blocktypes='blocktypes',
      :onButtonClick='addNewBlock'
    )
</template>

<script>
import state, {
  actionCreateNewBlock,
  resetActiveBlock,
  resetOveredBlock,
} from '@plugins/content-editor/state'

import { BLOCKTYPES_ADDIBLE } from '@plugins/content-editor/const'

export default {
  data () {
    return {
      showTooltip: false,
      targetTooltip: 'add-button',
      blocktypes: BLOCKTYPES_ADDIBLE,
    }
  },

  computed: {
    block () {
      return state.activeBlock
    },
  },

  beforeMount () {
    document.addEventListener('click', this.handleClickDocumentToHide)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.handleClickDocumentToHide)
  },

  methods: {
    addNewBlock (type) {
      actionCreateNewBlock(type)
      this.closeAddBlockTooltip()
    },

    handleClickAddBlockButton () {
      resetActiveBlock()
      this.showTooltip = !this.showTooltip
    },

    handleClickDocumentToHide (event) {
      if (
        this.showTooltip &&
        (!this.$el.contains(event.target) ||
          event.target === this.$refs.overlay)
      ) {
        this.closeAddBlockTooltip()
      }
    },

    closeAddBlockTooltip () {
      this.showTooltip = false
      resetOveredBlock()
    },
  },
}
</script>

<style lang="sass" scoped>
.AddBlockTool
  &-Overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
</style>
