import dayjs from 'dayjs'

export const formatDate = ({ date, format = 'EUROPE_FULL_DATE' }) => {
  const CUSTOM_FORMATS = {
    // Regional Date Formats
    EUROPE_FULL_DATE: 'DD/MM/YYYY HH:mm',
    EUROPE_SHORT_DATE: 'DD/MM/YYYY',
    ISO_DATE: 'YYYY-MM-DD',
    ISO_DATE_TIME: 'YYYY-MM-DDTHH:mm:ss',
    US_FULL_DATE: 'MM/DD/YYYY HH:mm',
    US_SHORT_DATE: 'MM/DD/YYYY',

    // Time
    TIME_24HR: 'HH:mm',

    // Date in String Formats
    MONTH_DAY_YEAR: 'MMMM DD, YYYY',
    MONTH_SHORT_DAY: 'MMM DD',
    MONTH_NAME_YEAR: 'MMMM YYYY',
  }

  return dayjs(date).format(CUSTOM_FORMATS[format] || format)
}
