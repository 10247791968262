<template lang="pug">
div
  #main-wrapper(:class='[{ "single-column": !sidebarEnabled }]')
    // Header
    ja-header

    transition(enter-active-class='animate__animated animate__fadeIn')
      .content-page
        ja-sidebar(v-show='sidebarEnabled')

        .page-wrapper
          ja-preloader.m-t-60(v-if='loading')

          .container-fluid
            ja-nav-header(
              :breadcrumb='breadcrumb',
              :navbutton='navbutton',
              :vars='vars'
            )

            router-view(:key='$route.fullPath')

            ja-alert(
              v-for='(error, index) in errors'
              type='danger'
              :content='error.message'
              :id='error.uid'
              :key='index',
              :on-discard='onDiscardAlert'
            )
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Page',

  props: {
    breadcrumb: {
      type: [Array, null],
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    navbutton: {
      type: [Object, Boolean, null],
      default: null,
    },
    onDiscardAlert: {
      type: Function,
      default: () => {},
    },
    sidebarEnabled: {
      type: Boolean,
      default: false,
    },
    vars: {
      type: Object,
      default: () => ({}),
    },
  },

  beforeMount () {
    $(window).on('resize', this.set)
  },

  mounted () {
    this.set()
  },

  methods: {
    /**
     * Set
     * material-pro/horizontal/js/custom.js:18
     */
    set () {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width
      const topOffset = 0
      if (width < 1170) {
        $('body').addClass('mini-sidebar')
        $('.page-wrapper').addClass('mini-sidebar')
        $('.sidebar-nav .sidebarnav li a span').addClass('hide-menu')
        $('.sidebartoggler i').addClass('ti-menu').parent().css('display', '')
      } else {
        $('body').removeClass('mini-sidebar')
        $('.page-wrapper').removeClass('mini-sidebar')
        $('.sidebar-nav .sidebarnav li a span').removeClass('hide-menu')
        $('.sidebartoggler i')
          .removeClass('ti-menu')
          .parent()
          .css('display', 'none')
      }

      let height =
        (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1
      height = height - topOffset
      if (height < 1) height = 1
      if (height > topOffset) {
        $('.page-wrapper').css('min-height', height + 'px')
      }
    },
  },
}
</script>

<style lang="sass">
@import '../../assets/scss/main.scss'

.page-wrapper
  padding-top: 60px
  height: 100%
</style>
