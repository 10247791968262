<template lang="pug">
ja-content(:pageActions='pageActions', :pageTitle='pageTitle')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        // Email
        ja-form-field#email(
          :fieldValue='fieldEmail',
          :on-change='handleChangeEmail',
          ref='inputEmail',
          label='email',
          type='text',
          placeholder='email_placeholder'
        )

        // First Name
        ja-form-field#name(
          :fieldValue='fieldName',
          :on-change='handleChangeName',
          ref='inputName',
          label='first_name',
          type='text',
          placeholder='name_placeholder'
        )

        // Surname
        ja-form-field#name(
          :fieldValue='fieldSurname',
          :on-change='handleChangeSurname',
          ref='inputSurname',
          label='surname',
          type='text',
          placeholder='surname_placeholder'
        )

        // Phone
        ja-form-field#phone(
          :fieldValue='fieldPhone',
          :on-change='handleChangePhone',
          ref='inputPhone',
          label='phone',
          type='text',
          placeholder='phone_placeholder'
        )

        // Birthdate
        ja-form-field#phone(
          :fieldValue='fieldBirthdate',
          :on-change='handleChangeBirthdate',
          ref='inputBirthdate',
          label='birthdate',
          type='text',
          placeholder='birthdate_placeholder'
        )
</template>

<script>
import state, {
  getContactById,
  updateContactById,
  deleteContactById,
  createContact,
} from '@apps/Dymal/Audiences/Contacts/store/contacts.store'

import { getAudienceById } from '@apps/Dymal/Audiences/Audiences/store/audiences.store'

export default {
  name: 'ContactOverview',
  data () {
    return {
      fieldBirthdate: '',
      fieldEmail: '',
      fieldName: '',
      fieldPhone: '',
      fieldSurname: '',

      pageActions: [],
      pageTitle: this.$t('new_contact'),
      showForm: false,
    }
  },

  computed: {
    contact () {
      return state.contact
    },
    contactData () {
      return {
        audience_id: this.$route.params.id_audience,
        first_name: this.fieldName,
        surname: this.fieldSurname,
        email: this.fieldEmail,
        phone: this.fieldPhone,
        birthdate: this.fieldBirthdate,
      }
    },
    isNew () {
      return !this.$route.params.id_contact
    },
  },

  async created () {
    // Add action buttons to create a new organization
    if (this.isNew) {
      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      // Load the project data
      await getAudienceById(this.$route.params.id_audience)

      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push({
      callback: this.delete,
      icon: 'ti-trash',
      text: 'delete',
      type: 'outline-danger',
    })
    this.pageActions.push({
      callback: this.update,
      icon: 'ti-save',
      text: 'update',
      type: 'outline-primary',
    })

    // Load the contact data
    await getContactById(this.$route.params.id_contact)

    // Load the organization data
    this.fieldName = this.contact?.first_name
    this.fieldSurname = this.contact?.surname
    this.fieldEmail = this.contact?.email
    this.fieldPhone = this.contact?.phone
    this.fieldBirthdate = this.contact?.birthdate
    this.pageTitle = this.contact?.email
    this.showForm = true
  },

  mounted () {
    if (this.isNew) {
      this.$refs.inputEmail.$el.querySelector('input').focus()
    }
  },

  methods: {
    handleChangeEmail (value) {
      this.fieldEmail = value
    },

    handleChangeName (value) {
      this.fieldName = value
    },

    handleChangeSurname (value) {
      this.fieldSurname = value
    },

    handleChangePhone (value) {
      this.fieldPhone = value
    },

    handleChangeBirthdate (value) {
      this.fieldBirthdate = value
    },

    async delete () {
      await deleteContactById(this.contact.id)
      this.$router.push({ name: 'contacts' })
    },

    async save () {
      const response = await createContact(this.contactData)

      // Go to the new project page
      this.$router.push({
        name: 'contact',
        params: {
          id_audience: this.$route.params.id_audience,
          id_contact: response.id,
        },
      })
    },

    async update () {
      await updateContactById(this.contact.id, this.contactData)

      // Go back the project overview page
      this.$router.push({
        name: 'contact',
        params: {
          id_audience: this.$route.params.id_audience,
          id_contact: this.contact.id,
        },
      })
    },

    submit () {
      if (this.isNew) this.save()
      else this.update()
    },
  },
}
</script>
