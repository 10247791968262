<template lang="pug">
  .card
    .card-body
      .card-title(v-if="title")
      .card-subtitle(v-if="subtitle")
      slot
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: { type: String, required: false, default: '' },
    subtitle: { type: String, required: false, default: '' },
  }
}
</script>
