<template lang="pug">
  // Content menu
  div.col-md-5.col-8.align-self-center.menu-wrapper
    li.nav-item(:class="show && 'show'")
      a.nav-link.dropdown-toggle.text-muted.waves-effect.waves-dark(@click="show = !show")
        i.mdi.mdi-dots-vertical.content-menu
      .dropdown-menu.dropdown-menu-right.scale-up.show(v-show="show")
        ul.dropdown-user
          li
            a.dropdown-item(href="#")
              i.ti-user
              span Edit
          li.divider
          li
            a.dropdown-item(href="#")
              i.ti-wallet
              span Duplicate
          li
            a.dropdown-item(href="#")
              i.ti-wallet
              span Reload
          li.divider
          li
            a.dropdown-item(href="#")
              i.ti-wallet
              span Use by default
          li.divider
          li
            a.dropdown-item(href="#")
              i.fa.fa-power-off
              span Delete
</template>

<script>
export default {
  name: 'Menu',

  data: () => ({
    show: false
  })
}
</script>

<style lang="scss">
  .menu-wrapper{
    display: flex;
    margin-left: auto;
  }
  li {
    display: flex;
    margin-left: auto;
  }

  i {
    margin-right: 10px;
  }

  .content-menu {
    position: fixed;
    right: 0;
    font-size: 30px;
    margin-right: 2%;
    display:flex;
    margin-left:auto
  }
</style>
