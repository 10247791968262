// Components
import TemplateList from './Templates/TemplateList'
import TemplateOverview from './Templates/TemplateOverview'
import TemplateEdit from './Templates/TemplateEdit'
import TemplateContentEditor from './Templates/TemplateContentEditor'
import TemplateContentPreview from './Templates/TemplateContentPreview'

// Sidebar Links
import sidebar from './templates.sidebar'

// Topbar Links
import topbar from '../dymal.topbar'

// Parent
const parent = 'templates'

// Breacrumb Level
const breadcrumbLevel = ({
  hasParent = false,
  hasTo = false,
  name = '',
  plural = true,
}) => ({ hasParent, hasTo, name, plural })

// Breacrumb Root
const breadcrumbRoot = breadcrumbLevel({
  hasParent: true,
  hasTo: true,
  name: 'template',
})

export default [
  // Template List (Default)
  {
    name: 'templates',
    path: '/templates',
    component: TemplateList,
    meta: {
      breadcrumb: [breadcrumbLevel({ name: 'template' })],
      navbutton: {
        icon: 'ti-plus',
        text: 'new_template',
        to: '/templates/new',
        type: 'outline-secondary'
      },
      pageTitle: 'templates',
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // New template
  {
    name: 'template-new',
    path: '/templates/new',
    component: TemplateEdit,
    meta: {
      breadcrumb: [
        {
          ...breadcrumbRoot,
          hasParent: false,
        },
        { name: 'new_template' },
      ],
      pageTitle: 'new_template',
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Template Management
  {
    name: 'template-manage',
    path: '/templates/:id_template/manage',
    component: TemplateEdit,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'manage_template' }],
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Template Content Editor
  {
    name: 'template-content-editor',
    path: '/templates/:id_template/content-editor',
    component: TemplateContentEditor,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'content_editor' }],
      navbutton: {
        icon: 'ti-eye',
        text: 'preview',
        to: '/templates/:id_template/preview',
        type: 'outline-secondary'
      },
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Template Content Preview
  {
    name: 'template-content-preview',
    path: '/templates/:id_template/preview',
    component: TemplateContentPreview,
    meta: {
      breadcrumb: [breadcrumbRoot, { name: 'preview' }],
      navbutton: {
        icon: 'ti-pencil',
        text: 'edit_content',
        to: '/templates/:id_template/content-editor',
        type: 'outline-secondary'
      },
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Template Overview
  {
    name: 'template',
    path: '/templates/:id_template',
    component: TemplateOverview,
    meta: {
      breadcrumb: [breadcrumbRoot],
      navbutton: {
        icon: 'ti-pencil',
        text: 'manage_template',
        to: '/templates/:id_template/manage',
        type: 'outline-secondary'
      },
      pageTitle: ':id_template',
      parent,
      sidebar: { ...sidebar, enabled: false },
      topbar,
    },
  },
  // Redirect: Template List
  {
    path: '/templates/*',
    redirect: '/templates',
  },
]
