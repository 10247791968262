// TODO https://bootstrapious.com/p/timeline
<template lang="pug">
.Timeline
  ja-timeline-item(
    v-for="item, index in timeline"
    :key="`tl_${index}`"
    :date="item.date"
    :events="item.events"
  )
</template>

<script>
export default {
  name: 'Timeline',
  props: {
    timeline: { type: Array, required: true },
  }
}
</script>

<style lang="sass" scoped>

</style>
