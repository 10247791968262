<template lang="pug">
ja-content(:pageTitle='$t("smtp-providers")')
  .ProviderDeck(v-if='providers.length')
    b-card.ProviderCard(
      v-for='(provider, key) in providers',
        :key='key'
      @click='() => handleClickProvider(provider)'
    )
      b-card-img.ProviderCard-Image(
        :src='getImageSrc(provider.provider_type.name)'
      )
      b-card-title.ProviderCard-Title {{ $t(provider.provider_type.name) }}
      .ProviderCard-Tick(v-if='provider.id')
        i.mdi.mdi-check-circle
</template>

<script>
import config from '@config/config.const'
// import { cloneDeep } from 'lodash'
// import { SMTP_PROVIDERS } from '@/const'
import state, { getSmtpProviderTypes } from './store/smtp-providers.store'

import projectState, {
  getProjectSmtpProviders,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

export default {
  name: 'SmtpProviderList',

  computed: {
    smtpProviders: () => projectState.smtpProviders,
    smtpProviderTypes: () => state.smtpProviderTypes,

    providers () {
      const providers = []

      if (!this.smtpProviderTypes.length) return providers

      this.smtpProviderTypes.forEach((provider_type) => {
        let provider = { provider_type }
        const configProvider = this.smtpProviders.find(
          (sp) => sp.provider_type.id === provider_type.id
        )
        if (configProvider) provider = configProvider
        providers.push(provider)
      })

      return providers
    },
  },

  beforeCreate () {
    getProjectSmtpProviders()
    getSmtpProviderTypes()
  },

  methods: {
    getImageSrc (name) {
      return `${config.BASE_URL_PUBLIC}/img/icons/${name}.png`
    },

    handleClickProvider (provider) {
      if (provider.id) {
        this.$set(state, 'smtpProvider', provider)

        return this.$router.push({
          name: 'smtp-provider-manage',
          params: {
            provider_name: provider.provider_type.name,
            id_smtp_provider: provider.id,
          },
        })
      }

      return this.$router.push({
        name: 'smtp-provider-install',
        params: { provider_name: provider.provider_type.name },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
$card: ".ProviderCard"
$pink: #ce3fd0
$purple: #6330e3

#{$card}

.ProviderDeck
  display: grid
  gap: 1.5rem
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr))

#{$card}
  position: relative
  transition: all .15s ease-in-out

  &-Image
    padding:20px

  &-Tick
    bottom: 5px
    color: $purple
    font-size: 20px
    position: absolute
    right: 0px

  &-Title
    margin-top: 20px
    text-align: center
    text-transform: capitalize

  &:hover
    outline: 3px solid $purple
    border: 1px solid $purple
    cursor: pointer

    #{$card}-Text, #{$card}-Title
      color: $purple
</style>
