<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageActions='pageActions')
  b-card(v-if='showForm')
    b-card-text
      form.form(@submit='submit')
        ja-form-field#name(
          v-for='(field, fieldKey) in fields',
          :key='fieldKey',
          :fieldValue='getFieldValue(fieldKey)',
          :on-change='(value) => handleFieldChange(fieldKey, value)',
          :label='field.label',
          :type='field.input'
        )
</template>

<script>
import { INTEGRATION_APP_CONFIG } from '@/const'
import state, {
  createIntegration,
  deleteIntegrationById,
  getIntegrationById,
  initApp,
  updateIntegrationById,
} from './store/integrations.store'

export default {
  name: 'IntegrationEdit',

  data () {
    const isNew = !this.$route.params.id_integration
    const pageActions = []
    const showForm = false

    return { isNew, pageActions, showForm }
  },

  computed: {
    integration () {
      return state.integration
    },

    fields () {
      return INTEGRATION_APP_CONFIG[this.integration.name]
    },

    pageTitle () {
      return this.integration.name
    },
  },

  async created () {
    // Add action buttons to create a new organization
    if (this.isNew) {
      const appName = this.$route.params.app_name
      await initApp(appName)

      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push(
      {
        callback: this.delete,
        icon: 'ti-trash',
        text: 'delete',
        type: 'outline-danger',
      },
      {
        callback: this.update,
        icon: 'ti-save',
        text: 'update',
        type: 'outline-primary',
      }
    )

    await getIntegrationById(this.$route.params.id_integration)
    this.showForm = true
  },

  methods: {
    getFieldValue (fieldName) {
      return this.integration?.config?.[fieldName] || ''
    },

    handleFieldChange (field, value) {
      this.$set(this.integration.config, field, value)
    },

    async delete () {
      await deleteIntegrationById(this.integration.id)

      this.$router.push({
        name: 'integrations',
      })
    },

    async save () {
      const integration = await createIntegration(this.integration)

      if (!integration) return

      this.$router.push({
        name: 'integration-app-manage',
        params: { id_integration: integration.id },
      })
    },

    async update () {
      await updateIntegrationById(this.integration.id, this.integration)
    },

    submit () {
      if (this.isNew) return this.save()
      else this.update()
    },
  },
}
</script>

<style lang="sass"></style>
