<template lang="pug">
ja-content(:pageTitle='$t("users")' :page-subtitle='pageSubtitle')

  ja-data-table(
    v-if='users.length',
    :items='users',
    :fields='fields',
    :pagination='pagination',
    :on-change-page='handleChangePage',
    :on-row-click='(row) => jaDetailView("user", row)'
  )
</template>

<script>
import state, {
  getUsersByOrganizationId,
} from '../Organizations/store/organizations.store'

export default {
  name: 'UserList',

  data () {
    const pageSubtitle = 'Manage the users who have access to your Yamp projects and corresponding permission settings.'

    const fields = [
      { key: 'id', label: 'Id User', class: 'col-240' },
      'name',
      'email',
    ]

    return {
      fields,
      pageSubtitle
    }
  },

  computed: {
    users () {
      return state.users.items || []
    },

    pagination () {
      return state.users.pagination || {}
    },
  },

  beforeCreate () {
    getUsersByOrganizationId(this.$route.params.id_organization)
  },

  methods: {
    handleChangePage (page) {
      getUsersByOrganizationId(this.$route.params.id_organization, {
        pagination: { page },
      })
    },
  },
}
</script>
