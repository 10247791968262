<template lang="pug">
.Badge(:class="variant ? `Badge--${variant}` : variant") {{ $t(value) }}
</template>

<script>
export default {
  name: 'Badge',
  props: {
    variant: { type: String, required: false, default: '' },
    value: { type: String, required: true },
  }
}
</script>

<style lang="sass" scoped>
.Badge
  background-color: #efefef
  border-radius: 10px
  display: inline-block
  font-size: 14px
  padding: 2px 10px
  &--info
    background-color: #d3f0ff
  &--primary
    background-color: #e7fedf
  &--warning
    background-color: #fff8d3
  &--danger
    background-color: #ffd1e8
</style>
