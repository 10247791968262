<template lang="pug">
ja-content(:pageTitle='$t("email-senders")')
  ja-data-table(
    :fields='fields',
    :items='emailSenders',
    :on-row-click='handleRowClick'
  )
</template>

<script>
import projectState, {
  getProjectEmailSenders,
  getProjectSmtpProviders,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

export default {
  name: 'EmailSenderList',

  data () {
    const fields = ['id', 'email', 'name', 'provider', 'amp_validated']
    return { fields }
  },

  computed: {
    emailSenders () {
      if (
        !projectState.emailSenders.length ||
        !projectState.smtpProviders.length
      ) {
        return []
      }

      return projectState.emailSenders.map(
        ({ id, email, name, email_provider_id, amp_validated, ...opts }) => {
          const smtpProvider = projectState.smtpProviders.find(
            (sp) => sp.id === email_provider_id
          )

          const provider = this.$t(smtpProvider.name)

          return {
            id,
            email,
            name,
            provider,
            amp_validated: amp_validated ? '✅' : '❌',
          }
        }
      )
    },
  },

  beforeCreate () {
    getProjectEmailSenders()
    getProjectSmtpProviders()
  },

  methods: {
    handleRowClick (row) {
      this.$router.push({
        name: 'email-sender-manage',
        params: { id_email_sender: row.id },
      })
    },
  },
}
</script>

<style lang="sass" scoped></style>
