<template lang="pug">
.BlockDynamicPhone(:style='cssVars')
  .BlockDynamicPhone-Button
    img.BlockDynamicPhone-Icon(:src='icon')

    .BlockDynamicPhone-CustomText(
      v-if='showCustomText',
      @input='handleChange',
      contenteditable
    ) {{ text }}

    .BlockDynamicPhone-DefaultPhone(
      v-if='!showCustomText',
      :contenteditable='false'
    ) {{ phone }}
</template>

<script>
import CONFIG_CONST from '@config/config.const'
import { DYNAMIC_PHONE_SIZES } from '@plugins/content-editor/const'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      text: this.block.content,
    }
  },

  computed: {
    phone () {
      return this.block.config.defaultPhone
    },

    icon () {
      const { iconColor, iconStyle } = this.block.config

      return `${CONFIG_CONST.BASE_URL_PUBLIC}/amp/phone/phone_${iconStyle}_${iconColor}.png`
    },

    showCustomText () {
      return this.block.config.customText
    },

    width () {
      return this.block.config.alignment === 'full' ? '100%' : 'auto'
    },

    cssVars () {
      const alignment = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
      }

      const shape = {
        pill: '80px',
        round: '5px',
        square: '0px',
      }

      return {
        '--alignment': alignment[this.block.config.alignment],
        '--button-color': this.block.config.colors.button,
        '--border-color':
          this.block.config.colors.border || this.block.config.colors.button,
        '--font-family': this.block.config.fontFamily,
        '--font-size': DYNAMIC_PHONE_SIZES[this.block.config.size].font,
        '--icon-size': DYNAMIC_PHONE_SIZES[this.block.config.size].icon,
        '--padding-bottom': this.block.config.padding.bottom + 'px',
        '--padding-top': this.block.config.padding.top + 'px',
        '--shape': shape[this.block.config.shape],
        '--text-color': this.block.config.colors.text,
        '--width': this.width,
      }
    },
  },

  methods: {
    handleChange (event) {
      this.onChange(event.target.innerText)
    },
  },
}
</script>

<style lang="sass">
.BlockDynamicPhone
  cursor: default
  display: flex
  justify-content: var(--alignment, center)
  padding-bottom: var(--padding-bottom)
  padding-top: var(--padding-top)

  &-Button
    align-items: center
    background-color: var(--button-color)
    border-radius: var(--shape)
    border: 2px solid var(--border-color)
    box-sizing: border-box
    display: grid
    grid-template-columns: min-content 1fr
    color: var(--text-color)
    cursor: text
    font-family: var(--font-family)
    font-size: var(--font-size)
    padding: .65em 1.1em
    text-align: center
    width: var(--width)

  &-DefaultPhone
    letter-spacing: 1px

  &-Icon
    display: block
    height: var(--icon-size)
    margin-right: .5em
    width: var(--icon-size)
</style>
