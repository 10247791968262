// Content Editor
import ContentEditor from './components/ContentEditor'

// Side Panel
import SidePanel from './components/side-panel/SidePanel'
import ColorPicker from './components/side-panel/ColorPicker'
import ImagePicker from './components/side-panel/ImagePicker'
import DiscountCreator from './components/side-panel/DiscountCreator'
import QueryParams from './components/side-panel/QueryParams'
import FieldsConfig from './components/side-panel/FieldsConfig'
import ImageLibrary from './components/side-panel/ImageLibrary'
import InputSearch from './components/side-panel/InputSearch'
import InputText from './components/side-panel/InputText'
import InputWrapper from './components/side-panel/InputWrapper'
import IntegrationDeck from './components/side-panel/IntegrationDeck'
import IntegrationSelector from './components/side-panel/IntegrationSelector'
import OptionsGroup from './components/side-panel/OptionsGroup'
import PanelBody from './components/side-panel/PanelBody'
import PanelHeader from './components/side-panel/PanelHeader'
import ProductList from './components/side-panel/ProductList'
import ProductSelector from './components/side-panel/ProductSelector'
import Range from './components/side-panel/Range'
import Select from './components/side-panel/Select'
import SocialLinks from './components/side-panel/SocialLinks'

// Preview
import ContentCompiler from './components/preview/ContentCompiler'
import ContentPreview from './components/preview/ContentPreview'

// Preview - Blocks
import PreviewBlockButton from './components/preview/blocks/Button'
import PreviewBlockCallme from './components/preview/blocks/Callme'
import PreviewBlockDynamicPhone from './components/preview/blocks/DynamicPhone'
import PreviewBlockGallery from './components/preview/blocks/Gallery'
import PreviewBlockImage from './components/preview/blocks/Image'
import PreviewBlockPrestashopCart from './components/preview/blocks/PrestashopCart'
import PreviewBlockReview from './components/preview/blocks/Review'
import PreviewBlockSocial from './components/preview/blocks/Social'
import PreviewBlockShopifyCart from './components/preview/blocks/ShopifyCart'

// Editing Zone
import EditingZone from './components/editing-zone/EditingZone'
import AddBlockButton from './components/editing-zone/AddBlockButton'
import AddBlockTool from './components/editing-zone/AddBlockTool'
import ContentBlock from './components/editing-zone/ContentBlock'
import ContentRow from './components/editing-zone/ContentRow'
import ContentTable from './components/editing-zone/ContentTable'
import ControlsBlockTool from './components/editing-zone/ControlsBlockTool'
import FallbackSwitch from './components/editing-zone/FallbackSwitch'
import ImageSkeleton from './components/editing-zone/ImageSkeleton'
import NewBlockButton from './components/editing-zone/NewBlockButton'
import NewBlockButtonList from './components/editing-zone/NewBlockButtonList'
import TextEditor from './components/editing-zone/TextEditor'
import Tooltip from './components/editing-zone/Tooltip'

// Editing Zone - Blocks
import BlockButton from './components/editing-zone/blocks/html/Button'
import BlockCallme from './components/editing-zone/blocks/amp/Callme'
import BlockDivider from './components/editing-zone/blocks/html/Divider'
import BlockDynamicPhone from './components/editing-zone/blocks/amp/DynamicPhone'
import BlockGallery from './components/editing-zone/blocks/amp/Gallery'
import BlockImage from './components/editing-zone/blocks/html/Image'
import BlockReview from './components/editing-zone/blocks/amp/Review'
import BlockShopifyCart from './components/editing-zone/blocks/amp/ShopifyCart'
import BlockSocial from './components/editing-zone/blocks/html/Social'
import BlockSpacer from './components/editing-zone/blocks/html/Spacer'
import BlockText from './components/editing-zone/blocks/html/Text'

export default {
  install (Vue) {
    Vue.config.ignoredElements = [/^amp-/] // Ignore AMP ⚡ Components

    Vue.component('CeAddBlockButton', AddBlockButton)
    Vue.component('CeAddBlockTool', AddBlockTool)
    Vue.component('CeBlockButton', BlockButton)
    Vue.component('CeBlockCallme', BlockCallme)
    Vue.component('CeBlockDivider', BlockDivider)
    Vue.component('CeBlockDynamicPhone', BlockDynamicPhone)
    Vue.component('CeBlockGallery', BlockGallery)
    Vue.component('CeBlockImage', BlockImage)
    Vue.component('CeBlockReview', BlockReview)
    Vue.component('CeBlockShopifyCart', BlockShopifyCart)
    Vue.component('CeBlockSocial', BlockSocial)
    Vue.component('CeBlockSpacer', BlockSpacer)
    Vue.component('CeBlockText', BlockText)
    Vue.component('CeColorPicker', ColorPicker)
    Vue.component('CeContentBlock', ContentBlock)
    Vue.component('CeContentCompiler', ContentCompiler)
    Vue.component('CeContentEditor', ContentEditor)
    Vue.component('CeContentPreview', ContentPreview)
    Vue.component('CeContentRow', ContentRow)
    Vue.component('CeContentTable', ContentTable)
    Vue.component('CeControlsBlockTool', ControlsBlockTool)
    Vue.component('CeDiscountCreator', DiscountCreator)
    Vue.component('CeQueryParams', QueryParams)
    Vue.component('CeEditingZone', EditingZone)
    Vue.component('CeFallbackSwitch', FallbackSwitch)
    Vue.component('CeFieldsConfig', FieldsConfig)
    Vue.component('CeImageLibrary', ImageLibrary)
    Vue.component('CeImagePicker', ImagePicker)
    Vue.component('CeImageSkeleton', ImageSkeleton)
    Vue.component('CeInputSearch', InputSearch)
    Vue.component('CeInputText', InputText)
    Vue.component('CeInputWrapper', InputWrapper)
    Vue.component('CeIntegrationDeck', IntegrationDeck)
    Vue.component('CeIntegrationSelector', IntegrationSelector)
    Vue.component('CeNewBlockButton', NewBlockButton)
    Vue.component('CeNewBlockButtonList', NewBlockButtonList)
    Vue.component('CeOptionsGroup', OptionsGroup)
    Vue.component('CePanelBody', PanelBody)
    Vue.component('CePanelHeader', PanelHeader)
    Vue.component('CePreviewBlockButton', PreviewBlockButton)
    Vue.component('CePreviewBlockCallme', PreviewBlockCallme)
    Vue.component('CePreviewBlockDynamicPhone', PreviewBlockDynamicPhone)
    Vue.component('CePreviewBlockGallery', PreviewBlockGallery)
    Vue.component('CePreviewBlockImage', PreviewBlockImage)
    Vue.component('CePreviewBlockPrestashopCart', PreviewBlockPrestashopCart)
    Vue.component('CePreviewBlockSocial', PreviewBlockSocial)
    Vue.component('CePreviewBlockReview', PreviewBlockReview)
    Vue.component('CePreviewBlockShopifyCart', PreviewBlockShopifyCart)
    Vue.component('CeProductList', ProductList)
    Vue.component('CeProductSelector', ProductSelector)
    Vue.component('CeRange', Range)
    Vue.component('CeSelect', Select)
    Vue.component('CeSidePanel', SidePanel)
    Vue.component('CeSocialLinks', SocialLinks)
    Vue.component('CeTextEditor', TextEditor)
    Vue.component('CeTooltip', Tooltip)
  },
}
