import ApiClient from '@api/client'

const client = new ApiClient({ path: '/audiences' })

export const AudiencesService = {
  /**
   * Create Audience
   * @param {Object} data
   */
  async create (data) {
    return await client.post('', data)
  },

  /**
   * Get Audience By Id
   * @param {Number|String} id
   * @return {Array} audiences
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },

  /**
   * Get Contacts By Audience
   * @param {Number|String} id
   * @param {Object} pagination
   * @return {Array} contacts
   */
  async getContactsByAudienceId (id, { page = 1, rows = 20 } = {}) {
    return await client.get(
      `/${id}/contacts?page=${page}&rows=${rows}`
    )
  },

  /**
   * Delete Audience
   * @param {Number|String} id
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },

  /**
   * Import Contacts
   * @param {Number|String} id
   * @param {Object} data
   */
  async importContacts (id, data) {
    return await client.post(`/${id}/import`, data, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  },

  /**
   * Uptade Audience
   * @param {Number|String} id
   * @param {Object} data
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
