export const ENGAGEMENT_DATE_FORMATS = {
  day: 'DD/MM',
  week: 'DD/MM',
  month: 'MMM',
}

export const DEFAULT_METRICS_PERFORMANCE = [
  'sent_emails',
  'open_rate',
  'click_rate',
  'unique_opens',
  'unique_clicks',
  'yamps'
]

export const DEFAULT_METRICS_ENGAGEMENT = [
  'unique_opens',
  'unique_clicks',
  'open_rate',
  'click_rate',
  'click_rate_html',
  'click_rate_amp',
]
