<template lang="pug">
ol.Breadcrumb.breadcrumb
  li.breadcrumb-item(v-for='item in items', :class='{ active: item.active }')
    router-link(v-if='item.to', :to='jaGetRouteWithParams(item.to)') {{ jaGetRouteParam(item.name) }}
    span(v-else) {{ jaGetRouteParam(item.name) }}
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    levels: {
      type: Array,
      required: true,
    },
    vars: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    items () {
      const items = []
      let to = ''

      this.levels.forEach((level, i, levels) => {
        const name = `${level.name}${level.plural ? 's' : ''}`
        const isTheLastOne = i === levels.length - 1
        const idLevelName = `id_${level.name}`
        const routeName = this.vars[idLevelName] || `:${idLevelName}`
        to += level.hasTo ? `/${level.path || name}` : ''

        if (level.hasParent) {
          items.push({ name, to })
          to += level.hasTo ? `/:${idLevelName}` : ''
          items.push({
            active: isTheLastOne,
            name: routeName,
            to: !isTheLastOne ? to : '',
          })
        } else if (level.isId) {
          items.push({ active: isTheLastOne, name: routeName })
        } else {
          items.push({ active: isTheLastOne, name, to: level.hasTo ? to : '' })
        }
      })

      return items
    },
  },
}
</script>

<style lang="stylus" scoped>
.Breadcrumb
  font-weight 500

  .breadcrumb-item::before
    line-height 21px
</style>
