<template lang="pug">
.EditingZone(
  :style='cssVars',
  :class='{ active: isActiveBlock || mouseIsOver }',
  @click='setActiveBlock',
  @mouseover='setMouseIsOver',
  @mouseout='setMouseIsNotOver'
)
  .EditingZone-Content
    ce-content-table(:rows='rows')
    ce-add-block-tool
    ce-controls-block-tool
</template>
<script>
import { isActiveBlock, setActiveBlock } from '@plugins/content-editor/state'

export default {
  props: {
    body: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      mouseIsOver: false,
    }
  },

  computed: {
    cssVars () {
      return {
        '--backdrop-color': this.body.config.colors.backdrop,
        '--canvas-color': this.body.config.colors.canvas,
        '--font-family': this.body.config.fontFamily,
        '--font-size': this.body.config.fontSize,
      }
    },

    isActiveBlock () {
      return isActiveBlock(this.body.id)
    },
  },

  methods: {
    setActiveBlock (event) {
      if (event.target !== event.currentTarget) return
      setActiveBlock(this.body)
    },

    setMouseIsOver (event) {
      this.mouseIsOver = event.target === event.currentTarget
    },

    setMouseIsNotOver () {
      this.mouseIsOver = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.EditingZone
  @include card-shadow
  background: var(--backdrop-color)
  border-radius: 5px
  display: flex
  flex-wrap: wrap
  font-family: var(--font-family, $content-font-family)
  font-size: var(--font-size)
  justify-content: center
  max-width: 800px
  min-height: 100px
  width: 100%

  &.active
    @include active-shadow

  &-Content
    background: var(--canvas-color)
    position: relative

  ::v-deep
    h1, h2, h3, h4, p
      font-family: var(--font-family, $content-font-family)

    h1
      font-size: $h1-fs
    h2
      font-size: $h2-fs
    h3
      font-size: $h3-fs
    h4
      font-size: $h4-fs
    h5
      font-size: $h5-fs
    h6
      font-size: $h6-fs
    p
      font-size: 1em
</style>
