<template lang="pug">
.UserMenu
  .UserAvatar
    i.mdi.mdi-account

  .UserInfo
    .UserName {{ userName }}
    .UserLinks
      router-link.UserLink(:to='{ name: "profile" }') {{ $t('profile') }}
      router-link.UserLink(:to='{ name: "password" }') {{ $t('password') }}
      router-link.UserLink(:to='{ name: "settings" }') {{ $t('settings') }}

  .UserLogout
    ja-button.ml-2(
      :on-click='onLogout'
      :text='$t("logout")'
      icon='mdi mdi-logout'
      type='outline-danger',
    )
</template>

<script>
export default {
  name: 'UserMenu',

  props: {
    onLogout: {
      type: Function,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

.UserMenu
  display: flex
  gap: 16px
  padding: 20px

.UserAvatar
  align-items: center
  background-color: #f3f2f8
  border-radius: 50%
  display: grid
  height: 54px
  justify-items: center
  width: 54px
  i
    color: #777993
    font-size: 40px
    margin-right: 0

.UserLinks
  display: flex
  gap: 16px

.UserLink
  &:hover
    color: $purple-dark
    text-decoration: underline
</style>
