<template lang="pug">
.Register
  form#registerform.form-horizontal.form-material(
    @submit.prevent="register"
    ref='form'
  )
    h3.auth-title.m-b-20 {{ $t('get_started_yamp')}}
    .form-group
      .col-xs-12
        input#name.form-control(
          autocomplete='name'
          name='name',
          type='text',
          required,
          placeholder='Full name'
        )
    .form-group
      .col-xs-12
        input#email.form-control(
          autocomplete='email'
          name='email',
          type='email',
          placeholder='Email'
          required,
        )
    .form-group
      .col-xs-12
        input#password.form-control(
          autocomplete='new-password',
          name='password',
          type='password',
          required,
          placeholder='Password'
        )
    .form-group
      .col-xs-12
        input#repeatedPassword.form-control(
          @change='checkRepeatedPassword'
          autocomplete='new-password',
          name='repeatedPassword',
          type='password',
          required,
          placeholder='Confirm Password'
        )
    .form-group
      ja-checkbox(
        required
        label='I agree to all terms'
        value='agree',
      )
    .form-group.text-center.m-t-20
      .col-xs-12
        button.btn.btn-primary.btn-lg.btn-block.waves-effect.waves-light.auth-button(
          type='submit'
        ) {{ $t('sign_up')}}

    .form-group
      .ml-auto
        p.text-muted
          span {{ $t('to_use_another_account')}}
          span &nbsp;
          router-link(:to='{ name: "login" }') {{ $t('go_to_login')}}
</template>

<script>
import { login } from '@apps/Common/common.store'

import {
  createUser,
  registerUserOrganization
} from '@apps/Accounts/Organizations/Users/store/users.store'

export default {
  name: 'Register',

  computed: {
    token () {
      return this.$route.params.token || false
    }
  },

  methods: {
    checkRepeatedPassword () {
      const {
        password: passwordInput,
        repeatedPassword: repeatedPasswordInput
      } = this.$refs.form.elements

      if (passwordInput.value !== repeatedPasswordInput.value) {
        repeatedPasswordInput.setCustomValidity('Passwords do not match')
      } else {
        repeatedPasswordInput.setCustomValidity('')
      }
    },

    async register (event) {
      const formData = new FormData(event.target)

      const {
        email,
        name,
        password,
      } = Object.fromEntries(formData)

      const newUser = await createUser({ name, email, password })

      if (newUser.errors) return

      const userLogin = await login({
        email,
        password,
      })

      if (!userLogin) return

      if (this.token) {
        await this.registerInvitation(userLogin.id)
        return
      }

      this.$router.push({ name: 'register-organization' })
    },

    async registerInvitation (user_id) {
      await registerUserOrganization({
        token: this.token,
        user_id
      })

      this.$router.push({ name: 'home' })
    },

  },
}
</script>
