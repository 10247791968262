import ApiClient from '@api/client'

const client = new ApiClient({ path: '/email-senders' })

export const EmailSendersService = {
  /**
   * Create Integration
   * @param {Object} data
   */
  async create (data) {
    return await client.post('', data)
  },
  /**
   * Get Integration By Id
   * @return {Object} integration
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },
  /**
   * Delete Integration
   * @param {Number|String} id
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },
  /**
   * Uptade Integration
   * @param {Number|String} id
   * @param {Object} data
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },
}
