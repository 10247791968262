export default {
  enabled: true,
  items: [
    {
      name: 'profile',
      classIcon: 'mdi mdi-account',
      url: '/profile',
      slug: 'profile',
    },
    {
      name: 'password',
      classIcon: 'mdi mdi-key',
      url: '/profile/password',
      slug: 'password',
    },
    {
      name: 'settings',
      classIcon: 'mdi mdi-cog',
      url: '/profile/settings',
      slug: 'settings',
    },
  ],
}
