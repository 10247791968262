<template lang="pug">
// Main wrapper - style you can find in pages.scss
header.topbar

  // Topbar header - style you can find in pages.scss
  nav.navbar.top-navbar.navbar-expand-md.navbar-light
    // Logo
    .navbar-header
      ja-toolbar-logo

    // navbar-collapse
    .navbar-collapse
      ul.navbar-nav.mr-auto.mt-md-0
        li.nav-item
          a.nav-link.nav-toggler.hidden-md-up.text-muted.waves-effect.waves-dark(
            @click='$eventBus.$emit("toggle-sidebar-m")'
          )
            i.mdi.mdi-menu

        li.nav-item
          a.nav-link.sidebartoggler.hidden-sm-down.waves-effect.waves-dark(
            @click='$eventBus.$emit("toggle-sidebar")'
          )
            i.ti-menu

        ja-topbar(
          :items='$route.meta.topbar.items'
        )

      // App Launcher
      ja-app-launcher(v-if='isAdmin')

      // Search Box
      // ja-search-box
</template>

<script>
import { isAdmin } from '@utils'

export default {
  name: 'Header',

  computed: {
    isAdmin,
  },
}
</script>

<style lang="sass" scoped>
header
  box-sizing: border-box
  position: fixed
  width: 100%
</style>
