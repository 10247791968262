<template lang="pug">
ja-content(:pageTitle='$t("activity_report")')

  ja-data-table(
    v-if='items.length'
    :fields='fields',
    :items='items',
    :pagination='pagination',
    :on-change-page='handleChangePage',
    :on-row-click='handleClickRow'
  )
</template>

<script>
import state, { getActivityReport } from './activity.store'

export default {
  name: 'ActivityReport',

  data () {
    const fields = ['id', 'rating', 'place', 'review', 'sentiment']

    return {
      fields,
    }
  },

  computed: {
    items () {
      return state.report?.items || []
    },
    pagination () {
      return state.report?.pagination
    },
  },

  async beforeCreate () {
    await getActivityReport()
  },

  methods: {
    handleChangePage (page) {
      getActivityReport({ page })
    },
    handleClickRow (row) {},
  }
}
</script>
