import { isAuthenticated } from '@utils'
import store from 'store2'

/**
 * Last Route Guard
 */
export const lastRouteGuard = async (to, _from, next) => {
  if (
    // User is authenticated
    isAuthenticated() &&
    // User goes to a not auth route
    !to.meta.isAuth
  ) {
    const userId = store.get('user_id')

    store.set(
      `last_route_${userId}`,
      JSON.stringify({
        fullPath: to.fullPath,
        hash: to.hash,
        name: to.name,
        params: to.params,
        query: to.query,
      })
    )
  }

  next()
}
