<template lang="pug">
.ControlsBlockTool(v-if='show', :style='cssVars')
  .ControlsBlockTool-Toolbar(@click='reset')
    // Buttons: SwapUp Block
    .ControlsBlockTool-Item(v-show='!block.isTheFirstOne', @click='swapUp')
      i.ti-arrow-up

    // Buttons: SwapDown Block
    .ControlsBlockTool-Item(v-show='!block.isTheLastOne', @click='swapDown')
      i.ti-arrow-down

    // Separator
    .ControlsBlockTool-Separator

    // Button: Clone Block
    .ControlsBlockTool-Item(@click='clone')
      i.ti-layers

    // Button: Delete Block
    .ControlsBlockTool-Item(@click='remove')
      i.ti-trash.danger
</template>

<script>
import state, {
  actionCloneActiveBlock,
  actionRemoveActiveBlock,
  actionSwapDownActiveBlock,
  actionSwapUpActiveBlock,
  resetOveredBlock,
} from '@plugins/content-editor/state'

export default {
  computed: {
    block () {
      return state.activeBlock
    },
    show () {
      return !!(this.block && this.block.id !== 'body')
    },
    cssVars () {
      const vars = {}

      if (this.block?.isTheLastOne) {
        vars['--bottom'] = 0
      } else {
        vars['--top'] = this.top
      }

      return vars
    },
    top () {
      return this.block?.positionStart ? this.block.positionStart + 'px' : 0
    },
  },

  methods: {
    clone () {
      actionCloneActiveBlock()
    },
    remove () {
      actionRemoveActiveBlock()
    },
    reset () {
      resetOveredBlock()
    },
    swapDown () {
      actionSwapDownActiveBlock()
    },
    swapUp () {
      actionSwapUpActiveBlock()
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables
@import @plugins/content-editor/styles/mixins

.ControlsBlockTool
  position: absolute
  top: var(--top)
  bottom: var(--bottom)
  left: $content-width
  z-index: 3
  padding-left: 10px
  padding-top: 2px

  &-Toolbar
    box-shadow: $card-box-shadow-intense
    background-color: $white
    border-radius: 2px
    padding: 4px

  &-Item
    align-items: center
    display: flex
    height: 42px
    justify-content: center
    width: 42px
    &:hover
      background-color: $gray-bg
      cursor: pointer
    i
      font-size: 22px
      margin-right: 1px
      &.danger
        color: #FF6395

  &-Separator
    background-color: $gray-bg
    height: 1px
    width: 100%
    margin: 4px auto
</style>
