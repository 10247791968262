export const INPUTTYPES_COMPONENTS = {
  alignment: 'CeOptionsGroup',
  colors: 'CeColorPicker',
  colorScheme: 'CeSelect',
  currency: 'CeSelect',
  customText: 'CeOptionsGroup',
  endpoint: 'CeInputText',
  defaultPhone: 'CeInputText',
  fieldsConfig: 'CeFieldsConfig',
  fontFamily: 'CeSelect',
  fontSize: 'CeRange',
  fullWidth: 'CeOptionsGroup',
  height: 'CeRange',
  iconColor: 'CeSelect',
  iconFigure: 'CeOptionsGroup',
  iconStyle: 'CeOptionsGroup',
  id: 'CeInputText',
  image: 'CeImagePicker',
  imageList: 'CeImagePicker',
  integration: 'CeIntegrationSelector',
  layer: 'CeOptionsGroup',
  link: 'CeInputText',
  name: 'CeInputText',
  padding: 'CeRange',
  price: 'CeInputText',
  product: 'CeProductSelector',
  product_prestashop: 'CeProductSelector',
  product_shopify: 'CeProductSelector',
  productDiscount: 'CeDiscountCreator',
  productTag: 'CeInputText',
  queryParams: 'CeQueryParams',
  rating: 'CeOptionsGroup',
  shape: 'CeOptionsGroup',
  size: 'CeOptionsGroup',
  socialLinks: 'CeSocialLinks',
  source: 'CeInputText',
  spacing: 'CeRange',
  style: 'CeOptionsGroup',
  thickness: 'CeRange',
  variants: 'CeInputText',
}

export const INPUT_LABELS = {
  alignment: 'alignment',
  backdrop: 'backdrop',
  blockBackground: 'block_background',
  border: 'border',
  button: 'button',
  buttonBackground: 'button background',
  buttonText: 'button text',
  canvas: 'canvas',
  colors: 'colors',
  colorScheme: 'color_scheme',
  currency: 'currency',
  customText: 'custom_text',
  endpoint: 'endpoint',
  defaultPhone: 'default_phone',
  fieldsConfig: 'fields_config',
  fontFamily: 'font_family',
  fontSize: 'font_size',
  fullWidth: 'full_width',
  height: 'height',
  iconColor: 'icon_color',
  iconFigure: 'icon_figure',
  icons: 'icons',
  iconStyle: 'icon_style',
  id: 'identifier',
  image: 'image',
  imageList: 'images',
  inputColor: 'input color',
  layer: 'layer',
  line: 'line',
  link: 'link',
  links: 'links',
  padding_bottom: 'padding_bottom',
  padding_top: 'padding_top',
  padding: 'padding',
  productDiscount: 'discount',
  productTag: 'tag',
  queryParams: 'query_params',
  rating: 'rating',
  shape: 'shape',
  size: 'size',
  source: 'source',
  spacing: 'spacing',
  style: 'style',
  text: 'text',
  thickness: 'thickness',
  variants: 'variants',
}

const HEIGHT_RANGE = {
  min: 1,
  max: 100,
}

const FONTSIZE_RANGE = {
  min: 1,
  max: 42,
}

const SPACING_RANGE = {
  min: 6,
  max: 60,
}

export const SELECT_DEFAULTS = {
  colorScheme: 'color',
  currency: 'EUR',
  customText: false,
  fontFamily: 'Roboto',
  fullWidth: false,
}

export const SELECT_OPTIONS = {
  alignment: ['left', 'center', 'right', 'full'],
  colorScheme: ['dark', 'gray', 'light', 'color'],
  currency: ['EUR', 'USD'],
  customText: [false, true],
  fontFamily: [
    'Arial',
    'Arvo',
    'Comic Sans MS',
    'Gilda Display',
    'Merriweather',
    'Open Sans',
    'Playfair Display',
    'Raleway',
    'Roboto Slab',
    'Roboto',
    'Space Mono',
    'Times New Roman',
    'Verdana',
    'Work Sans',
  ],
  fontSize: FONTSIZE_RANGE,
  fullWidth: [false, true],
  height: HEIGHT_RANGE,
  iconColor: [
    'black',
    'blue',
    'green',
    'grey',
    'pink',
    'purple',
    'red',
    'white',
    'yellow',
  ],
  iconStyle: ['circle', 'icon'],
  iconFigure: ['star', 'face', 'number'],
  layer: ['full', 'minimal'],
  padding_bottom: HEIGHT_RANGE,
  padding_top: HEIGHT_RANGE,
  rating: [3, 5, 10],
  shape: ['square', 'round', 'pill'],
  size: ['small', 'medium', 'large'],
  spacing: SPACING_RANGE,
  style: ['solid', 'dashed', 'dotted', 'double'],
  thickness: HEIGHT_RANGE,
}

export const DYNAMIC_PHONE_SIZES = {
  small: {
    icon: '24px',
    font: '18px',
  },
  medium: {
    icon: '28px',
    font: '22px',
  },
  large: {
    icon: '32px',
    font: '26px',
  },
}
