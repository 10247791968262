<template lang="pug">
ja-content(:pageTitle='pageTitle', :pageActions='pageActions')
  b-card(v-if='showForm')
    b-card-text
      // Name
      ja-form-field(
        :field-value='integration.name',
        :on-change='handleNameChange',
        :label='$t("name")',
        ref='inputName',
        name='name',
        type='text'
      )

      // URL
      ja-form-field(
        :field-value='integration.config.url',
        :on-change='(value) => handleFieldChange("url", value)',
        name='url',
        label='URL',
        type='text'
      )

      // Method
      ja-form-field(
        :field-value='integration.config.method',
        :on-change='(value) => handleFieldChange("method", value)',
        :options='methods',
        name='method',
        label='Method',
        type='select'
      )

      // Headers
      ja-form-field.mt-5(label='Headers')
        template(v-slot:value)
          ja-field-set-key-value(v-model='integration.config.headers')

      // QueryParams
      ja-form-field.mt-5(label='Query String Params')
        template(v-slot:value)
          ja-field-set-key-value(v-model='integration.config.queryParams')

      // Payload
      ja-form-field.mt-5(label='Payload')
        template(v-slot:value)
          ja-field-set-key-value(v-model='integration.config.payload')
</template>

<script>
import { sleep } from '@utils'
import { INTEGRATION_APP_CONFIG } from '@/const'
import state, {
  createIntegration,
  deleteIntegrationById,
  getIntegrationById,
  initWebhook,
  updateIntegrationById,
} from './store/integrations.store'

let loadingPromise = null

export default {
  name: 'IntegrationEdit',

  data () {
    const isNew = !this.$route.params.id_integration
    const methods = ['get', 'post', 'put', 'delete']
    const pageActions = []
    const showForm = false

    return { isNew, methods, pageActions, showForm }
  },

  computed: {
    integration: () => state.integration,

    fields () {
      if (!this.integration.id) return {}

      return INTEGRATION_APP_CONFIG[this.integration.name]
    },

    pageTitle () {
      return this.integration?.name || this.$t('new_webhook')
    },
  },

  async created () {
    // Add action buttons to create a new organization
    if (this.isNew) {
      loadingPromise = initWebhook()
      await loadingPromise

      this.pageActions.push({
        callback: this.save,
        icon: 'ti-save',
        text: 'save',
        type: 'outline-primary',
      })

      this.showForm = true

      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push(
      {
        callback: this.delete,
        icon: 'ti-trash',
        text: 'delete',
        type: 'outline-danger',
      },
      {
        callback: this.update,
        icon: 'ti-save',
        text: 'update',
        type: 'outline-primary',
      }
    )

    loadingPromise = getIntegrationById(this.$route.params.id_integration)
    await loadingPromise
    this.showForm = true
  },

  mounted () {
    this.$nextTick(async () => {
      await loadingPromise
      await sleep(1)

      if (this.isNew) {
        this.$refs.inputName.$el.querySelector('input').focus()
      }
    })
  },

  methods: {
    handleFieldChange (field, value) {
      this.$set(this.integration.config, field, value)
    },

    handleNameChange (value) {
      this.$set(this.integration, 'name', value)
    },

    async delete () {
      await deleteIntegrationById(this.integration.id)

      this.$router.push({
        name: 'integrations',
      })
    },

    async save () {
      await createIntegration(this.integration)
    },

    async update () {
      await updateIntegrationById(this.integration.id, this.integration)
    },

    submit () {
      if (this.isNew) return this.save()
      else this.update()
    },
  },
}
</script>

<style lang="sass"></style>
