<template lang='pug'>
.Engagement.card(v-if='show')
  .card-header
    // Metric Customizer
    .Engagement-Customizer
      .Engagement-CustomizerItem {{ $t('metrics') }}:
      .Engagement-CustomizerItem(v-for='(metricLabel, index) in selectedMetrics' :key='index')
        b-form-select(v-model="selectedMetrics[index]" :options="metricOptions")

  .card-body
    // Apex Charts
    apexchart(
      ref='apexchart'
      :options='chartOptions'
      :height='chartHeight'
      :series='shownMetrics'
    )
</template>

<script>
const chartOptions = {
  chart: {
    fontFamily: 'Roboto',
    type: 'line',
    stacked: false,
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: false,
        reset: false,
      },
    },
  },

  colors: [
    '#E8C953',
    '#BDE853',
    '#53E8C8',
    '#5372E8',
    '#7E53E8',
    '#E85373',
  ],

  dataLabels: {
    enabled: false,
  },

  legend: {
    fontSize: '14px',
  },

  stroke: {
    curve: 'straight',
    width: [3, 3, 3, 3, 3, 3],
  },

  xaxis: {},
  yaxis: []
}

export default {
  name: 'Engagement',

  props: {
    defaultMetrics: {
      type: Array,
      required: true,
    },

    labels: {
      type: Array,
      required: true
    },

    metrics: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      default: 'line'
    },
  },

  data () {
    const selectedMetrics = [...this.defaultMetrics]

    return {
      chartHeight: '480px',
      selectedMetrics
    }
  },

  computed: {
    metricOptions () {
      const options = [{ text: this.$t('none'), value: '' }]

      options.push(...this.metrics.map(({ label }) => ({
        text: this.$t(label),
        value: label,
        disabled: this.selectedMetrics.includes(label)
      })))

      return options
    },

    show () {
      return this.metrics?.length
    },

    shownMetrics () {
      return this.selectedMetrics.map(
        label => this.metrics.find(
          metric => metric.label === label
        )
      ).filter(item => item)
    },

    chartXaxis () {
      return {
        categories: this.labels || [],
        tickPlacement: 'between',
      }
    },

    chartYaxis () {
      const isTypeColumn = (metric) => metric.type === 'column'
      const isTypeLine = (metric) => metric.type === 'line'
      const mainMetricTypeColumn = this.shownMetrics.find(metric => isTypeColumn(metric))
      const mainMetricTypeLine = this.shownMetrics.find(metric => isTypeLine(metric))

      const yaxis = this.shownMetrics.map(metric => {
        const columnFormatter = val => parseInt(val)
        const lineFormatter = val => `${val.toFixed(2)}%`
        const matchWithMainColumnLabel = !!(metric.label === mainMetricTypeColumn?.label)
        const matchWithMainLineLabel = !!(metric.label === mainMetricTypeLine?.label)

        return {
          seriesName: isTypeColumn(metric) ? mainMetricTypeColumn?.name : mainMetricTypeLine?.name,
          show: matchWithMainColumnLabel || matchWithMainLineLabel,
          opposite: mainMetricTypeColumn && matchWithMainLineLabel,
          labels: { formatter: isTypeLine(metric) ? lineFormatter : columnFormatter },
        }
      })

      return yaxis
    },

    chartOptions () {
      return {
        ...chartOptions,
        xaxis: this.chartXaxis,
        yaxis: this.chartYaxis,
      }
    },
  },

  methods: {
    isMetricOptionDisabled (metric) {
      return this.selectedMetrics.includes(metric)
    }
  },
}
</script>

<style lang="sass" scoped>
.Engagement

  &-Customizer
    display: flex
    gap: 20px
    align-items: center
    justify-content: flex-start
    gap: 30px

  &-CustomizerItem
    option
      font-weight: 600
      &:disabled
        font-weight: 400
        color: #c2c5d4

  .apexcharts-datalabel
    font-size: 14px
    font-family: 'DM Sans', sans-serif !important
    font-weight: normal

  .apexcharts-legend
    gap: 10px
    padding-top: 20px

  .apexcharts-text
    font-family: Roboto, sans-serif
    font-size: 15px

  .apexcharts-tooltip
    top: -52px !important
    &.apexcharts-theme-light
      background: rgba(255, 255, 255, .8)
    .apexcharts-tooltip-text-y-label
      font-family: Roboto, sans-serif
      font-size: 15px
</style>

<style lang="sass">
.Engagement
  &-CustomizerItem
    option
      font-weight: 500
      color: #4b4c4e
      &:disabled
        font-weight: 400
        color: #9b9eab
</style>
