<template lang="pug">
ja-content(
  :pageActions="pageActions"
  :pageTitle="pageTitle"
)

  b-card(v-if="showForm")
    b-card-text
      form.form(
        @submit="submit"
      )
        // Name
        ja-form-field(
          :fieldValue="fieldName"
          :on-change="handleFieldName"
          ref="inputName"
          id="name"
          label="name"
          type="text"
          placeholder="name_organization_placeholder"
        )
</template>

<script>
import state, {
  createOrganization,
  deleteOrganizationById,
  getOrganizationById,
  updateOrganizationById,
} from './store/organizations.store'

export default {
  name: 'OrganizationEdit',

  data () {
    return {
      fieldName: '',
      pageActions: [],
      pageTitle: this.$t('new_organization'),
      showForm: false
    }
  },

  computed: {
    organization () {
      return state.organization
    },

    isNew () {
      return !this.$route.params.id_organization
    }
  },

  async created () {
    // Add action buttons to create a new organization
    if (this.isNew) {
      this.pageActions.push({ callback: this.save, icon: 'ti-save', text: 'save', type: 'outline-primary' })
      this.showForm = true
      return
    }

    // Add action buttons to edit an organization
    this.pageActions.push({ callback: this.delete, icon: 'ti-trash', text: 'delete', type: 'outline-danger' })
    this.pageActions.push({ callback: this.update, icon: 'ti-save', text: 'update', type: 'outline-primary' })

    // Load the organization data
    await getOrganizationById(this.$route.params.id_organization)
    this.fieldName = this.organization.name
    this.pageTitle = this.organization.name
    this.showForm = true
  },

  mounted () {
    if (this.isNew) {
      this.$refs.inputName.$el.querySelector('input').focus()
    }
  },

  methods: {
    handleFieldName (value) {
      this.organizationName = value
    },

    async delete () {
      await deleteOrganizationById(this.organization.id)
      this.$router.push({ name: 'organizations' })
    },

    async save () {
      const organization = await createOrganization({
        name: this.organizationName
      })

      if (!organization) return

      // Go to the new organization page
      this.$router.push({ name: 'organization', params: { id_organization: organization.id } })
    },

    async update () {
      await updateOrganizationById(this.organization.id, {
        name: this.organizationName
      })
      this.$router.push({ name: 'organization', params: { id_organization: this.organization.id } })
    },

    submit () {
      if (this.isNew) this.save()
      else this.update()
    }
  },
}
</script>
