<template lang="pug">
.Performance(v-if='metrics.length')
  b-row
    b-col(v-for='(metric, index) in shownMetrics' :key='index')
      ja-data-well(
        :data-label='metric.label'
        :data-value='metric.value'
        :amp-value='metric.amp'
        :html-value='metric.html'
        :extra-data='metric.extraData'
      )

  .Performance-ButtonEdit
    ja-button(
      text='edit'
      icon='mdi mdi-pencil'
      type='outline-secondary'
      :on-click='toggleModal'
    )

  // Modal
  b-modal(
    centered,
    modal-class='Performance-Modal',
    ref='modal-performance-customizer',
    size='lg'
    v-model='modalShow'
  )
    // Modal Title
    template(v-slot:modal-title)
      .Performance-ModalHeader.text-first-uppercase
        span {{ $t("customize_metrics") }}

    // Modal Content
    .Performance-Customizer
      .Performance-CustomizerItem(v-for='(metricLabel, index) in modalMetrics' :key='index')
        b-form-select(v-model="modalMetrics[index]" :options="metricOptions" )
        .Performance-Value {{ getMetricValue(metricLabel) }}

    // Modal Footer
    template(v-slot:modal-footer)
      .Performance-ModalFooter
        ja-button(
          :on-click='handleModalSave',
          :text='$t("save")',
          type='outline-primary'
        )
</template>

<script>
export default {
  name: 'Performance',

  props: {
    metrics: {
      type: Array,
      required: true,
    },

    defaultMetrics: {
      type: Array,
      required: true,
    },
  },

  data () {
    const selectedMetrics = [...this.defaultMetrics]
    const modalMetrics = [...this.defaultMetrics]

    return {
      modalShow: false,
      modalMetrics,
      selectedMetrics,
    }
  },

  computed: {
    metricOptions () {
      return this.metrics.map(({ label }) => ({
        value: label,
        text: this.$t(label),
      }))
    },

    shownMetrics () {
      return this.selectedMetrics.map(
        label => this.metrics.find(
          metric => metric.label === label
        )
      )
    },
  },

  methods: {
    getMetricValue (metricLabel) {
      return this.metrics.find(metric => metric.label === metricLabel).value
    },

    handleModalSave () {
      this.$set(this, 'selectedMetrics', [...this.modalMetrics])
      this.toggleModal()
    },

    toggleModal () {
      this.$refs['modal-performance-customizer'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
.Performance
  position: relative

  &-ButtonEdit
    position: absolute
    top: -42px
    right: 0
    z-index: 2

  &-Customizer
    display: flex
    gap: 40px
    justify-content: space-between

  &-CustomizerItem
    width: 100%

  &-Value
    padding: 10px 0 0 10px
    font-size: 28px
</style>
