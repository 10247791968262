<template lang="pug">
ja-content(:pageTitle='$t("dashboard")')

  // Filter
  .row

    // Campaign Selector
    .col-sm-3(v-if='campaignOptions.length')
      .form-group
        label.form-label.form-label-lg {{ $t("campaign") }}

        b-form-select.CampaignSelector(
          :value="campaignId"
          @input='handleChangeCampaign'
        )
          b-form-select-option(
            key=""
            value=""
          ) {{ $t("all") }}

          b-form-select-option(
            v-for='campaign in campaignOptions'
            v-bind='campaign'
            :key="campaign.key"
          ) {{ campaign.text }}

    // TimeFrame
    .col-sm-9
      ja-timeframe(
        v-model='timeframe'
        :on-change='handleChangeTimeframe'
      )

  // Performance
  h2 {{ $t("performance") }}

  ja-performance(
    :default-metrics='defaultMetricsPerformance'
    :metrics='performance'
  )

  // Engagement
  h2 {{ $t("engagement") }}

  ja-engagement(
    v-if='engagement.values'
    :default-metrics='defaultMetricsEngagement'
    :labels='engagement.labels'
    :metrics='engagement.values'
  )
</template>

<script>
import store from 'store2'

import state, {
  getCampaignEngagement,
  getProjectEngagement,
  getProjectPerformance,
  setPerformance,
} from '@/store/analytics.store'

import projectState, {
  getProjectCampaigns,
} from '@apps/Accounts/Organizations/Projects/store/projects.store'

import { DEFAULT_METRICS_ENGAGEMENT, DEFAULT_METRICS_PERFORMANCE } from '@/const/analytics'

const setPerformanceFromCampaign = async (campaignId) => {
  const campaignData = projectState.campaigns.items?.find(campaign => campaign.id === campaignId)?.stats
  if (!campaignData) return
  setPerformance(campaignData)
}

const userId = store.get('user_id')

export default {
  name: 'Dashboard',

  data () {
    let campaignId = ''

    let timeframe = {
      timeframe: 'CURRENT_MONTH',
      startDate: '',
      endDate: '',
    }

    const storedCampaign = store.get(`analytics_campaign_${userId}`)
    const storedTimeframe = store.get(`analytics_timeframe_${userId}`)

    if (storedCampaign) {
      campaignId = JSON.parse(storedCampaign)
    }

    if (storedTimeframe) {
      timeframe = JSON.parse(storedTimeframe)
    }

    return { campaignId, timeframe }
  },

  computed: {
    campaignOptions () {
      if (!projectState.campaigns?.items?.length) return []

      return projectState.campaigns.items.map((campaign) => ({
        key: campaign.id,
        value: campaign.id,
        text: campaign.name,
      }))
    },

    engagement () {
      const values = state.engagement.values?.map(value => {
        return {
          ...value,
          label: value.name,
          name: this.$t(value.name),
        }
      }) || null

      return {
        ...state.engagement,
        values
      }
    },

    defaultMetricsEngagement () {
      return DEFAULT_METRICS_ENGAGEMENT
    },

    defaultMetricsPerformance () {
      return DEFAULT_METRICS_PERFORMANCE
    },

    performance () {
      return state.performance
    },
  },

  async beforeCreate () {
    await getProjectCampaigns({ pagination: { rows: 99 } })

    const storedCampaignId = store.get(`analytics_campaign_${userId}`)

    if (storedCampaignId) {
      setPerformanceFromCampaign(storedCampaignId)
    }
  },

  methods: {
    saveTimeframe () {
      const { startDate, endDate, timeframe } = this.timeframe
      store.set(
        `analytics_timeframe_${userId}`,
        JSON.stringify({
          timeframe,
          startDate,
          endDate,
        })
      )
    },

    handleChangeCampaign (campaignId) {
      this.$set(this, 'campaignId', campaignId)

      store.set(`analytics_campaign_${userId}`, campaignId)

      if (campaignId) {
        this.updateMetricsByCampaign()
      } else {
        this.updateProjectMetrics()
      }
    },

    handleChangeTimeframe () {
      this.saveTimeframe()

      if (this.campaignId) {
        this.updateMetricsByCampaign()
      } else {
        this.updateProjectMetrics()
      }
    },

    updateProjectMetrics () {
      const { startDate, endDate } = this.timeframe
      getProjectEngagement({ startDate, endDate })
      getProjectPerformance({ startDate, endDate })
    },

    updateMetricsByCampaign () {
      const { startDate, endDate } = this.timeframe
      getCampaignEngagement(this.campaignId, { startDate, endDate })

      setPerformanceFromCampaign(this.campaignId)
    }
  }
}
</script>
