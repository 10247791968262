import { formatDate } from '@utils'

/**
 * Transform Data Usage for showing in ApexChart
 * @param {Array} dataUsage
 * @return {Object} Data Usage: Billing Cycles segmented by Projects
 */
export const transformOrganizationDataUsageForApexChart = (dataUsage = []) => {
  const series = []
  const categories = []
  const projectSeries = []
  const categoriesSends = {}

  dataUsage.forEach((billingCycle = {}, seriesDataPosition) => {
    const startDate = formatDate({ date: billingCycle.start_date, format: 'MONTH_SHORT_DAY' })
    const endDate = formatDate({ date: billingCycle.end_date, format: 'MONTH_SHORT_DAY' })
    const category = `${startDate} - ${endDate}`
    let categorySends = 0

    billingCycle.projects.forEach((project = { plan_usage: [] }) => {
      if (!projectSeries[project.id]) {
        projectSeries[project.id] = {
          id: project.id,
          name: project.name,
          dataUsage: new Array(dataUsage.length).fill(0, 0, dataUsage.length),
        }
      }

      projectSeries[project.id].dataUsage[seriesDataPosition] = project.sent_emails
      categorySends += project.sent_emails
    })

    categories.push(category)
    categoriesSends[category] = categorySends
  })

  projectSeries.forEach(project => {
    if (!project) return

    const data = project.dataUsage.map(usage => usage || 0)

    const serie = {
      id: project.id,
      name: project.name,
      label: project.name,
      type: 'column',
      data,
    }

    series.push(serie)
  })

  return {
    series,
    categories,
    categoriesSends,
  }
}

/**
 * Transform Data Usage for showing in a Table
 * @param {Array} dataUsage
 * @return {Array} Summary Data Usage per Billing Cycle
 */
export const transformOrganizationDataUsageForTable = (dataUsage = []) => {
  const rows = []

  dataUsage.forEach((billingCycle = {}) => {
    const startDate = formatDate({ date: billingCycle.start_date, format: 'ISO_DATE' })
    const endDate = formatDate({ date: billingCycle.end_date, format: 'ISO_DATE' })
    let sends = 0

    billingCycle.projects.forEach(project => { sends += project.sent_emails })

    const row = {
      id: billingCycle.id,
      cycle_start_date: startDate,
      cycle_end_date: endDate,
      sends
    }

    rows.push(row)
  })

  return rows
}

/**
 * Transform Biling Cycle for showing in a Table
 * @param {Object} billingCycle
 * @return {Array} Summary Data Usage per Project
 */
export const transformBillingCycleForTable = (billingCycle = {}) => {
  const rows = []

  billingCycle.projects.forEach((project = {}) => {
    let sends = 0

    project.campaigns.forEach((campaign = {}) => {
      sends += (campaign.sent_emails || 0)
    })

    const row = {
      id: project.id,
      name: project.name,
      sends
    }

    rows.push(row)
  })

  return rows
}

/**
 * Transform Biling Cycle for showing in ApexChart
 * @param {Array} dataUsage
 * @return {Object} Biling Cycle: Projects segmented by Campaigns
 */
export const transformBillingCycleForApexChart = (billingCycle = {}) => {
  const { projects = [] } = billingCycle
  const series = []
  const categories = []
  const categoriesSends = {}

  projects.forEach((project = {}, seriesDataPosition) => {
    let categorySends = 0

    const campaignsSerie = project.campaigns.map(campaign => {
      const serie = {
        name: campaign.name,
        label: campaign.name,
        type: 'column',
        data: new Array(projects.length).fill(0, 0, projects.length),
      }

      serie.data[seriesDataPosition] = campaign.sent_emails
      categorySends += campaign.sent_emails

      return serie
    })

    categories.push(project.name)
    categoriesSends[project.name] = categorySends
    series.push(...campaignsSerie)
  })

  return {
    series,
    categories,
    categoriesSends,
  }
}

/**
 * Transform Biling Cycle for showing a summary
 * @param {Object} billingCycle
 * @return {Object} Summary Data Usage per Project
 */
export const transformBillingCycleForSummary = (billingCycle = {}) => {
  const { start_date, end_date, plan } = billingCycle
  const limit = calculateUsageLimit(plan)
  let totalSends = 0

  const dateRange = `${formatDate({ date: start_date, format: 'MONTH_DAY_YEAR' })} - ${formatDate({ date: end_date, format: 'MONTH_DAY_YEAR' })}`
  const name = `${formatDate({ date: start_date, format: 'MONTH_SHORT_DAY' })} - ${formatDate({ date: end_date, format: 'MONTH_SHORT_DAY' })}`

  billingCycle.projects?.forEach((project = {}) => {
    if (project.sent_emails) {
      totalSends += project.sent_emails
      return
    }

    let projectSends = 0
    project.campaigns?.forEach((campaign = {}) => {
      projectSends += (campaign.sent_emails || 0)
    })
    totalSends += projectSends
  })

  const rest = limit - totalSends

  return {
    ...billingCycle,
    name,
    dateRange,
    sends: totalSends,
    limit,
    rest,
  }
}

function calculateUsageLimit (plan = {}) {
  const { sent_emails_interval, sent_emails_limit } = plan
  let limit = 0

  if (sent_emails_interval === 'daily') {
    limit = sent_emails_limit * 30
  } else {
    limit = sent_emails_limit
  }

  return limit
}
