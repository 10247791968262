export const transformAmpBlockEventsMap = (ampBlocks) => {
  const ampBlockEventsMap = {}

  ampBlocks.forEach((ampBlock) => {
    const ampBlockKey = ampBlock.name.toUpperCase()

    const ampBlockValue = ampBlock.events.reduce(
      (events, event) => ({
        ...events,
        [event.name.toUpperCase()]: event.id,
      }),
      {}
    )

    ampBlockEventsMap[ampBlockKey] = ampBlockValue
  })

  return ampBlockEventsMap
}
