<template lang="pug">
.ProductSelector
  // Button: Config App Integration
  template(v-if='integration')
    button.ProductSelector-ConfigButton.btn.btn-outline-primary.btn-block.text-first-uppercase(
      type='button',
      @click='handleShopifyConfig'
    )
      i.mdi.mdi-connection
      span {{ $t("config") }} {{ integration.type.name }}

  // Button: Choose Product
  template(v-if='integration && !value')
    button.ProductSelector-ConfigButton.btn.btn-outline-primary.btn-block(
      type='button',
      @click='openModalProductList'
    ) {{ $t("choose_product") }}

  // Button: Product Summary
  template(v-if='value')
    .ProductSelector-ProductSummary(@click='openModalProductList')
      img(:src='value.image')
      .ProductSelector-ProductName {{ value.name }}
      .ProductSelector-EditButton
        i.mdi.mdi-pencil

  // Modal
  b-modal(
    ref='modal-product-list',
    centered,
    modal-class='ProductSelector-Modal',
    size='lg',
    scrollable,
    v-model='modalShow',
    @hidden='resetSearch'
  )
    // Modal Title
    template(v-slot:modal-title)
      .ProductSelector-ModalHeader.text-first-uppercase
        span {{ $t("cart") }}

    // Filters
    .ProductSelector-Filters
      ce-input-search.ProductSelector-Filters-Input(
        name='search',
        placeholder='Search for product',
        @input='handleSearchChange',
        @keyup.enter='handleSearchEnter'
      )

    // Modal Content
    ce-product-list(
      :products='productList',
      :selected-product.sync='selectedProduct'
    )

    // Modal Footer
    template(v-slot:modal-footer)
      .ProductSelector-ModalFooter
        ja-button(
          :disabled='!areThereAnySelected',
          :on-click='handleModalSelect',
          :text='$t("select_product")',
          type='primary'
        )
</template>

<script>
import { dataTransformers } from '@plugins/content-editor/data-transformers'

import {
  actionGetProductList,
  getIntegration,
} from '@plugins/content-editor/state'

const INTEGRATIONS_MAP = {
  product: 'shopify', // remove asap
  product_shopify: 'shopify',
  product_prestashop: 'prestashop',
}

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [Object, null],
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    const selectedProduct = this.value || null

    return {
      modalShow: false,
      productList: [],
      selectedProduct,
      search: '',
    }
  },

  computed: {
    areThereAnySelected () {
      return !!this.selectedProduct
    },
    integration () {
      return getIntegration(INTEGRATIONS_MAP[this.field])
    },
    integrationType () {
      return this.integration.type.name
    },
    name () {
      return this.selectedProduct?.name || ''
    },
    source () {
      return this.selectedProduct?.image || ''
    },
  },

  methods: {
    handleModalSelect () {
      this.onChange({
        value: this.selectedProduct,
      })

      this.toggleProductList()
    },

    handleSearchChange (e) {
      this.search = e.target.value
    },

    handleSearchEnter () {
      this.loadProductList()
    },

    // TODO: Modal Integration Config
    handleShopifyConfig () {
      let route

      if (this.integration) {
        route = {
          name: 'integration-app-manage',
          params: {
            app_name: this.integrationType,
            id_integration: this.integration.id,
          },
        }
      } else {
        route = {
          name: 'integration-app-install',
          params: {
            app_name: this.integrationType,
          },
        }
      }

      this.$router.push(route)
    },

    async loadProductList () {
      const data = await actionGetProductList({
        search: this.search,
        integration: this.integration,
      })

      this.productList = dataTransformers[this.integrationType](
        data,
        this.integration
      )
    },

    openModalProductList () {
      this.toggleProductList()
    },

    resetSearch () {
      this.search = ''
    },

    toggleProductList () {
      this.$refs['modal-product-list'].toggle()
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@plugins/content-editor/styles/variables"
@import "@plugins/content-editor/styles/mixins"

.ProductSelector
  &-EditButton
    border-radius: 6px
    color: $gray-light
    justify-self: end
    cursor: pointer
    text-align: center
    padding: 0 6px
    transition: all 0.15s ease-in-out
    i
      margin-right: 0
      font-size: 26px
    &:hover
      background-color: $gray-light-bg
      color: $gray-dark

  &-Filters
    margin: 10px 0 20px

  &-ProductName
    padding-left: 20px

  &-ProductSummary
    align-items: center
    display: grid
    grid-template-columns: 1fr 3fr 1fr
    img
      display: block
      max-width: 100%
      object-fit: cover

  &-ConfigButton
    margin-bottom: 20px
    text-transform: capitalize
</style>

<style lang="sass">
.ProductSelector-Modal
  .modal-body
    padding: 16px 32px
</style>
