<template lang="pug">
.NewBlockButton(@click='() => onClick(type)')
  i.NewBlockButton-Icon(:class='[icon]')
  span.NewBlockButton-Name {{ name }}
</template>

<script>
import {
  BLOCKTYPES_ICONS,
  BLOCKTYPES_NAMES,
} from '@plugins/content-editor/const'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    icon () {
      return BLOCKTYPES_ICONS[this.type]
    },
    name () {
      return BLOCKTYPES_NAMES[this.type]
    },
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/content-editor/styles/variables

.NewBlockButton
  cursor: pointer
  padding: 16px 0 10px
  display: grid
  justify-items: center
  row-gap: 6px
  &:hover
    background-color: $gray-light-bg

  &-Icon, &-Name
    display: block

  &-Icon
    font-size: 20px
    line-height: 22px
    margin-right: 0
    &.mdi
      font-size: 24px
    &[class*="ti-"]
      font-size: 22px

  &-Name
    text-align: center
    text-transform: capitalize
</style>
