<template lang="pug">
.ProjectSelection
  h3.auth-title.m-b-20.text-first-uppercase {{ $t('select_project') }}

  ja-projects-tree(
    :organizations='organizations'
    :on-select-project='assingProject'
  )
</template>

<script>
import { assingProject } from '@apps/Accounts/Organizations/Users/store/users.store'
import state, { getCurrentUser } from '@apps/Common/common.store'

export default {
  name: 'ProjectSelection',

  computed: {
    organizations () {
      return state.user?.organizations || []
    },
  },

  async beforeMount () {
    await getCurrentUser()
  },

  methods: {
    async assingProject (project) {
      await assingProject(state.user.id, project.id)
      await getCurrentUser()
      this.$router.push({
        name: 'home',
        query: { _pid: project.id }
      })
    }
  },
}
</script>
