import Vue from 'vue'
import { UserService } from '@api/services/user.service'
import { AuthService } from '@api/services/auth.service'
import { CommonsService } from './services/commons.services'

const TOKEN_EXPIRATION_TIME = 36_000 // seconds
let asyncList = []

// state
const state = Vue.observable({
  errors: [],
  loading: false,
  loadingMessage: '',
  navbuttonEnabled: true,
  pageTitle: '',
  queryParams: {},
  timezones: [],
  user: null,
})

// actions
export const getCurrentUser = async () => {
  const user = await useLoader(UserService.getById, {
    id: store.get('user_id')
  })

  if (!user.id) {
    store.remove('token')
    return false
  }

  setUser(user)
  setQueryParam('id_user', user.name)

  return user
}

export const getTimeZones = async (id) => {
  const timezones = await useLoader(CommonsService.getTimeZones)
  if (!timezones) return
  Vue.set(state, 'timezones', timezones)
  return timezones
}

export const login = async (credentials) => {
  const data = await useLoader(AuthService.login, credentials)

  if (data?.token) {
    store.set('token', data.token, TOKEN_EXPIRATION_TIME)
    store.set('user_id', data.id)
  }

  return data
}
export const logout = async () => {
  await useLoader(AuthService.logout)

  store.remove('token')
  store.remove('user_id')
}

export const cleanErrorList = () => {
  Vue.set(state, 'errors', [])
}

export const removeError = (uid) => {
  const errorIndex = state.errors.findIndex(error => error.uid === uid)
  state.errors.splice(errorIndex, 1)
}

export const requestNewPassword = async (email) => {
  await useLoader(UserService.requestNewPassword, email)
}

export const resetPassword = async ({ token, password }) => {
  await useLoader(UserService.resetPassword, { token, password })
}

export const setErrors = (newErrors) => {
  if (Array.isArray(newErrors)) {
    state.errors.push(...newErrors)
  } else {
    state.errors.push(newErrors)
  }
}

export const setLoading = (value) => (state.loading = value)

export const setLoadingMessage = (value) => (state.loadingMessage = value)

export const setNavbuttonEnabled = (value) => (state.navbuttonEnabled = value)

export const setPageTitle = (value) => (state.pageTitle = value)

export const setQueryParam = (param, value) =>
  Vue.set(state.queryParams, param, value)

export const setUser = (value) => Vue.set(state, 'user', value)

export const useLoader = async (asyncProcess, ...params) => {
  // Add this async process to the async list
  const asyncItemList = Date.now()

  asyncList.push(asyncItemList)

  // Show Loader
  setLoading(true)

  // Get Response
  const { success, errors, data } = await asyncProcess(...params)

  // Remove this async process from the async list
  const asyncItemIndex = asyncList.findIndex((item) => item === asyncItemList)
  delete asyncList[asyncItemIndex]
  asyncList = asyncList.flat()

  // Hide Loader if there are not any async process
  if (!asyncList.length) {
    setLoading(false)
    setLoadingMessage('')
  }

  if (!success) {
    setErrors(errors)
    return false
  }

  // Return response data
  return data
}

export default state
